import { useContext } from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite';

/**
 * Визуальный компонент отображает информацию об авторизированном пользователе
 * 
 * @returns {HTMLDivElement} Html-разметку информации об авторизированном пользователе
 * 
 * @see [Вызов компонента](./components_header_userInfo_UserInfoContainer.js.html#line103)
 */
function UserInfo() {
    const { userStore } = useContext(Context)

    return (
        <div className="tw-relative tw-mt-6 tw-flex-1 tw-px-4 sm:tw-px-6 tw-overflow-y-auto">
            <dl>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Имя</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.user?.first_name}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Фамилия</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.user?.last_name}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Логин</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.user?.username}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Email</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.user?.email}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Телефон</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.user?.phone_number}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ID пользователя</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.user?.id}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ID организации</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.user?.company?.id}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Полное название организации</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.user?.company?.name}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Сокращенное название юр. лица</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.abbreviation}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Полное название юр. лица</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.name}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ИНН юр. лица</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.tin}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">КПП юр. лица</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.rrc}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ОГРН юр. лица</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.msrn}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ID юр. лица</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.id}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Руководитель юр. лица</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.owner}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Должность руководителя</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.owner_position}</dd>
                </div>
                <div className="tw-px-2 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Адрес регистрации юр. лица</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.registration_address}</dd>
                </div>
            </dl>
        </div>
    )
}

export default observer(UserInfo)