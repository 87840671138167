import { Tab } from '@headlessui/react'
import Spinner from '../../../assets/Spinner'
import { observer } from 'mobx-react-lite'
import NestedModelMenu from './NestedModelMenu'
import NestedTableFieldsList from './NestedTableFieldsList'


/**
 * Визуальный компонент отображает файлы, сообщения и вложенные таблицы выбранной записи 
 * 
 * @param {Boolean} readOnly Признак "только чтение" для файлов
 * @param {Object[]} list Массив записей таблицы
 * @param {Object} selectedNestedDataModel Выбранная вложенная таблица
 * @param {Function} setNestedDataModels Изменение в массиве вложенных таблицы
 * [setNestedDataModels](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line47),
 * [setNestedDataModels](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line104),
 * [setNestedDataModels](./components_main_page_controller_data_object_add_AddDataObjectContainer.js.html#line45),
 * [setNestedDataModels](./components_main_page_controller_data_object_add_DuplicateDataObjectContainer.js.html#line52),
 * [setNestedDataModels](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line55)
 * @param {Object} selectedNestedDataObject Выбранная запись вложенной таблицы
 * @param {Function} setSelectedNestedDataObject Изменение выбранной записи вложенной таблицы
 * [setSelectedNestedDataObject](./components_tabs_DataObjectTabs.js.html#line56)
 * @param {Object[]} nestedDataModels Массив вложенных таблиц
 * @param {Boolean} isDocumentPage Признак нахождения на странице документов
 * @param {Function} onChange Сторонняя функция для изменений в массиве вложенных таблицы
 * [handleChangeNestedTables](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line199),
 * [handleChangeProcesses](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line189)
 * @param {Boolean} isNestedLoading Признак загрузки вложенных таблиц
 * @param {Object} panel Панель, где находятся элементы
 * 
 * @returns {HTMLDivElement} Html-разметку файлов, сообщений и вложенных таблиц выбранной записи 
 * с использованием визуальных компонентов {@link NestedTableFieldsList}, {@link NestedModelMenu}, {@link Spinner}
 * 
 * @see [Вызов компонента](./components_tabs_DataObjectTabs.js.html#line138)
 */
const NestedTableTabPanel = ({readOnly, list, selectedNestedDataModel, setNestedDataModels, selectedNestedDataObject, 
                            setSelectedNestedDataObject, nestedDataModels, isDocumentPage, onChange, isNestedLoading, panel}) => {
    return (
        <Tab.Panel
            className={`${isDocumentPage
                            ?   readOnly
                                    ? 'tw-h-full'
                                    : 'tw-h-[calc(100%_-_2.5rem)]'
                            :   ''} 
                        tw-w-full tw-ring-white tw-ring-opacity-60 tw-ring-offset-2
                        focus:tw-outline-none focus:tw-ring-offset-0`}
        >
            {!isNestedLoading && selectedNestedDataModel
                ?
                    <>
                        {!readOnly && 
                            <NestedModelMenu
                                nestedDataModels={nestedDataModels}
                                selectedNestedDataModel={selectedNestedDataModel}
                                selectedNestedDataObject={selectedNestedDataObject}
                                setNestedDataModels={setNestedDataModels}
                                setSelectedNestedDataObject={setSelectedNestedDataObject}                                
                                isDocumentPage={isDocumentPage}
                                onChange={onChange}
                            />
                        }
                        <NestedTableFieldsList
                            readOnly={readOnly}
                            dataModel={selectedNestedDataModel}
                            dataObjects={list}
                            nestedDataModels={nestedDataModels}
                            selectedNestedDataObject={selectedNestedDataObject}
                            setNestedDataModels={setNestedDataModels}
                            setSelectedNestedDataObject={setSelectedNestedDataObject}
                            isDocumentPage={isDocumentPage}
                            onChange={onChange}
                            panel={panel}
                        />
                    </>
                : <Spinner size='small'/>
            }
        </Tab.Panel>
    )
}

export default observer(NestedTableTabPanel)