import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ExporterService from '../../../../../services/ExporterService'
import Spinner from '../../../../../assets/Spinner'
import { toast } from 'react-toastify'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает модальное окно печати (экспорта) выбранной записи в файл формата PDF или ODT
 * с выбором печатной формы из списка существующих печатных форм (если форма одна, то сразу печать)
 * 
 * @param {Boolean} isVisible Признак видимости (отображения) модального окна
 * @param {Object} dataModelID ID таблицы, к которой принадлежит экспортируемая запись
 * @param {Boolean} isPrinting Признак видимости (отображения) модального окна
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке экспорта
 * [handlePrintDocumentClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line227)
 * [handlePrintDocumentClick](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line125)
 * @param {Function} onCloseModal Обработчик клика мыши на кнопке отмены экспорта
 * [arrow function](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line594)
 * [arrow function](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line292)
 * 
 * @returns {JSXElement} Визуальный компонент Dialog из библиотеки {@link https://headlessui.com/|@headlessui}
 * с использованием визуального компонента {@link DataTypeListBox}
 * 
 * @see [Вызов компонента DataObjectListContainer,](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line589)
 * [EditDataObjectContainer](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line287)
 */
const PrintDocumentModal = ({isVisible, dataModelID, isPrinting, onSubmitClick, onCloseModal}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [exporters, setExporters] = useState([])

    useEffect( () => {
        if (isVisible) {
            const noResponse = setTimeout(() => toast.error('Сервис экспорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut }
            ), responseTimeOut)
            
            setIsLoading(true)
            ExporterService
                .getExporters(50)
                .then(data => {
                    clearTimeout(noResponse)
                    const filteredList = data.filter(item => item.meta.data_model_id === dataModelID)
                    if (filteredList.length === 1)
                        onSubmitClick(filteredList[0])
                    else
                        setExporters(filteredList)
                })
                .catch(error => {
                    clearTimeout(noResponse)
                    showErrorToast(error, 'fetching', '')
                })
                .finally(setIsLoading(false))
        }
    }, [isVisible])

    const focusRef = useRef(null)

    const keydownHandler = ({ key }) => {
        switch (key) {
          case 'Escape':
            onCloseModal();
            break;
          default:
        }
      };  

    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    });

    if (isLoading) {
        return  <Spinner/>
    }

    return (
        <Transition.Root show={isVisible} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" initialFocus={focusRef} onClose={onCloseModal}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
                    <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="tw-ease-out tw-duration-300"
                            enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                            enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leave="tw-ease-in tw-duration-200"
                            leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        >
                            <Dialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-text-left tw-shadow-xl 
                                                    tw-transition-all sm:tw-my-4 sm:tw-w-full sm:tw-max-w-4xl">
                                <div className="tw-px-4 tw-pb-4 tw-pt-5 sm:tw-p-6">
                                    <div className="sm:tw-flex sm:tw-items-start">
                                        <div className="tw-h-80 tw-flex tw-flex-col tw-w-full tw-mt-3 tw-text-left sm:tw-mt-0 sm:tw-text-left">
                                            <Dialog.Title as="h3" className="tw-text-lg tw-font-semibold tw-py-2 tw-mb-2 tw-text-gray-900 tw-border-b ">
                                                Скачать файл PDF/ODT
                                            </Dialog.Title>
                                            <div className="tw-grow tw-overflow-y-auto tw-flex tw-flex-col tw-w-full tw-py-2 tw-rounded-md">
                                                { !isPrinting
                                                    ?
                                                    <>
                                                        {exporters.length > 0 ? 
                                                                exporters.map((exporter, index) => 
                                                                <div key={index} className='tw-mt-1 tw-mr-1 tw-flex tw-flex-row tw-justify-between tw-items-center'>
                                                                    <span>{exporter.name}</span>
                                                                    <button
                                                                        type='button'
                                                                        className='tw-text-sm tw-px-2 tw-py-1 tw-text-white tw-bg-gray-500 tw-rounded-md'
                                                                        onClick={() => onSubmitClick(exporter)}
                                                                    >
                                                                        Экспортировать
                                                                    </button>
                                                                </div>
                                                            )
                                                        : 
                                                            <div className="tw-p-4 tw-text-md tw-text-center">
                                                                <p>Не удалось найти печатные формы для данной таблицы</p>
                                                            </div>
                                                        }
                                                    </>
                                                    :
                                                        <div className="tw-p-4 tw-text-md tw-text-center">
                                                            <p>Выполняется скачивание файла</p>
                                                            <p>Ожидайте завершения формирования файла...</p>
                                                        </div>
                                                }
                                            </div>
                                            { !isPrinting &&
                                                <div className=" tw-w-full tw-mt-2 tw-pt-2 tw-flex tw-justify-center tw-border-t">
                                                    <button
                                                        ref={focusRef}
                                                        type="button"
                                                        className="tw-inline-flex tw-justify-center tw-border-2 tw-border-gray-700 tw-rounded-md 
                                                                tw-bg-white tw-mx-2 tw-px-3 tw-my-2 tw-py-1 tw-text-sm tw-font-semibold
                                                                tw-text-gray-700 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-200"
                                                        onClick={onCloseModal}
                                                    >
                                                        Закрыть
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default PrintDocumentModal