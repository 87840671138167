import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ExclamationCircleIcon, InformationCircleIcon} from '@heroicons/react/20/solid'
import { toast } from 'react-toastify'
import { setReferenceValue } from '../../../../../../config/constTypes'
import {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"
import { Tooltip } from 'react-tooltip'
import DirectoryContainer from '../../../common/panels/directory/DirectoryContainer'
import StageButtons from '../../stage/StageButtons'
import { Context } from '../../../../../..'
import { observer } from 'mobx-react-lite'
import DataReferenceInput from '../../../common/inputs/DataReferenceInput'
import SuggestionsList from './SuggestionsList'
import DocumentService from '../../../../../../services/DocumentService'
import { showErrorToast } from '../../../../../../functions/errorHandlers'
import FormErrorToastPanel from '../../../common/panels/toast/FormErrorToastPanel'

registerLocale("ru", ru)

/**
 * Визуальный компонент отображает форму для выбора неактуальных показателей значимости и добавления обоснований к ним
 * 
 * @returns {JSXElement} Html-разметку формы для выбора неактуальных показателей значимости и добавления обоснований к ним 
 * с использованием визуальных компонентов {@link DataReferenceInput}, {@link SuggestionsList}, {@link StageButtons}, {@link DirectoryContainer},
 * {@link FormErrorToastPanel}
 * 
 * @see [Вызов компонента](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line156)
 */
const SignificanceIndicatorsForm = () => {   
    const {
        control,
        register,
        unregister,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm()

    const { categorizingCIIStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [suggestionsRuleId, setSuggestionsRuleId] = useState('')
    const [deletedIndicators, setDeletedIndicators] = useState([])
    const [isSavedRecord, setIsSavedRecord] = useState(true)
    const [isEditMode, setIsEditMode] = useState(false)
    const [formFields, setFormFields] = useState(null)
    const [formNestedModels, setFormNestedModels] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    register('data.commission__irrelevant_indicators_3.0', { 
        required: false,
        shouldUnregister: true,
        value: categorizingCIIStore.commissionID
    })

    const loadSavedRecord = () => {
        if (categorizingCIIStore.irrelevantIndicatorsValues) {
            categorizingCIIStore.irrelevantIndicatorsValues.forEach(item => {
                setText(item.data['indicator__irrelevant_indicators_2'].value.values[0].id, 
                        item.data['justification_of_inapplicability__irrelevant_indicators_2'].value, true)
            })
            if (categorizingCIIStore.irrelevantIndicatorsValues.length > 0) {
                setIsEditMode(true)
                setIsSavedRecord(true)
            }
        } else {
            setFormFields(categorizingCIIStore.activeDataModel.fields)
        }
    }

    const handleSelectClick = (dataModelID) => {
        setSelectedDataModelID(dataModelID)
        setIsSelectFormOpen(true)
    }

    const handleDoubleClick = (item) => {
        if(item.data.code.value !== '09'){
            setIsSavedRecord(false)
            let selectedIndicatorItem = {
                data: {
                    indicator__irrelevant_indicators_2: {
                        value: setReferenceValue(item),
                        type: 'reference',
                        ref_model_ids: [item.data_model_id]
                    },
                    code: {value: item.data.code.value},
                    name: {value: item.data.name.value},
                },
                format: item.format,
                status: 'added'
            }
            categorizingCIIStore.setIrrelevantIndicatorsValues([...categorizingCIIStore.irrelevantIndicatorsValues
                .filter((selectedIndicator) => selectedIndicator.id !== "0"), selectedIndicatorItem])
        } else {
            toast.error(<div>Вы не можете добавить этот показатель!</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }

    const handleCloseClick = () => {
        docStore.setIsDetailView(false)
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    const handleBackClick = () => {
        categorizingCIIStore.loadIrrelevantIndicators()
        categorizingCIIStore.goPrevStage()
    }

    const onDeleteClick = (id) => {
        unregister("data.indicator__irrelevant_indicators_2." + id)
        unregister("data.justification_of_inapplicability__irrelevant_indicators_2." + id)
        
        categorizingCIIStore.setIrrelevantIndicatorsValues(categorizingCIIStore.irrelevantIndicatorsValues.filter((selectedIndicator) => {
            if(selectedIndicator.data['indicator__irrelevant_indicators_2'].value.values[0].id === id && selectedIndicator.status !== 'added')
                setDeletedIndicators([...deletedIndicators, {record_id: selectedIndicator.record_id}])
            return selectedIndicator.data['indicator__irrelevant_indicators_2'].value.values[0].id !== id
        }))

        setIsSavedRecord(false)
    }

    const handleForwardClick = async (form) => {
        setIsLoading(true)
        let indicatorFields = [],
        justificationFields = []
        const dataObject = {}
        dataObject.data_model_id = categorizingCIIStore.activeDataModel.id
        dataObject.data = {
            'irrelevant_indicators__irrelevant_indicators_3': {
                upsert: [],
                remove: []
            } 
        }
        
        for (const [key, value] of Object.entries(form.data)) {
            if (key === 'indicator__irrelevant_indicators_2') {
                value.forEach((key, value) => {
                    indicatorFields.push({key, value})
                })
            }
            if (key === 'justification_of_inapplicability__irrelevant_indicators_2'){
                value.forEach((key, value) => {
                    justificationFields.push({key, value})
                })             
            }
        }

        categorizingCIIStore.irrelevantIndicatorsValues.forEach(item => {
            if(item.status === 'added'){
                justificationFields.forEach(field => {
                    if(field.value.toString() === item.data['indicator__irrelevant_indicators_2'].value.values[0].id)
                        dataObject.data['irrelevant_indicators__irrelevant_indicators_3'].upsert.push({
                            data:{
                                'indicator__irrelevant_indicators_2': [item.data['indicator__irrelevant_indicators_2'].value.values[0].record_id],
                                'justification_of_inapplicability__irrelevant_indicators_2': field.key
                            }
                        })                
                })
            }
            if(item.status === 'edited'){
                justificationFields.forEach(field => {     
                    if(field.value.toString() === item.data['indicator__irrelevant_indicators_2'].value.values[0].id)
                        dataObject.data['irrelevant_indicators__irrelevant_indicators_3'].upsert.push({
                            record_id: item.record_id,
                            data:{
                                'indicator__irrelevant_indicators_2': [item.data['indicator__irrelevant_indicators_2'].value.values[0].record_id],
                                'justification_of_inapplicability__irrelevant_indicators_2': field.key
                            }
                        })                
                })
            }
        })

        if (deletedIndicators)
            dataObject.data['irrelevant_indicators__irrelevant_indicators_3'].remove = deletedIndicators.filter(item => item.record_id !== undefined)

        dataObject.data['commission__irrelevant_indicators_3'] = form.data['commission__irrelevant_indicators_3'].map(item => item.values[0].record_id)

        try{
            let isForcedLastStageUpdate = false
            if (!isSavedRecord) {
                if (isEditMode) {
                    const indicatorRecord = categorizingCIIStore.irrelevantIndicators.values[0]
                    const response = await DocumentService.updateDataObject(indicatorRecord.record_id, dataObject)
                    categorizingCIIStore.setIrrelevantIndicators({ ...categorizingCIIStore.irrelevantIndicators, values: [{...indicatorRecord, id: response.id}] })
                    categorizingCIIStore.loadSavedProject(categorizingCIIStore.project.id)

                    // формирование необходимости заново сохранить и напечатать существующие акты категорирования
                    if (categorizingCIIStore.objectsCategorization && categorizingCIIStore.objectsCategorization.length) {
                        isForcedLastStageUpdate = true
                        const objectsCategorizationRequests = categorizingCIIStore.objectsCategorization.map(objectCategorization => {
                            return DocumentService.updateDataObject(objectCategorization.record.record_id, { 
                                data: { 'date_of_print_act__act_of_categorir': null, 'is_data_current__act_of_categorir': false }
                            })
                        })
        
                        Promise.all(objectsCategorizationRequests)
                            .then(() => {
                                categorizingCIIStore.loadCategorizingActs(categorizingCIIStore.project, categorizingCIIStore.resourceList.dataObjects)
                            })
                    }    
                } else {
                    const response = await DocumentService.createDataObject(dataObject)
                    await categorizingCIIStore.updateProject('irrelevant_indicators_for_stgs__stages_of_categorization', [response.record_id], true)
                }

                toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            }

            categorizingCIIStore.goNextStage(isForcedLastStageUpdate)

        } catch (error) {
            setIsLoading(false)
            showErrorToast(error, 'saving', '')
        }
    }

    // Функция выбора поля для ввода/редактирования значения в зависимости от типа поля записи
    const selectFieldInput = (item, quantity, isError) => {
        switch(item.validator_type) {
            case 'one':
            case 'many':
                return <Controller
                            name={'data.' + item.tech_name + '.' + quantity}
                            control={control}
                            rules={ {validate: value => value.id !== '0'} }
                            defaultValue={{value : {format: "", values: []}}}
                            render={({field}) =>{
                            return <DataReferenceInput
                                    value={field.value}
                                    onChange={(e) => field.onChange(e)}
                                    onSelectClick={() => handleSelectClick("significance_indicators")}
                                    onClearClick={() => onDeleteClick(quantity)}
                                    isNested={false}
                                    isRowSelected={false}
                                    isCellSelected={true}
                                    readOnly={false}
                                />}
                            }
                        />
            default:
                return <textarea
                            onSelect={(e) => {
                                e.preventDefault();
                                categorizingCIIStore.setIrrelevantIndicatorsValues(categorizingCIIStore.irrelevantIndicatorsValues.map((selectedIndicator) => {
                                    if(selectedIndicator.data['indicator__irrelevant_indicators_2'].value.values[0].id === quantity 
                                            && selectedIndicator.status === 'saved'
                                    )
                                        return {...selectedIndicator, status: 'edited'}
                                
                                        return selectedIndicator
                                }));
                                setIsSavedRecord(false)
                            }}
                            className={`tw-w-full tw-h-12 tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                                ${isError ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                            `}
                            {...register('data.' + item.tech_name + '.' + quantity, { 
                                required: true,
                                value:  item.default || '',
                            })} 
                        />
        }
    }

    const setText = (id, text, isInitial) => {
        setValue('data.justification_of_inapplicability__irrelevant_indicators_2.' + id, text)
        clearErrors('data.justification_of_inapplicability__irrelevant_indicators_2.' + id)
        setIsSavedRecord(false)
        categorizingCIIStore.setIrrelevantIndicatorsValues(categorizingCIIStore.irrelevantIndicatorsValues.map((selectedIndicator) => {
            if (selectedIndicator.data['indicator__irrelevant_indicators_2'].value.values[0].id === id && selectedIndicator.status === 'saved')
                return {...selectedIndicator, status: isInitial ? 'saved' : 'edited'}
            
            return selectedIndicator
        }))
    }

    useEffect(() => {
        categorizingCIIStore.irrelevantIndicatorsValues.forEach((indicator) => {
            clearErrors('data.indicator__irrelevant_indicators_2.' + indicator.data['indicator__irrelevant_indicators_2'].value.values[0].id)
            setValue('data.indicator__irrelevant_indicators_2.' + indicator.data['indicator__irrelevant_indicators_2'].value.values[0].id
                ,  indicator.data['indicator__irrelevant_indicators_2'])
        })
    }, [categorizingCIIStore.irrelevantIndicatorsValues])

    useEffect(() => {
        if(errors.data){
            toast.error(<FormErrorToastPanel errors={errors.data} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors.data])

    useEffect(() => {
        DocumentService.getOneDataModel('significance_indicators')
            .then(response => {
                response.nested_models.forEach(nestedModel => {    
                    if(nestedModel.id === 'justification_of_inapp_vt'){
                        setSuggestionsRuleId(nestedModel.rule_id)
                    }
                })
            })
        categorizingCIIStore.getActiveDataModel('irrelevant_indicators_3', setFormNestedModels)
    }, [])
 
    useEffect(() => {
        if (formNestedModels && formNestedModels.length > 0) {
            if(formNestedModels[0].id === 'irrelevant_indicators_2'){
                setFormFields(formNestedModels[0].fields)
                if (categorizingCIIStore.irrelevantIndicators && categorizingCIIStore.irrelevantIndicators.values.length > 0)
                    loadSavedRecord()
            }
        }
    }, [formNestedModels])

    return (
        <>
            <div className='tw-grow tw-p-6 tw-overflow-auto'>
                <form className='tw-flex tw-flex-col tw-h-full tw-rounded-md tw-bg-white'>
                
                    <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-mx-auto tw-mb-1 tw-py-1'>
                        <button 
                            className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                        hover:tw-bg-gray-600 hover:tw-border-gray-600 focus-visible:tw-outline focus-visible:tw-outline-2 
                                        focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600 tw-mr-4'
                            onClick={(e) => {e.preventDefault(); handleSelectClick("significance_indicators")}}
                        >
                            Выбрать показатели
                        </button>
                    </div>
                    
                    {categorizingCIIStore.irrelevantIndicatorsValues.slice()
                        .sort((a, b) => a.data['indicator__irrelevant_indicators_2'].value.values[0].code < b.data['indicator__irrelevant_indicators_2'].value.values[0].code ? -1 : 1)
                        .map((indicator) => {
                        return (
                            <div key={indicator.data['indicator__irrelevant_indicators_2'].value.values[0].id} className="tw-relative tw-pr-6">
                                { formFields?.slice().map((fieldItem, index) => {
                                    let isError = false
                                    if(errors?.data) {
                                        Object.entries(errors.data).map(([key, value]) => {
                                            if(key === fieldItem.tech_name && value[indicator.data['indicator__irrelevant_indicators_2'].value.values[0].id])
                                                isError = true
                                        })
                                    }
                                    return ( !fieldItem.hide && fieldItem.type !== 'include' &&
                                        <div key={index} className="tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-2">
                                                <span
                                                    data-tooltip-id="significance-indicators-form-tooltip"
                                                    data-tooltip-content={fieldItem.alias}
                                                    data-tooltip-delay-show={1000}
                                                >
                                                    {fieldItem.alias}
                                                </span>
                                                <span
                                                    data-tooltip-id="significance-indicators-form-tooltip" data-tooltip-content={fieldItem.description} data-tooltip-delay-show={250}
                                                >
                                                    { fieldItem.description &&
                                                        <InformationCircleIcon className="tw-h-5 tw-w-5 tw-text-gray-500" aria-hidden="true"/>
                                                    }
                                                </span>
                                                <span>
                                                    { isError && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                                                </span>
                                            </dt>
                                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                { selectFieldInput(fieldItem, indicator.data['indicator__irrelevant_indicators_2'].value.values[0].id, isError) } 
                                            </dd>
                                        </div>
                                    )
                                })}

                               <div className='tw-absolute tw-inset-y-0 -tw-right-2 tw-flex tw-flex-col tw-pb-3 tw-pt-16'>
                                    <SuggestionsList
                                        id={indicator.data['indicator__irrelevant_indicators_2'].value.values[0].id}
                                        setText={setText}
                                        suggestionsRuleId={suggestionsRuleId}
                                        tooltipId={"significance-indicators-form-tooltip"}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </form>
            </div>
            <StageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleSubmit(handleForwardClick)}
                disabled={errors.data || isLoading}
            />
            <DirectoryContainer
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
                chosenDataObjectsList={categorizingCIIStore.irrelevantIndicatorsValues}
                isChosenObjectDuplicationForbidden={true}
                isMultiSelect={true}
            />
            <Tooltip id="significance-indicators-form-tooltip" place="top" className='tw-max-w-xl'/>
        </>
    )
}

export default observer(SignificanceIndicatorsForm)