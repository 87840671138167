import React from 'react'
import { getFieldValue, getFormattedDate } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает содержимое выбранной записи (текущей или архивной)
 * @returns {HTMLDivElement} Html-разметку информации о выбранной печатной форме
 * 
 * @see [Вызов компонента](./components_main_page_controller_common_panels_directory_DirectoryPanel.js.html#line99)
 */
const DataObjectInfo = ({selectedDataObject}) => {    
    return (
        <div id='data-object-info' className='tw-h-[calc(100%_-_3rem)] tw-overflow-auto'>
            <dl>
                { Object.values(selectedDataObject.data).sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                        return ( !fieldItem.hide &&
                            <div key={index} className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                                <dt className="tw-text-sm tw-font-medium tw-text-gray-500">
                                    {fieldItem.full_name}
                                </dt>
                                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                    {getFieldValue(fieldItem)}
                                </dd>
                            </div>
                    )}
                )}
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500"></dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0"></dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Файлы</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                        { selectedDataObject.system_data.files &&
                            selectedDataObject.system_data.files.map((file, index) => { return <span key={index}>{file.metadata.name + '.' + file.metadata.extension}<br/></span>})
                        }
                    </dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500"></dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0"></dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Дата изменения записи</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{getFormattedDate(selectedDataObject.created, 'dd.MM.yyyy HH:mm:ss')}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ID изменения</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{selectedDataObject.id}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ID записи</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{selectedDataObject.record_id}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Автор изменения</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                        { selectedDataObject.author
                            ?   selectedDataObject.author.first_name + ' ' + selectedDataObject.author.last_name + ' (' + selectedDataObject.author.email + ')'
                            :   selectedDataObject.author_id
                        }
                    </dd>
                </div>
            </dl>                
        </div>
    )
}

export default DataObjectInfo