import React, { useEffect, useRef, useState, useContext } from 'react'
import { Context } from '../../../../../..'
import { observer } from 'mobx-react-lite'
import ReactPlayer from 'react-player'
import DialogTab from '../../../../../dialog_tab/DialogTab'
import EducationCloseButton from '../common/EducationCloseButton'
import VideoNavigationMenu from './VideoNavigationMenu'
import VideoScaleMenu from './VideoScaleMenu'

/**
 * Визуальный компонент отображает содержимое файла видео в формате mp4, прикрепленного к материалу по обучению
 * 
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * [handleViewPanelCloseClick](./components_main_page_controller_education_materials_MaterialsObjectsForm.js.html#line80)
 * 
 * @returns {HTMLDivElement} Html-разметку для отображения содержимого файла видео в формате mp4, прикрепленного к материалу по обучению
 * с использованием визуального компонента {@link DialogTab}, {@link VideoNavigationMenu}, {@link VideoScaleMenu}
 * 
 * @see [Вызов компонента](./components_main_page_controller_education_viewers_EducationViewFileContainer.js.html#line96)
 */
const EducationVideoPlayer = ({onCloseClick}) => {
    const { EducationStore, DialogTabStore, userStore } = useContext(Context)
    const [videoScale, setVideoScale] = useState(100)
    const [src, setSrc] = useState(null)
    const [duration, setDuration] = useState(0)
    const [playedSeconds, setPlayedSeconds] = useState(0)
    const [maxPlayedSeconds, setMaxPlayedSeconds] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [volume, setVolume] = useState(1)

    const playerRef = useRef(null)

    const dialogTabFunction = async () => {
        DialogTabStore.setDialogTabIsOpen(false)
        setPlayedSeconds(maxPlayedSeconds)
        playerRef.current.seekTo(maxPlayedSeconds)
        setIsPlaying(true)
    }

    const handleCloseDialogClick = () => {
        setIsPlaying(false)
        const progress = (maxPlayedSeconds >= duration - 2) ? duration : maxPlayedSeconds
        if (EducationStore.viewedFile && progress > 0 &&
           (!EducationStore.savedMaterialProgress || (progress > EducationStore.savedMaterialProgress.data["material_progress"].value)))
        {
            EducationStore.saveMaterialProgress(progress, duration, userStore.user.id)
        }
        onCloseClick()
    }

    const handleProgress = (info) => {
        const seconds = Math.floor(info.playedSeconds)
        setPlayedSeconds(seconds)
        if (seconds > maxPlayedSeconds)
            setMaxPlayedSeconds(seconds)
        if (seconds === duration)
            setIsPlaying(false)
    }

    const handleScaleChange = (isIncreased) => {
        const nextVideoScale = isIncreased ? videoScale + 10 : videoScale - 10
        
        if (nextVideoScale > 0 && nextVideoScale <= 100) {
            setVideoScale(nextVideoScale)
        }
    }

    const handlePlayClick = () => {
        setIsPlaying(!isPlaying)
    }

    const handleRewindClick = (isIncreased) => {
        let nextPlayedSeconds = isIncreased ? playedSeconds + 5 : playedSeconds - 5
        if (nextPlayedSeconds < 0)
            nextPlayedSeconds = 0
        if (nextPlayedSeconds > maxPlayedSeconds)
            nextPlayedSeconds = maxPlayedSeconds
        playerRef.current.seekTo(nextPlayedSeconds)
    }

    const handleRestartClick = () => {
        playerRef.current.seekTo(0)
    }

    const handleVolumeChange = (e) => {
        setVolume(parseFloat(e.target.value))
      }
    
    useEffect(() => {
        if (EducationStore.viewedFile && EducationStore.materialType) {
            if (EducationStore.materialType === 'stream') {
                setSrc(EducationStore.viewedFile)
            } else {
                setSrc([{src: EducationStore.viewedFile, type: 'video/mp4'}])
            }
        }
    }, [EducationStore.viewedFile, EducationStore.materialType])

    useEffect(() => {
        if (EducationStore.savedMaterialProgress) {
            setMaxPlayedSeconds(EducationStore.savedMaterialProgress.data["material_progress"].value)
            DialogTabStore.setParentName('EducationVideoPlayer')
            DialogTabStore.setDialogTabTitle("Продолжение обучения")
            DialogTabStore.setDialogTabText('Вы хотите продолжить просмотр с фрагмента, на котором остановились?')
            DialogTabStore.setDialogTabButtons(["Да", "Нет"])
            DialogTabStore.setDialogTabIsOpen(true)
        }
    }, [EducationStore.savedMaterialProgress])

    return (
        <div className='tw-mx-auto tw-w-full tw-h-full tw-overflow-hidden tw-flex tw-flex-col tw-bg-black'>
            <div id='menu-panel' className='tw-w-full tw-h-12 tw-py-1 tw-flex tw-flex-row tw-justify-start tw-items-center tw-bg-white tw-border-b tw-border-gray-300'>
                <EducationCloseButton onCloseClick={handleCloseDialogClick}/>
                <VideoNavigationMenu
                    playing={isPlaying}
                    volume={volume}
                    time={duration}
                    activeTime={playedSeconds}
                    lastTime={maxPlayedSeconds}
                    onRestartClick={handleRestartClick}
                    onPlayClick={handlePlayClick}
                    onRewindClick={handleRewindClick}
                    onVolumeChange={handleVolumeChange}
                />
                <VideoScaleMenu
                    scale={videoScale}
                    onScaleChange={handleScaleChange}
                />
            </div>
            <div id='video-panel' className='tw-grow tw-w-full tw-overflow-auto'>
                <ReactPlayer
                    className="tw-mx-auto tw-ring-media-focus tw-data-[focus]:ring-4"
                    url={src}
                    controls={true}
                    playing={isPlaying}
                    volume={volume}
                    width={videoScale + '%'}
                    height={videoScale + '%'}
                    progressInterval={250}
                    onProgress={handleProgress}
                    onDuration={(seconds) => setDuration(Math.floor(seconds))}
                    onContextMenu={(e) => e.preventDefault()}
                    ref={playerRef}
                />
            </div>
            <DialogTab
                parentName='EducationVideoPlayer'
                dialogTabFunction={dialogTabFunction}
            />
        </div>
    )
}

export default observer(EducationVideoPlayer)