import { makeAutoObservable, runInAction } from "mobx"
import { responseTimeOut, serviceMessageTimeOut } from "../config/constTypes"
import AuthService from "../services/AuthService"
import { toast } from "react-toastify"
import { showErrorToast } from "../functions/errorHandlers"

/**
 * Класс реализует хранилище информации о странице администрирования юр лиц и пользователей компании пользователя
 * @class
 * @property {Boolean} isLoading Загрузка
 * @property {Object[]} subCompanies  Массив юр.лиц
 * @property {Object} selectedSubCompany  Выбранное юр. лицо
 * @property {Object[]} users  Массив пользователей
 * @property {Object} selectedUser  Выбранный пользователь
 * @property {Boolean} isSubCompanyCreating Создание юр. лица
 * @property {Boolean} isSubCompanyEditing Редактирование информации о юр.лице
 * @property {Boolean} isUserCreating Создание пользователя
 * @property {Boolean} isUserEditing Редактирование информации о пользователе
 * @property {Object[]} subCompanyUsers  Массив пользователей юр. лица
 */
class CompanyAdminStore {
    isLoading = false
    subCompanies = []
    selectedSubCompany = null
    users = []
    selectedUser = null
    isSubCompanyCreating = false
    isSubCompanyEditing = false
    isUserCreating = false
    isUserEditing = false
    subCompanyUsers = []

    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    */
   constructor(){
        makeAutoObservable(this)
    }

     /**
     * Метод осуществляет сохранение информации о загрузке
     * @method
     * 
     * @param {Boolean} bool Информация о загрузке
     */
     setIsLoading(bool) {
        this.isLoading = bool
    }

    /**
     * Метод осуществляет сохранение информации о создании юр.лица
     * @method
     * 
     * @param {Boolean} value Информация о создании юр.лица
     */
    setIsSubCompanyCreating(value) {
        sessionStorage.removeItem('subCompanyForm')
        this.isSubCompanyCreating = value
    }

    /**
     * Метод осуществляет сохранение информации о редактировании юр.лица
     * @method
     * 
     * @param {Boolean} value Информация о редактировании юр.лица
     */
    setIsSubCompanyEditing(value) {
        sessionStorage.removeItem('subCompanyForm')
        this.isSubCompanyEditing = value
    }

    /**
     * Метод осуществляет сохранение информации о создании пользователя
     * @method
     * 
     * @param {Boolean} value Информация о создании пользователя
     */
    setIsUserCreating(value) {
        sessionStorage.removeItem('userForm')
        this.isUserCreating = value
    }

    /**
     * Метод осуществляет сохранение информации о редактировании пользователя
     * @method
     * 
     * @param {Boolean} value Информация о редактировании пользователя
     */
    setIsUserEditing(value) {
        sessionStorage.removeItem('userForm')
        this.isUserEditing = value
    }

    /**
     * Метод осуществляет сохранение информации о выбранном пользователе
     * @method
     * 
     * @param {Object} user Информация о выбранном пользователе
     */
    setSelectedUser(user) {
        this.selectedUser = user
    }

    /**
     * Метод осуществляет сохранение информации о выбранном юр.лице
     * @method
     * 
     * @param {Object} subCompany Информация о выбранном юр.лице
     */
    setSelectedSubCompany(subCompany) {
        this.selectedSubCompany = subCompany
    }

     /**
     * Метод осуществляет получение информации о компании авторизованного пользователя
     * @method
     */
     async getMyCompany () {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsLoading(false)
        }, responseTimeOut)
        
        AuthService
            .getCompany()
            .then(response => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                runInAction(() => {
                    this.subCompanies = response.data.sub_companies
                })
                if(this.selectedSubCompany){
                    let selectedSubCompany = response.data.sub_companies.filter((item) => item.id === this.selectedSubCompany.id)
                    this.setSelectedSubCompany(selectedSubCompany[0])
                }
            })
            .catch(error => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                showErrorToast(error, 'fetching', '')
            })
    }

    /**
     * Метод осуществляет получение массива пользователей собственной компании
     * @method
     */
    async getMyCompanyUsers() {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .getMyCompanyUsers()
            .then(data => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                runInAction(() => {
                    this.users = data.data
                    if(this.selectedUser){
                        let selectedUser = data.data.filter((item) => item.id === this.selectedUser.id)
                        this.setSelectedUser(selectedUser[0])
                    }
                    if(this.selectedSubCompany){
                        this.getSubCompanyUsers(this.selectedSubCompany.id)
                    }
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет получение пользователей определенного юр.лица
     * @method
     * 
     * @param {Number} id ID юр.лица 
     */
    getSubCompanyUsers(id) {
        this.subCompanyUsers = this.users.filter(item => {
            return item.allowed_sub_company_ids.find(item => item === id)
        })
    }

    /**
     * Метод осуществляет редактирование юр. лица
     * @method
     * 
     * @param {Object} subCompany Информация о  юр. лице 
     * @param {Object} id ID  юр. лица 
     */
    async editSubCompany (subCompany, id) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .editMySubCompany(subCompany, id)
            .then(() => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                this.setIsSubCompanyCreating(false) 
                this.setIsSubCompanyEditing(false)
                toast.success('Информация о юр. лице изменена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                this.getMyCompany()
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error('Ошибка при изменении информации о юр. лице! Такое юр. лицо уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет создание юр. лица
     * @method
     * 
     * @param {Object} subCompany Информация о  юр. лице 
     */
    async registerSubCompany (subCompany) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .registerMySubCompany(subCompany)
            .then((data) => {
                clearTimeout(noResponse)
                this.setSelectedSubCompany(data.data)
                this.setIsLoading(false)
                this.setIsSubCompanyCreating(false) 
                this.setIsSubCompanyEditing(false)
                toast.success('Юр. лицо успешно создано', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                this.getMyCompany()
            })
            .catch(error => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                if (error?.response?.status === 409) {
                    toast.error('Ошибка при создании юр. лица! Такое юр. лицо уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
            })
    }

    /**
     * Метод осуществляет привязку и отвязку пользователя к юр лицу компании авторизованного пользователя
     * @method
     * @param {Object} users Пользователи
     */
    async rebindMyUsers (users) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .rebindMyUsers(users, this.selectedSubCompany.id)
            .then(() => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                this.getMyCompanyUsers()
            })
            .catch(error => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                if (error?.response?.status === 422 && error?.response?.data?.message.includes('have single allowed sub-company')) {
                    toast.error('Ошибка при изменении информации о пользователе! Пользователь должен быть привязан хотя бы к одному юр. лицу', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
            })
    }
}

export default CompanyAdminStore
