import React from 'react'
import { MinusIcon, PlusIcon} from '@heroicons/react/20/solid'

/**
 * Визуальный компонент отображает меню управления масштабом видео
 * 
 * @param {Number} scale Значение масштаба видео
 * @param {Function} onScaleChange Обработчик клика мыши на кнопках изменения масштаба
 * [handleScaleChange](./components_main_page_controller_education_viewers_video_EducationVideoPlayer.js.html#line60)
 * 
 * @returns {HTMLDivElement} Html-разметку для отображения меню управления масштабом видео
 * 
 * @see [Вызов компонента](./components_main_page_controller_education_viewers_video_EducationVideoPlayer.js.html#line125)
 */
const VideoScaleMenu = ({scale, onScaleChange}) => {
    return (
        <div id='scale-menu' className='tw-h-full tw-px-4 tw-py-1 tw-flex tw-flex-row tw-justify-end tw-items-center tw-border-l tw-border-gray-300'>
            <button 
                className='tw-text-gray-500 hover:tw-text-gray-800 focus-visible:tw-text-gray-800 focus-visible:tw-outline 
                        focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-800 focus-visible:tw-rounded-sm'
                type='button'
                onClick={() => onScaleChange(false)}
            >
                <MinusIcon  className="tw-w-5 tw-h-5" aria-hidden="true"/>
            </button>
            <span className='tw-w-32 tw-text-sm tw-text-center tw-select-none'>Масштаб {scale + '%'}</span>
            <button 
                className='tw-text-gray-500 hover:tw-text-gray-800 focus-visible:tw-text-gray-800 focus-visible:tw-outline 
                        focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-800 focus-visible:tw-rounded-sm'
                type='button'
                onClick={() => onScaleChange(true)}
            >
                <PlusIcon  className="tw-w-5 tw-h-5" aria-hidden="true"/>
            </button>
        </div>
    )
}

export default VideoScaleMenu