import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../../..'
import ObjectsList from '../../../common/panels/object_list/ObjectsList'
import QuestionsForm from './QuestionsForm'
import Spinner from '../../../../../../assets/Spinner'


/**
 * Визуальный компонент отображает список вопросов теста
 * @param {Function} register Метод, реализующий регистрацию поля UseForm
 * [register](./components_main_page_controller_education_materials_MaterialsObjectsForm.js.html#line35)
 * @param {Function} saveTestResults Сохранение результатов теста
 * [saveTestResults](./components_main_page_controller_education_materials_MaterialsObjectsForm.js.html#line84)
 * 
 * @returns {JSXElement} Html-разметку этапа категорирования объектов КИИ
 * с использованием визуальных компонентов {@link ObjectsList}, {@link QuestionsForm}, {@link Spinner}
 * 
 * @see [Вызов компонента TestContainer](./components_main_page_controller_education_materials_tests_TestContainer.js.html#line85)
 */
const QuestionsListContainer = ({register, saveTestResults}) => {
    const { EducationStore } = useContext(Context)

    const handleJumpStageClick = (item) => {
        saveTestResults()
        EducationStore.setQuestionsStage(item)
    }

    useEffect(() => {
        EducationStore.loadQuestions()
    }, [])

    return (
        <div className='tw-grid tw-grid-cols-4 tw-gap-x-8 tw-grow tw-overflow-auto'>
            { !EducationStore.isQuestionsLoading
                ? <>
                        {EducationStore.questionsList.length > 0
                            ?   <>
                                    <div id='stage-list' className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                                        <div id='stage-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                                            <p className='tw-text-md tw-font-semibold'>Вопросы</p>
                                        </div>
                                        <ObjectsList
                                            objectsList={EducationStore.questionsList}
                                            objectsListStage={EducationStore.questionsStage}
                                            isDelete={false}
                                            onJumpStageClick={handleJumpStageClick}
                                            isSearch={false}
                                        />
                                    </div>
                                    <div id='stage-column' className='tw-col-span-3 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-flex tw-flex-col'>
                                        <div id='stage-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                                            <p className='tw-text-md tw-font-semibold tw-truncate'>Вопрос "{EducationStore.questionsStage.name}"</p>
                                        </div>
                                        <QuestionsForm
                                            register={register}
                                            saveTestResults={saveTestResults}
                                        />
                                    </div> 
                                </>
                            :
                                <p className='tw-text-md tw-font-semibold tw-mt-4 tw-text-center tw-col-span-4'>
                                    Для данного теста нет доступных вопросов
                                </p>
                        }
                    </>
                :
                    <div className='tw-col-span-4'>
                        <Spinner/>
                    </div>               
            }
        </div> 
    )
}

export default observer(QuestionsListContainer)