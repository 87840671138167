import React, { useContext } from 'react'
import { DocumentPlusIcon, PencilSquareIcon, TrashIcon, BarsArrowUpIcon, BarsArrowDownIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import DialogTab from '../../../../dialog_tab/DialogTab'


/**
 * Визуальный компонент отображает меню возможных действий над категориями
 * 
 * @returns {HTMLDivElement} Html-разметку ряда кнопок меню с использованием визуальных компонентов {@link DialogTab}
 * 
 * @see [Вызов компонента](./components_main_page_controller_category_CategoryListContainer.js.html#line65)
 */
const CategoryMenu = () => {
    const { docStore, DialogTabStore } = useContext(Context)

    const onEditItemClick = () => {
        if (!docStore.isCategoryLinking)
            docStore.setIsCategoryEditing(true)
    }

    const onAddItemClick = () => {
        docStore.setIsCategoryCreating(true)
        docStore.setSelectedCategory(null)
    }

    const deleteCategory = () => {
        docStore.deleteCategory()
        DialogTabStore.setDialogTabIsOpen(false) 
    }

    const onDeleteItemClick = () => {
        DialogTabStore.setParentName('CategoryMenu')
        DialogTabStore.setDialogTabTitle("Удаление категории") 
        DialogTabStore.setDialogTabText("Вы уверены, что хотите удалить эту категорию?")
        DialogTabStore.setDialogTabButtons(["Да", "Нет"])
        DialogTabStore.setDialogTabIsOpen(true) 
    }

    const onUpItemClick = () => {
        docStore.moveUpCategory()
    }

    const onDownItemClick = () => {
        docStore.moveDownCategory()
    }

    return (
        <div id='category-menu' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
            <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Категории</p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onAddItemClick}
                    disabled={docStore.isCategoryLinking}
                    data-tooltip-id="category-tooltip" data-tooltip-content="Создать" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onEditItemClick}
                    disabled={!docStore.selectedCategory || docStore.isCategoryLinking}
                    data-tooltip-id="category-tooltip" data-tooltip-content="Редактировать" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onDeleteItemClick}
                    disabled={!docStore.selectedCategory || docStore.isCategoryLinking}
                    data-tooltip-id="category-tooltip" data-tooltip-content="Удалить" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onUpItemClick}
                    disabled={!docStore.selectedCategory || docStore.isCategoryLinking || docStore.selectedCategory.order === 1}
                    data-tooltip-id="category-tooltip" data-tooltip-content="Переместить вверх" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <BarsArrowUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onDownItemClick}
                    disabled={!docStore.selectedCategory || docStore.isCategoryLinking || docStore.selectedCategory.order === docStore.categories.length}
                    data-tooltip-id="category-tooltip" data-tooltip-content="Переместить вниз" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <BarsArrowDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="category-tooltip" place="top"/>
            <DialogTab
                parentName='CategoryMenu'
                dialogTabFunction={deleteCategory}
            />
        </div>
    )
}

export default observer(CategoryMenu)