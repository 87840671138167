import { makeAutoObservable } from "mobx"
import DocumentService from "../services/DocumentService"
import ExporterService from "../services/ExporterService"
import { toast } from "react-toastify"
import { responseTimeOut, serviceMessageTimeOut, successMessageTimeOut } from "../config/constTypes"
import { saveReceivedFile } from "../functions/fileHandlers"
import { showErrorToast } from "../functions/errorHandlers"
import { findNestedModelName } from "../functions/nestedModels"

/**
 * Класс реализует хранилище информации о процессе обработки персональных данных (ПДн)
 * @class
 *
 * @property {Object[]} allStages Этапы процесса
 * @property {Object} stage Текущий этап процесса
 * @property {Number} actualStageIndex Номер текущего этапа процесса
 * @property {Object} activeDataModel Структура таблицы, относящейся к текущему этапу
 * @property {Object[]} nestedModels Список вложенных таблиц для редактируемой формы текущего этапа
 * @property {Object} selectedNestedValue Выбранная запись вложенной таблицы
 * @property {Boolean} isDataLoading Состояние процесса загрузки данных
 * @property {Object} project Запись процесса
 * @property {Number} lastStageIndex Номер этапа, на котором остановлен процесс
 * @property {Object[]} ISPDList Список ИСПДн
 * @property {Object[]} processList Список процессов обработки (направлений деятельности)
 * @property {Object[]} thirdPartyList Список третьих лиц-обработчиков
 * @property {Object} companyInfoID Запись с информацией о компании
 * @property {Date} orderPrintDate Дата печати приказа о назначении ответственных
 * @property {Object} questionRecordID Запись с дополнительными вопросами для ПДн
 * @property {Date} policiesPrintDate Дата печати политик обработки и защиты ПДн, технического задания и пр.
 * @property {Object} forRKNRecordID Запись с данными для РосКомНадзора
 * @property {Object} thirdPartyRecordID Запись с данными о сторонних обработчиках
 * @param {Boolean} hideStageList Информация о скрытии списка этапов
 * @property {Object} printingDatesFld Запись с данными о номерах приказов и датах печати
 */
class PersonalDataStore {
    allStages = null
    stage = null
    actualStageIndex = 1
    activeDataModel = null
    nestedModels = []
    selectedNestedValue = null
    isDataLoading = false
    project = null
    lastStageIndex = 0
    ISPDlist = null
    processList = null
    thirdPartyList = null
    companyInfoID = null
    orderPrintDate = null
    questionRecordID = null
    policiesPrintDate = null
    forRKNRecordID = null
    thirdPartyRecordID = null
    hideStageList = false
    printingDatesFld = null

    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    */
    constructor(){
        makeAutoObservable(this)
    }

    /**
     * Метод осуществляет сохранение справочной информации об этапах процесса
     * @method
     *
     * @param {Object[]} stages Этапы процесса
     */
    setAllStages(stages) {
        this.allStages = stages
    }

    /**
     * Метод осуществляет сохранение текущего этапа процесса
     * @method
     *
     * @param {Object} stage Текущий этап
     */
    setStage(stage) {
        this.stage = stage
    }

    /**
     * Метод осуществляет сохранение номера текущего этапа, на котором находится пользователь
     * @method
     *
     * @param {Number} stage Номер этапа
     */
    setActualStageIndex(stage) {
        this.actualStageIndex = stage
    }

    /**
     * Метод осуществляет сохранение структуры таблицы, относящейся к текущему этапу
     * @method
     *
     * @param {Object} dataModel Структура текущей таблицы
     */
    setActiveDataModel(dataModel) {
        this.activeDataModel = dataModel
    }

    /**
     * Метод осуществляет сохранение списка вложенных таблиц для редактируемой формы текущего этапа
     * @method
     *
     * @param {Object[]} dataModels Список вложенных таблиц
     */
    setNestedModels(dataModels) {
        this.nestedModels = dataModels
    }

    /**
     * Метод осуществляет сохранение выбранной записи вложенной таблицы
     * @method
     *
     * @param {Object} nestedModelRow Запись вложенной таблицы
     */
    setSelectedNestedValue(nestedModelRow) {
        this.selectedNestedValue = nestedModelRow
    }

    /**
     * Метод осуществляет сохранение состояния процесса загрузки данных
     * @method
     *
     * @param {Boolean} bool Состояние процесса загрузки данных
     */
    setIsDataLoading(bool) {
        this.isDataLoading = bool
    }

    /**
     * Метод осуществляет сохранение записи с информацией о процессе
     * @method
     *
     * @param {Object} project Запись с информацией о процессе
     */
    setProject(project) {
        this.project = project
    }

    /**
     * Метод осуществляет сохранение номера этапа, на котором остановлен процесс
     * @method
     *
     * @param {Number} stage Номер этапа
     */
    setLastStageIndex(stage) {
        this.lastStageIndex = stage
    }

    /**
     * Метод осуществляет сохранение записи с информацией о компании
     * @method
     *
     * @param {Object} companyInfoRecord Запись с информацией о компании
     */
    setCompanyInfoID(companyInfoRecord) {
        this.companyInfoID = companyInfoRecord
    }

    /**
     * Метод осуществляет сохранение даты печати приказа о назначении ответственных
     * @method
     *
     * @param {Date} date Дата печати приказа о назначении ответственных
     */
    setOrderPrintDate(date) {
        this.orderPrintDate = date
    }

    /**
     * Метод осуществляет сохранение записи с дополнительными вопросами для ПДн
     * @method
     *
     * @param {Object} record Запись с дополнительными вопросами для ПДн
     */
    setQuestionRecordID(record) {
        this.questionRecordID = record
    }

    /**
     * Метод осуществляет сохранение даты печати политик обработки и защиты ПДн, технического задания и пр.
     * @method
     *
     * @param {Date} date Дата печати политик обработки и защиты ПДн, технического задания и пр.
     */
    setPoliciesPrintDate(date) {
        this.policiesPrintDate = date
    }

    /**
     * Метод осуществляет сохранение записи с данными для РосКомНадзора
     * @method
     *
     * @param {Object} record Запись с данными для РосКомНадзора
     */
    setForRKNRecordID(record) {
        this.forRKNRecordID = record
    }

    /**
     * Метод осуществляет сохранение записи с данными о сторонних обработчиках
     * @method
     *
     * @param {Object} record Запись с данными о сторонних обработчиках
     */
    setThirdPartyRecordID(record) {
        this.thirdPartyRecordID = record
    }

    /**
     * Метод осуществляет сохранение списка ИСПДн
     * @method
     *
     * @param {Object[]} list Список ИСПДн
     */
    setISPDList(list) {
        this.ISPDList = list
    }

    /**
     * Метод осуществляет сохранение списка процессов
     * @method
     *
     * @param {Object[]} processes Список процессов
     */
    setProcessList(processes) {
        this.processList = processes
    }

    /**
     * Метод осуществляет сохранение списка третьих лиц-обработчиков
     * @method
     *
     * @param {Object[]} handlers Список третьих лиц-обработчиков
     */
    setThirdPartyList(handlers) {
        this.thirdPartyList = handlers
    }

    /**
     * Метод осуществляет сохранение информации о скрытии списка этапов
     * @method
     *
     * @param {Boolean} value Информация о скрытии списка
     */
    setHideStageList(value) {
        this.hideStageList = value
    }

    /**
     * Метод осуществляет сохранение данных о номерах приказов и датах печати
     * @method
     *
     * @param {Object} record Данные о номерах приказов и датах печати
     */
    setPrintingDatesFld(record) {
        this.printingDatesFld = record
    }


    /**
     * Метод осуществляет возвращение к предыдущему этапу процесса обработки ПДн
     * @method
     */
    goPrevStage() {
        const prevStage = this.allStages.find(stage => stage.data['id_of_stage'].value === (this.actualStageIndex - 1))
        if (prevStage) {
            this.setStage(prevStage)
            this.setActualStageIndex(this.actualStageIndex - 1)
        }
    }

    /**
     * Метод осуществляет обновление прогресса прохождения этапов ПДн
     * @method
     *
     * @param {Boolean} isForcedUpdate Признак принудительного изменения номера этапа, на котором остановился процесс
     * @param {Boolean} isActualStage Признак остановки на текущем этапе
     */
    updateCurrentStage = async (isForcedUpdate, isActualStage) => {
        let stageIndex = isActualStage ? this.actualStageIndex - 1 : this.actualStageIndex
        // фиксация завершения текущего этапа
        this.setAllStages(this.allStages.map(stage =>
            stage.data['id_of_stage'].value === stageIndex
                ?   {...stage, status: 'finished'}
                :   stage
        ))
        // обновление номера этапа, на котором остановился процесс обработки ПДн
        try {
            if (stageIndex >= this.lastStageIndex || isForcedUpdate) {
                this.setLastStageIndex(stageIndex + 1)
                this.setAllStages(this.allStages.map(stage =>
                    stage.data['id_of_stage'].value < (stageIndex + 1)
                        ?   {...stage, status: 'finished'}
                        :   {...stage, status: ''}
                ))
                const updatedProject = await this.updateProject('current_stage', stageIndex + 1, false)
                if (updatedProject) {
                    const loadedProject = await DocumentService.getOneDataObject(updatedProject.id)
                    this.setProject(loadedProject)
                }
            }
        } catch (error) {
            toast.error('Ошибка при обновлении проекта!', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }
    }

    /**
     * Метод осуществляет переход к следующему этапу процесса обработки ПДн
     * @method
     *
     * @param {Boolean} isForcedUpdate Признак принудительного изменения номера этапа, на котором остановился процесс
     */
    goNextStage = async (isForcedUpdate) => {
        this.updateCurrentStage(isForcedUpdate, false)
        // переход к следующему этапу
        const nextStage = this.allStages.find(stage => stage.data['id_of_stage'].value === (this.actualStageIndex + 1))
        if (nextStage) {
            this.setStage(nextStage)
            this.setActualStageIndex(this.actualStageIndex + 1)
        }
    }

    /**
     * Метод осуществляет загрузку информации об этапах
     * @method
     */
    loadStages = async () => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setAllStages([])
        }, responseTimeOut)

        try {
            const infoFilter = JSON.stringify([
                {property: 'data_model_id', value: 'stages_of_pdn', operator: 'eq'},
                {property: 'active', value: true, operator: 'eq'}
            ])
            const infoSorter = JSON.stringify([
                {property: 'data["id_of_stage"]', desc: false},
            ])
            // загрузка информации по этапам
            const info = await DocumentService.getAllDataObjects(50, infoFilter, infoSorter)
            clearTimeout(noResponse)
            if (info) {
                this.setAllStages(info)
                if (info.length > 0)
                    this.setStage(info[0])
            }
        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'fetching', '')
            this.setAllStages([])
        }
    }

    /**
     * Метод осуществляет проверку наличия сохраненных процессов обработки ПДн
     * @method
     */
    checkSavedProject = async () => {
        try {
            const defaultFilter = JSON.stringify([
                {property: 'data_model_id', value: 'stages_of_documentation_pdn', operator: 'eq'},
                {property: 'active', value: true, operator: 'eq'}
            ])
            const defaultSorter = JSON.stringify([
                {property: 'created', desc: true},
            ])
            // загрузка существующих записей процессов обработки ПДн
            const savedRecords = await DocumentService.getAllDataObjects(50, defaultFilter, defaultSorter, 'w')
            return savedRecords.length

        } catch (error) {
            showErrorToast(error, 'fetching', '')
        }

        return 0
    }

    /**
     * Метод осуществляет загрузку сохраненной информации о процессе обработки ПДн
     * @method
     *
     * @param {String} projectID ID проекта
     */
    loadSavedProject = async (projectID) => {
        let actualStage = 0

        try {
            const savedProject = await DocumentService.getOneDataObject(projectID)
            this.setProject(savedProject)

            if (savedProject.data['current_stage'] && savedProject.data['current_stage'].value) {
                this.setLastStageIndex(savedProject.data['current_stage'].value)
                actualStage = savedProject.data['current_stage'].value
            }

            const companyInfoRecord = savedProject.data['info_about_company_fld'].value
            this.setCompanyInfoID(companyInfoRecord)

            this.setOrderPrintDate(savedProject.data['date_of_print_of_appointment_orders'].value)

            const projectIspdns = await DocumentService.getNestedDataObjects(50, [], JSON.stringify([{property: 'created', desc: true}]), savedProject.id, savedProject.data['ispdn'].rule_id)
            this.setISPDList(projectIspdns)

            const projectProcesses = await DocumentService.getNestedDataObjects(50, [], JSON.stringify([{property: 'created', desc: true}]), savedProject.id, savedProject.data['process_of_lines_areas_of_activity'].rule_id)
            this.setProcessList(projectProcesses)

            const projectHandlers = await DocumentService.getNestedDataObjects(50, [], JSON.stringify([{property: 'created', desc: true}]), savedProject.id, savedProject.data['list_of_third_party_processors'].rule_id)
            this.setThirdPartyList(projectHandlers)

            const questionRecord = savedProject.data['additional_question_pdn'].value
            this.setQuestionRecordID(questionRecord)

            const forRKNRecord = savedProject.data['form_data_rkn_pdn'].value
            this.setForRKNRecordID(forRKNRecord)

            const thirdPartyRecord = savedProject.data['list_of_third_party_processors'].value
            this.setThirdPartyRecordID(thirdPartyRecord)

            this.setPoliciesPrintDate(savedProject.data['date_of_print_policy_pdn'].value)

            this.setPrintingDatesFld(savedProject.data['order_numbers_and_pdn_printing_dates_fld'].value)
        } catch (error) {
            showErrorToast(error, 'fetching', '')
        }

        return actualStage
    }

    /**
     * Метод осуществляет обновление информации об обработке ПДн
     * @method
     *
     * @param {String} field Название поля
     * @param {Any} value Значение поля
     * @param {Boolean} isReload Признак повторной загрузки проекта
     */
    updateProject = async (field, value, isReload) => {
        const project = {}
        project.data_model_id = 'stages_of_documentation_pdn'
        project.data = {}
        project.data[field] = value

        const response = await DocumentService.updateDataObject(this.project.record_id, project)
        if (isReload && response)
            await this.loadSavedProject(response.id)

        return response
    }

    /**
     * Метод осуществляет получение выбранного этапа обработки ПДн
     * @method
     *
     * @param {String} id ID таблицы для выбранного этапа обработки ПДн
     * @param {Function} setModels Метод, сохраняющий полученные вложенные таблицы
     */
    getActiveDataModel = async (id, setModels) => {
        try {
            const dataModel = await DocumentService.getOneDataModel(id)
            this.setActiveDataModel(dataModel)
            const includedFields = dataModel.fields
                                    .slice()
                                    .sort((a, b) => a.order - b.order)
                                    .filter(field => field.type === 'include')

            let requests = includedFields.map(includedField => DocumentService.getOneDataModel(includedField.ref_model_ids[0]))
            Promise.all(requests)
                .then(responses => {
                    setModels(includedFields.map((includedField, index) =>  {
                        return {
                            ...includedField,
                            fields: responses[index].fields,
                            ref_model_type: responses[index].type,
                            id: responses[index].id,
                            fieldName: findNestedModelName(dataModel, includedField),
                            dataObjects: []
                        }
                    }))
                })

            return dataModel
        } catch (error) {
            showErrorToast(error, 'fetching', '')
        }

        return null
    }

    /**
     * Метод осуществляет проверку значений для первичного поля вложенной таблицы
     * @method
     *
     * @param {Object} nestedModel Вложенная таблица
     * @param {Object[]} values Значения первичного поля вложенной таблицы
     */
    checkPrimaryFieldErrors = (nestedModel, values) => {
        let isError = false
        const primaryField = nestedModel.fields.find(field => field.order === 0)
        if (primaryField) {
            isError = this.checkValueErrors(values, primaryField.tech_name, primaryField.type)
        }

        return isError
    }

    /**
     * Метод осуществляет сброс этапа печати политики пдн
     * @method
     *
     * @param {String} id ID проекта
     */
    resetPrintPolicyStage = async (id) => {
        if (this.policiesPrintDate) {
            await this.updateProject('date_of_print_policy_pdn', null, true)
            this.setAllStages(this.allStages.map(stage =>
                stage.data['id_of_stage'].value === 10
                    ?   {...stage, status: ''}
                    :   stage
            ))
        } else {
            await this.loadSavedProject(id)
        }
    }
}

export default PersonalDataStore
