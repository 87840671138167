import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../../..'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import FieldItem from '../../../../form_fields/FieldItem'
import DirectoryContainer from '../../common/panels/directory/DirectoryContainer'
import PDataStageButtons from '../stage/PDataStageButtons'
import { observer } from 'mobx-react-lite'
import DocumentService from '../../../../../services/DocumentService'
import Spinner from '../../../../../assets/Spinner'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import { processData } from '../../../../../functions/forms'
import { deleteNestedDataObjects, updateNestedDataObjects, deleteAllNestedDataObjects } from '../../../../../functions/nestedModels'
import { checkNestedTables } from '../../../../../functions/checkNestedTables'
import FormErrorToastPanel from '../../common/panels/toast/FormErrorToastPanel'
import { setReferenceValue } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает этап данных для РосКомНадзора
 * 
 * @returns {JSXElement} Html-разметку формы для этапа данных для РосКомНадзора с использованием визуальных компонентов 
 * {@link FieldItem}, {@link Spinner}, {@link PDataStageButtons}, {@link DirectoryContainer}, {@link FormErrorToastPanel}
 * 
 * @see [Вызов компонента](./components_main_page_controller_personal_data_PersonalDataContainer.js.html#line151)
 */
const PDataRKNForm = () => {   
    const {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        setError,
        clearErrors,
        formState: { isSubmitting, errors },
    } = useForm()

    const dummyModel = {id: '0', entity_name: ''}

    const { personalDataStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [isSavedRecord, setIsSavedRecord] = useState(false)
    const [formFields, setFormFields] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [formNestedModels, setFormNestedModels] = useState(null)
    const [selectedRefModel, setSelectedRefModel] = useState(dummyModel)
    const [isDataSaving, setIsDataSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const watchProcessing = watch('data.data_processing_before_date_or_event', 
                                formFields && formFields.length
                                    ?   formFields.find(field => field.tech_name === 'data_processing_before_date_or_event')?.value
                                    :   null
    )

    const watchSKZI = watch('data.is_using_skzi', 
                                formFields && formFields.length
                                    ?   formFields.find(field => field.tech_name === 'is_using_skzi')?.value
                                    :   false
    )

    const handleSelectClick = (dataModelList, name, field) => {
        setSelectedName(name)
        setSelectedDataModelID(dataModelList)
        setIsSelectFormOpen(true)
    }

    const handleClearClick = (name) => {
        setValue(name, {format: '', values: []})
        handleRecordChange()
    }

    const handleRecordChange = () => {
        setIsSavedRecord(false)
    }

    const handleDoubleClick = (item) => {
        if (selectedName) {
            setValue(selectedName, {value: setReferenceValue(item)})
        } else {
            if (selectedRefModel) {
                personalDataStore.setNestedModels(personalDataStore.nestedModels.map((nestedModel) =>  { 
                    if (nestedModel.rule_id === selectedRefModel.rule_id) {
                        return {
                        ...nestedModel,
                            dataObjects: [...selectedRefModel.dataObjects, {...item, status: 'added'} ],
                            errors: false
                        }
                    }
                    return nestedModel
                }))
        
                setSelectedRefModel(dummyModel)
            }
        }
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)
        setIsSavedRecord(false)
    }

    const handleCloseClick = () => {
        if (selectedRefModel) {
            personalDataStore.setNestedModels(personalDataStore.nestedModels.map((nestedModel) =>  { 
                if (nestedModel.rule_id === selectedRefModel.rule_id) {
                    return {
                    ...nestedModel,
                        dataObjects: selectedRefModel.dataObjects.filter(item => item.data['name'].value),
                        errors: false
                    }
                }
                return nestedModel
            }))
    
            setSelectedRefModel(dummyModel)
        }
        docStore.setIsDetailView(false);
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    const handleBackClick = () => {
        personalDataStore.goPrevStage()
    }

    const handleForwardClick = async (form) => {
        setIsDataSaving(true)

        const nestedModelsWithErrors = checkNestedTables(personalDataStore.nestedModels, formFields, watchSKZI, setError,
            'Включенные таблицы не должны быть пустыми, а также содержать повторяющиеся или пустые значения!')

        if (nestedModelsWithErrors.some(nestedModel => nestedModel.errors)) {
            setIsDataSaving(false)
            
        } else {
            const dataObject = processData(form.data, personalDataStore.activeDataModel.id)

            if (!watchSKZI) {
                dataObject.data['description_skzi'] = null
                dataObject.data['class_skzi_vt'] = {
                    upsert: [],
                    remove: deleteAllNestedDataObjects(personalDataStore.nestedModels[0]),
                }
            } else {
                dataObject.data['class_skzi_vt'] = {
                    upsert: updateNestedDataObjects(personalDataStore.nestedModels[0]),
                    remove: deleteNestedDataObjects(personalDataStore.nestedModels[0]),
                }
            }
            if (watchProcessing === 'дата') {
                dataObject.data['reason_for_ending_processing'] = null
            } else {
                dataObject.data['date_until_process_data'] = null
            }

            try {
                if (!isSavedRecord) {
                    let response
                    if (personalDataStore.forRKNRecordID && personalDataStore.forRKNRecordID.values.length > 0) {
                        await DocumentService.updateDataObject(personalDataStore.forRKNRecordID.values[0].record_id, dataObject)
                        await personalDataStore.resetPrintPolicyStage(personalDataStore.project.id)
                    } else {
                        response = await DocumentService.createDataObject(dataObject)
                        await personalDataStore.updateProject('form_data_rkn_pdn', [response.record_id], true)
                    }

                    toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                }

                personalDataStore.goNextStage()  

            } catch (error) {
                showErrorToast(error, 'saving', '')
            } finally {
                setIsDataSaving(false)
            }
        }
    }

    const loadSavedRecord = async (id) => {
        try {
            const savedRecord = await DocumentService.getOneDataObject(id)
            const fields = Object.values(savedRecord.data)
            handleSetFormFields(fields)
            setIsEditMode(true)
            if (personalDataStore.actualStageIndex >= personalDataStore.lastStageIndex)
                setIsSavedRecord(false)
            else 
                setIsSavedRecord(true)

        } catch (error) {
            showErrorToast(error, 'fetching', '') 
            handleSetFormFields(personalDataStore.activeDataModel.fields)
        }

        return null
    }

    const initNestedModels = async (id, isDuplicate) => {  
        setIsLoading(true)

        const nestedValueRequests = formNestedModels.map(nestedModel => id ? DocumentService.getNestedDataObjects(50, [], [], id, nestedModel.rule_id) : nestedModel)
        Promise.all(nestedValueRequests)
            .then(responses => {
                personalDataStore.setNestedModels(formNestedModels.map((nestedModel, nestedModelIndex) =>  { 
                    return {
                        ...nestedModel,
                        dataObjects: id ? responses[nestedModelIndex].map(value => { return {...value, status: isDuplicate ? 'added' : 'saved'} }) : []
                    }
                }))
            })
            .finally(() => setIsLoading(false))
    }

    const handleChangeNestedTables = (editedValues, editedNestedModel) => {
        personalDataStore.setNestedModels(personalDataStore.nestedModels.map((nestedModel) =>  { 
            if (nestedModel.rule_id === editedNestedModel.rule_id) {
                return {
                ...nestedModel,
                    dataObjects: editedValues,
                    errors: false
                }
            }
            return nestedModel
        }))
        clearErrors()
        handleRecordChange()

        if (editedNestedModel.ref_model_type === 'directory') {
            if (editedValues.some(item => !item.data['name'].value)) {
                setSelectedRefModel(editedNestedModel)
                setSelectedDataModelID(editedNestedModel.ref_model_ids[0])
                setIsSelectFormOpen(true)    
            }
        }
    }

    const handleSetFormFields = (fields) => {
      setFormFields(fields.map(field => {
            if (['class_skzi_vt', 'description_skzi'].includes(field.tech_name)) {
                return ({...field, hide: !watchSKZI, mandatory: watchSKZI})
            } else if (watchProcessing) {
                if (watchProcessing === 'дата') {
                    if (['reason_for_ending_processing'].includes(field.tech_name)) {
                        return {...field, hide: true, mandatory: false}
                    } else if (['date_until_process_data'].includes(field.tech_name)) {
                        return {...field, hide: false, mandatory: true} 
                    }
                } else {
                    if (['date_until_process_data'].includes(field.tech_name)) {
                        return {...field, hide: true, mandatory: false}
                    } else if (['reason_for_ending_processing'].includes(field.tech_name)) {
                        return {...field, hide: false, mandatory: true} 
                    }
                }
            } else if (!watchProcessing) {
                if (['date_until_process_data', 'reason_for_ending_processing'].includes(field.tech_name)) {
                    return {...field, hide: true, mandatory: false}
                }
            }
            
            return field
        }))            
    }

    useEffect(() => {
        personalDataStore.setIsDataLoading(true)
        personalDataStore.getActiveDataModel('missing_data_on_the_rkn_form', setFormNestedModels)
    }, [])

    useEffect(() => {
        if (personalDataStore.activeDataModel && personalDataStore.activeDataModel.id === 'missing_data_on_the_rkn_form') {
            if (personalDataStore.forRKNRecordID && personalDataStore.forRKNRecordID.values.length > 0) {
                loadSavedRecord(personalDataStore.forRKNRecordID.values[0].id)
            } else {
                handleSetFormFields(personalDataStore.activeDataModel.fields)
            }
                
            personalDataStore.setIsDataLoading(false)
        }
    }, [personalDataStore.activeDataModel])

    useEffect(() => {
        if (formFields) {
            clearErrors()
            handleSetFormFields(formFields)
        }
    }, [watchSKZI, watchProcessing])

    useEffect(() => {
        if (formNestedModels) {
            initNestedModels(null, false)

            if (personalDataStore.forRKNRecordID && personalDataStore.forRKNRecordID.values.length > 0) {               
                initNestedModels(personalDataStore.forRKNRecordID.values[0].id, false)
            } 
        }
    }, [formNestedModels])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(<FormErrorToastPanel errors={errors.data} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <>
            <div className='tw-grow tw-overflow-auto tw-mt-2'>
                <form className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-px-4 tw-py-1'>
                        { !formFields
                            ?
                                <Spinner />
                            :
                                formFields.slice().sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                    return (!fieldItem.hide && 
                                            <FieldItem
                                                key={index}
                                                fieldItem={fieldItem}
                                                nestedModels={personalDataStore.nestedModels}
                                                selectedNestedValue={personalDataStore.selectedNestedValue}
                                                errors={errors}
                                                handleSelectClick={handleSelectClick}
                                                handleClearClick={handleClearClick}
                                                handleRecordChange={setIsSavedRecord}
                                                isEditMode={isEditMode}
                                                isDuplicateMode={false}
                                                isLoading={isLoading}
                                                control={control}
                                                register={register}
                                                setValue={setValue}
                                                onSelectNestedValue={e => personalDataStore.setSelectedNestedValue(e)}
                                                onNestedTableChange={handleChangeNestedTables}
                                            />
                                    )
                                })
                        }
                    </div>
                </form>
            </div>
            <PDataStageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleSubmit(handleForwardClick)}
                disabled={isDataSaving}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
            />
        </>                                                             
    )
}

export default observer(PDataRKNForm)
