import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../../..'
import { getFormattedDate } from '../../../../../../config/constTypes'

function calculateProgress(item) {
    if (item.data.material_volume.value) {
        let progress = (item.data.material_progress.value / item.data.material_volume.value) * 100
        if (progress > 100)
            progress = 100
        return progress.toFixed(0) + ' %'
    } else {
        return 'Не возможно рассчитать прогресс, так как объем материала равен нулю'
    }

}

/**
 * Визуальный компонент отображает информацию о прогрессе по обучению
 * 
 * @returns {HTMLFormElement} Html-разметку информации о прогрессе по обучению
 * с использованием визуальных компонентов {@link MaterialProgressRow}, {@link MaterialProgressSort}
 * 
 * @see [Вызов компонента MaterialProgress](./components_main_page_controller_education_materials_material_progress_MaterialProgress.js.html#line39)
*/
const MaterialProgressFields = () => {
    const { EducationStore } = useContext(Context)

    return (
        <>
            <div className='tw-my-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-py-2'>
                <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-justify-between">
                    Дата и время
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                    <p>{getFormattedDate(EducationStore.progressRecord.data.date.value, 'dd.MM.yyyy HH:mm:ss')}</p>
                </dd>                                    
            </div>
            <div className='tw-my-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-py-2'>
                <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-justify-between">
                    Прогресс
                </dt>
                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                    <p>{calculateProgress(EducationStore.progressRecord)}</p>
                </dd>                                    
            </div>
        </>
    )       
}

export default observer(MaterialProgressFields)