import React from 'react'

/**
 * Визуальный компонент отображает универсальный заголовок отображаемой страницы в виде текстовой строки
 * 
 * @param {String} title Заголовок
 * 
 * @returns {HTMLDivElement} Html-разметку заголовка страницы
 * 
 * @see [Вызов компонента AddDataModelContainer,](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line123)
 * [AddDataObjectContainer,](./components_main_page_controller_data_object_add_AddDataObjectContainer.js.html#line200)
 * [AdminContainer,](./components_admin_page_AdminContainer.js.html#line37)
 * [AgreementInfo,](./components_agreement_AgreementInfo.js.html#line15)
 * [CategorizingContainer,](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line111)
 * [CategoryListContainer,](./components_main_page_controller_category_CategoryListContainer.js.html#line53)
 * [ConfigurationInfo,](./components_configuration_page_ConfigurationInfo.js.html#line14)
 * [ConfigurationUpload,](./components_configuration_page_ConfigurationUpload.js.html#line48)
 * [DataModelListContainer,](./components_main_page_controller_data_model_DataModelListContainer.js.html#line124)
 * [DataObjectListContainer,](./components_main_page_controller_data_object_list_DataObjectListContainer.js.html#line532)
 * [DuplicateDataObjectContainer,](./components_main_page_controller_data_object_add_DuplicateDataObjectContainer.js.html#line206)
 * [EditDataModelContainer,](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line153)
 * [EditDataObjectContainer,](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line239)
 * [EditFilters,](./components_filters_EditFilters.js.html#line336)
 * [EducationContainer,](./components_main_page_controller_education_EducationContainer.js.html#line39)
 * [ExporterListContainer,](./components_main_page_controller_exporter_ExporterListContainer.js.html#line223)
 * [ImportDataForm,](./components_main_page_controller_importer_from_server_ImportDataForm.js.html#line47)
 * [ImportDataViewContainer,](./components_main_page_controller_importer_from_server_ImportDataViewContainer.js.html#line61)
 * [ImporterListContainer,](./components_main_page_controller_importer_ImporterListContainer.js.html#line200)
 * [ImportPreviewContainer,](./components_main_page_controller_importer_preview_ImportPreviewContainer.js.html#line167)
 * [LicenseInfo,](./components_license_LicenseInfo.js.html#line15)
 * [MainPageContainer](./components_main_page_MainPageContainer.js.html#line157)
 * [PersonalDataContainer,](./components_main_page_controller_personal_data_PersonalDataContainer.js.html#line106)
 * [PolicyInfo,](./components_policy_PolicyInfo.js.html#line15)
 * [ViewFileContainer](./components_main_page_controller_data_object_view_ViewFileContainer.js.html#line110)
 */
const PageTitle = ({title}) => {
    return (
        <div id='page-title' className='tw-h-16 tw-w-full tw-text-xl tw-font-bold tw-px-4 tw-py-4 tw-truncate'>
            {title}
        </div>
    )
}

export default PageTitle