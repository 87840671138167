import React from 'react'
import FieldTable from '../../field/FieldTable'
import { getDataModelType, getFormattedDate } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает служебную информацию о выбранной таблице и список полей
 * @param {Object} dataModel Выбранная таблица в списке таблиц
 * @param {Object} selectedField Выбранное поле в списке полей таблицы
 * @param {Function} onFieldClick Обработчик клика мыши на элементе списка полей
 * [setSelectedField](./components_main_page_controller_data_model_DataModelListContainer.js.html#line27)
 * @returns {HTMLDivElement} Html-разметку служебной информации и списка полей таблицы с использованием визуального компонента {@link FieldTable}
 * 
 * @see [Вызов компонента](./components_main_page_controller_data_model_DataModelListContainer.js.html#line144)
 */
const DataModelInfo = ({dataModel, selectedField, onFieldClick}) => {
    return (
        <div id='data-model-info' className='tw-w-full tw-h-full tw-overflow-hidden'>
            <dl className='tw-h-40 tw-py-1 tw-border-b tw-border-gray-300'>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Название</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 tw-truncate">{dataModel.entity_name}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Тип</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 tw-truncate">{getDataModelType(dataModel)}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Видимость</dt>
                    <dd className={`${dataModel.hide_mark ? 'tw-text-red-500' : 'tw-text-gray-900'} tw-mt-1 tw-text-sm sm:tw-col-span-2 sm:tw-mt-0`}>
                        {dataModel.hide_mark ? 'Скрыта' : 'Отображается'}
                    </dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Дата создания</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{getFormattedDate(dataModel.created, 'dd.MM.yyyy')}</dd>
                </div>
                <div className="tw-px-4 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Дата изменения</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{getFormattedDate(dataModel.last_modified, 'dd.MM.yyyy')}</dd>
                </div>
            </dl>                
            <div className='tw-h-[calc(100%_-_13rem)] tw-overflow-auto'>
                <FieldTable 
                    fields={dataModel.fields}
                    selectedField={selectedField}
                    onFieldClick={onFieldClick}
                />
            </div>
        </div>
    )
}

export default DataModelInfo