import React, { useState, useEffect, useContext } from 'react'
import PageTitle from '../common/panels/PageTitle'
import { Context } from '../../../..'
import StageListContainer from '../common/panels/stage_list/StageListContainer'
import MaterialsObjectsContainer from './materials/MaterialsObjectsContainer'
import { observer } from 'mobx-react-lite'
import FilterStore from '../../../../store/FilterStore'
import Spinner from '../../../../assets/Spinner'


/**
 * Визуальный компонент реализует логику модуля обучение персонала
 * 
 * @returns {JSXElement} Html-разметку с модулем обучение персонала
 * с использованием визуальных компонентов {@link PageTitle}, {@link StageListContainer}, {@link MaterialsObjectsContainer}
 */
const EducationContainer = () => {
    const { EducationStore, userStore, docStore } = useContext(Context)
    const [filterStore] = useState(() => new FilterStore())
    const [hideStageList, setHideStageList] = useState(false)

    const topicDataModel = {
        id: "topics",
        fields: [{
            alias: "Наименование",
            field_id: "name",
            full_name: "Наименование",
            tech_name: "name",
            type: "value",
            validator_type: "string"
        }]
    }

    const handleHideStageClick = (isVisible) => {
        setHideStageList(isVisible)
    }
    
    const handleJumpStageClick = (item) => {
        EducationStore.setTopic(item)
    }

    const updateDataObjectList = () => {
        EducationStore.loadTopics(filterStore.searchFilters)
    }

    const handleTopicSearch = (progress) => {
        const foundProgress = EducationStore.allProgress.find(item => item.id === progress.id)
        if (foundProgress) {
            const materialRecordID = EducationStore.getMaterialRecordID(foundProgress)

            let lastTopic
            EducationStore.allTopics.forEach(topic => {
                const foundMaterial = topic.dataObjects.find(material => material.materialRecordID === materialRecordID)
                if (foundMaterial) {
                    lastTopic = topic
                    EducationStore.setTopic(lastTopic)
                    EducationStore.setLastViewedMaterial(foundMaterial)
                }
            })
        }
    }

    const initModule = async () => {
        await EducationStore.loadTopics([])
        await EducationStore.loadAllMaterialProgress(userStore.user.id)
        await EducationStore.loadAllTestAttempts(userStore.user.id)

        if  (docStore.moduleType &&
            (docStore.moduleType === 'education_progress' || docStore.moduleType === 'test_results') && 
            docStore.moduleRecordID)
        {
            await handleTopicSearch({id: docStore.moduleRecordID})
            docStore.setModuleType(null)
            docStore.setModuleRecordID(null)
        }
    }

    useEffect(() => {
        initModule()
    }, [])

    useEffect(() => {
        if (EducationStore.allTopics && EducationStore.allProgress && (EducationStore.isMaterialProgressUpdate || EducationStore.isTestAttemptUpdate)) {
            EducationStore.updateTopicStatus()
        }
    }, [EducationStore.allTopics, EducationStore.allProgress, EducationStore.isMaterialProgressUpdate, EducationStore.isTestAttemptUpdate])

    useEffect(() => {
        if (EducationStore.isMaterialProgressSaved) {
            EducationStore.loadAllMaterialProgress(userStore.user.id)
        }
    }, [EducationStore.isMaterialProgressSaved])

    useEffect(() => {
        if (EducationStore.isTestAttemptSaved) {
            EducationStore.loadAllTestAttempts(userStore.user.id)
        }
    }, [EducationStore.isTestAttemptSaved])

    return (
        <>
            <PageTitle title='Обучение персонала'/>
            <div id='education-page' className={`${hideStageList ? 'lg:tw-grid-cols-[2rem_1fr] lg:tw-gap-x-4' : 'lg:tw-grid-cols-4 lg:tw-gap-x-8'} tw-duration-500 lg:tw-grid lg:tw-max-h-full lg:tw-h-full max-lg:tw-w-full`}>
                <StageListContainer 
                    title={'Темы'}
                    list={EducationStore.allTopics}
                    activeStage={EducationStore.topic}
                    idField='name'
                    isHideList={hideStageList}
                    onHideClick={handleHideStageClick}
                    onItemClick={handleJumpStageClick}
                    isSearch={true}
                    isDisabled={false}
                    filterStore={filterStore}
                    selectedFullDataModel={topicDataModel}
                    updateDataObjectList={updateDataObjectList}
                    isLoading={EducationStore.isTopicsLoading}
                />
                <div
                    id='stage-column'
                    className={`${!hideStageList && 'lg:tw-col-span-3'} tw-duration-500 tw-bg-white tw-rounded-md
                                tw-mt-8 lg:tw-mt-0 lg:tw-flex lg:tw-flex-col lg:tw-h-full lg:tw-overflow-hidden`}
                >
                    { EducationStore.isTopicsLoading
                        ?   <Spinner/>
                        :   EducationStore.allTopics && EducationStore.allTopics.length > 0
                                ?
                                    <MaterialsObjectsContainer />
                                : 
                                    <p className='tw-text-md tw-font-semibold tw-mt-4 tw-text-center tw-col-span-4'>
                                        Для обучения персонала нет доступных тем
                                    </p>
                    }
                </div>
            </div>
        </>
    )
}

export default observer(EducationContainer)