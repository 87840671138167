import { Fragment, useContext, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { KeyIcon, PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'
import UserInfoForm from './UserInfoForm'
import UserInfo from './UserInfo'
import ChangePasswordForm from './ChangePasswordForm'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import Spinner from '../../../assets/Spinner'


/**
 * Визуальный компонент отображает информацию об авторизованном пользователе, а так же формы редактирования информации и смены пароля
 * 
 * @returns {JSXElement} Визуальный компонент Dialog из библиотеки {@link https://headlessui.com/|@headlessui}
 * 
 * @see [Вызов компонента](./components_header_HeaderContainer.js.html#line15)
 */
function UserInfoContainer() {
    const [isChangePasswordFormOpen, setIsChangePasswordFormOpen] = useState(false)
    const { userStore } = useContext(Context)

    return (
        <Transition.Root show={userStore.isUserInfoOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={() => {userStore.setIsUserInfoFormOpen(false); userStore.setIsUserInfoOpen(false); setIsChangePasswordFormOpen(false)}}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"/>
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex tw-max-w-full tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel className="tw-pointer-events-auto tw-relative tw-w-screen tw-max-w-lg">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                        <button
                                            type="button"
                                            className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                            onClick={() => {userStore.setIsUserInfoFormOpen(false); userStore.setIsUserInfoOpen(false); setIsChangePasswordFormOpen(false)}}
                                        >
                                            <span className="tw-sr-only">Закрыть</span>
                                            <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                        </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-hidden tw-bg-white tw-py-6 tw-shadow-xl">

                                        <div className="tw-px-4 sm:tw-px-6 tw-flex tw-flex-row">
                                            <Dialog.Title className="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">
                                                {isChangePasswordFormOpen ? 'Изменение пароля' : 'Информация о пользователе'}
                                            </Dialog.Title>
                                            <button 
                                                className={`${userStore.isFullUserInfo ? 'tw-text-gray-800 hover:tw-text-gray-600' 
                                                                : 'tw-text-red-500 hover:tw-text-red-400'} 
                                                            tw-ml-4 disabled:tw-text-gray-400
                                                        `}
                                                disabled={userStore.isUserInfoFormOpen || isChangePasswordFormOpen}
                                                onClick={() => {userStore.setIsUserInfoFormOpen(true); setIsChangePasswordFormOpen(false)}}
                                                data-tooltip-id="user-info-tooltip" data-tooltip-content="Редактировать" data-tooltip-delay-show={1000}
                                            >
                                                <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                            </button>
                                            <button 
                                                className='tw-text-gray-800 hover:tw-text-gray-600 tw-ml-4 disabled:tw-text-gray-400'
                                                disabled={userStore.isUserInfoFormOpen || isChangePasswordFormOpen}
                                                onClick={() => {setIsChangePasswordFormOpen(true); userStore.setIsUserInfoFormOpen(false)}}
                                                data-tooltip-id="user-info-tooltip" data-tooltip-content="Сменить пароль" data-tooltip-delay-show={1000}
                                            >
                                                <KeyIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                            </button>
                                        </div>

                                        { userStore.isLoading
                                            ?  <Spinner/>
                                            : <>
                                                {!userStore.isUserInfoFormOpen && !isChangePasswordFormOpen && 
                                                    <UserInfo />
                                                }

                                                {userStore.isUserInfoFormOpen &&
                                                    <UserInfoForm />
                                                }

                                                {isChangePasswordFormOpen &&
                                                    <ChangePasswordForm
                                                        onCloseClick={setIsChangePasswordFormOpen}
                                                    />
                                                }
                                            </>
                                        }
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
                <Tooltip id="user-info-tooltip" place="top"/>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(UserInfoContainer)