// Функция создает структуры, соответствующие вложенным таблицам записи
export function findNestedModelName (dataModel, field) {
    return dataModel.referenced_models.find(item => item.rule_id === field.rule_id)?.entity_name || field.alias
}

// Функция создает структуры, соответствующие вложенным таблицам записи
export function initNestedModels (dataModel) {
    return dataModel.fields
        .slice()
        .sort((a, b) => a.order - b.order)
        .filter(field => field.type === 'include')
        .map(field => {
            return {
                ...field,
                fieldName: findNestedModelName(dataModel, field),
                dataObjects: [],
            }
        })        
}

// Функция формирует массив новых записей включенной таблицы для создания ссылок на существующие записи справочников
export function addIncludedDataObjects (nestedDataModel) {
    if (nestedDataModel && nestedDataModel?.dataObjects?.length > 0) {
        const addedDataObjects = nestedDataModel.dataObjects
            .slice()
            .filter(nestedDataObject => nestedDataObject.status === 'added')
            .map(nestedDataObject => { return { record_id: nestedDataObject.record_id } })
        
        return addedDataObjects
    }

    return []
}

// Функция формирует массив новых записей вложенной таблицы для сохранения вместе с записью основной таблицы
export function addNestedDataObjects (nestedDataModel) {
    if (nestedDataModel && nestedDataModel?.dataObjects?.length > 0) {
        const addedDataObjects = nestedDataModel?.dataObjects
            .slice()
            .filter(nestedDataObject => nestedDataObject.status === 'added')
            .map(nestedDataObject => Object.entries(nestedDataObject.data))
            .map(nestedDataObject => nestedDataObject.filter(item => 
                item[1].value !== undefined && 
                ((item[1].type === 'value' && item[1].value !== '') || (item[1].type === 'reference' && item[1].value?.values?.length))
            ))
            .map(nestedDataObject => nestedDataObject.map(item => 
                item[1].type !== 'reference' 
                    ?   item
                    :   [item[0], 
                        {...item[1], value: item[1].value.values.map(ref => ref.record_id)}
                    ]))
            .map(nestedDataObject => nestedDataObject.map(item => [item[0], item[1].value]))
            .map(nestedDataObject => { return nestedDataObject.length ? { data: Object.fromEntries(nestedDataObject) } : null})
            .filter(nestedDataObject => nestedDataObject !== null)

        return addedDataObjects
    }

    return []
}

// Функция формирует массив новых и отредактированных записей вложенной таблицы для сохранения вместе с записью основной таблицы
export function updateNestedDataObjects (nestedDataModel) {
    if (nestedDataModel && nestedDataModel?.dataObjects?.length > 0) {
        const addedDataObjects = addNestedDataObjects(nestedDataModel)

        const editedDataObjects = nestedDataModel.dataObjects
            .slice()
            .filter(nestedDataObject => nestedDataObject.status === 'edited')
            .map(nestedDataObject => { return {
                ...nestedDataObject, 
                modified_fields: (Object.entries(nestedDataObject.data)).filter(item => 
                    item[1].value !== undefined && 
                    ((item[1].type === 'value' && item[1].value !== '') || (item[1].type === 'reference' && item[1].value?.values?.length))
                )
            }})
            .map(nestedDataObject => { return {
                ...nestedDataObject, 
                modified_fields: nestedDataObject.modified_fields.map(item => 
                    item[1].type !== 'reference' 
                        ?   item
                        :   [item[0], 
                            {...item[1], value: item[1].value.values.map(ref => ref.record_id).filter(ref => ref !== '0')}
                            ])
            }})
            .map(nestedDataObject => { return {
                ...nestedDataObject, 
                modified_fields: nestedDataObject.modified_fields.map(item => [item[0], item[1].value])
            }})
            .map(nestedDataObject => { return { record_id: nestedDataObject.record_id, data: Object.fromEntries(nestedDataObject.modified_fields) } })

        return addedDataObjects.concat(editedDataObjects)
    }

    return []
}

// Временно сделана отдельная функция для этапа "Категорирование объектов" для удаления обоснования неактуальности показателя значимости
// Функция формирует массив новых и отредактированных записей вложенной таблицы для сохранения вместе с записью основной таблицы
export function updateCIINestedDataObjects (nestedDataModel) {
    if (nestedDataModel && nestedDataModel?.dataObjects?.length > 0) {
        const addedDataObjects = addNestedDataObjects(nestedDataModel)

        const editedDataObjects = nestedDataModel.dataObjects
            .slice()
            .filter(nestedDataObject => nestedDataObject.status === 'edited')
            .map(nestedDataObject => { return {
                ...nestedDataObject, 
                modified_fields: (Object.entries(nestedDataObject.data)).filter(item => 
                    item[1].value !== undefined && 
                    (item[1].type === 'value' || (item[1].type === 'reference' && item[1].value?.values?.length))
                )
            }})
            .map(nestedDataObject => { return {
                ...nestedDataObject, 
                modified_fields: nestedDataObject.modified_fields.map(item => 
                    item[1].type !== 'reference' 
                        ?   item
                        :   [item[0], 
                            {...item[1], value: item[1].value.values.map(ref => ref.record_id).filter(ref => ref !== '0')}
                            ])
            }})
            .map(nestedDataObject => { return {
                ...nestedDataObject, 
                modified_fields: nestedDataObject.modified_fields.map(item => [item[0], (item[1].type === 'value' && item[1].value === '') ? null : item[1].value])
            }})
            .map(nestedDataObject => { return { record_id: nestedDataObject.record_id, data: Object.fromEntries(nestedDataObject.modified_fields) } })

        return addedDataObjects.concat(editedDataObjects)
    }

    return []
}

// Функция формирует массив удаленных записей вложенной таблицы для удаления из записи основной таблицы
export function deleteNestedDataObjects (nestedDataModel) {
    if (nestedDataModel && nestedDataModel?.dataObjects?.length > 0) {
        const deletedDataObjects = nestedDataModel.dataObjects
            .slice()
            .filter(nestedDataObject => nestedDataObject.status === 'deleted')
            .map(nestedDataObject => { return { record_id: nestedDataObject.record_id } })
        
        return deletedDataObjects
    }

    return []
}

// Функция осуществляет удаление всех записей вложенной таблицы
export function deleteAllNestedDataObjects (nestedModel) {
    if (nestedModel && nestedModel?.dataObjects?.length > 0) {
        const deletedDataObjects = nestedModel.dataObjects
            .slice()
            .filter(nestedDataObject => ['saved', 'edited', 'deleted'].includes(nestedDataObject.status))
            .map(nestedDataObject => { return { record_id: nestedDataObject.record_id } })
        
        return deletedDataObjects
    }

    return []
}

// Функция осуществляет добавление записи во вложенную таблицу
export function createNestedDataObject (data, key, selectedNestedDataModel) {
    return {
        id: Date.now() + '_' + key,
        data: data, 
        created: Date.now(),
        order: key,
        status: 'added',
        data_model_id: selectedNestedDataModel.id 
    }
}

// Функция осуществляет добавление пустой записи во вложенную таблицу
export function createEmptyFieldData (nestedModel) {
    return Object.fromEntries(nestedModel.fields.map(field => { 
        return [field.tech_name, 
                Object.assign({}, {
                    ...field, 
                    value: field.default !== undefined
                        ?   field.default
                        :   field.type === 'reference' 
                            ?   {format: '', values: []}
                            :   field.validator_type === 'bool'
                                    ?   false
                                    :   null
                })
        ]
    }))
}
