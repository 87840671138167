import React from 'react'
import { observer } from 'mobx-react-lite'
import { getFormattedDate } from '../../../../../config/constTypes'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { htmlFormatter } from '../../../../../functions/htmlFormatter'
import { handleFileDownload } from '../../../../../functions/fileHandlers'

/**
 * Визуальный компонент отображает информацию о выбранной печатной форме
 * @param {Object} activeItem Выбранная печатная форма
 * @param {String} dataModel Имя таблицы, для которой предназначена печатная форма
 * @returns {HTMLDivElement} Html-разметку информации о выбранной печатной форме
 * 
 * @see [Вызов компонента](./components_main_page_controller_exporter_ExporterListContainer.js.html#line248)
 */
const ExporterInfo = ({activeItem, dataModel}) => {
    return (
        <div id='data-model-info' className='tw-w-full tw-min-w-fit tw-h-[calc(100%_-_3rem)] tw-overflow-auto'>
            <dl>
            <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Название</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">{activeItem.name}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Таблица</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">{dataModel || activeItem?.meta?.data_model_id}</dd>
                </div>
                {activeItem?.render_data?.type === 'html' &&
                    <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Содержимое</dt>
                        <dd className="tw-whitespace-pre-wrap tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">{activeItem?.render_data?.content ? htmlFormatter(activeItem?.render_data?.content) : ''}</dd>
                    </div>
                }
                {activeItem.render_data.type === 'file' &&
                    <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                        <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Файл шаблона</dt>
                        <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">
                            { activeItem.render_data.file &&
                                <div className='tw-flex tw-items-center tw-gap-x-2'>
                                    <span>{activeItem.render_data.file?.metadata?.name}.{activeItem.render_data.file?.metadata?.extension}</span>
                                    <button 
                                        className='tw-text-gray-500 hover:tw-text-gray-800 focus-visible:tw-text-gray-600 focus-visible:tw-outline 
                                                focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600 focus-visible:tw-rounded-sm'
                                        type='button'
                                        onClick={() => handleFileDownload(activeItem.render_data.file)}
                                    >
                                        <DocumentArrowDownIcon className="tw-w-6 tw-h-6" aria-hidden="true"/>
                                    </button>
                                </div>
                            }
                        </dd>
                    </div>
                }
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ID</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">{activeItem.id}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Дата создания</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">{getFormattedDate(activeItem.created, 'dd.MM.yyyy')}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Дата изменения</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">{getFormattedDate(activeItem.last_modified, 'dd.MM.yyyy')}</dd>
                </div>
            </dl>                
        </div>
    )
}

export default observer(ExporterInfo)