import React from 'react'
import { DocumentPlusIcon, DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite'
import { createEmptyFieldData, createNestedDataObject } from '../../../functions/nestedModels'


/**
 * Визуальный компонент отображает меню редактора вложенных таблиц
 * 
 * @param {Object[]} nestedDataModels Массив вложенных таблиц
 * @param {Object} selectedNestedDataModel Выбранная вложенная таблица
 * @param {Object} selectedNestedDataObject Выбранная запись вложенной таблицы
 * @param {Function} setNestedDataModels Изменение в массиве вложенных таблицы
 * [setNestedDataModels](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line47),
 * [setNestedDataModels](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line104),
 * [setNestedDataModels](./components_main_page_controller_data_object_add_AddDataObjectContainer.js.html#line45),
 * [setNestedDataModels](./components_main_page_controller_data_object_add_DuplicateDataObjectContainer.js.html#line52),
 * [setNestedDataModels](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line55)
 * @param {Function} setSelectedNestedDataObject Изменение выбранной записи вложенной таблицы
 * [setSelectedNestedDataObject](./components_main_page_controller_data_object_tabs_DataObjectTabs.js.html#line56),
 * [arrow function](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line403),
 * [arrow function](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line358),
 * [arrow function](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line372),
 * [arrow function](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line212),
 * [arrow function](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line280),
 * [arrow function](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line325),
 * [arrow function](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line395),
 * [arrow function](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsFormItem.js.html#line286)
 * @param {Boolean} isDocumentPage Признак нахождения на странице документов
 * @param {Function} onChange Сторонняя функция для изменений в массиве вложенных таблицы 
 * [handleObjectListChange](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line299),
 * [handleChangeNestedTables](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsFormItem.js.html#line66),
 * [handleChangeNestedTables](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line268),
 * [handleChangeNestedTables](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line229),
 * [handleChangeNestedTables](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line244),
 * [handleChangeMembers](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line136),
 * [handleChangeNestedTables](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line199),
 * [handleChangeProcesses](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line189),
 * [handleChangeNestedTables](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line82)
 * 
 * @returns {HTMLDivElement} Html-разметку кнопок меню редактора вложенных таблиц
 * 
 * @see [Вызов компонента NestedTableTabPanel,](./components_tabs_nested_tables_NestedTableTabPanel.js.html#line52)
 * [ResourcesForm,](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line425)
 * [CategorizingObjectsFormItem,](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsFormItem.js.html#line282)
 * [FieldItem](./components_form_fields_FieldItem.js.html#line142)
 */
const NestedModelMenu = ({nestedDataModels, selectedNestedDataModel, selectedNestedDataObject, setNestedDataModels, 
                            setSelectedNestedDataObject, isDocumentPage, onChange}) => {
    let isButtonDisabled = !selectedNestedDataObject || !selectedNestedDataModel.fields?.length || selectedNestedDataModel.id === 'indicatorss' 
                            || selectedNestedDataModel?.id !== selectedNestedDataObject?.data_model_id

    const handleAddItemClick = (e) => {
        e.preventDefault()

        const originalNestedModel = nestedDataModels.find(item => item.rule_id === selectedNestedDataModel.rule_id)

        const  dataField = createEmptyFieldData(selectedNestedDataModel)

        const editedNestedModel = originalNestedModel.dataObjects.concat([
            createNestedDataObject(dataField, originalNestedModel.dataObjects.length, selectedNestedDataModel)
        ])

        if(isDocumentPage){
            setNestedDataModels(nestedDataModels.map(item => 
                item.rule_id === selectedNestedDataModel.rule_id
                    ?   {...item, dataObjects: editedNestedModel}
                    :   item
            ))
        } else {
            onChange(editedNestedModel, selectedNestedDataModel)
        }
    }


    const handleDuplicateItemClick = (e) => {
        e.preventDefault()

        const originalNestedModel = nestedDataModels.find(item => item.rule_id === selectedNestedDataModel.rule_id)

        const  dataField = JSON.parse(JSON.stringify(selectedNestedDataObject.data))
        const duplicatedDataObject = createNestedDataObject(dataField, originalNestedModel.dataObjects.length, selectedNestedDataModel)

        const editedNestedModel = originalNestedModel.dataObjects.concat([duplicatedDataObject])

        setSelectedNestedDataObject(duplicatedDataObject)
        
        if(isDocumentPage){
            setNestedDataModels(nestedDataModels.map(item => 
                item.rule_id === selectedNestedDataModel.rule_id
                    ?   {...item, dataObjects: editedNestedModel}
                    :   item
            ))
        } else {
            onChange(editedNestedModel, selectedNestedDataModel)
        }
    }


    const handleDeleteItemClick = (e) => {
        e.preventDefault()

        const originalNestedModel = nestedDataModels.find(item => item.rule_id === selectedNestedDataModel.rule_id)

        let editedNestedModel
        if (selectedNestedDataObject && selectedNestedDataObject.status === 'added')
            // вновь добавленная запись удаляется из списка
            editedNestedModel = originalNestedModel.dataObjects.filter(item => item.id !== selectedNestedDataObject.id).map((item, index) => {return {...item, order: index}})
        else
            // у сохраненной записи меняется статус
            editedNestedModel = originalNestedModel.dataObjects.map(item => item.id !== selectedNestedDataObject.id ? item : {...item, status: 'deleted'})

        setSelectedNestedDataObject(null)

        if(isDocumentPage){
            setNestedDataModels(nestedDataModels.map(item =>
                item.rule_id === selectedNestedDataModel.rule_id
                    ?   {...item, dataObjects: editedNestedModel}
                    :   item
            ))
        } else {
            onChange(editedNestedModel, selectedNestedDataModel)
        }
    }

    return (
        <div className='tw-flex tw-flex-row tw-justify-end tw-items-center tw-space-x-1 tw-pr-2 tw-pb-1 tw-border-b tw-border-gray-400'>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={handleAddItemClick}
                disabled={!selectedNestedDataModel.fields?.length || selectedNestedDataModel.id === 'indicatorss'}
                data-tooltip-id="nested-editor-tooltip" data-tooltip-content="Создать"
            >
                <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={handleDuplicateItemClick}
                disabled={isButtonDisabled}
                data-tooltip-id="nested-editor-tooltip" data-tooltip-content="Дублировать"
            >
                <DocumentDuplicateIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={handleDeleteItemClick}
                disabled={isButtonDisabled}
                data-tooltip-id="nested-editor-tooltip" data-tooltip-content="Удалить"
            >
                <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <Tooltip id="nested-editor-tooltip" place="top"/>
        </div>
    )
}

export default observer(NestedModelMenu)