import React, { useState, useContext } from 'react'
import { Context } from '../../../../..'
import { Tooltip } from 'react-tooltip'
import StageButtons from '../stage/StageButtons'
import { observer } from 'mobx-react-lite'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import Spinner from '../../../../../assets/Spinner'
import DocumentService from '../../../../../services/DocumentService'
import { printDocumentPacket } from '../../../../../functions/fileHandlers'

/**
 * Визуальный компонент отображает этап печати перечня под категорирование
 * 
 * @returns {JSXElement} Html-разметку этапа печати перечня под категорирование 
 * с использованием визуальных компонентов {@link Spinner}, {@link StageButtons}
 * 
 * @see [Вызов компонента](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line153) 
 */
const PrintListForm = () => {   
    const { categorizingCIIStore } = useContext(Context)
    const [isPrintingDocument, setIsPrintingDocument] = useState(false)

    const handlePrintDocument = async () => {
        try {
            let categorizingList = categorizingCIIStore.linkedList.values[0]

            const documents = {}
            documents.templates = {
                "regulator_cover_letter": categorizingList.id,
                "regulator_registry": categorizingList.id,
                "fstek_cover_letter": categorizingList.id,
                "fstek_registry": categorizingList.id,
                "kii_objects_act": categorizingList.id,
                "order_create_commission": categorizingCIIStore.commissionID.values[0].id
            }

            // печать комплекта документов с перечнем под категорирование
            printDocumentPacket('kii_objects_act', documents, setIsPrintingDocument)

            // формирование необходимости заново сохранить и напечатать существующие акты категорирования
            if (categorizingCIIStore.objectsCategorization && categorizingCIIStore.objectsCategorization.length) {
                const objectsCategorizationRequests = categorizingCIIStore.objectsCategorization.map(objectCategorization => {
                    return DocumentService.updateDataObject(objectCategorization.record.record_id, { 
                        data: { 'date_of_print_act__act_of_categorir': null, 'is_data_current__act_of_categorir': false }
                    })
                })

                Promise.all(objectsCategorizationRequests)
                    .then(() => {
                        categorizingCIIStore.updateProject('date_of_print_st_6__stages_of_categorization', Date.now(), true)
                    })    
            } else
                await categorizingCIIStore.updateProject('date_of_print_st_6__stages_of_categorization', Date.now(), true)

        } catch (error) {
            setIsPrintingDocument(false)
            showErrorToast(error, 'printing', '')
        }
    }

    const handleBackClick = () => {
        categorizingCIIStore.goPrevStage()
    }

    const handleForwardClick = () => {
        categorizingCIIStore.goNextStage()  
    }

    return (
        <>
            { !isPrintingDocument &&
                <>
                    <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-mx-auto tw-py-2'>
                        <button 
                            className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                    hover:tw-bg-gray-600 hover:tw-border-gray-600 
                                    focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                            onClick={handlePrintDocument}
                        >
                            Скачать перечень под категорирование
                        </button>
                    </div>
                    <div className='tw-grow tw-overflow-auto tw-text-center'>
                        <div className='tw-px-4 tw-py-1 tw-mt-1'>
                            <p className='tw-text-sm tw-mt-1'>Уважаемый пользователь, Вам нужно скачать и распечатать пакет документов, относящихся к акту о составлении перечня объектов КИИ</p>
                        </div>
                    </div>
                </>                                                             
            }
            { isPrintingDocument &&
                <div className='tw-grow tw-overflow-auto'>
                    <div className='tw-px-4 tw-py-1 tw-mt-1'>
                        <p className='tw-mt-2 tw-text-center tw-text-sm tw-font-semibold'>Выполняется скачивание перечня под категорирование и сопроводительных документов...</p>
                        <Spinner/>
                    </div>
                </div>
            }
            <StageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={!categorizingCIIStore.linkedListPrintDate || isPrintingDocument}
            />
            <Tooltip id="print-form-tooltip" place="top"/>
        </>                                                             
    )
}

export default observer(PrintListForm)