import React, { useContext, useState } from 'react'
import UsersListItem from './UsersListItem';
import { Context } from '../../../..';
import { observer } from 'mobx-react-lite';
import SearchInput from '../../../search/SearchInput';
import FilterStore from '../../../../store/FilterStore';
import { Tooltip } from 'react-tooltip';

/**
 * Визуальный компонент отображает список существующих пользователей
 * @returns {JSXElement} Html-разметку списка существующих пользователей
 * с использованием визуального компонента {@link UsersListItem}
 * 
 * @see [Вызов компонента](./components_admin_page_AdminContainer.js.html#line64)
 */
const UsersList = () => {
    const { AdminStore } = useContext(Context)
    const [filterStore] = useState(() => new FilterStore())
    const [searchResults, setSearchResults] = useState(AdminStore.users)

    let className = `tw-flex tw-flex-col tw-h-[calc(100%_-_6rem)] tw-w-full tw-overflow-auto`,
        searchIsDisabled = false

    if (AdminStore.isSubCompanyCreating || AdminStore.isSubCompanyEditing || AdminStore.isUserCreating || AdminStore.isUserEditing) {
        className += ` tw-pointer-events-none tw-opacity-50`
        searchIsDisabled = true
    }
    
    const handleChange = () => {
        if (filterStore.searchField !== "") {
            let searchArray = filterStore.searchField.split(" ")
            const results = AdminStore.users.filter(user => {
                let matches = 0
                searchArray.forEach(item => {                
                    if (user.first_name?.toLowerCase().includes(item.trim().toLowerCase()) 
                        || user.last_name?.toLowerCase().includes(item.trim().toLowerCase())
                        || user.email?.toLowerCase().includes(item.trim().toLowerCase())
                    ) {
                        matches++
                    }
                })
                if (matches === searchArray.length) {
                    return true
                }
            })
            setSearchResults(results)
        } else {
            setSearchResults(AdminStore.users)
        }
    }
    
    return (
        <>
            <div className='tw-p-2'>
                <SearchInput
                    isDisabled={searchIsDisabled}
                    filterStore={filterStore}
                    selectedFullDataModel={{id: "users", fields: []}}
                    updateDataObjectList={handleChange}
                />
            </div>
            
            <div  id='users-list-items' className={className}>
                {searchResults.slice()
                    .sort((a) => a.is_active ? -1 : 1)
                    .sort((a) => a.allowed_sub_company_ids.find(item => item === AdminStore.selectedSubCompany.id) ? -1 : 1)
                    .sort((a) => a.is_active && a.allowed_sub_company_ids.find(item => item === AdminStore.selectedSubCompany.id) ? -1 : 1)
                    .map(user => (
                        <UsersListItem 
                            key={user.id}
                            item={user}
                        />
                    ))
                }
            </div>
            <Tooltip id="users-list-tooltip" place="top" style={{ zIndex: 99 }}/>
        </>
    )
}

export default observer(UsersList)