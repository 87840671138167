/**
 * Константы типов полей, а также методы обработки полей
 * @module TypeConsts
 */
import { format } from "date-fns"

/**
 * Типы полей
 */
export const fieldTypes = [
    {id: 1, name: 'Целый', value: 'int', type: 'value', tech_name: 'int', default: 0, option: {}, hidden: false},
    {id: 2, name: 'Вещественный', value: 'float', type: 'value', tech_name: 'float', default: 0, option: { format: 2 }, hidden: false},
    {id: 3, name: 'Логический', value: 'bool', type: 'value', tech_name: 'bool', default: false, option: {}, hidden: false},
    {id: 4, name: 'Строка', value: 'string', type: 'value', tech_name: 'string', default: '', option: {}, hidden: false},
    {id: 5, name: 'Список', value: 'enum', type: 'value', tech_name: 'enum', default: '', option: {}, hidden: false},
    {id: 6, name: 'Дата', value: 'date', type: 'value', tech_name: 'date', default: '', option: { format: 'dd.MM.yyyy' }, hidden: false},
    {id: 7, name: 'Дата и время', value: 'datetime', type: 'value', tech_name: 'date', default: '', option: { format: 'dd.MM.yyyy HH:mm:ss' }, hidden: false},
    {id: 8, name: 'Ссылка', value: 'one', type: 'reference', tech_name: 'one', default: {id: '0', format: '{name}', data: { name: {value: ''}}}, option: {}, hidden: false},
    {id: 9, name: 'Включенная таблица', value: 'many', type: 'include', tech_name: 'many', default: '', option: {}, hidden: false},
]

/**
 * Типы таблиц
 */
export const dataModelTypes = [
    {id: '1', name: 'Справочник', value: 'directory', fixed_fields: ['Наименование', 'Комментарий']},
    {id: '2', name: 'Документ', value: 'document', fixed_fields: ['Дата', 'Комментарий']},
    {id: '3', name: 'Вложенная таблица', value: 'nested', fixed_fields: []},
]

/**
 * Типы групп модулей МЕДОЕДа
 */
export const moduleTypes = [
    {id: '1', name: 'Активные модули', value: 'active', theme: 'green', link: '', actionText: 'Продолжить', modules: [], placeholder: 'У вас пока нет активных модулей'}, 
    {id: '2', name: 'Доступные модули', value: 'available', theme: 'blue', link: '', actionText: 'Начать', modules: [], placeholder: 'У вас уже активны все доступные модули'}, 
    {id: '3', name: 'Недоступные модули', value: 'unavailable', theme: 'red', link: 'mailto:support.medoed@rtmtech.ru?subject=Подключить модуль платформы MEDOED', actionText: 'Запросить', modules: [], placeholder: 'Вам доступны все модули'}, 
    {id: '4', name: 'В разработке', value: 'plan', theme: 'gray', link: 'mailto:support.medoed@rtmtech.ru?subject=Интерес к модулю платформы MEDOED', actionText: 'Хочу', modules: [], placeholder: 'В данный момент нет модулей, находящихся в разработке'}, 
]

/**
 * Типы вкладок записей по умолчанию
 */
export const defaultTabs = [
    { id: 'files', entity_name: 'Файлы' },
    { id: 'notes', entity_name: 'Сообщения' },
]

/** Время ожидания ответа от сервиса */
export const responseTimeOut = 20000

/** Время ожидания показа сообщения о недоступности сервиса */
export const serviceMessageTimeOut = 3000

/** Время ожидания показа сообщения об успешной операции */
export const successMessageTimeOut = 1000

/** Время задержки отображения всплывающей подсказки кнопки меню */
export const menuTooltipTimeOut = 500

/** Время задержки отображения всплывающей подсказки содержимого поля */
export const contentTooltipTimeOut = 1000

/** Максимальный размер файла, прикрепляемого к записи (в МБ) */
export const maxFileSize = 250

/** Максимально допустимый уровень вложенности ссылки на поля в шаблоне печатной формы */
export const maxFieldHierarchyLevel = 4

/**
 * Метод выводит простой тип поля в удобочитаемом для пользователя виде
 * @param {String} type Значение простого типа поля
 * @returns {String} Название простого типа
 */
export const getValueTypeName = (field) => {
    let foundType
    if (field.validator_type === 'date') {
        foundType = fieldTypes.find(item => item.option.format === field?.options?.format)
    } else if (field.validator_type === 'many') {
        foundType = fieldTypes.find(item => item.type === field.type)
    } else {
        foundType = fieldTypes.find(item => item.value === field.validator_type)
    }
    return foundType ? foundType.name : 'неизвестный' 
}

/**
 * Метод выводит ссылочный тип поля в удобочитаемом для пользователя виде
 * @param {String} list Контекстные таблицы
 * @param {String | Array<string>} id ID или массив ID таблиц, имя которых необходимо отобразить
 * @returns {String} Название ссылочной(ых) таблиц
 */
export const getReferenceTypeName = (list, id) => {
    if (Array.isArray(id)) {
        const foundTypes = list.filter(item => id.includes(item.id))
        return foundTypes.length > 0 ? foundTypes.map(item => item.entity_name).join(' или ') : 'неизвестный'        
    } else {
        const foundType = list.find(item => id === item.id)
        return foundType ? foundType.entity_name : 'неизвестный'        
    }
}

/**
 * Метод выводит дату в заданном формате
 * @param {String|Number} date Дата
 * @param {String} formatStr Формат
 * @returns {String} Дата в заданном формате
 */
export const getFormattedDate = (date, formatStr) => {
    const regStr = new RegExp(/[A-za-z]/)
    if (date) {
        if (formatStr && formatStr !== 'undefined') {
            try {
                if (regStr.test(date)) {
                    const dateISO = new Date(date)
                    const timestamp = dateISO.getTime()

                    return format(timestamp, formatStr)
                } else return format(Number(date), formatStr)
            } catch (error) {
                return 'Ошибка чтения записи'
            }
        } else return 'Неизвестный формат записи'
    } else return ''
}

/**
 * Метод выводит соответствующее обозначение для различия типов 'Дата' или 'Дата и время'
 * @param {Object} field Поле типа 'Дата' или 'Дата и время'
 * @returns {String} Строку 'date' (для типа 'Дата') или 'datetime' (для типа 'Дата и время')
 */
export const getFieldType = (field) => {
    switch(field.validator_type) {
        case 'date':
            return fieldTypes
                .filter(item => item.tech_name === 'date')
                .find(item => item.option.format.toLowerCase() === field.options.format.toLowerCase())
        default:
            return fieldTypes.find(item => item.tech_name === field.validator_type)
    }
}

/**
 * Метод выводит представление поля типа "Reference" для отображения в записях
 * @param {Object} value Значение ссылочного поля таблицы
 * @returns {String} Представление поля
 */
export const getReferenceValue = (value) => {
    if (value?.values?.length) {    
        if (value.format && value.format !== 'undefined') {
            let formatFieldStr = value.format
                .replace(/{(.*?)}/g, (match) => {
                    let field = match.slice(1, -1).split('|')

                    if (field[1] === 'date') {
                        return getFormattedDate(value.values[0][field[0]], field[2])
                    }

                    return value.values[0][field[0]] 
                })
            try {
                return formatFieldStr === 'undefined'? 'Ошибка чтения записи' : formatFieldStr
            } catch (error) {
                return 'Ошибка чтения записи'
            }
       } else return 'Неизвестный формат записи'
    } else return ''
}

/**
 * Метод формирует представление поля типа "Reference" 
 * для отображения текущего (выбранного) значения в выпадающем списке записей и в компонентах форм
 * @param {Object} value Значение ссылочного поля таблицы
 * @param {Number} index Индекс значения в массиве значений (из-за наличия типа "Ссылка множественная")
 * @returns {String} Представление поля
 */
export const setReferenceValue = (dataObject) => {
    const RegExpFields = new RegExp(/{([a-zA-Z1-9_-]+)[|}]{1}/g)
    let fields = []

    if (dataObject.format) {
        const matchAll = dataObject.format.matchAll(RegExpFields)
        fields = Array.from(matchAll)
    }

    const newObject = {}
    newObject.format = dataObject.format
    newObject.values = [{}]

    try {
        newObject.values[0].entity_name = dataObject.entity_name
        newObject.values[0].id = dataObject.id
        newObject.values[0].record_id = dataObject.record_id

        fields.forEach(field => {
            if (field[1] !== 'entity_name')
                newObject.values[0][field[1]] = dataObject.data[field[1]]?.value
        })

    } catch (error) {
        newObject.values[0].entity_name = ''
        fields.forEach(field => {
            if (field[1] !== 'entity_name')
                newObject.values[0][field[1]] = ''
        })
    }

    return newObject
}

/**
 * Метод выводит значение поля в соответствующем формате
 * @param {Object} field Поле таблицы
 * @returns {String} Значение поля
 */
export const getFieldValue = (field) => {
    switch(field.validator_type) {
        case 'date':
            return getFormattedDate(field.value, field.options.format)
        case 'float': 
            return field.value === undefined || field.value === '' ? '' : Number(field.value).toFixed(field.options.number_decimal_places)
        case 'int': 
            return field.value === undefined || field.value === '' ? '' : field.value
        case 'bool': 
            return field.value === undefined ? '' : field.value ? 'Да' : 'Нет'
        case 'one':  
            return getReferenceValue(field.value)
        case 'many': 
            return getReferenceValue(field.value)
        default:
            return  field.value || ''
    }
}

/**
 * Метод выводит значение по умолчанию поля в соответствующем формате
 * @param {Object} field Поле таблицы
 * @returns {String} Значение поля
 */
export const getFieldDefaultValue = (field) => {
    switch(field.validator_type) {
        case 'date':
            return getFormattedDate(field.default, field.options.format)
        case 'bool': 
            return field.default === undefined 
                ? '' 
                : ((field.default + '').toLowerCase() === 'true') 
                    ? 'Да' 
                    : 'Нет'
        case 'one':
            return getReferenceValue(field.default)
        default:
            return  field.default
    }
}

/**
 * Метод формирует значение поля типа "Дата" для редактирования
 * для остальных типов полей - без изменений
 * @param {Object} field Поле таблицы
 * @returns {String} Значение поля для подстановки в компонент редактирования
 */
export const setFieldValue = (field) => {
        switch(field.validator_type) {
        case  'date':
            if (field.value == null) {
                return null
            } else {
                const date = new Date(field.value);
                return date
            }
        case 'one':
        case 'many': 
            return field.value.map(item => item.id).join(', ')
        case 'bool': 
            return (field.value === undefined) 
                ? Boolean(field.default && (field.default + '').toLowerCase() === 'true') 
                : field.value
        case 'enum': 
            return (!field.value) ? (field.default || null) : field.value
        default:
            if (field.value === 0) {
                return field.value
            }
            return (!field.value) ? (field.default || null) : field.value
    }
}

/**
 * Метод формирует значение по умолчанию для поля
 * @param {Object} field Поле таблицы
 * @returns {String} Значение поля для подстановки в компонент редактирования
 */
export const setFieldDefaultValue = (field) => {
    switch(field.validator_type) {
        case  'date': 
            if (!field.default) {
                return undefined
            } else {
                const date = new Date(field.default);
                return date
            }
        case  'bool': return Boolean(field.default && (field.default + '').toLowerCase() === 'true')
        case  'enum': return (!field.default) ? '' : field.default
        default:
            return field.default
    }
}

/**
 * Метод выводит тип таблицы в удобочитаемом для пользователя виде
 * @param {String} type Значение простого типа поля
 * @returns {String} Название простого типа
 */
export const getDataModelType = (dataModel) => {
    const type = dataModel.type === 'document' 
                ?   'Документ' 
                :   dataModel.type === 'nested' 
                    ? 'Вложенная' 
                    : 'Справочник'
    const builtIn = dataModel.built_in ? ' (из конфигурации системы)' : ' (пользовательский)'

    return  type + builtIn
}
