import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../..'
import { toast } from 'react-toastify'
import RegistrationForm from './RegistrationForm'
import Spinner from '../../../assets/Spinner'


/**
 * Компонент реализует логику самостоятельной регистрации пользователя в системе
 * 
 * @returns {JSXElement} Html-разметку страницы для регистрации пользователя
 * с использованием визуальных компонентов {@link RegistrationForm}, {@link Spinner}
 */
const RegistrationFormContainer = () => {
    const { userStore } = useContext(Context)
    const [isPasswordError, setIsPasswordError] = useState(false)
    const [isPhoneError, setIsPhoneError] = useState(false)
    const [isUsernameError, setIsUsernameError] = useState(false)
    const regexPhone = new RegExp(/^(\+7|7|8)[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/)
    const regexPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w])(?!.*[А-я])(?!.*[\s]).{8,}/g)

    const onSubmitClick = (form) => {
        setIsPasswordError(false)
        setIsPhoneError(false)
        setIsUsernameError(false)
        regexPassword.lastIndex = 0

        if (!regexPassword.test(form.password)) {
            toast.error(`Пароль должен быть не менее 8 символов, содержать прописные и строчные буквы латинского алфавита, спецсимволы и цифры.\n
                Пароль не должен включать прописные и строчные буквы кириллического алфавита, а так же пробелы`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
            setIsPasswordError(true)
        } else if(form.password !== form.password_repeat){
            toast.error('Пароли не совпадают', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
            setIsPasswordError(true)
        } else if (!regexPhone.test(form.phone_number)) {
            toast.error(`Неверный формат номера телефона`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
            setIsPhoneError(true)
        } else if (form.username.includes('@')) {
            toast.error(`Логин не должен содержать символ "@"`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
            setIsUsernameError(true)
        } else {
            const user = {}
            user.email = form.email.trim()
            user.password = form.password.trim()
            user.first_name = form.first_name.trim()
            user.last_name = form.last_name.trim()
            user.username = form.username.trim()
            user.name = form.name.trim()
            user.phone_number = form.phone_number.trim()
            user.newsletter_sub = form.subscription

            sessionStorage.setItem('userRegistration', JSON.stringify(user))
            userStore.userRegistration(user)
        }
    }

    useEffect(() => {
        sessionStorage.removeItem('userRegistration')
    }, [])

    return (
        <>
           {userStore.isLoading 
                ?   <Spinner/>
                :   <>
                        {userStore.isRegistrationSuccess 
                            ?   <div className='tw-mx-auto tw-p-12 tw-text-center tw-text-xl'>
                                    <p className='tw-font-bold'>Регистрация в системе MEDOED прошла успешно!</p>
                                    <p className='tw-mt-4'>
                                        Для подтверждения регистрации, необходимо перейти по ссылке,
                                        <br/>отправленной на почту, которую Вы указали
                                    </p>
                                    <p className='tw-mt-4'>Срок действия ссылки - 1 час</p>
                                </div>
                            :   <RegistrationForm
                                    isPasswordError={isPasswordError}
                                    isPhoneError={isPhoneError}
                                    isUsernameError={isUsernameError}
                                    onSubmitClick={onSubmitClick}
                                /> 
                        }
                    </>
            }
        </>
    )
}

export default observer(RegistrationFormContainer)