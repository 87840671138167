import React from 'react'
import ImportDataForm from './ImportDataForm'
import ImporterService from '../../../../../services/ImporterService'
import { domainControllers, protocolTypes } from '../../../../../config/constsImport'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { paths } from '../../../../../config/constsURN'


/**
 * Компонент реализует логику импорта данных из сервера домена
 * @returns {JSXElement} Визуальный компонент {@link ImportDataForm}
 */
const ImportDataContainer = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const dummyProtocolType = { name: 'Выберите протокол...', value: '', hidden: false }
    const dummyDomainController = { name: 'Выберите контроллер домена...', value: '', hidden: false }

    const handleSumbitClick = (form) => {
        const newConnectionConfig = {}
        newConnectionConfig.protocol = form.protocolType.value
        newConnectionConfig.server = form.domainController.value
        newConnectionConfig.url = form.URL
        newConnectionConfig.domain = form.domainAddress
        newConnectionConfig.user = form.userName
        newConnectionConfig.passwd = form.password
        newConnectionConfig.users = form.dataType.value.includes('users')
        newConnectionConfig.hosts = form.dataType.value.includes('hosts')
        
        ImporterService
            .importServerData(newConnectionConfig)
            .then(() => {
                navigate(paths.IMPORT_VIEW_ROUTE + '/' + id + '/' + newConnectionConfig.domain)
            })
            .catch(() => {
                toast.error('Ошибка при выполнении импорта данных!', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
            })
    }

    return (
        <ImportDataForm
            protocolTypeList={protocolTypes}
            defaultProtocolType={dummyProtocolType}
            domainControllerList={domainControllers}
            defaultDomainController={dummyDomainController}
            onSubmitClick={handleSumbitClick}
        />
    )
}

export default ImportDataContainer