import React from 'react'
import LinkedItem from './LinkedItem'


/**
 * Визуальный компонент отображает список связываемых сущностей (для списка из выбранных пользователем записей)
 * @param {Object[]} list список связанных объектов КИИ или ресурсов доступа
 * @param {Object} selectedItem Выбранный элемент списка
 * @param {Object} linkingItem Элемент, связанный с выбранным элементом списка
 * @param {String} linkField Имя поля, по которому выполняется связывание
 * @param {Function} onItemClick Обработчик клика мыши на элементе списка
 * [handleProcessClick](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line45),
 * [handleResourceClick](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line53),
 * [handleResourceClick](./components_main_page_controller_categorizing_cii_form_SpheresForm.js.html#line40),
 * [handleSphereClick](./components_main_page_controller_categorizing_cii_form_SpheresForm.js.html#line33)
 * @param {Function} onLinkClick Обработчик клика мыши на элементе списка, связанном с выбранным элементом списка
 * [handleLinkingClick](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line61),
 * [handleLinkingClick](./components_main_page_controller_categorizing_cii_form_SpheresForm.js.html#line48)
 * @param {Object[]} keyFieldNames Массив названий требуемых полей
 * 
 * @returns {HTMLDivElement} Html-разметку списка связываемых объектов
 * с использованием визуального компонента {@link LinkedItem}
 * 
 * @see [Вызов компонента ResourcesForm,](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line443)
 * [ResourcesForm,](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line475)
 * [SpheresForm,](./components_main_page_controller_categorizing_cii_form_SpheresForm.js.html#line225)
 * [SpheresForm](./components_main_page_controller_categorizing_cii_form_SpheresForm.js.html#line256)
 */
const LinkedList = ({list, selectedItem, linkingItem, linkField, onItemClick, onLinkClick, keyFieldNames}) => {
    return (
        <>
            { list
                .slice()
                .filter(item => item.status !== 'deleted')
                .map((item, index) => 
                    <LinkedItem 
                        key={index}
                        item={item}
                        selectedItem={selectedItem}
                        linkingItem={linkingItem}
                        linkField={linkField}
                        onClick={onItemClick}
                        onLinkClick={onLinkClick}
                        keyFieldNames={keyFieldNames}
                    />
            )}
        </>
    )
}

export default LinkedList