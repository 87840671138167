import React from 'react'
import PageTitle from '../main_page/controller/common/panels/PageTitle'
import { agreementText } from './agreement'
import parse from 'html-react-parser'

/**
 * Визуальный компонент отображает информацию о согласии на обработку персональных данных
 * 
 * @returns {JSXElement} Html-разметку для информации о согласии на обработку персональных данных
 * с использованием визуальных компонентов {@link PageTitle}
 */
const AgreementInfo = () => {
    return (
        <>
            <PageTitle title='СОГЛАСИЕ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ'/>
            <div className='tw-px-4'>
                {parse(agreementText)}
            </div>
        </>
    )
}

export default AgreementInfo