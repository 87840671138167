// Функция сравнивает содержимое исходной записи и формы редактирования после нажатия кнопки "Сохранить",
// чтобы запрос на сохранение в БД отправлять только при наличии изменений
export function compareDataObjects(original, edited) {
    const sourceDataObject = Object.entries(original.data).filter(item => (!item[1].hide || item[1].mandatory)).sort()
    const resultDataObject = Object.entries(edited.data).sort()

    let isEdited = false
    sourceDataObject.forEach(([key, value], index) => {
        const originalValue = value.value
        if(resultDataObject[index]){
            const editedValue = resultDataObject[index][1]
            if (value.type !== 'include') {
                switch (value.validator_type) {
                    case 'int':
                    case 'float':
                    case 'bool':
                    case 'string':
                    case 'enum':
                        if ((originalValue !== undefined || editedValue !== null) && (originalValue !== editedValue))
                            isEdited = true
                        break
                    case 'date':
                        const originalDate = originalValue ? (new Date(originalValue)).toISOString() : null
                        const editedDate = (editedValue instanceof Date) ? editedValue.toISOString() : null
                        if (originalDate !== editedDate)
                            isEdited = true
                        break
                    case 'one':
                    case 'many':
                        if (originalValue.values.map(ref => ref.record_id).toString() !== editedValue?.toString())
                            isEdited = true
                        break
                    default:
                }
            }
        }
    })
    sourceDataObject.files = original.system_data.files ? original.system_data.files.map(item => item.id).join(',') : ''
    resultDataObject.files = edited.system_data.files ? edited.system_data.files.map(item => item.id).join(',') : ''
    if (sourceDataObject.files !== resultDataObject.files)
        isEdited = true
    if (original.system_data.parent_record_id !== edited.system_data.parent_record_id)
        isEdited = true

    return isEdited
}

//Метод осуществляет подготовку основных полей формы
export function processData (data, activeDataModelID) {
    const dataObject = {}
    if(activeDataModelID)
        dataObject.data_model_id = activeDataModelID
    dataObject.data = {}

    // подготовка основных полей
    for (const [key, value] of Object.entries(data)) {
        if (Array.isArray(value)) {
            if (value[0]?.value?.values?.length)
                dataObject.data[key] = [value[0].value.values[0].record_id]
            else 
                dataObject.data[key] = []
        } else if (typeof value === 'object') {
            if (value !== undefined && value !== null && value !== '')
                dataObject.data[key] = value.id || value
            else
                dataObject.data[key] = null
        } else if (typeof value === 'boolean') {
            if(value !== null)
                dataObject.data[key] = value
            else
                dataObject.data[key] = false
        } else if (typeof value === 'number') {
            if(value !== null && value !== '' && !isNaN(value))
                dataObject.data[key] = value
            else
                dataObject.data[key] = null
        } else if (typeof value === 'string') {
            if (value !== undefined && value?.trim() !== '')
                dataObject.data[key] = value.trim()
            else if (value?.trim() === '')
                dataObject.data[key] = null
        } else {
            if (value !== undefined && value !== null && value !== '')
                dataObject.data[key] = value.id || value
            else if (value === '' || value === null)
                dataObject.data[key] = null
        }
    }
    
    return dataObject
}
