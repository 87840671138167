import React, { Fragment, useState, useContext } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ExclamationCircleIcon, FunnelIcon } from '@heroicons/react/20/solid'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import { getDataModelType } from '../../../../../config/constTypes'
import EditFilters from '../../../../filters/EditFilters'

/**
 * Визуальный компонент отображает поле выбора таблицы из выпадающего списка существующих таблиц
 * @param {String} label Текстовая надпись (заголовок) над компонентом
 * @param {Object} selectedItem Выбранный элемент в списке существующих таблиц
 * @param {Object} error Ошибка при валидации выбранного значения (для визуализации)
 * @param {String[]} typeFilter Массив типов таблиц для фильтрации выпадающего списка таблиц
 * @param {Function} onItemChange Обработчик клика мыши при выборе таблицы в списке
 * [handleDataModelChange](./components_main_page_controller_exporter_form_ExporterForm.js.html#line82), 
 * [handleDataModelChange](./components_main_page_controller_field_FieldForm.js.html#line101),
 * [handleDataModelChange](./components_main_page_controller_importer_form_ImporterForm.js.html#line85)
 * @param {Object} object Объект с массивом полей фильтрации
 * 
 * @returns {JSXElement} Визуальный компонент ComboBox из библиотеки {@link https://headlessui.com/|@headlessui} 
 * с использованием визуального компонента  {@link EditFilters}
 * 
 * @see [Вызов компонента ExporterForm,](./components_main_page_controller_exporter_form_ExporterForm.js.html#line122)
 * [FieldForm,](./components_main_page_controller_field_FieldForm.js.html#line518)
 * [FieldForm](./components_main_page_controller_field_FieldForm.js.html#line526)
 * [и ImporterForm](./components_main_page_controller_importer_form_ImporterForm.js.html#line138)
 */
const DataModelComboBox = ({label, selectedItem, error, typeFilter, onItemChange, object}) => {
    const { docStore, FilterStore } = useContext(Context)
    const [query, setQuery] = useState('')
    const [isFiltersFormOpen, setIsFiltersFormOpen] = useState(false)

    const filteredList =
        query === ''
        ? docStore.dataModels.filter(dataModel => !typeFilter || !typeFilter.length || typeFilter.includes(dataModel.type))
        : docStore.dataModels
            .filter(dataModel => !typeFilter || !typeFilter.length || typeFilter.includes(dataModel.type))
            .filter(dataModel =>
                dataModel.entity_name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <Combobox
            value={selectedItem}
            by={'id'}
            onChange={onItemChange}
        >
            { label &&
                <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-mt-2'>
                    <Combobox.Label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-700 tw-leading-6'>{label}</Combobox.Label>
                    {error && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                    {object &&
                        <button
                            className={`focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 
                                focus-visible:tw-ring-opacity-50 focus-visible:tw-rounded-sm ${FilterStore.isFiltering 
                                    ? 'tw-text-sky-600 hover:tw-text-sky-500' : 'tw-text-gray-600 hover:tw-text-gray-900'}
                            `}
                            onClick={(e) => {
                                e.preventDefault()
                                if(!FilterStore.isFiltering)
                                    FilterStore.filterClick(object, false, false)

                                setIsFiltersFormOpen(!isFiltersFormOpen)
                            }}
                            data-tooltip-id="importer-editor-tooltip" data-tooltip-content={"Задать фильтры"}
                        >
                            <FunnelIcon className='tw-w-4 tw-h-4' aria-hidden='true'/>
                        </button>
                    }
                </div>
            }
            <div className="tw-relative tw-mt-1">
                <div className="tw-relative tw-w-full tw-cursor-default tw-overflow-hidden tw-rounded-md tw-text-left 
                                focus:tw-outline-none sm:tw-text-sm">
                    <Combobox.Input
                        className={`tw-w-full tw-cursor-default tw-rounded-md tw-bg-white tw-py-1 tw-pl-3 tw-pr-10
                            tw-text-left tw-text-gray-900 tw-ring-1 tw-ring-inset focus-visible:tw-outline-none
                            tw-text-sm sm:tw-leading-6 disabled:tw-bg-gray-100 disabled:tw-text-gray-300 focus:tw-ring-2
                            ${error ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                        `}
                        displayValue={dataModel => dataModel.entity_name + (dataModel.entity_name !== '' ? ' => ' + getDataModelType(dataModel) : '')}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 tw-text-gray-400 disabled:tw-text-gray-300">
                        <ChevronUpDownIcon
                            className="tw-h-5 tw-w-5 tw-text-gray-400"
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="tw-transition tw-ease-in tw-duration-100"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                    afterLeave={() => setQuery('')}
                >
                    <Combobox.Options className="tw-absolute tw-mt-1 tw-z-10 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base
                                                tw-shadow-md tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm"
                    >
                        { filteredList.length === 0  
                            ?
                                query !== ''
                                ?
                                    <div className="tw-relative tw-cursor-default tw-select-none tw-py-2 tw-px-4 tw-text-gray-700">
                                        Ничего не найдено
                                    </div>
                                :
                                    <div className="tw-relative tw-cursor-default tw-select-none tw-py-2 tw-px-4 tw-text-red-400">
                                        Записи отсутствуют
                                    </div>
                            : 
                            (
                                filteredList.map((dataModel, index) => (
                                    <Combobox.Option
                                        key={index}
                                        className={({ active }) =>
                                        `tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-10 tw-pr-4 ${
                                            active ? 'tw-bg-gray-500 tw-text-white' : 'tw-text-gray-900'
                                        }`
                                        }
                                        value={dataModel}
                                    >
                                        {({ selected, active }) => (
                                        <>
                                            <span
                                                className={`tw-block tw-truncate ${
                                                selected ? 'tw-font-medium' : 'tw-font-normal'}`}
                                            >
                                                {dataModel.entity_name + ' => ' + getDataModelType(dataModel)}
                                            </span>
                                            {selected ? (
                                                <span
                                                    className={`tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3 ${
                                                    active ? 'tw-text-white' : 'tw-text-gray-600'
                                                    }`}
                                                >
                                                    <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )
                        }
                    </Combobox.Options>
                </Transition>
            </div>
            {object && 
                <EditFilters
                    editColumnName = {"Название таблицы"}
                    editColumnType = {"string"}
                    isOpen={isFiltersFormOpen}
                    onCloseClick={setIsFiltersFormOpen}
                    author={false}
                    object={object}
                    data={false}
                />
            }
        </Combobox>
    )
}

export default observer(DataModelComboBox)