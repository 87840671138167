import React, { useContext } from 'react'
import { Context } from '../../../../..'
import { DocumentPlusIcon, PencilSquareIcon, TrashIcon, ClockIcon, DocumentDuplicateIcon, ListBulletIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite'
import DataImportMenu from './DataImportMenu'
import DataPrintMenu from './DataPrintMenu'
import DataExportMenu from './DataExportMenu'
import SearchInput from '../../../../search/SearchInput'

/**
 * Визуальный компонент отображает меню возможных действий над записями таблицы
 * @param {Function} onAddItemClick Обработчик клика мыши на кнопке создания новой записи в таблице
 * [handleAddDataObjectClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line127),
 * [handleAddDataObjectClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line137)
 * @param {Function} onDuplicateItemClick Обработчик клика мыши на кнопке создания новой записи в таблице посредством дублирования существующей записи
 * [handleDuplicateClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line136),
 * [handleDuplicateClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line146)
 * @param {Function} onEditItemClick Обработчик клика мыши на кнопке редактирования выбранной записи в таблице
 * [handleEditDataObjectClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line146),
 * [handleEditDataObjectClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line156)
 * @param {Function} onViewRecordClick Обработчик клика мыши на кнопке просмотра детальной информации о выбранной записи
 * [handleViewRecordClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line156),
 * [handleViewRecordClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line166)
 * @param {Function} onItemHistoryClick Обработчик клика мыши на кнопке просмотра истории выбранной записи в таблице
 * [handelViewHistoryClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line160),
 * [handelViewHistoryClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line170)
 * @param {Function} onDeleteItemClick Обработчик клика мыши на кнопке установки/снятия метки удаления записи в таблице
 * [handleDeleteDataObjectClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line165),
 * [handleDeleteDataObjectClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line175)
 * @param {Object} selectedDataObject Выбранный элемент в списке записей таблицы
 * @param {Object} selectedFullDataModel Выбранная таблица
 * @param {Function} handleImportFromFileClick Обработчик клика мыши на кнопке импорта записей из Excel
 * [handleImportFromFileClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line246)
 * @param {Function} handleImportFromServerClick Обработчик клика мыши на кнопке импорта записей с сервера
 * [handleImportFromServerClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line305)
 * @param {Function} handlePrintTableClick Обработчик клика мыши на кнопке экспорта всех записей в файл Excel
 * [handlePrintTableClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line170)
 * @param {Function} handlePrintRecordClick Обработчик клика мыши на кнопке экспорта выбранной записи в файл Excel
 * [handlePrintRecordClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line192)
 * @param {Function} handleOpenExportersClick Обработчик клика мыши на кнопке экспорта выбранной записи в файл Excel
 * [handleOpenExportersClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line222)
 * @param {Boolean} isDocumentPage Признак нахождения на странице документов
 * @param {Function} updateDataObjectList Функция обновления списка записей
 * [updateDataObjectList](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line322),
 * [updateDataObjectList](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line186)
 * @param {Object} filterStore Экземпляр хранилища информации о применяемых фильтрах
 * 
 * @returns {HTMLDivElement} Html-разметку ряда кнопок меню с использованием визуальных компонентов 
 * {@link DataImportMenu}, {@link DataPrintMenu}, {@link DataExportMenu}, {@link SearchInput}
 * 
 * @see [Вызов компонента DataObjectListContainer,](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line547)
 * [DirectoryContainer](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line458)
 */
const DataObjectMenu = ({onAddItemClick, onDuplicateItemClick, onEditItemClick, onViewRecordClick, onItemHistoryClick, onDeleteItemClick, selectedDataObject,
        selectedFullDataModel, handleImportFromFileClick, handleImportFromServerClick, handlePrintTableClick, handlePrintRecordClick, handleOpenExportersClick, 
        isDocumentPage, updateDataObjectList, filterStore}) => {
    const { docStore, userStore } = useContext(Context)

    return (
        <div 
            id='data-object-menu'
            className='tw-h-12 tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center tw-space-x-1 tw-py-2'
        >
            <p className='tw-text-md tw-font-semibold tw-truncate'>
                { docStore.isHistoryView 
                    ? docStore.isDetailView
                        ? `Просмотр ${selectedDataObject.active ? 'актуальной' : 'архивной'} версии записи`
                        : 'История изменений записи'
                    : docStore.isDetailView
                        ? 'Просмотр актуальной записи'
                        : `${selectedFullDataModel
                                ?   selectedFullDataModel.type === 'directory'
                                        ?   'Справочник'
                                        :   selectedFullDataModel.type === 'document'
                                                ?   'Документ'
                                                :   'Таблица'
                                :   'Записи'
                            } ${selectedFullDataModel ? `"${selectedFullDataModel.entity_name}"` : ' '}`
                }
            </p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <SearchInput
                    isDisabled={!selectedFullDataModel}
                    filterStore={filterStore}
                    selectedFullDataModel={selectedFullDataModel}
                    updateDataObjectList={updateDataObjectList}
                />

                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onAddItemClick}
                    disabled={!selectedFullDataModel || !(!selectedFullDataModel.subgroup_id || selectedFullDataModel.subgroup_id == userStore.currentSubCompany.id)}
                    data-tooltip-id="data-object-menu-tooltip" data-tooltip-content="Создать"
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onDuplicateItemClick(selectedDataObject.id)}
                    disabled={!selectedDataObject || docStore.isHistoryView || docStore.isDetailView || !(!selectedFullDataModel.subgroup_id || selectedFullDataModel.subgroup_id == userStore.currentSubCompany.id)}
                    data-tooltip-id="data-object-menu-tooltip" data-tooltip-content="Дублировать"
                >
                    <DocumentDuplicateIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onEditItemClick(selectedDataObject.id)}
                    disabled={!selectedDataObject || docStore.isHistoryView || docStore.isDetailView || !(!selectedDataObject.subgroup_id || selectedDataObject.subgroup_id == userStore.currentSubCompany.id)}
                    data-tooltip-id="data-object-menu-tooltip" data-tooltip-content="Редактировать"
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className={`${docStore.isDetailView ? 'tw-bg-red-600 hover:tw-bg-red-400' : 'tw-bg-gray-800 hover:tw-bg-gray-600'} tw-rounded-md tw-p-1.5 tw-text-white  disabled:tw-bg-gray-400`}
                    onClick={onViewRecordClick}
                    disabled={!selectedDataObject}
                    data-tooltip-id="data-object-menu-tooltip" data-tooltip-content={docStore.isDetailView ? "Закрыть просмотр" : "Просмотреть запись"}
                >
                    <ListBulletIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className={`${docStore.isHistoryView ? 'tw-bg-red-600 hover:tw-bg-red-400' : 'tw-bg-gray-800 hover:tw-bg-gray-600'} tw-rounded-md tw-p-1.5 tw-text-white  disabled:tw-bg-gray-400`}
                    onClick={onItemHistoryClick}
                    disabled={!selectedDataObject}
                    data-tooltip-id="data-object-menu-tooltip" data-tooltip-content={docStore.isHistoryView ? "Скрыть историю" : "Просмотреть историю"}
                >
                    <ClockIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onDeleteItemClick(selectedDataObject)}
                    disabled={!selectedDataObject || docStore.isHistoryView || docStore.isDetailView || !(!selectedDataObject.subgroup_id || selectedDataObject.subgroup_id == userStore.currentSubCompany.id)}
                    data-tooltip-id="data-object-menu-tooltip"
                    data-tooltip-content={(selectedDataObject && selectedDataObject.system_data && selectedDataObject.system_data.deletion_mark) ? "Отменить удаление" : "Удалить"}
                >
                    <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                
                {isDocumentPage &&
                    <>
                        <DataImportMenu
                            dataModel={selectedFullDataModel}
                            onImportFromXLSXClick={handleImportFromFileClick}
                            onImportFromServerClick={handleImportFromServerClick}
                        />
                        <DataExportMenu
                            notPrintTable={!selectedFullDataModel}
                            notPrintRecord={!docStore.selectedDataObject}
                            itemHistory={docStore.isHistoryView}
                            onPrintTableClick={handlePrintTableClick}
                            onPrintRecordClick={handlePrintRecordClick}
                        />
                        <DataPrintMenu
                            notPrintRecord={!docStore.selectedDataObject}
                            onPrintDocumentClick={handleOpenExportersClick}
                        />
                    </>
                }
            </div>
            <Tooltip id="data-object-menu-tooltip" place="top"/>
        </div>
    )
}

export default observer(DataObjectMenu)