import { Tab } from '@headlessui/react'
import FileTabPanel from './files/FileTabPanel'
import NoteTabPanel from './notes/NoteTabPanel'
import { observer } from 'mobx-react-lite';
import NestedTableTabPanel from './nested_tables/NestedTableTabPanel';
import { defaultTabs } from '../../config/constTypes';
import { useContext, useState, useEffect } from 'react';
import { Context } from '../..';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


/**
 * Визуальный компонент отображает файлы, сообщения и вложенные таблицы выбранной записи 
 * 
 * @param {Object[]} files Список файлов, относящихся к выбранной записи таблицы
 * @param {Boolean} readOnly Признак "только чтение" для файлов
 * @param {Function} onFileSelect Обработчик клика мыши на кнопке выбора файла
 * [handleFileSelect](./functions_fileHandlers.js.html#line41)
 * @param {Function} onFileRemove Обработчик клика мыши на кнопке удаления файла
 * [handleFileRemove](./functions_fileHandlers.js.html#line88)
 * @param {Object} selectedNestedDataModel Выбранная вложенная таблица
 * @param {Object[]} nestedDataModels Массив вложенных таблиц
 * @param {Function} setSelectedNestedDataModel Изменение выбранной вложенной таблицы
 * [setSelectedNestedDataModel](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line50),
 * [setSelectedNestedDataModel](./components_main_page_controller_common_panels_info_DirectoryContainer.js.html#line107),
 * [setSelectedNestedDataModel](./components_main_page_controller_data_object_add_AddDataObjectContainer.js.html#line46),
 * [setSelectedNestedDataModel](./components_main_page_controller_data_object_add_DuplicateDataObjectContainer.js.html#line53),
 * [setSelectedNestedDataModel](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line56)
 * @param {Function} setNestedDataModels Изменение в массиве вложенных таблицы
 * [setNestedDataModels](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line47),
 * [setNestedDataModels](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line104),
 * [setNestedDataModels](./components_main_page_controller_data_object_add_AddDataObjectContainer.js.html#line45),
 * [setNestedDataModels](./components_main_page_controller_data_object_add_DuplicateDataObjectContainer.js.html#line52),
 * [setNestedDataModels](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line55)
 * @param {Boolean} isDocumentPage Признак нахождения на странице документов
 * @param {Function} onChange Сторонняя функция для изменений в массиве вложенных таблицы 
 * @param {Boolean} isNestedLoading Признак загрузки вложенных таблиц
 * @param {Object} panel Панель, где находятся элементы
 * @param {Object} recordNotesStore Экземпляр хранилища информации о сообщениях
 * 
 * @returns {HTMLDivElement} Html-разметку файлов, сообщений и вложенных таблиц выбранной записи 
 * с использованием визуальных компонентов {@link FileTabPanel}, {@link NestedTabPanel}, {@link NoteTabPanel}
 * 
 * @see [Вызов компонента DirectoryPanel,](./components_main_page_controller_common_panels_directory_DirectoryPanel.js.html#line131)
 * [AddDataObjectContainer,](./components_main_page_controller_data_object_add_AddDataObjectContainer.js.html#line232)
 * [DuplicateDataObjectContainer,](./components_main_page_controller_data_object_add_DuplicateDataObjectContainer.js.html#line239)
 * [EditDataObjectContainer](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line271)
 */
const DataObjectTabs = ({files, readOnly, onFileSelect, onFileRemove, setNestedDataModels, setSelectedNestedDataModel, 
                            nestedDataModels, selectedNestedDataModel, isDocumentPage, onChange, isNestedLoading, panel, recordNotesStore}) => {

    const { docStore } = useContext(Context)
    const [selectedNestedDataObject, setSelectedNestedDataObject] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(0)

    const onTabClick = (item) => {
        if (isDocumentPage)
            docStore.getNestedDataModel(item, setSelectedNestedDataModel)
    }

    useEffect(()=>{
        if (!selectedNestedDataModel)
            setSelectedIndex(0)
    }, [selectedNestedDataModel])

    return (
        <div className="tw-flex tw-flex-col tw-h-full tw-w-full tw-px-2 sm:tw-px-0 tw-bg-gray-200 tw-rounded-md">
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <Tab.List className="tw-flex tw-space-x-1 tw-rounded-md">
                    {isDocumentPage &&   
                        <>
                            {defaultTabs.map((category) => (
                                <Tab
                                    key={category.id}
                                    onClick={() => setSelectedNestedDataModel(null)}
                                    data-tooltip-id="data-object-table-tooltip" data-tooltip-content={category.entity_name} data-tooltip-delay-show={1000}
                                    className={({ selected }) =>
                                        classNames(
                                            'tw-h-10 tw-w-48 tw-rounded-t-md tw-p-2 tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-900 tw-truncate',
                                            'tw-ring-white tw-ring-opacity-60 focus:tw-outline-none focus:tw-ring-offset-0 disabled:tw-invisible',
                                            selected
                                                ? 'tw-bg-white'
                                                : 'tw-bg-gray-300 hover:tw-bg-gray-100'
                                        )
                                    }
                                >
                                    {category.entity_name}
                                </Tab>
                            ))}
                        </>
                    }

                    {nestedDataModels
                        .slice()
                        .filter(nestedModel => !nestedModel.hide)
                        .sort((a, b) => a.fieldName < b.fieldName ? -1 : 1)
                        .map((nestedModel, index) => (
                            <Tab
                                key={index}
                                onClick={() => onTabClick(nestedModel)}
                                data-tooltip-id="data-object-table-tooltip" data-tooltip-content={nestedModel.fieldName} data-tooltip-delay-show={1000}
                                className={({ selected }) =>
                                    classNames(
                                        'tw-h-10 tw-w-48 tw-rounded-t-md tw-p-2 tw-text-sm tw-font-medium tw-leading-5 tw-truncate',
                                        'tw-ring-white tw-ring-opacity-60 focus:tw-outline-none focus:tw-ring-offset-0 disabled:tw-invisible',
                                        nestedModel.errors ? 'tw-font-semibold tw-text-red-500' : 'tw-font-medium tw-text-gray-900',
                                        selected
                                            ? 'tw-bg-white'
                                            : 'tw-bg-gray-300 hover:tw-bg-gray-100'
                                    )
                                }
                            >
                                {nestedModel.fieldName}
                            </Tab>
                    ))}
                </Tab.List>

                <Tab.Panels className='tw-grow tw-overflow-auto tw-rounded-b-md tw-rounded-tr-md tw-bg-white tw-pt-2'>
                    {isDocumentPage &&
                        <>
                            <FileTabPanel
                                list={files}
                                readOnly={readOnly}
                                onFileSelect={onFileSelect}
                                onFileRemove={onFileRemove}
                            />
                            <NoteTabPanel 
                                recordNotesStore={recordNotesStore}
                            />
                        </>
                    }

                    {nestedDataModels
                        .slice()
                        .filter(nestedModel => !nestedModel.hide)
                        .sort((a, b) => a.fieldName < b.fieldName ? -1 : 1)
                        .map((nestedModel, index) => (
                            <NestedTableTabPanel
                                key={index}
                                readOnly={readOnly}
                                list={nestedModel.dataObjects}
                                selectedNestedDataModel={isDocumentPage ? selectedNestedDataModel : nestedModel}
                                setNestedDataModels={setNestedDataModels}
                                selectedNestedDataObject={selectedNestedDataObject}
                                setSelectedNestedDataObject={setSelectedNestedDataObject}
                                nestedDataModels={nestedDataModels}
                                isDocumentPage={isDocumentPage}
                                onChange={onChange}
                                isNestedLoading={isNestedLoading}
                                panel={panel}
                            />
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

export default observer(DataObjectTabs)
