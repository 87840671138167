import { makeAutoObservable, runInAction } from 'mobx'
import { responseTimeOut, serviceMessageTimeOut } from "../config/constTypes"
import DocumentService from '../services/DocumentService'
import CategoryService from '../services/CategoryService'
import { toast } from 'react-toastify'
import { showErrorToast } from '../functions/errorHandlers'
import { checkVersionsModification } from '../functions/checkVersionsModification'
import { findNestedModelName } from '../functions/nestedModels'

/**
 * Класс реализует хранилище информации о полученных от сервера таблицах
 * @class
 * 
 * @property {Object[]} dataModels Массив существующих таблиц
 * @property {Object[]} dataObjects Массив существующих записей в таблице
 * @property {Object[]} categories Массив существующих категорий таблиц
 * @property {Object} selectedDataModel Информация о выбранной таблице
 * @property {Object} selectedDataObject Информация о выбранной записи таблицы
 * @property {Boolean} isDataModelLoading Признак загрузки таблицы
 * @property {Boolean} isDataObjectLoading Признак загрузки записи таблицы
 * @property {Boolean} isHistoryView Признак видимости истории записи таблицы
 * @property {Boolean} isDetailView Признак видимости информации о записи таблицы
 * @property {Object[]} attachedFiles Массив файлов записи таблицы
 * @property {String} sortingColumn Столбец, по которому осуществляется сортировка
 * @property {String} sortingDirection Направление сортировки
 * @property {Object[]} selectedFilters  Массив используемых фильтров
 * @property {Number} dataQueryOffset Смещение при получении записей
 * @property {Number} totalCount Общее количество записей
 * @property {Boolean} isFetching Признак загрузки новых записей таблицы
 * @property {Object[]} shownTypes  Массив видимых типов категорий
 * @property {Boolean} isShowInfo Признак видимости типа категории записи таблицы
 * @property {Object[]} dataModelCategories  Список категорий выбранной таблицы
 * @property {Object[]} categoryDataModels  Массив таблиц, относящихся к выбранной категории
 * @property {Object} selectedCategory  Выбранная категория
 * @property {Boolean} isCategoryCreating Признак создания категории
 * @property {Boolean} isCategoryEditing Признак редактирования категории
 * @property {String} moduleType Таблица, запись которой открывается со стартовой страницы
 * @property {String} moduleRecordID ID записи таблицы, которая открывается со стартовой страницы
 */
class DocumentStore {
    dataModels = []
    dataObjects = []
    selectedDataModel = null
    selectedDataObject = null
    isDataModelLoading = false
    isDataObjectLoading = false
    isStartLoading = false
    isHistoryView = false
    isDetailView = false
    attachedFiles = []
    sortingColumn = 'created'
    sortingDirection = 'down'
    selectedFilters = []
    totalCount = 0
    dataQueryOffset = 0
    isFetching = false
    isFetchingData = false
    selectedSorting = []
    
    categories = []
    shownTypes = []
    isShowInfo = false
    dataModelCategories = []
    categoryDataModels = []
    selectedCategory = null
    isCategoryCreating = false
    isCategoryEditing = false

    moduleType = null
    moduleRecordID = null
    
    /**
     * Конструктор с указанием, что все свойства класса observable
     * @constructor
     */
    constructor() {
        makeAutoObservable(this)
    }

    /**
     * Метод сохраняет полученный от сервера массив существующих таблиц
     * @method
     * 
     * @param {Object[]} dataModels Массив существующих таблиц
     */
    setDataModels(dataModels) {
        this.dataModels = dataModels
    }

    /**
     * Метод сохраняет полученный от сервера массив записей выбранной таблицы
     * @method
     * 
     * @param {Object[]} dataObjects Массив записей выбранной таблицы
     */
    setDataObjects(dataObjects) {
        this.dataObjects = dataObjects
    }

    /**
     * Метод сохраняет полученную от сервера выбранную таблицу
     * @method
     * 
     * @param {Object} dataModel Выбранная таблица
     */
    setSelectedDataModel(dataModel) {
        this.selectedDataModel = dataModel
    }

    /**
     * Метод сохраняет полученную от сервера выбранную запись таблицы
     * @method
     * 
     * @param {Object} dataObject Выбранная запись таблицы
     */
    setSelectedDataObject(dataObject) {
        this.selectedDataObject = dataObject
    }

    /**
     * Метод сохраняет состояние процесса загрузки списка существующих таблиц
     * @method
     * 
     * @param {Boolean} bool Состояние процесса загрузки списка существующих таблиц
     */
    setIsDataModelLoading(bool) {
        this.isDataModelLoading = bool
    }

    /**
     * Метод сохраняет состояние процесса загрузки записей таблицы
     * @method
     * 
     * @param {Boolean} bool Состояние процесса загрузки списка записей таблицы
     */
    setIsDataObjectLoading(bool) {
        this.isDataObjectLoading = bool
    }

    /**
     * Метод сохраняет состояние процесса загрузки записей таблицы
     * @method
     * 
     * @param {Boolean} bool Состояние процесса загрузки списка записей таблицы
     */
    setIsStartLoading(bool) {
        this.isStartLoading = bool
    }

    /**
     * Метод сохраняет состояние просмотра истории изменений записи таблицы
     * @method
     * 
     * @param {Boolean} bool Состояние просмотра истории изменений записи таблицы
     */
    setIsHistoryView(bool) {
        this.isHistoryView = bool
    }

    /**
     * Метод сохраняет состояние детального просмотра записи таблицы
     * @method
     * 
     * @param {Boolean} bool Состояние детального просмотра записи таблицы
     */
    setIsDetailView(bool) {
        this.isDetailView = bool
    }

    /**
     * Метод сохраняет полученные от сервера описания файлов, прикрепленных к выбранной записи таблицы
     * @method
     * 
     * @param {Object[]} files Прикрепленные к записи файлы
     */
    setAttachedFiles(files) {
        this.attachedFiles = files
    }

    /**
     * Метод сохраняет имя столбца, по которому осуществляется сортировка записей таблицы
     * @method
     * 
     * @param {String} column Имя столбца
     */
    setSortingColumn(column) {
        this.sortingColumn = column
    }

    /**
     * Метод сохраняет направление сортировки записей таблицы (down - по возрастанию, up - по убыванию)
     * @method
     * 
     * @param {String} direction Направление сортировки
     */
    setSortingDirection(direction) {
        this.sortingDirection = direction
    }

    /**
     * Метод сохраняет выбранные пользователем фильтры
     * @method
     * 
     * @param {Object[]} filters Выбранные фильтры
     */
    setSelectedFilters(filters) {
        this.selectedFilters = filters
    }

    /**
     * Метод сохраняет общее количества записей в таблице
     * @method
     * 
     * @param {Number} totalCount Общее количество записей
     */
    setTotalCount(totalCount) {
        this.totalCount = totalCount
    }

    /**
     * Метод сохраняет текущее смещение для запрашиваемой порции записей относительно общего количества записей таблицы
     * @method
     * 
     * @param {Number} offset Смещение
     */
    setDataQueryOffset(offset) {
        this.dataQueryOffset = offset
    }

    /**
     * Метод сохраняет состояние процесса загрузки списка новых записей таблицы
     * @method
     * 
     * @param {Boolean} bool Состояние процесса загрузки списка новых записей таблицы
     */
    setIsFetching(bool) {
        this.isFetching = bool
    }

    /**
     * Метод сохраняет состояние процесса загрузки списка новых записей таблицы
     * @method
     * 
     * @param {Boolean} bool Состояние процесса загрузки списка новых записей таблицы
     */
    setIsFetchingData(bool) {
        this.isFetchingData = bool
    }


    // Методы для работы с категориями

    /**
     * Метод сохраняет полученный от сервера массив существующих категорий
     * @method
     * 
     * @param {Object[]} categories Массив существующих категорий
     */
    setCategories(categories) {
        this.categories = categories
    }

    /**
     * Метод сохраняет массив типов документов, отображаемых на данной странице
     * @method
     * 
     * @param {Object[]} types Массив отображаемых типов
     */
    setShownTypes(types) {
        this.shownTypes = types
    }

    /**
     * Метод сохраняет признак отображения дополнительной информации о типе таблицы в списке таблиц
     * @method
     * 
     * @param {Boolean} bool Признак отображения дополнительной информации о типе таблицы
     */
    setIsShowInfo(bool) {
        this.isShowInfo = bool
    }

    /**
     * Метод сохраняет вычисленный массив таблиц, относящихся к выбранной категории
     * @method
     * 
     * @param {Object[]} dataModels Массив таблиц
     */
    setCategoryDataModels(dataModels) {
        this.categoryDataModels = dataModels
    }

    /**
     * Метод сохраняет выбранную категорию
     * @method
     * 
     * @param {Object} dataModel Выбранная категория
     */
    setSelectedCategory(category) {
        this.selectedCategory = category
    }
    
    /**
     * Метод сохраняет состояние режима создания новой категории
     * @method
     * 
     * @param {Boolean} bool Состояние режима создания новой категории
     */
    setIsCategoryCreating(bool) {
        this.isCategoryCreating = bool
    }
    
    /**
     * Метод сохраняет состояние режима редактирования категории
     * @method
     * 
     * @param {Boolean} bool Состояние режима редактирования категории
     */
    setIsCategoryEditing(bool) {
        this.isCategoryEditing = bool
    }
    
    /**
     * Метод сохраняет список категорий выбранной таблицы
     * @method
     * 
     * @param {Object[]} categories Список категорий выбранной таблицы
     */
    setDataModelCategories(categories) {
        this.dataModelCategories = categories
    }

    /**
     * Метод сохраняет таблицу, запись которой открывается со стартовой страницы
     * @method
     * 
     * @param {String} dataModel Таблица
     */
    setModuleType(dataModel) {
        this.moduleType = dataModel
    }

    /**
     * Метод сохраняет ID записи таблицы, которая открывается со стартовой страницы
     * @method
     * 
     * @param {Boolean} id ID записи
     */
    setModuleRecordID(id) {
        this.moduleRecordID = id
    }

    /**
     * Метод осуществляет получение таблиц
     * @method
     * 
     * @param {Object[]} filter Примененные фильтры
     * @param {Object[]} sorter Примененные сортировки
     * @param {String} mode Тип доступа к записи (r - только чтение, w - чтение и запись)
     */
    getDataModels(filter, sorter, mode) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setDataModels([])
            this.setIsDataModelLoading(false)
        }, responseTimeOut)

        this.setIsDataModelLoading(true)

        DocumentService
            .getDataModels(filter, sorter, mode)
            .then(data => {
                clearTimeout(noResponse)
                this.setDataModels(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                this.setDataModels([])                
                showErrorToast(error, 'fetching', '')
            })
            .finally(() => this.setIsDataModelLoading(false))
    }

    /**
     * Метод осуществляет получение выбранной таблицы
     * @method
     * 
     * @param {String} dataModelID ID выбранной таблицы
     * @param {Function} setDataModel Метод, сохраняющий полученную от сервера выбранную таблицу
     */
    getOneDataModel(dataModelID, setDataModel) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setDataModel({id:'0', entity_name: '', fields: []})
        }, responseTimeOut)

        DocumentService
            .getOneDataModel(dataModelID)
            .then(data => {
                clearTimeout(noResponse)
                setDataModel(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                setDataModel({id: '0', entity_name: '', fields: []})
            })
    }

    /**
     * Метод осуществляет получение выбранной вложенной таблицы
     * @method
     * 
     * @param {Object} dataModel Выбранная вложенная таблица
     * @param {Function} setSelectedNestedDataModel Метод, сохраняющий полученную от сервера выбранную вложенную таблицу
     */
    getNestedDataModel(dataModel, setSelectedNestedDataModel) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setSelectedNestedDataModel({id:'0', entity_name: '', fields: []})
        }, responseTimeOut)

        DocumentService
            .getOneDataModel(dataModel.ref_model_ids[0])
            .then(data => {
                clearTimeout(noResponse)
                setSelectedNestedDataModel({...data, rule_id: dataModel.rule_id})
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                setSelectedNestedDataModel({id:'0', entity_name: '', fields: []})
            })
    }

    /**
     * Метод осуществляет получение записей выбранной вложенной таблицы для одной записи таблицы
     * @method
     * 
     * @param {Object} dataObject Запись таблицы
     * @param {Object} selectedNestedModel Выбранная вложенная таблица 
     * @param {Function} setNestedDataModels Метод, сохраняющий вложенные таблицы выбранной записи 
     */
    getNestedDataObjects = async (dataObject, selectedNestedModel, setNestedDataModels, setIsNestedLoading) => {
        try {
            setIsNestedLoading(true)
            const response = await DocumentService.getNestedDataObjects(50, [], [], dataObject.id, selectedNestedModel.rule_id)
            if (response) {
                const includedModels = Object.values(dataObject.data)
                    .sort((a, b) => a.order - b.order)
                    .filter(field => field.type === 'include')
                    .map(nestedModel => 
                        nestedModel.rule_id === selectedNestedModel.rule_id
                            ?   {...nestedModel, fieldName: findNestedModelName(dataObject, nestedModel), dataObjects: response}
                            :   {...nestedModel, fieldName: findNestedModelName(dataObject, nestedModel)}
                    ) 
                setNestedDataModels(includedModels)
                setIsNestedLoading(false)
            }
        } catch (error) {
            showErrorToast(error, 'fetching', '') 
            setIsNestedLoading(false)
        }
    }
    
    /**
     * Метод осуществляет получение всех записей всех вложенных таблиц для одной записи таблицы
     * @method
     * 
     * @param {Object} dataObject Запись таблицы
     * @param {Function} setNestedDataModels Метод, сохраняющий вложенные таблицы выбранной записи 
     * @param {Boolean} isDuplicate Признак дублирования записи 
     */
    getAllNestedDataObjects = (dataObject, setNestedDataModels, isDuplicate) => {
        const includedModels = Object.values(dataObject.data)
            .sort((a, b) => a.order - b.order)
            .filter(field => field.type === 'include')
        try {
            const nestedValueRequests = includedModels.map(nestedModel => DocumentService.getNestedDataObjects(50, [], [], dataObject.id, nestedModel.rule_id))
            Promise.all(nestedValueRequests)
                .then(responses => {
                    setNestedDataModels(includedModels.map((nestedModel, index) =>  {  
                        return {
                            ...nestedModel,
                            fieldName: findNestedModelName(dataObject, nestedModel),
                            dataObjects: responses[index].map(response => {return {...response, status: isDuplicate ? 'added' : 'saved'}}),
                        }
                    }))
                })
        } catch (error) {
            showErrorToast(error, 'fetching', '') 
            setNestedDataModels(includedModels.map((nestedModel) =>  {
                return {
                    ...nestedModel,
                    fieldName: findNestedModelName(dataObject, nestedModel), 
                    dataObjects: []
                }
            }))
        }
    }

    /**
     * Метод осуществляет запрос количества записей таблицы
     * @method
     * 
     * @param {Object} directory Выбранная таблица
     */
    getRecordCount = async (dataModel) => {
        try {
            const filter = JSON.stringify([
                {property: 'data_model_id', value: dataModel.id, operator: 'eq'},
                {property: 'active', value: true, operator: 'eq'}
            ])            
            const count = await DocumentService.getDataObjectCount(filter)
            if (count)
                return count
        } catch (error) {
            showErrorToast(error, 'fetching', '') 
        }

        return 0
    }

    /**
     * Метод осуществляет изменение категории выбранной таблицы
     * @method
     * 
     * @param {Object} dataModel Выбранная таблица
     * @param {Object[]} categories Массив категорий таблицы
     */
    updateDataModelCategories(dataModel, categories) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            runInAction(() => {
                this.setDataModels([])
                this.setIsDataModelLoading(false)
            })
        }, responseTimeOut)

        DocumentService
            .editDataModel(dataModel.id, {category_ids: categories, type: dataModel.type})
            .then(() => {
                clearTimeout(noResponse)
                const defaultFilter = []
                const defaultSorter = JSON.stringify([
                    {property: 'entity_name', desc: false},
                ])      
                this.getDataModels(defaultFilter, defaultSorter, 'w')
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет получение записей таблицы
     * @method
     * 
     * @param {Object[]} filter Примененные фильтры
     * @param {Object[]} sorter Примененные сортировки
     * @param {Boolean} isSelected Признак выбора записи 
     * @param {Function} setDataObjects Метод, сохраняющий записи выбранной таблицы
     * @param {Function} setSelectedDataObject Метод, сохраняющий выбранную запись таблицы   
     * @param {String} mode Тип доступа к записи (r - только чтение, w - чтение и запись)
     * @param {Object} selectedDataObject Выбранная запись таблицы
     */
    getDataObjectsByID = (filter, sorter, offset, dataObjects, setDataObjects, setTotalCount, setOffset, mode) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setDataObjects([])
            this.setIsDataObjectLoading(false)
        }, responseTimeOut)

        this.setIsDataObjectLoading(true)

        DocumentService
            .getDataObjects(50, offset, filter, sorter, mode)
            .then(([data, totalCount]) => {
                clearTimeout(noResponse)
                const updatedData = data.map(item => {return {...item, hierarchyVisible: false, hierarchyLevel: 0}})
                if (!offset) {
                    setDataObjects(updatedData)
                } else {
                    const addedData = dataObjects.concat(updatedData)
                    setDataObjects(addedData)    
                }
                setTotalCount(Number(totalCount))
                setOffset(offset + data.length)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                setDataObjects([])
            })
            .finally(() => this.setIsDataObjectLoading(false))
    }

    /**
     * Метод осуществляет получение истории записи таблицы
     * @method
     * 
     * @param {Object[]} filter Примененные фильтры
     * @param {Object[]} sorter Примененные сортировки
     * @param {Boolean} isStartLoading Признак типа записи 
     * @param {Function} setDataObjects Метод, сохраняющий записи выбранной таблицы
     */
    getDataObjectHistory = (filter, sorter, isStartLoading, setDataObjects) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            isStartLoading ? this.setIsStartLoading(false) : this.setIsDataObjectLoading(false)
        }, responseTimeOut)

        isStartLoading ? this.setIsStartLoading(true) : this.setIsDataObjectLoading(true)

        DocumentService
            .getAllDataObjects(50, filter, sorter)
            .then(data => {
                clearTimeout(noResponse)
                setDataObjects(checkVersionsModification(data))
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
        
        .finally(() =>  isStartLoading ? this.setIsStartLoading(false) : this.setIsDataObjectLoading(false))
    }

    /**
     * Метод осуществляет удаление записи таблицы
     * @method
     * 
     * @param {String} id ID выбранной записи
     * @param {Function} setDataObjects Метод, сохраняющий записи выбранной таблицы
     * @param {Function} setSelectedDataObject Метод, сохраняющий выбранную запись таблицы
     * @param {String} selectedDataModelID ID выбранной таблицы
     */
    deleteDataObject(id, mark, dataObjects, setDataObjects, selectedDataModelID,  setTotalCount, setDataObjectOffset) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        DocumentService
            .deleteDataObject(id, {system_data: {deletion_mark: mark}})
            .then(() => {
                clearTimeout(noResponse)

                const filter = JSON.stringify([
                    {property: 'data_model_id', value: selectedDataModelID, operator: 'eq'},
                    {property: 'transaction_id', value: null, operator: 'eq'},
                    {property: 'active', value: true, operator: 'eq'}
                ])
                const sorter =[]
                this.getDataObjectsByID(filter, sorter, 0, dataObjects, setDataObjects,  setTotalCount, setDataObjectOffset)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'deleting', '')
            })            
    }

    /**
     * Метод осуществляет сохранение в хранилище сортировки записей выбранной таблицы
     * @method
     * 
     * @param {String} dataModelID ID выбранной таблицы
     * @param {String} column Столбец, по которому выполняется сортировка
     * @param {String} direction Направление сортировки
     */
    setSelectedSorting(dataModelID, column, direction) {
        let editedSorting = []
        if (this.selectedSorting.find(item => item.id === dataModelID)) {
            editedSorting = this.selectedSorting.map(item => 
                item.id === dataModelID
                    ?   {...item, column: column, direction: direction}
                    :   item
            )
        } else {
            editedSorting = [...this.selectedSorting, {id: dataModelID, column: column, direction: direction}]
        }
        this.selectedSorting = editedSorting
        localStorage.setItem('sortingParameters', JSON.stringify(editedSorting))
    }

    /**
     * Метод осуществляет загрузку из хранилища сортировки записей выбранной таблицы
     * @method
     * 
     * @param {String} dataModelID ID выбранной таблицы
     */
    loadSavedSorting(dataModelID) {
        const savedSorting = JSON.parse(localStorage.getItem('sortingParameters'))
        if (savedSorting && Array.isArray(savedSorting)) {
            this.selectedSorting = savedSorting
            const foundSorting = savedSorting.find(item => item.id === dataModelID)
            if (foundSorting) {
                return [foundSorting.column, foundSorting.direction]
            }
        }

        return ['created', 'down']
    }

    /**
     * Метод осуществляет получение дочерних записей текущей записи иерархической таблицы
     * @method
     * 
     * @param {Object[]} dataObjects Отображаемые записи таблицы
     * @param {Object} dataObject Текущая запись таблицы
     * @param {Object[]} filters Примененные фильтры
     * @param {String} column Столбец сортировки
     * @param {String} direction Направление сортировки
     */
    async showTreeNode(dataObjects, dataObject, filters, column, direction) {
        let editedDataObjects = dataObjects.slice()
        try {
            const selectedFilters = JSON.stringify(filters)
            const sorter = JSON.stringify([
                {property: column, desc: direction !== 'down'},
            ])
            const data = await DocumentService.getChildDataObjects(50, selectedFilters, sorter, dataObject.id)
            editedDataObjects = dataObjects.map(item => item.id === dataObject.id ? {...item, hierarchyVisible: true} : item)
            if (data.length) {
                const currentLevel = dataObject.hierarchyLevel + 1
                const updatedData = data.map(item => {return {...item, hierarchyVisible: false, hierarchyLevel: currentLevel}})
                const parentIndex = dataObjects.findIndex(item => item.id === dataObject.id)
                editedDataObjects.splice(parentIndex + 1, 0, ...updatedData)
            }                    
        } catch (error) {
            showErrorToast(error, 'fetching', '')
        }

        return editedDataObjects
    }

    /**
     * Метод осуществляет скрытие всех дочерних записей текущей записи иерархической таблицы
     * @method
     * 
     * @param {Object[]} dataObjects Отображаемые записи таблицы
     * @param {Object} dataObject Текущая запись таблицы
     */
    hideTreeNode(dataObjects, dataObject) {
        let hiddenRecords = [dataObject.record_id]
        dataObjects.forEach(item => {
            if (item.system_data && hiddenRecords.includes(item.system_data.parent_record_id))
                hiddenRecords.push(item.record_id)
        })
        const editedDataObjects = dataObjects
                                    .map(item => item.id === dataObject.id ? {...item, hierarchyVisible: false} : item)
                                    .filter(item => !item.system_data || !hiddenRecords.includes(item.system_data.parent_record_id))

        return editedDataObjects
    }

    /**
     * Метод осуществляет проверку таблицы на осуществление импорта данных (наличие активных транзакций)
     * @method
     * 
     * @param {String} dataModelID ID таблицы
     */
    async checkDataModelLock(dataModelID) {
        try {
            const isDataModelLocked = await DocumentService.checkDataModelLock(dataModelID)
            return isDataModelLocked
        } catch (error) {
            showErrorToast(error, 'fetching', '')
            return false
        }
    }

    /**
     * Метод осуществляет получение списка категорий
     * @method
     * 
     * @param {Object[]} filter Примененные фильтры
     * @param {Object[]} sorter Примененные сортировки
     */
    getCategories(filter, sorter) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .getCategories(50, filter, sorter)
            .then(data => {
                clearTimeout(noResponse)
                this.setCategories(data)
                if (this.selectedCategory) {
                    const foundCategory = data.find(category => category.id === this.selectedCategory.id)
                    this.setSelectedCategory(foundCategory ? foundCategory : null)
                }
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
    }

    /**
     * Метод осуществляет создание категории
     * @method
     * 
     * @param {Object} category Категория
     */
    createCategory(category) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .createCategory(category)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Категория успешно создана', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                
                const defaultFilter = []
                const defaultSorter = JSON.stringify([
                    {property: 'order', desc: false},
                ])      
                this.getCategories(defaultFilter, defaultSorter)
                this.setIsCategoryCreating(false)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет редактирование категории
     * @method
     * 
     * @param {Object} category Категория
     */
    editCategory(category) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .editCategory(this.selectedCategory.id, category)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Категория успешно обновлена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })

                const defaultFilter = []
                const defaultSorter = JSON.stringify([
                    {property: 'order', desc: false},
                ])      
                this.getCategories(defaultFilter, defaultSorter)
                this.setIsCategoryEditing(false)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет перемещение категории вверх
     * @method
     */
    moveUpCategory() {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .moveCategory(this.selectedCategory.id, -1)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Категория успешно обновлена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })

                const defaultFilter = []
                const defaultSorter = JSON.stringify([
                    {property: 'order', desc: false},
                ])      
                this.getCategories(defaultFilter, defaultSorter)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет перемещение категории вниз
     * @method
     */
    moveDownCategory() {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .moveCategory(this.selectedCategory.id, 1)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Категория успешно обновлена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })

                const defaultFilter = []
                const defaultSorter = JSON.stringify([
                    {property: 'order', desc: false},
                ])      
                this.getCategories(defaultFilter, defaultSorter)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    /**
     * Метод осуществляет удаление категории
     * @method
     */
    deleteCategory() {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        CategoryService
            .deleteCategory(this.selectedCategory.id)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Категория успешно удалена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                
                const dataModelFilter = []
                const dataModelSorter = JSON.stringify([
                    {property: 'entity_name', desc: false},
                ])      
                this.getDataModels(dataModelFilter, dataModelSorter, 'w')

                const categoryFilter = []
                const categorySorter = JSON.stringify([
                    {property: 'order', desc: false},
                ])      
                this.getCategories(categoryFilter, categorySorter)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'deleting', '')
            })
    }
}

export default DocumentStore
