import React from 'react'
import SimpleItem from './SimpleItem'


/**
 * Визуальный компонент отображает список связываемых сущностей (для списка из выбранных пользователем записей)
 * @param {Object[]} list список связанных объектов КИИ или ресурсов доступа
 * @param {Object} selectedItem Выбранный элемент списка
 * @param {Object} linkingItem Элемент, связанный с выбранным элементом списка
 * @param {String} linkField Имя поля, по которому выполняется связывание
 * @param {Function} onItemClick Обработчик клика мыши на элементе списка
 * [handleEmployeeClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line275),
 * [handlePositionClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line288),
 * [handleSubdivisionClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line298)
 * @param {Function} onLinkClick Обработчик клика мыши на элементе списка, связанном с выбранным элементом списка
 * [handleEmployeeLinkClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line284),
 * [handleEmployeeLinkClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line284),
 * [handleEmployeeLinkClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line284)
 * 
 * @returns {HTMLDivElement} Html-разметку списка связываемых объектов
 * с использованием визуального компонента {@link SimpleItem}
 * 
 * @see [Вызов компонента EmployeesDataForm,](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line368)
 * [EmployeesDataForm,](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line400)
 * [EmployeesDataForm](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line433)
 */
const SimpleItemList = ({list, selectedItem, linkingItem, linkField, onItemClick, onLinkClick}) => {
    return (
        <div className='tw-grow tw-overflow-auto'>
            { list
                .slice()
                .filter(item => !item.system_data.deletion_mark)
                .map((item, index) => 
                    <SimpleItem 
                        key={index}
                        item={item}
                        selectedItem={selectedItem}
                        linkingItem={linkingItem}
                        linkField={linkField}
                        onClick={onItemClick}
                        onLinkClick={onLinkClick}
                    />
            )}
        </div>
    )
}

export default SimpleItemList