import React, { useContext, useEffect, useState } from 'react'
import AppRouter from './components/router/AppRouter'
import HeaderContainer from './components/header/HeaderContainer'
import { observer } from 'mobx-react-lite'
import { Context, medoedImage } from '.'
import AuthService from './services/AuthService'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Spinner from './assets/Spinner'
import ErrorBoundary from './components/error_page/ErrorBoundary'
import { responseTimeOut, serviceMessageTimeOut } from './config/constTypes'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from './config/constsURN'

/**
 * Главный компонент приложения
 * @returns {JSXElement} Компоненты заголовка приложения {@link HeaderContainer} и основного контента {@link AppRouter},
 * компонент перехвата ошибок {@link ErrorBoundary},
 * а также визуальный компонент ToastContainer из библиотеки {@link https://fkhadra.github.io/react-toastify/|React-Tostify}.
 * При ожидании ответа от сервера вместо указанных выше компонентов отображается визуальный компонент {@link Spinner}
 */
const AppHoneyBadger = () => {
    const { userStore } = useContext(Context)
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()
    document.title = 'MEDOED' + (medoedImage.name && medoedImage.name !== '' ? ' [' + medoedImage.name + ']' : '')

    const handleLogOut = async () => {
        await AuthService.logout(false)
        userStore.clearUser()
        navigate(paths.LOGIN_ROUTE)
    }

    useEffect( () => {
        const isMobile = window.matchMedia("only screen and (max-width: 800px)").matches
        if (isMobile) {
            if (location.pathname === '/')
                toast.warning(<div>Структура данного сайта рассчитана на минимальную ширину окна 800px<br/><br/>
                                    Включите в браузере опцию <strong>"Версия для ПК"</strong> для продолжения работы</div>, { position: toast.POSITION.TOP_CENTER })
            else
                navigate(paths.RESOLUTION_ROUTE)
        }
        userStore.setIsLowResolution(isMobile)

        const accessToken = localStorage.getItem('token')
        if (accessToken) {
            const noResponse = setTimeout(() => {
                toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                handleLogOut()
            }, responseTimeOut)
    
            AuthService
                .getUser()
                .then(response => {
                    clearTimeout(noResponse)
                    if (response.status === 200) {
                        userStore.setAuth(true)
                        userStore.setUser(response.data)
                        userStore.getUserConfiguration(false)
                        userStore.getMySubCompanies()
                    }
                })
                .catch(error => {
                    clearTimeout(noResponse)
                    if (error.response && error.response.status !== 401) {
                        let errorMessage = "Ошибка получения данных о пользователе! "
                        switch (error?.response?.status) {
                            case 403:
                                toast.error(errorMessage + "Доступ запрещен", { position: toast.POSITION.TOP_CENTER })
                                break
                            default:
                                toast.error(errorMessage + "Возникла непредвиденная ошибка!", { position: toast.POSITION.TOP_CENTER })
                        }
                    } else {
                        localStorage.setItem('isTokenExpired', true)
                    }
                    handleLogOut()
                })
                .finally(() => setIsLoading(false))
        } else {
            setIsLoading(false)
        }
    }, [])

    if (isLoading || userStore.isConfigurationLoading) {
        return  <>
            <Spinner/>
            {userStore.isConfigurationLoading &&
                <p className='tw-text-center tw-mt-4'>Пожалуйста, подождите. Происходит обновление конфигурации</p>
            }
        </> 
    }

    return (
        <>
            <HeaderContainer />
            <ToastContainer className='toasts'/>
            <div id='container' className='tw-mx-8 tw-h-[calc(100vh_-_9rem)] tw-max-h-[calc(100vh_-_9rem)]'>
                <ErrorBoundary
                    fallback={
                        <div className='tw-w-full tw-mt-4 tw-text-center tw-text-xl tw-font-bold tw-text-red-600'>
                            Обнаружена внутренняя ошибка. <br/> Информация отображена в консоли браузера
                        </div>
                    }
                >
                    <AppRouter userIsAuth={userStore.isAuth}/>
                </ErrorBoundary>
            </div>
        </>
    )        
}

export default observer(AppHoneyBadger)
