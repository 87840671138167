import React, { useContext, useEffect, useState } from 'react'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { serviceMessageTimeOut } from '../../../../config/constTypes'

/**
 * Визуальный компонент отображает название одного пользователя для списка существующих пользователей
 * @param {Object} item Текущий элемент списка пользователей 
 * 
 * @returns {HTMLDivElement} Html-разметку с названием пользователей
 * 
 * @see [Вызов компонента](./components_admin_page_users_users_list_UsersList.js.html#line60)
 */
const UsersListItem = ({item}) => {
    const [userAssigned, setUserAssigned] = useState(false)
    const { AdminStore } = useContext(Context)
    let className = `${!item.is_active && 'tw-line-through tw-text-red-400'} tw-px-4 tw-truncate`

    useEffect(()=>{
        if (item.allowed_sub_company_ids.find(item => item === AdminStore.selectedSubCompany.id)) {
            setUserAssigned(true)
        } else {
            setUserAssigned(false)
        }
    }, [AdminStore.selectedSubCompany])

    const onItemClick = (item) => {
        if (AdminStore.selectedUser?.id !== item.id) {
            AdminStore.setSelectedUser(item)
        }
    }

    const bindUser = () => {
        if (!item.company_id || item.company_id === AdminStore.selectedCompany.id) {
            AdminStore.rebindUsers({users: {add: [item.id]}})

            if (!item.company_id) {
                AdminStore.editUser({company_id: AdminStore.selectedCompany.id}, item.id)
            }
        } else {
            toast.error('Вы можете привязать пользователя только к юр. лицу его компании', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }
    }

    const unBindUser = () => {
        AdminStore.rebindUsers({users: {remove: [item.id]}})
    }
    
    return (
        <div className='tw-flex tw-justify-between tw-items-center tw-text-sm tw-py-2 tw-border-b hover:tw-cursor-pointer 
                        tw-text-gray-900 tw-bg-white hover:tw-bg-gray-200 hover:tw-border-gray-300'
        >
            <div
                className = 'tw-truncate tw-w-2/3'
                onClick={() => onItemClick(item)}
            >
                <p className = {className}>
                    {item.first_name} {item.last_name}
                </p>
                <p className = {className}>
                    {item.email}
                </p>
            </div>

            <div  className="tw-h-5 tw-w-5 tw-mr-6" data-tooltip-id="users-list-tooltip" data-tooltip-content={userAssigned ? "Отвязать" : "Привязать"}>
                {userAssigned 
                    ? <MinusIcon className="hover:tw-text-gray-500" onClick={unBindUser}/> 
                    : <PlusIcon className="hover:tw-text-gray-500" onClick={bindUser}/>
                }
            </div>
        </div>
    )
}

export default observer(UsersListItem)
