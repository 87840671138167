import React, { useContext } from 'react'
import PageTitle from '../main_page/controller/common/panels/PageTitle'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import Spinner from '../../assets/Spinner'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../config/constsURN'


/**
 * Визуальный компонент отображает информацию о версиях конфигурации
 * 
 * @returns {JSXElement} Html-разметку для информации о лицензионном соглашении
 * с использованием визуальных компонентов {@link PageTitle} configuration_page
 */
const ConfigurationUpdate = () => {
    const { userStore } = useContext(Context)
    const navigate = useNavigate()

    const handleUpdateClick = () => {
        userStore.updateUserConfiguration()
    }

    const handleReturnClick = () => {
        navigate(paths.MAIN_ROUTE)
    }

    return (
        <>
            <PageTitle title='Обновление конфигурации'/>
            <div className='tw-w-full tw-h-full tw-flex tw-flex-col tw-bg-white tw-rounded-md tw-overflow-hidden'>
                { userStore.updateTimerID
                    ?
                        <div className='tw-h-16 tw-w-full tw-flex tw-justify-center tw-items-center tw-gap-x-2 tw-mx-auto tw-py-4 tw-border-b tw-border-gray-300'>
                            <Spinner size='small'/>
                            <div className='tw-text-md tw-font-semibold'>
                                Выполняется обновление конфигурации...
                            </div>
                        </div>
                    :   userStore.isUpdateFlag
                            ?
                                <div className='tw-h-16 tw-w-full tw-flex tw-justify-center tw-items-center tw-mx-auto tw-py-4 tw-border-b tw-border-gray-300'>
                                    <button 
                                        className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                                hover:tw-bg-gray-600 hover:tw-border-gray-600 disabled:tw-bg-gray-300  disabled:tw-border-gray-300
                                                focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                        onClick={handleUpdateClick}
                                    >
                                        Обновить конфигурацию
                                    </button>
                                </div>
                            :
                                <div className='tw-h-16 tw-w-full tw-flex tw-justify-center tw-items-center tw-mx-auto tw-py-4 tw-border-b tw-border-gray-300'>
                                    <button 
                                        className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-indigo-600  tw-bg-indigo-600 tw-text-white
                                                hover:tw-bg-indigo-500 hover:tw-border-indigo-500 disabled:tw-bg-gray-300  disabled:tw-border-gray-300
                                                focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                        onClick={handleReturnClick}
                                    >
                                        Перейти на главную
                                    </button>
                                </div>
                }
                <div className='tw-grow tw-overflow-auto'>
                    {userStore.configuration &&
                        <div className='tw-px-4'>
                            <div className='tw-text-sm tw-font-semibold tw-mt-2 tw-py-2'>
                                Состояние конфигурации по компонентам:
                            </div>
                            { userStore.configuration.modules_mapping &&
                              userStore.configuration.modules_mapping.main &&
                              userStore.configuration.modules_mapping.main.apps_mapping &&
                              Object.values(userStore.configuration.modules_mapping.main.apps_mapping).length
                                ?
                                    Object.values(userStore.configuration.modules_mapping.main.apps_mapping).map((module, index) => (
                                        <div key={index} className="tw-text-sm tw-font-medium tw-leading-5 tw-px-4 tw-py-1">
                                            <span>Компонент </span>
                                            <span className='tw-font-semibold'>"{module.app.name}"</span>
                                            <span> : </span>
                                            <span className={`tw-font-semibold ${module.status === 'failed' ? 'tw-text-red-600' : module.status === 'successfully' ? 'tw-text-teal-600' : 'tw-text-gray-600'}`}>
                                                {userStore.getModuleStatus(module.status)}
                                            </span>
                                        </div>))
                                :
                                    <div className='tw-text-sm tw-italic tw-mt-2 tw-px-4 tw-py-1'>
                                        Нет данных
                                    </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default observer(ConfigurationUpdate)