import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../..'
import ExporterList from './list/ExporterList'
import PageTitle from '../common/panels/PageTitle'
import DocumentService from '../../../../services/DocumentService'
import Spinner from '../../../../assets/Spinner'
import ExporterMenu from './menu/ExporterMenu'
import ExporterInfo from './info/ExporterInfo'
import ExporterForm from './form/ExporterForm'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'
import { responseTimeOut, serviceMessageTimeOut, getReferenceTypeName } from '../../../../config/constTypes'
import { handleFileRemove } from '../../../../functions/fileHandlers'
import { showErrorToast } from '../../../../functions/errorHandlers'
import ExporterService from '../../../../services/ExporterService'
import { Tooltip } from 'react-tooltip'
import DialogTab from '../../../dialog_tab/DialogTab'


/**
 * Компонент реализует логику работы с печатными формами
 * 
 * @returns {HTMLDivElement} Html-разметку страницы с использованием визуальных компонентов
 * {@link PageTitle}, {@link ExporterList}, {@link ExporterMenu}, {@link ExporterInfo}, {@link ExporterForm}, {@link DialogTab} 
 */
const ExporterListContainer = () => {
    const { docStore, FilterStore, DialogTabStore } = useContext(Context)

    const [isLoading, setIsLoading] = useState(true)
    const [exporters, setExporters] = useState(null)
    const [selectedExporter, setSelectedExporter] = useState(null)
    const [isCreating, setIsCreating] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [attachedFiles, setAttachedFiles] = useState([])
    const [dataModelName, setDataModelName] = useState('')
    const [dataModelsList, setDataModelsList] = useState([])

    const handleItemClick = (item) => {
        if (!isCreating && !isEditing) {
            setDataModelName(getReferenceTypeName(docStore.dataModels, item.meta.data_model_id))
            setSelectedExporter(item)
            if (item.render_data.type === 'file' && item.render_data.file) {
                setAttachedFiles([item.render_data.file])
            } else {
                setAttachedFiles([])
            }
        }
    }

    const getAllExporters = () => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис экспорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setIsLoading(false)
            setExporters([])
        }, responseTimeOut)

        setIsLoading(true)

        ExporterService
            .getExporters(50)
            .then(data => {
                clearTimeout(noResponse)
                setExporters(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                setExporters([])
            })
            .finally(setIsLoading(false))   
    }

    const handleAddExporterClick = (form) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис экспорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const exporter = {}
        exporter.name = form.templateName
        exporter.meta = {}
        exporter.meta.data_model_id = form.dataModelID
        exporter.render_data = {}
        exporter.render_data.type = form.templateType.value
        if (exporter.render_data.type === 'file') {
            if (attachedFiles.length)
                exporter.render_data.file = {id: attachedFiles[0].id}
        } else {
            exporter.render_data.content = form.templateText
        }

        ExporterService
            .createExporter(exporter)
            .then(() => {
                clearTimeout(noResponse)
                getAllExporters()
                setIsCreating(false)
                setIsEditing(false)
                setAttachedFiles([])
                FilterStore.clearAllFilters()
                toast.success('Печатная форма успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    const handleEditExporterClick = (form) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис экспорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const exporter = {}
        exporter.id = form.exporterID
        exporter.name = form.templateName
        exporter.meta = {}
        exporter.meta.data_model_id = form.dataModelID
        exporter.render_data = {}
        exporter.render_data.type = form.templateType.value
        if (exporter.render_data.type === 'file') {
            if (attachedFiles.length)
                exporter.render_data.file = {id: attachedFiles[0].id}
        } else {
            exporter.render_data.content = form.templateText
        }

        ExporterService
            .updateExporter(exporter)
            .then(() => {
                clearTimeout(noResponse)
                getAllExporters()
                setSelectedExporter(null)
                setIsCreating(false)
                setIsEditing(false)
                setAttachedFiles([])
                FilterStore.clearAllFilters()
                toast.success('Печатная форма успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    const handleCreateModeClick = () => {
        setSelectedExporter(null)
        setAttachedFiles([])
        setIsCreating(true)
        setIsEditing(false)
    }

    const handleEditModeClick = () => {
        setIsCreating(false)
        setIsEditing(true)
    }

    const handleCancelClick = () => {
        FilterStore.clearAllFilters()
        setIsCreating(false)
        setIsEditing(false)
    }

    const handleDeleteExporterClick = () => {
        DialogTabStore.setDialogTabIsOpen(false)
        
        const noResponse = setTimeout(() => {
            toast.error('Сервис экспорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        ExporterService
            .deleteExporter(selectedExporter.id)
            .then(() => {
                clearTimeout(noResponse)
                getAllExporters()
                setSelectedExporter(null)
                setIsCreating(false)
                setIsEditing(false)
                toast.success('Печатная форма успешно удалена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'deleting', '')
            })
    }

    const getDataModels = (setDataModels) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setIsLoading(false)
        }, responseTimeOut)

        setIsLoading(true)

        const filters = JSON.stringify(FilterStore.selectedFilters)
        const sorter = JSON.stringify([
            {property: 'entity_name', desc: false},
        ])

        DocumentService
            .getDataModels(filters, sorter)
            .then(data => {
                clearTimeout(noResponse)
                setDataModels && setDataModels(data)
                docStore.setDataModels(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
            .finally(setIsLoading(false))
    }

    useEffect( () => {
        getDataModels(setDataModelsList)
        getAllExporters()
    }, [])

    useEffect(()=>{
        getDataModels()
    }, [FilterStore.selectedFilters])

    if (isLoading || !exporters) {
        return  <Spinner/>
    }

    return (
        <>
            <PageTitle title={'Печатные формы'} />
            <div id='exporter-page' className='tw-grid tw-grid-cols-3 tw-gap-x-8 tw-max-h-full tw-h-full'>
                <div id='exporter-list' className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <ExporterMenu
                        activeItem={selectedExporter}
                        onAddItemClick={handleCreateModeClick}
                        onEditItemClick={handleEditModeClick}
                    />
                    <ExporterList
                        list={exporters}
                        activeItem={selectedExporter}
                        onItemClick={handleItemClick}
                        dataModelsList={dataModelsList}
                    />
                </div>
                <div id='exporter-column' className='tw-col-span-2 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-flex tw-flex-col'>
                    <div id='exporter-header' className='tw-h-12 tw-flex tw-items-center tw-justify-between tw-border-b-2 tw-border-gray-400 tw-px-4 tw-py-2'>
                        <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Информация о шаблоне</p>
                    </div>
                    { selectedExporter && !isCreating && !isEditing &&
                        <ExporterInfo
                            activeItem={selectedExporter}
                            dataModel={dataModelName}
                        />
                    }
                    { (isCreating || isEditing) &&
                        <ExporterForm 
                            editMode={isEditing}
                            exporter={selectedExporter}
                            files={attachedFiles}
                            setAttachedFiles={setAttachedFiles}
                            onFileRemove={handleFileRemove(attachedFiles, setAttachedFiles)}
                            onSubmitClick={isEditing ? handleEditExporterClick: handleAddExporterClick}
                            onCancelClick={handleCancelClick}
                        />
                    }
                </div>
                <Tooltip id="exporter-editor-tooltip" place="top"/>
            </div>
            <DialogTab
                parentName='ExporterListContainer'
                dialogTabFunction={handleDeleteExporterClick}
            />
        </>
    )
}

export default observer(ExporterListContainer)