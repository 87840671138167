import React, { useState, useContext } from 'react'
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { observer } from 'mobx-react-lite'
import Spinner from '../../../../../../../assets/Spinner'
import { Context } from '../../../../../../..'
import { setSignificanceCategory } from '../../../../../../../functions/significanceCategories'
import { runInAction } from 'mobx'
import FormFieldName from '../../../../../../form_fields/FormFieldName'
import FormFieldInput from '../../../../../../form_fields/FormFieldInput'
import NestedTableFieldsList from '../../../../../../tabs/nested_tables/NestedTableFieldsList'
import NestedModelMenu from '../../../../../../tabs/nested_tables/NestedModelMenu'
import { toast } from 'react-toastify'


/**
 * Визуальный компонент отображает форму для создания или редактирования информации об объекте категорирования
 * 
 * @param {Object} fieldItem Поле формы
 * @param {Object[]} errors Список ошибок
 * @param {Function} handleSelectClick Обработчик клика для открытия таблицы относящейся к полю ссылочного типа
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line79)
 * @param {Function} handleClearClick Обработчик клика для очистки поля ссылочного типа
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line85)
 * @param {Function} handleRecordChange Обработчик изменения значения в любом поле формы
 * [handleRecordChange](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line90)
 * @param {Boolean} isEditMode Признак режима редактирования
 * @param {Boolean} isDuplicateMode Признак режима дублирования
 * @param {Boolean} isLoading Признак загрузки
 * @param {Object} control Объект, реализующий регистрацию компонентов
 * @param {Function} register Метод, реализующий регистрацию поля UseForm
 * [register](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line45)
 * @param {Function} setValue Метод, реализующий сохранение значения поля UseForm
 * [setValue](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line47)
 * @param {Function} clearErrors Метод, реализующий сброс ошибок UseForm
 * [clearErrors](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line51)
 * @param {Object[]} categories Список категорий
 * @param {Function} setError Метод, реализующий добавление ошибок UseForm
 * [setError](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line49)
 * 
 * @returns {HTMLDivElement} Html-разметку элемента формы с использованием визуальных компонентов {@link Spinner}, {@link FormFieldInput}, 
 * {@link NestedModelMenu}, {@link NestedTableFieldsList}, {@link FormFieldName}
 *   
 * @see [Вызов компонента](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line512)
 */
const CategorizingObjectsFormItem = ({fieldItem, errors, handleSelectClick, handleClearClick, handleRecordChange, isEditMode, isDuplicateMode, isLoading, 
                                        control, register, setValue, clearErrors, categories, setError}) => {
    const { categorizingCIIStore } = useContext(Context)
    const [isNestedModelsShow, setIsNestedModelsShow] = useState(true)
    const [isMinScrollPosition, setIsMinScrollPosition] = useState(true)
    const [isMaxScrollPosition, setIsMaxScrollPosition] = useState(false)

    const checkExtremeProvisions = (scrollElement) => {
        setIsMinScrollPosition((scrollElement.scrollLeft) <= 0)
        setIsMaxScrollPosition((scrollElement.scrollLeft + scrollElement.offsetWidth) >= scrollElement.scrollWidth)
    }

    const changeScrollPosition = (positionDelta, isLeft) => {
        const scrollElement = document.getElementById('nested_indicatorss')
        if (scrollElement) {
            isLeft
                ?   scrollElement.scrollLeft += positionDelta
                :   scrollElement.scrollLeft -= positionDelta
        }
    }
   
    const handleChangeNestedTables = (editedValues, editedNestedModel, editedRow) => {
        categorizingCIIStore.setNestedModels(categorizingCIIStore.nestedModels.map((nestedModel) =>  {
            if (nestedModel.id === editedNestedModel.id) {
                let sumError = false
                let checkedValues
                // HARD-CODE дополнительных проверок для вложенной таблицы показателей значимости
                if (editedNestedModel.id === 'indicatorss') {

                    editedValues.forEach(indicator => {
                        if (indicator.id === editedRow.id && indicator.status !== 'deleted') {
                            // для неактуальных показателей
                            if (indicator.data['justification_of_irrelevance__indicatorss'].value &&
                                indicator.data['justification_of_irrelevance__indicatorss'].value !== undefined &&
                                indicator.data['justification_of_irrelevance__indicatorss'].value.trim() !== '') {
                            
                                nestedModel.dataObjects.forEach(dataObject => {
                                    if (dataObject.id === indicator.id) {
                                        // для показателя, у которого уже была определена категория значимости
                                        if (dataObject.data['category_of_sign_by_indicator__indicatorss'].value.values.length > 0) {
                                            if (indicator.status === 'added') {
                                                // вновь добавленный показатель удаляется из списка
                                                // !!! avoid such code
                                                editedValues = editedValues.filter(item => !(item.id === indicator.id && item.status === 'added'))
                                            } else {
                                                // у сохраненного показателя меняется статус
                                                runInAction(() => {
                                                    indicator.status = "deleted"
                                                })
                                            }
                                            // создается дубликат
                                            runInAction(() => {
                                                indicator.data['category_of_sign_by_indicator__indicatorss'].value = {format: '', values: []}
                                                indicator.data['value_of_the_indicator__indicatorss'].value = ''
                                                indicator.data['justification_of_the_indicator_val__indicatorss'].value = ''
                                            })
                                            // !!! avoid such code
                                            editedValues = editedValues.concat([{...indicator, status: 'added'}])

                                        } else if  (indicator.data['justification_of_the_indicator_val__indicatorss'].value &&
                                                    indicator.data['justification_of_the_indicator_val__indicatorss'].value !== undefined &&
                                                    indicator.data['justification_of_the_indicator_val__indicatorss'].value.trim() !== '') 
                                        {
                                            indicator.data['justification_of_the_indicator_val__indicatorss'].value = ''
                                        }
                                    }
                                })

                            } else { // для актуальных показателей
                                if (indicator.status === 'edited' && 
                                    indicator.data['justification_of_irrelevance__indicatorss'].value &&
                                    indicator.data['justification_of_irrelevance__indicatorss'].value.trim() === '') {
                                        indicator.data['justification_of_irrelevance__indicatorss'].value = ' '
                                }
                                
                                if (indicator.data['value_of_the_indicator__indicatorss'].value && indicator.data['value_of_the_indicator__indicatorss'].value !== undefined )
                                {   
                                    const result = setSignificanceCategory(indicator, categories)

                                    if (result) {
                                        runInAction(() => {
                                            indicator.data['category_of_sign_by_indicator__indicatorss'].value = result
                                        })
                                    }
                                }
                            }
                        }
                    })
                    
                    const [isError, checkedNestedModel] = categorizingCIIStore.checkIndicatorErrors(editedValues)
                    sumError = isError
                    checkedValues = checkedNestedModel

                } else {
                    const primaryField = nestedModel.fields.find(field => field.order === 0)
                    const [isError, checkedNestedModel] = categorizingCIIStore.checkNestedValueErrors(editedValues, primaryField.tech_name, true)
                    sumError = isError
                    checkedValues = checkedNestedModel
                }

                if (!sumError)
                    clearErrors('data.' + nestedModel.tech_name)
                // для отредактированной вложенной таблицы
                return {
                    ...nestedModel,
                    dataObjects: nestedModel.errors ? checkedValues : editedValues,
                    errors: nestedModel.errors ? sumError : false
                }
            }
            // для нередактированных вложенных таблиц
            return nestedModel
        }))
        handleRecordChange()
    }

    const calculate9thIndicator = (nestedModel) => {
        const currentYear = new Date().getFullYear()
        const recoveryTime = document.getElementsByName("data.recovery_time")
        const schedule = document.getElementsByName("data.schedule_object_kii")
        
        let lastYearTax = 0
        categorizingCIIStore.organizationNestedModels.forEach(item => {
            if (item.id === 'taxs') {
                item.dataObjects.forEach(dataObject => {
                    if (dataObject.data.year_string__taxs.value === currentYear - 1) {
                        lastYearTax = dataObject.data.summa__taxs.value
                    }
                })
            }
        })

        if (lastYearTax === 0){
            toast.error(`Не возможно рассчитать 9й показатель. Укажите налог за ${currentYear - 1} год`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        } else if (!recoveryTime[0].value || recoveryTime[0].value < 0) {
            setError(`data.recovery_time`)
            toast.error(`Не возможно рассчитать 9й показатель. Время восстановления должно быть неотрицательным числом`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        } else if (!schedule[0]) {
            setError(`data.schedule_object_kii`)
            toast.error(`Не возможно рассчитать 9й показатель. Укажите график работы объекта КИИ`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        } else {
            clearErrors()
            let workDays = schedule[0].value === '7 дней в неделю' ? 365 : 247
            let indicator = (lastYearTax * (recoveryTime[0].value / workDays)) / 34000000000000

            if (indicator < 0.0001) {
                indicator = 'менее 0.0001'
            } else {
                indicator = indicator.toFixed(4)
            }

            let editedRow
            const editedValues = nestedModel.dataObjects.map(dataObject => {
                if (dataObject.data.indicator__indicatorss.value.values[0].code === '09' && dataObject.status !== 'deleted') {
                    let editedDataObject = JSON.parse(JSON.stringify(dataObject))
                    editedRow = dataObject

                    if (dataObject.status === 'saved')
                        editedDataObject.status = 'edited'

                    let organizationLoss = recoveryTime[0].value / workDays
                    if (organizationLoss < 0.0001) {
                        organizationLoss = 'менее 0.0001'
                    } else {
                        organizationLoss = 'не более ' + organizationLoss.toFixed(4)
                    }

                    editedDataObject.data.justification_of_the_indicator_val__indicatorss.value = 
`Максимальное время восстановления после атаки для объекта КИИ составляет ${recoveryTime[0].value} дня(ей). 
Таким образом, за период неисправности, организация потеряет ${organizationLoss}% прибыли. 
При объеме выплачиваемых (за предыдущий год) в федеральный бюджет налогов ${lastYearTax} рублей, ущерб бюджету составит не более 
${(lastYearTax * (recoveryTime[0].value / workDays)).toFixed(4)} рублей. С учетом планируемого объема федерального бюджета РФ на 
${currentYear} год - ${indicator}%`

                    editedDataObject.data.value_of_the_indicator__indicatorss.value = indicator
                    categorizingCIIStore.setSelectedNestedValue(editedDataObject)

                    document.getElementById(nestedModel.id +'nested_table_row' + dataObject.id).scrollIntoView({ block: "center", behavior: "smooth" });
                    return editedDataObject
                } else {
                    return dataObject
                }
            })
            handleChangeNestedTables(editedValues, nestedModel, editedRow)
            toast.success(`9й показатель рассчитан успешно`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        }
    }

    return (
        <div className={`${fieldItem.type === 'include' ? 'tw-my-4' : 'sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-py-2'}`}>
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-justify-between">
                <FormFieldName                
                    item={fieldItem}
                    errors={errors}
                />
                {fieldItem.type === 'include' && 
                    <button 
                        type='button'
                        className='tw-p-0.5 tw-ml-1 hover:tw-text-gray-700 tw-flex tw-flex-row tw-items-center'
                        onClick={() => setIsNestedModelsShow(!isNestedModelsShow)}
                    >
                        { isNestedModelsShow
                            ?   <>
                                    <span>Свернуть</span>
                                    <ChevronUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                </>
                            :   <>
                                    <span>Развернуть</span>
                                    <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                </> 
                        }
                    </button> 
                }
            </dt>
            {fieldItem.type === 'include' 
                ?
                    (categorizingCIIStore.nestedModels.map((nestedModel, index) => {
                        if (nestedModel.id === fieldItem.ref_model_ids[0]) {
                            let calculate9thIndicatorDisabled = false
                            if (nestedModel.id === 'indicatorss') {
                                calculate9thIndicatorDisabled = !nestedModel.dataObjects.find(item => 
                                    item.data.indicator__indicatorss.value.values[0].code === '09' &&
                                    item.status !== 'deleted' &&
                                    !item.data.justification_of_irrelevance__indicatorss.value)                                
                            }
                            
                            return  <dd key={index} 
                                        className={`${isNestedModelsShow
                                                        ?   nestedModel.id !== 'indicatorss'
                                                                ?   'tw-p-4'
                                                                :   'tw-pt-4 tw-pb-2 tw-px-1 tw-h-full'
                                                        :   'tw-h-4 tw-overflow-hidden tw-pt-4'} 
                                                    tw-duration-500 tw-mt-1 tw-bg-gray-200 tw-rounded-md`}
                                    >
                                    { isLoading
                                        ?   <Spinner/>
                                        :   <>
                                                {nestedModel.id !== 'indicatorss' 
                                                    ?   <NestedModelMenu
                                                            nestedDataModels={categorizingCIIStore.nestedModels}
                                                            selectedNestedDataModel={nestedModel}                                                       
                                                            selectedNestedDataObject={categorizingCIIStore.selectedNestedValue}
                                                            setSelectedNestedDataObject={e => categorizingCIIStore.setSelectedNestedValue(e)}
                                                            isDocumentPage={false}
                                                            onChange={handleChangeNestedTables}
                                                        />
                                                    : 
                                                        <div className='tw-w-full tw-text-right'>
                                                            <button 
                                                                className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                                                            hover:tw-bg-gray-600 hover:tw-border-gray-600 focus-visible:tw-outline focus-visible:tw-outline-2 
                                                                            focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600 tw-mr-6
                                                                            disabled:tw-bg-gray-400 disabled:tw-border-gray-400 tw-mb-1'
                                                                disabled={calculate9thIndicatorDisabled}
                                                                onClick={(e) => {e.preventDefault(); calculate9thIndicator(nestedModel)}}
                                                            >
                                                                <span
                                                                    data-tooltip-id="categorizing-objects-form-tooltip"
                                                                    data-tooltip-content={`${calculate9thIndicatorDisabled
                                                                        ? 'Расчет невозможен, так как для показателя указано "Обоснование неактуальности"' : ''}`}
                                                                >
                                                                    Рассчитать 9й показатель 
                                                                </span>
                                                            </button>
                                                        </div>
                                                }
                                                <div className='tw-h-full tw-overflow-hidden tw-flex tw-flex-row'>
                                                    {nestedModel.id === 'indicatorss' && 
                                                        <button
                                                            type='button'
                                                            className='tw-flex tw-flex-col tw-justify-evenly tw-mr-1 tw-w-4 tw-rounded-sm tw-text-gray-900 tw-bg-gray-100 hover:tw-bg-gray-300 disabled:tw-text-gray-400 disabled:tw-bg-gray-100'
                                                            onClick={() => changeScrollPosition(100, false)}
                                                            disabled={isMinScrollPosition}
                                                        >
                                                            <ChevronLeftIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                                            <ChevronLeftIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                                            <ChevronLeftIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                                        </button>
                                                    }
                                                    <NestedTableFieldsList
                                                        readOnly={false}
                                                        dataModel={nestedModel}
                                                        dataObjects={nestedModel.dataObjects}
                                                        selectedNestedDataObject={categorizingCIIStore.selectedNestedValue}
                                                        setSelectedNestedDataObject={e => categorizingCIIStore.setSelectedNestedValue(e)}
                                                        isDocumentPage={false}
                                                        onChange={handleChangeNestedTables}
                                                        onScrollX={checkExtremeProvisions}
                                                        isChosenObjectDuplicationForbidden={true}
                                                    />
                                                    {nestedModel.id === 'indicatorss' && 
                                                        <button
                                                            type='button'
                                                            className='tw-flex tw-flex-col tw-justify-evenly tw-ml-1 tw-w-4 tw-rounded-sm tw-text-gray-900 tw-bg-gray-100 hover:tw-bg-gray-300 disabled:tw-text-gray-400 disabled:tw-bg-gray-100'
                                                            onClick={() => changeScrollPosition(100, true)}
                                                            disabled={isMaxScrollPosition}
                                                        >
                                                            <ChevronRightIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                                            <ChevronRightIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                                            <ChevronRightIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                                        </button>
                                                    }
                                                </div>
                                            </> 
                                    }
                                </dd>
                        } else return null
                    }))
                : 
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                        <FormFieldInput
                            item={fieldItem}
                            isEditMode={isEditMode}
                            isDuplicateMode={isDuplicateMode}
                            onReferenceSelect={handleSelectClick}
                            onReferenceClear={handleClearClick}
                            onRecordChange={handleRecordChange}
                            register={register}
                            control={control}
                            setValue={setValue}
                            isError={errors?.data && errors?.data[fieldItem.tech_name]}
                        />
                    </dd>
            }
        </div>
    )
}

export default observer(CategorizingObjectsFormItem)
