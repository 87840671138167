import React from 'react'
import PageTitle from '../main_page/controller/common/panels/PageTitle'


/**
 * Визуальный компонент отображает информацию о версиях конфигурации
 * 
 * @returns {JSXElement} Html-разметку для информации о лицензионном соглашении
 * с использованием визуальных компонентов {@link PageTitle} configuration_page
 */
const ConfigurationInfo = () => {
    return (
        <>
            <PageTitle title='ИНФОРМАЦИЯ О ВЕРСИЯХ КОНФИГУРАЦИИ ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ «MEDOED»'/>
            <div className='tw-px-4'>
                <div> 
                    <p className='tw-font-bold'>v.0.9.4</p>
                    <p className='tw-text-xs'>Опубликовано: 28.09.2023 г.</p>
                    <p className='tw-my-2'>Завершена таблица "Документирование ПДн"</p>
                    <p className='tw-my-2'>Стало доступным полное прохождение ПДн</p>
                </div>

                <div className='tw-mt-2'> 
                    <p className='tw-font-bold'>v.0.9.3</p>
                    <p className='tw-text-xs'>Опубликовано: 28.09.2023 г.</p>
                    <p className='tw-my-2'>Добавлены таблицы:</p>
                    <ul  className='tw-list-disc'>
                        <li className='tw-ml-10'>Дополнительные вопросы</li>
                        <li className='tw-ml-10'>Вопросы для ркн</li>
                    </ul>
                </div>

                <div className='tw-mt-2'> 
                    <p className='tw-font-bold'>v.0.9.2</p>
                    <p className='tw-text-xs'>Опубликовано: 28.09.2023 г.</p>
                    <p className='tw-my-2'>Добавлена возможность загружать архив документов для 1 этапа</p>
                </div>

                <div className='tw-mt-2'>
                    <p className='tw-font-bold'>v.0.9.1</p>
                    <p className='tw-text-xs'>Опубликовано: 28.09.2023 г.</p>
                    <p className='tw-my-2'>Добавлены таблицы:</p>
                    <ul  className='tw-list-disc'>
                        <li className='tw-ml-10'>Документирование ПДн</li>
                        <li className='tw-ml-10'>Сторонние организации</li>
                    </ul>

                    <p className='tw-my-2'>Добавлена возможность ссылаться на несколько записей других таблиц</p>
                </div>
                
                <div className='tw-mt-2'>
                    <p className='tw-font-bold'>v.0.9.0</p>
                    <p className='tw-text-xs'>Опубликовано: 28.09.2023 г.</p>
                    <p className='tw-my-2'>Добавлены документы:</p>
                    <ul  className='tw-list-disc'>
                        <li className='tw-ml-10'>Информация о компании</li>
                        <li className='tw-ml-10'>Карточка ИСПДн</li>
                    </ul>

                    <p className='tw-my-2'>Добавлены справочники:</p>
                    <ul className='tw-list-disc'>
                        <li className='tw-ml-10'>Направления деятельности</li>
                        <li className='tw-ml-10'>Этапы обработки персональных данных</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ConfigurationInfo