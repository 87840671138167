import React from 'react'
import { Controller } from 'react-hook-form'
import { setFieldDefaultValue, setFieldValue } from '../../config/constTypes'
import DataSwitch from '../main_page/controller/common/inputs/DataSwitch'
import DataEnumListBox from '../main_page/controller/common/inputs/DataEnumListBox'
import DataReferenceInput from '../main_page/controller/common/inputs/DataReferenceInput'
import { XMarkIcon, CalendarIcon } from '@heroicons/react/20/solid'
import ReactDatePicker, {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"

registerLocale("ru", ru)

/**
 * Визуальный компонент выбора поля для ввода/редактирования значения в зависимости от типа поля записи
 * 
 * @param {Object} item Поле
 * @param {Boolean} isEditMode Признак режима редактирования
 * @param {Boolean} isDuplicateMode Признак режима дублирования
 * @param {Function} onReferenceSelect Обработчик выбора значения ссылочного поля
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line79),
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line48),
 * [handleSelectClick](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line86),
 * [handleSelectClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line79),
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line52),
 * [handleSelectClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line71),
 * [handleSelectClick](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line55),
 * [handleSelectClick](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line105),
 * [handleSelectClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line71),
 * [handleSelectClick](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line64),
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line55)
 * @param {Function} onReferenceClear Обработчик очистки значения ссылочного поля
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line85),
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line55),
 * [handleClearClick](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line93),
 * [handleClearClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line85),
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line59),
 * [handleClearClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line84),
 * [handleClearClick](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line63),
 * [handleClearClick](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line118),
 * [handleClearClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line77),
 * [handleClearClick](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line70),
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line61)
 * @param {Function} onRecordChange Обработчик изменения значения поля
 * [handleRecordChange](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line90),
 * [handleRecordChange](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line90),
 * [handleRecordChange](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line89),
 * [handleRecordChange](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line68),
 * [handleRecordChange](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line123),
 * [handleRecordChange](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line82),
 * [handleRecordChange](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line75),
 * [setIsSavedRecord](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line42),
 * [setIsSavedRecord](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line45),
 * [setIsSavedRecord](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line48)
 * @param {Object} control Объект, реализующий регистрацию компонентов
 * @param {Function} register Метод, реализующий регистрацию поля UseForm
 * [register](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line45),
 * [register](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line30),
 * [register](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line47),
 * [register](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line50),
 * [register](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line33),
 * [register](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line46),
 * [register](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line28),
 * [register](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line30),
 * [register](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line45),
 * [register](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line29),
 * [register](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line36)
 * @param {Function} setValue Метод, реализующий сохранение значения поля UseForm
 * [setValue](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line47),
 * [setValue](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line32),
 * [setValue](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line49),
 * [setValue](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line52),
 * [setValue](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line35),
 * [setValue](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line48),
 * [setValue](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line30),
 * [setValue](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line32),
 * [setValue](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line47),
 * [setValue](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line31),
 * [setValue](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line38)
 * @param {Boolean} isError Признак ошибки
 * 
 * @returns {JSXElement} Html-разметку поля для ввода/редактирования значения в зависимости от типа поля записи 
 * с использованием визуальных компонентов {@link DataReferenceInput}, {@link DataSwitch}, {@link DataEnumListBox}
 * 
 * @see [Вызов компонента CategorizingObjectsFormItem,](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsFormItem.js.html#line354)
 * [DataObjectForm,](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line184)
 * [FieldItem,](./components_form_fields_FieldItem.js.html#line167)
 * [PDataAddQuestionForm,](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line208)
 */ 
const FormFieldInput = ({item, isEditMode, isDuplicateMode, onReferenceSelect, onReferenceClear, onRecordChange, control, register, setValue, isError}) => {
    return (
        <>
            { ['one', 'many'].includes(item.validator_type)  &&
                <Controller
                    name={'data.' + item.tech_name + '.0'}
                    control={control}
                    rules={ {validate: value => value.id !== '0' || !item.mandatory} }
                    defaultValue={ (isEditMode || isDuplicateMode) ? item  : {value : {format: "", values: []}} }
                    render={({field}) =>
                        <DataReferenceInput
                            value={field.value}
                            onChange={(e) => {field.onChange(e)}}
                            onSelectClick={() => onReferenceSelect(item.ref_model_ids.length > 1 ? item.ref_model_ids : item.ref_model_ids[0], 'data.' + item.tech_name + '.0', item)}
                            onClearClick={() => onReferenceClear('data.' + item.tech_name + '.0')}
                            isError={isError}
                            isNested={false}
                            isRowSelected={false}
                            isCellSelected={true}
                            readOnly={false}
                        />}
                />
            }
            { item.validator_type === 'bool' &&
                <Controller
                    name={'data.' + item.tech_name}
                    control={control}
                    defaultValue={(isEditMode || isDuplicateMode) ? setFieldValue(item) : setFieldDefaultValue(item)}
                    render={({field}) =>
                        <DataSwitch 
                            isChecked={field.value}
                            onClick={(e) => {onRecordChange(false); field.onChange(e)}}
                        />}
                />
            }
            { item.validator_type === 'int' &&
                <input
                    type='number'
                    defaultValue={item.default !== '-' ? item.default : ''}
                    className={`tw-w-52 tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                        ${isError ? 'tw-ring-red-400 focus-visible:tw-ring-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-gray-400'}
                    `}
                    {...register('data.' + item.tech_name, { 
                        required: item.mandatory, 
                        value: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0,
                        valueAsNumber: true,
                        validate: v => !item.mandatory || Number.isInteger(v),
                        onChange: (e) => onRecordChange(false)
                    })} 
                />
            }
            { item.validator_type === 'float' &&
                <input
                    type='number'
                    defaultValue={item.default !== '' ? item.default : 0}
                    step={1 / (10 ** item.options.number_decimal_places)}
                    className={`tw-w-52 tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 
                            tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                            ${isError ? 'tw-ring-red-400 focus-visible:tw-ring-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-gray-400'}
                    `}
                    {...register('data.' + item.tech_name, { 
                        required: item.mandatory,
                        value: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0,
                        valueAsNumber: true,
                        validate: v => (v - Number(v?.toFixed(item.options.number_decimal_places)) === 0), // проверка соответствия количества знаков после запятой
                        onChange: (e) => onRecordChange(false)
                    })} 
                />
            }
            { item.validator_type === 'enum' &&
                <div className='tw-flex tw-flex-row tw-items-center tw-w-full'>
                    <div className='tw-grow'>
                        <Controller
                            name={'data.' + item.tech_name}
                            control={control}
                            rules={ {required: item.mandatory, setValueAs: v => v === '' ? undefined : v} }
                            defaultValue={(isEditMode || isDuplicateMode) ? setFieldValue(item) : setFieldDefaultValue(item)}
                            render={({field}) =>
                                <DataEnumListBox
                                    itemList={item.options.allowed_values}
                                    selectedItem={field.value}
                                    onItemChange={(e) => {onRecordChange(false); field.onChange(e)}}
                                    isError={isError}
                                    id={'data.' + item.tech_name}
                                />
                            }
                        />
                    </div>
                </div>
            }
            { item.validator_type === 'date' &&
                <div className='tw-flex tw-flex-row tw-justify-start tw-items-center tw-w-52 tw-py-1'>
                    <Controller
                        name={'data.' + item.tech_name}
                        control={control}
                        defaultValue={(isEditMode || isDuplicateMode) ? setFieldValue(item) : setFieldDefaultValue(item)}
                        rules={ {required: item.mandatory, valueAsDate: true} }
                        render={({field}) =>
                            <ReactDatePicker
                                className={`tw-w-52 tw-border tw-rounded-md tw-text-sm tw-text-gray-900 tw-p-1
                                    focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white 
                                    focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                                    ${isError ? 'tw-border-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-border-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                `}
                                calendarStartDay={1}
                                calendarIcon={<CalendarIcon/>}
                                dateFormat={item.options.format.toLowerCase() !== 'dd.mm.yyyy' ? 'dd.MM.yyyy HH:mm:ss' : 'dd.MM.yyyy'}
                                timeFormat="HH:mm:ss"
                                locale='ru'
                                timeCaption='Время'
                                showTimeSelect={item.options.format.toLowerCase() !== 'dd.mm.yyyy'}
                                selected={field.value}
                                onChange={(e) => {onRecordChange(false); field.onChange(e)}}
                            />
                        }
                    />
                    <button 
                        type='button'
                        className='tw-rounded-md tw-p-0.5 tw-ml-1 tw-border-0 tw-text-gray-600 tw-bg-white hover:tw-text-gray-900 hover:tw-border-gray-200 hover:tw-bg-gray-200'
                        onClick={() => {onRecordChange(false); setValue('data.' + item.tech_name, null)}}
                    >
                        <XMarkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </button>
                </div>
            }
            { item.validator_type === 'string' &&
                <textarea
                    defaultValue={item.default !== '-' ? item.default : ''}
                    placeholder={item.placeholder}
                    className={`tw-w-full tw-h-8 tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm
                        focus-visible:tw-outline-none focus-visible:tw-border-gray-600 focus-visible:tw-ring-2 
                        focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                        ${isError ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                    `}
                    {...register('data.' + item.tech_name, { 
                        required: item.mandatory,
                        value: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || '',
                        onChange: (e) => onRecordChange(false)
                    })} 
                />
            }
        </>
    )
}

export default FormFieldInput