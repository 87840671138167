import { makeAutoObservable, runInAction } from "mobx"
import { responseTimeOut, serviceMessageTimeOut } from "../config/constTypes"
import AuthService from "../services/AuthService"
import { toast } from "react-toastify"
import { showErrorToast } from "../functions/errorHandlers"
import ConfigurationService from "../services/ConfigurationService"

/**
 * Класс реализует хранилище информации о странице администрирования компаний и пользователей
 * @class
 * @property {Object[]} companies  Массив компаний
 * @property {Object[]} subCompanies  Массив юр. лиц
 * @property {Object[]} users  Массив пользователей
 * @property {Object[]} subCompanyUsers  Массив пользователей юр. лица
 * @property {Object[]} companyUsers  Массив пользователей компании
 * @property {Boolean} isLoading Загрузка
 * @property {Boolean} isSubCompanyCreating Создание юр. лица
 * @property {Boolean} isSubCompanyEditing Редактирование информации о юр. лице
 * @property {Boolean} isCompanyEditing Редактирование информации о компании
 * @property {Boolean} isUserCreating Создание пользователя
 * @property {Boolean} isUserEditing Редактирование информации о пользователе
 * @property {Object} selectedCompany  Выбранная компания
 * @property {Object} selectedSubCompany  Выбранное юр. лицо
 * @property {Object} selectedUser  Выбранный пользователь
 * @property {Boolean} isSubCompaniesLoading Загрузка юр. лиц компании
 */
class AdminStore {
    companies = []
    subCompanies = []
    users = []
    subCompanyUsers = []
    companyUsers = []
    isLoading = false
    isSubCompanyCreating = false
    isSubCompanyEditing = false
    isCompanyEditing = false
    isUserCreating = false
    isUserEditing = false
    selectedCompany = null
    selectedSubCompany = null
    selectedUser = null
    isSubCompaniesLoading = false
 
    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    */
    constructor(){
        makeAutoObservable(this)
    }

    /**
     * Метод осуществляет получение массива компаний
     * @method
     */
    async getAllCompanies() {
        this.setIsLoading(true)
        
        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .getCompanies()
            .then(data => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.setIsLoading(false)
                    this.companies=data.data
                    if (this.selectedCompany) {
                        let selectedCompany = data.data.filter((item) => item.id === this.selectedCompany.id)
                        this.selectedCompany = selectedCompany[0]
                    }
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет получение массива юр. лиц компании
     * @method
     * @param {Number} id ID компании 
     */
    async getSubCompanies(id) {
        runInAction(() => {
            this.isSubCompaniesLoading=true
        })

        const noResponse = setTimeout(() => {
            runInAction(() => {
                this.subCompanies=[]
                this.isSubCompaniesLoading=false
                toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            })
        }, responseTimeOut)

        AuthService
            .getSubCompanies(id)
            .then(data => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.isSubCompaniesLoading=false
                    this.subCompanies=data.data
                    if (this.selectedSubCompany) {
                        let selectedSubCompany = data.data.filter((item) => item.id === this.selectedSubCompany.id)
                        this.selectedSubCompany = selectedSubCompany[0]
                    }
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                runInAction(() => {
                    this.subCompanies=[]
                    this.isSubCompaniesLoading=false
                })
            })
    }

    /**
     * Метод осуществляет получение массива пользователей
     * @method
     */
    async getAllUsers() {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .getUsers()
            .then(data => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.setIsLoading(false)
                    this.users = data.data
                    if (this.selectedUser) {
                        let selectedUser = data.data.filter((item) => item.id === this.selectedUser.id)
                        this.selectedUser = selectedUser[0]
                    }
                    if (this.selectedCompany) {
                        this.getCompanyUsers(this.selectedCompany.id)
                    }
                    if (this.selectedSubCompany) {
                        this.getSubCompanyUsers(this.selectedSubCompany.id)
                    }
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет создание компании
     * @method
     * 
     * @param {Object} company Информация о компании 
     */
    async registerCompany (company) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .registerCompany(company)
            .then((data) => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.setSelectedCompany(data.data)
                    this.setIsLoading(false)
                    this.isSubCompanyCreating  = false
                    this.isSubCompanyEditing = false
                    toast.success('Компания успешно создана', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                    this.getAllCompanies()
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error('Ошибка при создании компании! Такая компания уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет создание юр. лица
     * @method
     * 
     * @param {Object} subCompany Информация о юр. лице 
     */
    async registerSubCompany (subCompany) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .registerSubCompany(subCompany, this.selectedCompany.id)
            .then((data) => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.setSelectedSubCompany(data.data)
                    this.setIsLoading(false)
                    this.isSubCompanyCreating  = false
                    this.isSubCompanyEditing = false
                    toast.success('Юр. лицо успешно создано', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error('Ошибка при создании юр. лица! Такое юр. лицо уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет создание пользователя
     * @method
     * 
     * @param {Object} user Информация о пользователе 
     */
    async registerUser (user) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .registerUser(user)
            .then((data) => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.setSelectedUser(data.data)
                    this.setIsLoading(false)
                    this.isUserCreating  = false 
                    this.isUserEditing = false
                    toast.success('Пользователь успешно создан', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                    this.getAllUsers()
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error('Ошибка при создании пользователя! Такой пользователь уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет редактирование юр. лица
     * @method
     * 
     * @param {Object} subCompany Информация о юр. лице
     */
    async editSubCompany (subCompany) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .editSubCompany(subCompany, this.selectedCompany.id, this.selectedSubCompany.id)
            .then(() => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.setIsLoading(false)
                    this.isSubCompanyCreating  = false 
                    this.isSubCompanyEditing = false
                    toast.success('Информация о юр. лице изменена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                    this.getSubCompanies(this.selectedCompany.id)
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error('Ошибка при изменении информации о юр. лице! Такое юр. лицо уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                this.setIsLoading(false)
            })
    }

     /**
     * Метод осуществляет редактирование компания
     * @method
     * 
     * @param {Object} company Информация о компания
     */
     async editCompany (company) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .editCompany(company, this.selectedCompany.id)
            .then(() => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.setIsLoading(false)
                    this.isCompanyEditing = false
                    toast.success('Информация о компании изменена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                    this.getAllCompanies()
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error('Ошибка при изменении информации о компании! Такая компания уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет редактирование пользователя
     * @method
     * 
     * @param {Object} user Информация о пользователе 
     * @param {Number} id ID пользователя 
     */
    async editUser (user, id) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .editUser(user, id)
            .then(() => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.setIsLoading(false)
                    this.isUserCreating  = false 
                    this.isUserEditing = false
                    toast.success('Информация о пользователе изменена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                    this.getAllUsers()
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error('Ошибка при изменении информации о пользователе! Такой пользователь уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет привязку и отвязку пользователя к юр лицу компании
     * @method
     * @param {Object} users Пользователи 
     * @param {Number} id ID юр лица
     * @param {Number} id ID компании
     */
    async rebindUsers (users) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            this.setIsLoading(false)
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .rebindUsers(users, this.selectedCompany.id, this.selectedSubCompany.id)
            .then(() => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                this.getAllUsers()
            })
            .catch(error => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                if (error?.response?.status === 422 && error?.response?.data?.message.includes('have single allowed sub-company')) {
                    toast.error('Ошибка при изменении информации о пользователе! Пользователь должен быть привязан хотя бы к одному юр. лицу', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
            })
    }

    /**
     * Метод осуществляет получение пользователей определенного юр.лица
     * @method
     * 
     * @param {Number} id ID юр.лица 
     */
    getSubCompanyUsers(id) {
        this.subCompanyUsers = this.users.filter(item => {
            return item.allowed_sub_company_ids.find(item => item === id)
        })
    }

    /**
     * Метод осуществляет получение пользователей определенной компании
     * @method
     * 
     * @param {Number} id ID компании 
     */
    getCompanyUsers(id) {
        this.companyUsers = this.users.filter(item => item?.company?.id === id)
    }

    /**
     * Метод осуществляет сохранение информации о загрузке
     * @method
     * 
     * @param {Boolean} bool Информация о загрузке
     */
    setIsLoading(bool) {
        this.isLoading = bool
    }

    /**
     * Метод осуществляет сохранение информации о создании юр. лица
     * @method
     * 
     * @param {Boolean} value Информация о создании юр. лица
     */
    setIsSubCompanyCreating(value) {
        sessionStorage.removeItem('companyForm')
        this.isSubCompanyCreating = value
    }

    /**
     * Метод осуществляет сохранение информации о редактировании юр. лица
     * @method
     * 
     * @param {Boolean} value Информация о редактировании юр. лица
     */
    setIsSubCompanyEditing(value) {
        sessionStorage.removeItem('companyForm')
        this.isSubCompanyEditing = value
    }
    
    /**
     * Метод осуществляет сохранение информации о редактировании компании
     * @method
     * 
     * @param {Boolean} value Информация о редактировании компании
     */
    setIsCompanyEditing(value) {
        sessionStorage.removeItem('companyForm')
        this.isCompanyEditing = value
    }

    /**
     * Метод осуществляет сохранение информации о создании пользователя
     * @method
     * 
     * @param {Boolean} value Информация о создании пользователя
     */
    setIsUserCreating(value) {
        sessionStorage.removeItem('userForm')
        this.isUserCreating = value
    }

    /**
     * Метод осуществляет сохранение информации о редактировании пользователя
     * @method
     * 
     * @param {Boolean} value Информация о редактировании пользователя
     */
    setIsUserEditing(value) {
        sessionStorage.removeItem('userForm')
        this.isUserEditing = value
    }

    /**
     * Метод осуществляет сохранение информации о выбранном пользователе
     * @method
     * 
     * @param {Object} user Информация о выбранном пользователе
     */
    setSelectedUser(user) {
        this.selectedUser = user
    }

    /**
     * Метод осуществляет сохранение информации о выбранной компании
     * @method
     * 
     * @param {Object} company Информация о выбранной компании
     */
    setSelectedCompany(company) {
        this.selectedCompany = company
    }

    /**
     * Метод осуществляет сохранение информации о выбранном юр. лице
     * @method
     * 
     * @param {Object} subCompany Информация о выбранном юр. лице
     */
    setSelectedSubCompany(subCompany) {
        this.selectedSubCompany = subCompany
    }

    /**
     * Метод осуществляет загрузку на сервер пакета с конфигурацией
     * @method
     * 
     * @param {Object} file Файл с конфигурацией
     */
    async uploadConfiguration(file, setFiles) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера файлов не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)
    
        const formData = new FormData()
        formData.append('package', file, 'package.zip')

        try {
            await ConfigurationService.uploadConfiguration(formData)
            clearTimeout(noResponse)
            toast.success('Файл успешно загружен', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            setFiles([])        
        } catch (error) {
            clearTimeout(noResponse)
            showErrorToast(error, 'saving', '')       
        }
    }
}

export default AdminStore
