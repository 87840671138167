import React, { useState, useContext, useEffect, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"
import { observer } from 'mobx-react-lite'
import { showErrorToast } from '../../../../../../functions/errorHandlers'
import Spinner from '../../../../../../assets/Spinner'
import { Context } from '../../../../../..'
import { setFieldDefaultValue, setFieldValue, setReferenceValue } from '../../../../../../config/constTypes'
import DirectoryContainer from '../../../common/panels/directory/DirectoryContainer'
import DocumentService from '../../../../../../services/DocumentService'
import ObjectsListButtons from '../../../common/panels/object_list/ObjectsListButtons'
import FieldItem from '../../../../../form_fields/FieldItem'
import { updateNestedDataObjects, deleteNestedDataObjects, deleteAllNestedDataObjects } from '../../../../../../functions/nestedModels'
import { processData } from '../../../../../../functions/forms'
import { personalDataISPDnCategories } from '../../../../../../config/constPersonalData'
import FieldCategory from '../../../../../form_fields/FieldCategory'
import FormErrorToastPanel from '../../../common/panels/toast/FormErrorToastPanel'

registerLocale("ru", ru)


/**
 * Визуальный компонент отображает форму для создания или редактирования информации о карточке ИСПДн
 * 
 * @param {Function} onBackClick Обработчик клика мыши для возвращения к предыдущей карточке
 * [ObjectsListButtonsBackClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line43)
 * @param {Function} onForwardClick Обработчик клика мыши для перехода к следующей карточке
 * [ObjectsListButtonsForwardClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line47)
 * @param {Object} stage Текущая активная карточка ИСПДн
 * @param {Object[]} objectsList Список существующих карточек ИСПДн
 * @param {Function} setLastObjectSaved Сохранение информации о сохранении последней карточки
 * [setLastObjectSaved](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line28)
 * @param {Function} markObjectAsFinished Сохранение информации о завершении заполнения карточки
 * [markObjectAsFinished](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line51)
 * @param {Function} loadObjectsList Загрузка списка карточек ИСПДн
 * [loadObjectsList](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line133)
 * 
 * @returns {JSXElement} Html-разметку формы создания или редактирования информации о карточке ИСПДн
 * с использованием визуальных компонентов {@link Spinner}, {@link ObjectsListButtons}, {@link DirectoryContainer},
 * {@link FieldItem}, {@link FieldCategory}, {@link FormErrorToastPanel}
 *   
 * @see [Вызов компонента](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line192)
 */
const ISPDataForm = ({onBackClick, onForwardClick, stage, objectsList, setLastObjectSaved, markObjectAsFinished, loadObjectsList}) => {   
    const {
        control,
        register,
        handleSubmit,
        setValue,
        resetField,
        setError,
        formState: { isSubmitting, errors },
        clearErrors
    } = useForm()

    const { personalDataStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [isSavedRecord, setIsSavedRecord] = useState(false)
    const [formFields, setFormFields] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [recordId, setRecordId] = useState('')
    const [formNestedModels, setFormNestedModels] = useState(null)
    const [isDuplicateMode, setIsDuplicateMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [replacedNestedDataObjects, setReplacedNestedDataObjects] = useState([])

    register('data.object', { 
        required: false,
        shouldUnregister: true,
    })

    const handleSelectClick = (dataModel, name) => {
        setSelectedDataModelID(dataModel)
        setSelectedName(name)
        setIsSelectFormOpen(true)
    }

    const handleClearClick = (name) => {
        setValue(name, {format: '', values: []})
        handleRecordChange()
    }

    const handleRecordChange = () => {
        setIsSavedRecord(false)
        setLastObjectSaved(false)
    }

    const handleDoubleClick = (item) => {
        if(selectedName !== 'duplicateRecord'){
            setValue(selectedName, {value: setReferenceValue(item)})
        } else {
            duplicateRecord(item)
        }
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)
        setIsSavedRecord(false)
        setLastObjectSaved(false)
    }

    const handleCloseClick = () => {
        docStore.setIsDetailView(false)
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    // Функция заполнения полей значениями по умолчанию в зависимости от типа поля записи
    const setFieldInputDefaultValues = (item) => {
        switch(item.validator_type) {
            case 'one':
            case 'many':
                return resetField('data.' + item.tech_name + '.0', { defaultValue: 
                    (isEditMode || isDuplicateMode) ? item : {format: '', values: []}
                })
            case 'bool':
            return resetField('data.' + item.tech_name, { defaultValue: 
                    (isEditMode || isDuplicateMode) ? setFieldValue(item) : setFieldDefaultValue(item)})
            case 'int':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0})
            case 'float':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0})
            case 'enum':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : null})
            case 'date':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : null})
            default:
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : null})
        }
    }
    
    const handleSaveRecord = async (form) => {
        let error = false
        let errorCategories = []

        personalDataStore.nestedModels.forEach(nestedModel => {
            if (nestedModel.mandatory && !nestedModel.dataObjects.find(item => item.status !== 'deleted')) {
                error = true
                setError(`data.${nestedModel.tech_name}`, { type: 'invalid', message: ' Включенные таблицы не должны быть пустыми!' })
            } else {
                nestedModel.dataObjects.forEach(value => {
                    if (value.status !== 'deleted') {
                        Object.entries(value).forEach(([key, field]) =>{
                            if (key === 'data') {
                                Object.entries(field).forEach(([key, item]) =>{
                                    if (item.value == null || item.value === ''  || item.value?.values?.length === 0) {
                                        error = true
                                        setError(`data.${nestedModel.tech_name}`, { type: 'invalid', message: ' Включенные таблицы не должны содержать пустые значения!' })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })

        personalDataISPDnCategories.forEach(category => {
            const isCheckedField = category.fields.some(field => form.data[field]) || !category.mandatory
            if (!isCheckedField) {
                error = true
                errorCategories.push(category.name)
            }
        })

        if (error) {
            if (errorCategories.length)
                toast.error(`Не выбрано ни одного значения в "${errorCategories.join('", "')}"`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })

        } else {
            const dataObject = processData(form.data, personalDataStore.activeDataModel.id)

            dataObject.data['object'] = [stage.object_record_id]
            dataObject.data['date_of_print_ispdn'] = null

            personalDataStore.nestedModels.forEach(nestedModel => {
                let deletedNestedDataObjects = []

                replacedNestedDataObjects.forEach(replacedNestedModel => {
                    if(replacedNestedModel.techName === nestedModel.tech_name){
                        deletedNestedDataObjects = replacedNestedModel.dataObjects
                    }
                })

                dataObject.data[nestedModel.tech_name] = {
                    upsert: updateNestedDataObjects(nestedModel),
                    remove: deleteNestedDataObjects(nestedModel).concat(deletedNestedDataObjects),
                }
            })

            try {
                if (isEditMode) {
                    await DocumentService.updateDataObject(recordId, dataObject)
                    await personalDataStore.resetPrintPolicyStage(personalDataStore.project.id)

                    loadObjectsList(false)
                } else {                        
                    const response = await DocumentService.createDataObject(dataObject)

                    const project = {}
                    project.data_model_id = 'stages_of_documentation_pdn'
                    project.data = {} 
                   
                    project.data = {
                        'ispdn':{
                            upsert: [{record_id: response.record_id}],
                            remove: []
                        } 
                    }
            
                    const savedProject  = await DocumentService.updateDataObject(personalDataStore.project.record_id, project)
                    await personalDataStore.resetPrintPolicyStage(savedProject.id)
                    
                    loadObjectsList(true)
                }

                toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                setIsSavedRecord(true)

            } catch (error) {
                showErrorToast(error, 'saving', '')
            }
        }
    }

    const duplicateRecord = (item) => {
        //Пометка на удаление сохраненных записей вложенных таблиц дублируемого объекта
        if(personalDataStore.nestedModels.some(nestedModel => nestedModel.dataObjects.some(nestedDataObject => nestedDataObject.status === 'saved'))){
            let replacedArr = []
            personalDataStore.nestedModels.forEach(nestedModel => {
                replacedArr.push({
                    techName: nestedModel.tech_name, 
                    dataObjects: deleteAllNestedDataObjects(nestedModel)
                })
            })
            setReplacedNestedDataObjects(replacedArr)
        }
        
        const fields = Object.values(item.data)
        setIsDuplicateMode(true)
        setFormFields(fields)
        initNestedModels(item.id, true)
        clearErrors()
    }  
    
    const initNestedModels = async (id, isDuplicate) => {  
        setIsLoading(true)

        const nestedValueRequests = formNestedModels.map(nestedModel => id ? DocumentService.getNestedDataObjects(50, [], [], id, nestedModel.rule_id) : nestedModel)
        Promise.all(nestedValueRequests)
            .then(responses => {
                personalDataStore.setNestedModels(formNestedModels.map((nestedModel, nestedModelIndex) =>  { 
                    return {
                        ...nestedModel,
                        dataObjects: id ? responses[nestedModelIndex].map(value => { return {...value, status: isDuplicate ? 'added' : 'saved'} }) : []
                    }
                }))
            })
            .finally(() => setIsLoading(false))
    }

    const handleChangeNestedTables = (editedValues, editedNestedModel) => {
        personalDataStore.setNestedModels(personalDataStore.nestedModels.map((nestedModel) =>  { 
            if(nestedModel.rule_id === editedNestedModel.rule_id){
                return {
                ...nestedModel,
                    dataObjects: editedValues,
                    errors: false
                }
            }
            return nestedModel
        }))
        clearErrors()
        handleRecordChange()
    }

    useEffect(() => {
        personalDataStore.getActiveDataModel('card_of_isdpn', setFormNestedModels)
    }, [])

    useEffect(() => {
        if (personalDataStore.activeDataModel && personalDataStore.activeDataModel.id === 'card_of_isdpn') {
            setIsEditMode(false)
            setIsDuplicateMode(false)
            setIsSavedRecord(false)
            setFormFields(personalDataStore.activeDataModel.fields)
            if (personalDataStore.ISPDList && personalDataStore.ISPDList.length > 0) {
                const foundAct = personalDataStore.ISPDList.find(object => 
                    stage.record_id === object.record_id
                )

                if (foundAct) {
                    const fields = Object.values(foundAct.data)
                    setRecordId(foundAct.record_id)
                    setFormFields(fields)
    
                    setIsEditMode(true)
                    setIsSavedRecord(true)
                }
            }
        }
    }, [stage, personalDataStore.activeDataModel])

    useEffect(() => {
        if(formFields){
            formFields.forEach(field => {
                if(field.field_id !== 'object' && !field.hide && field.type !== 'include')
                    setFieldInputDefaultValues(field)
            })
            setValue('data.object', [stage.object_record_id])
        }
    }, [formFields])

    useEffect(() => {
        if (formNestedModels) {
            initNestedModels(null, false)

            if (personalDataStore.ISPDList && personalDataStore.ISPDList.length > 0) {
                personalDataStore.ISPDList.forEach(object => {
                    if(stage.record_id === object.record_id){
                        initNestedModels(stage.id, false)
                    }
                })
            } 
        }
    }, [stage, formNestedModels])

    useEffect(() => {
        setLastObjectSaved(objectsList.every(object => {
            return object.status === "finished"
        }))
    }, [objectsList])

    
    useEffect(() => {
        markObjectAsFinished(isSavedRecord)
    }, [isSavedRecord, stage])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(<FormErrorToastPanel errors={errors.data} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <>
            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-mx-auto tw-py-2'>
                <button 
                    className='tw-rounded-md  tw-mr-2 tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-600 hover:tw-border-gray-600 
                            focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={handleSubmit(handleSaveRecord)}
                >
                    Сохранить
                </button>
                <button 
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-600 hover:tw-border-gray-600 
                            focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={(e) => {e.preventDefault(); handleSelectClick('card_of_isdpn', 'duplicateRecord')}}
                >
                    Загрузить
                </button>
            </div>
            <div className='tw-grow tw-overflow-auto'>
                <form className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-px-4 tw-py-1'>
                        { !formFields
                            ?
                                <Spinner />
                            :
                                formFields.slice().sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                    if (fieldItem.field_id !== 'object' && !fieldItem.hide) {
                                        return  <Fragment key={index}>
                                                    <FieldCategory
                                                        categories={personalDataISPDnCategories}
                                                        fieldItem={fieldItem}
                                                    />
                                                    <FieldItem 
                                                        fieldItem={fieldItem}
                                                        nestedModels={personalDataStore.nestedModels}
                                                        selectedNestedValue={personalDataStore.selectedNestedValue}
                                                        errors={errors}
                                                        handleSelectClick={handleSelectClick}
                                                        handleClearClick={handleClearClick}
                                                        handleRecordChange={handleRecordChange}
                                                        isEditMode={isEditMode}
                                                        isDuplicateMode={isDuplicateMode}
                                                        isLoading={isLoading}
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                        onSelectNestedValue={e => personalDataStore.setSelectedNestedValue(e)}
                                                        onNestedTableChange={handleChangeNestedTables}
                                                    />
                                                </Fragment>
                                    } else return null
                                })
                        }
                    </div>
                </form>
            </div>
            <ObjectsListButtons
                onBackClick={onBackClick}
                onForwardClick={onForwardClick}
                stage={stage}
                objectsList={objectsList}
                disabled={!isSavedRecord}
                forwardButtonTitle={'Следующая ИСПДн'}
                backButtonTitle={'Предыдущая ИСПДн'}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
                isDuplicateObject={selectedName === 'duplicateRecord' ? true : false}
            />
        </>                                                             
    )
}

export default observer(ISPDataForm)
