import React, { useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import Spinner from '../../assets/Spinner'
import FormFieldName from './FormFieldName'
import FormFieldInput from './FormFieldInput'
import NestedTableFieldsList from '../tabs/nested_tables/NestedTableFieldsList'
import NestedModelMenu from '../tabs/nested_tables/NestedModelMenu'


/**
 * Визуальный компонент отображает поле формы в зависимости от типа поля записи
 * 
 * @param {Object} fieldItem Поле формы
 * @param {Object[]} nestedModels Массив вложенных таблиц для редактируемой формы текущего этапа
 * @param {Object} selectedNestedValue Выбранная запись вложенной таблицы
 * @param {Object[]} errors Список ошибок
 * @param {Function} handleSelectClick Обработчик клика для открытия таблицы относящейся к полю ссылочного типа
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line48),
 * [handleSelectClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line79),
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line52),
 * [handleSelectClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line71),
 * [handleSelectClick](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line105),
 * [handleSelectClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line71),
 * [handleSelectClick](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line64),
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line55)
 * @param {Function} handleClearClick Обработчик клика для очистки поля ссылочного типа
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line55),
 * [handleClearClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line85),
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line59),
 * [handleClearClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line84),
 * [handleClearClick](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line118),
 * [handleClearClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line77),
 * [handleClearClick](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line70),
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line61)
 * @param {Function} handleRecordChange Обработчик изменения значения в любом поле формы
 * [handleRecordChange](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line90),
 * [handleRecordChange](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line89),
 * [handleRecordChange](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line82),
 * [setIsSavedRecord](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line42),
 * [setIsSavedRecord](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line45),
 * [setIsSavedRecord](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line45),
 * [setIsSavedRecord](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line44),
 * [setIsSavedRecord](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line48)
 * @param {Boolean} isEditMode Признак режима редактирования
 * @param {Boolean} isDuplicateMode Признак режима дублирования
 * @param {Boolean} isLoading Признак загрузки
 * @param {Object} control Объект, реализующий регистрацию компонентов
 * @param {Function} register Метод, реализующий регистрацию поля UseForm
 * [register](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line30),
 * [register](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line50),
 * [register](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line33),
 * [register](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line46),
 * [register](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line30),
 * [register](./components_main_page_controller_personal_data_form_PDataPrintOrderForm.js.html#line32),
 * [register](./components_main_page_controller_personal_data_form_PDataPrintTaskForm.js.html#line33),
 * [register](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line45),
 * [register](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line29),
 * [register](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line36)
 * @param {Function} setValue Метод, реализующий сохранение значения поля UseForm
 * [setValue](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line32)
 * [setValue](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line52),
 * [setValue](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line35)
 * [setValue](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line48)
 * [setValue](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line32)
 * [setValue](./components_main_page_controller_personal_data_form_PDataPrintOrderForm.js.html#line34)
 * [setValue](./components_main_page_controller_personal_data_form_PDataPrintTaskForm.js.html#line35)
 * [setValue](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line47),
 * [setValue](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line31)
 * [setValue](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line38)
 * @param {Function} onSelectNestedValue Обработчик клика для выбора ячейки вложенной таблицы
 * [arrow function](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line212),
 * [arrow function](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line403),
 * [arrow function](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line280),
 * [arrow function](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line372),
 * [arrow function](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line395),
 * [arrow function](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line358),
 * [arrow function](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line335),
 * [arrow function](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line325)
 * @param {Function} onNestedTableChange Обработчик изменения значения вложенной таблицы
 * [handleChangeMembers](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line136)
 * [handleChangeNestedTables](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line268),
 * [handleChangeNestedTables](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line199)
 * [handleChangeNestedTables](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line244)
 * [handleChangeNestedTables](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line82)
 * [handleChangeNestedTables](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line229),
 * [handleChangeNestedTables](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line217)
 * [handleChangeProcesses](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line189)
 * @param {Boolean} isChosenObjectDuplicationForbidden Признак запрета повторного добавления записи
 * 
 * @returns {HTMLDivElement} Html-разметку поля формы с использованием визуальных компонентов  
 * {@link Spinner}, {@link FormFieldName}, {@link FormFieldInput}, {@link NestedModelMenu}, {@link NestedTableFieldsList}
 *   
 * @see [Вызов компонента CommissionForm](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line197)
 * [ISPDataForm,](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line389)
 * [OrganizationForm,](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line265)
 * [PData3PartyForm,](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line357)
 * [PDataOrganizationForm,](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line380)
 * [PDataPrintOrderForm,](./components_main_page_controller_personal_data_form_PDataPrintOrderForm.js.html#line154)
 * [PDataPrintTaskForm,](./components_main_page_controller_personal_data_form_PDataPrintTaskForm.js.html#line171)
 * [PDataProcessForm,](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line344)
 * [PDataRKNForm,](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line320)
 * [ProcessesForm,](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line310)
 */
const FieldItem = ({fieldItem, nestedModels, selectedNestedValue, errors, handleSelectClick, handleClearClick, handleRecordChange, isEditMode, 
        isDuplicateMode, isLoading, control, register, setValue, onSelectNestedValue, onNestedTableChange, isChosenObjectDuplicationForbidden}) => {
    const [isNestedModelsShow, setIsNestedModelsShow] = useState(true)

    return (
        <div className={`${fieldItem.type === 'include' ? 'tw-my-4' : 'sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-py-2'}`}>
            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-justify-between">
                <FormFieldName
                    item={fieldItem}
                    errors={errors}
                />
                { fieldItem.type === 'include' && 
                    <button 
                        type='button'
                        className='tw-p-0.5 tw-ml-1 hover:tw-text-gray-700 tw-flex tw-flex-row tw-items-center'
                        onClick={() => setIsNestedModelsShow(!isNestedModelsShow)}
                    >
                        { isNestedModelsShow
                            ?   <>
                                    <span>Свернуть</span>
                                    <ChevronUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                </>
                            :   <>
                                    <span>Развернуть</span>
                                    <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                </> 
                        }
                    </button> 
                }
            </dt>
            { fieldItem.type === 'include' 
                ?
                    (nestedModels.map((nestedModel, index) => {
                        if (nestedModel.rule_id === fieldItem.rule_id) {
                            return  <dd key={index} className={`${isNestedModelsShow ? 'tw-p-4' : 'tw-h-4 tw-overflow-hidden tw-pt-4'} tw-duration-500 tw-mt-1 tw-bg-gray-200 tw-rounded-md`}>
                                        { isLoading
                                            ?   <Spinner/>
                                            :   <>
                                                    <NestedModelMenu
                                                        selectedNestedDataModel={nestedModel}
                                                        selectedNestedDataObject={selectedNestedValue}
                                                        setSelectedNestedDataObject={onSelectNestedValue}
                                                        nestedDataModels={nestedModels}
                                                        isDocumentPage={false}
                                                        onChange={onNestedTableChange}
                                                    />
                                                    <NestedTableFieldsList
                                                        readOnly={false}
                                                        dataModel={nestedModel}
                                                        dataObjects={nestedModel.dataObjects}
                                                        selectedNestedDataObject={selectedNestedValue}
                                                        setSelectedNestedDataObject={onSelectNestedValue}
                                                        isDocumentPage={false}
                                                        onChange={onNestedTableChange}
                                                        isChosenObjectDuplicationForbidden={isChosenObjectDuplicationForbidden}
                                                    />
                                                </> 
                                        }
                                    </dd>
                        }
                    }))
                : 
                    <dd className="tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 tw-mt-1">
                        <FormFieldInput
                            item={fieldItem}
                            isEditMode={isEditMode}
                            isDuplicateMode={isDuplicateMode}
                            onReferenceSelect={handleSelectClick}
                            onReferenceClear={handleClearClick}
                            onRecordChange={handleRecordChange}
                            register={register}
                            control={control}
                            setValue={setValue}
                            isError={errors?.data && errors?.data[fieldItem.tech_name]}
                        />
                    </dd>
            }
        </div>
    )
}

export default FieldItem
