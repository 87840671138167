import React, { useContext } from 'react'
import { Context } from '../..'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'react-tooltip'


/**
 * Визуальный компонент отображает поле поиска
 * @param {Boolean} isDisabled Признак неактивности строки поиска
 * @param {Object} filterStore Экземпляр хранилища информации о фильтрах
 * @param {Object} selectedFullDataModel Выбранная таблица
 * @param {Function} updateDataObjectList Функция обновления списка записей
 * [updateDataObjectList](./components_main_page_controller_education_EducationContainer.js.html#line29)
 * [updateDataObjectList,](./components_main_page_controller_education_materials_MaterialsObjectsContainer.js.html#line29)
 * [handleChange,](./components_admin_page_companies_list_CompanyList.js.html#line30)
 * [handleChange](./components_admin_page_users_users_list_UsersList.js.html#line20)
 * [updateDataObjectList](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line186)
 * [updateDataObjectList](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line322)
 * 
 * @returns {JSXElement} Html-разметку поля поиска
 * 
 * @see [Вызов компонента StageListContainer,](./components_main_page_controller_common_stage_list_StageListContainer.js.html#line63)
 * [MaterialsObjectsContainer,](./components_main_page_controller_education_materials_MaterialsObjectsContainer.js.html#line60)
 * [CompanyList,](./components_admin_page_companies_list_CompanyList.js.html#line65)
 * [UsersList,](./components_admin_page_users_users_list_UsersList.js.html#line46)
 * [DataObjectMenu,](./components_main_page_controller_data_object_menu_DataObjectMenu.js.html#line83)
 * [SubCompanyList,](./components_company_admin_page_sub_companies_list_SubCompanyList.js.html#line69)
 * [UsersList](./components_company_admin_page_users_users_list_UsersList.js.html#line55)
*/
const SearchInput = ({isDisabled, filterStore, selectedFullDataModel, updateDataObjectList}) => {
    const { FilterStore } = useContext(Context)
    const searchFilterStore = filterStore ? filterStore : FilterStore
    const {
        handleSubmit,
    } = useForm()

    const onSearchClick = async () => {
        await searchFilterStore.updateSearchFilters(selectedFullDataModel)
        updateDataObjectList(selectedFullDataModel.id, 0)
    }

    const onClearClick = async () => {
        searchFilterStore.setSearchField('')
        searchFilterStore.setSearchFilters([])
        updateDataObjectList(selectedFullDataModel.id, 0)
    }

    return (
        <>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <form
                    className={`tw-h-8 tw-rounded-md tw-border-0 tw-pl-2 tw-py-1.5 tw-text-gray-900 tw-w-full
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm
                        focus-visible:tw-outline-none focus-visible:tw-border-gray-600 focus-visible:tw-ring-2 
                        focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                        tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400 tw-flex tw-flex-row tw-items-center
                    `}
                >
                    <input
                        disabled={isDisabled}
                        className='tw-text-gray-900 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none 
                            focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2 tw-w-full
                            disabled:tw-text-gray-400 tw-min-w-[8rem]'
                        placeholder="Поиск..."
                        value={searchFilterStore.searchField}
                        onChange={e => {searchFilterStore.setSearchField(e.target.value)}}
                    />
                    <button 
                        disabled={isDisabled}
                        type='button'
                        className={`${searchFilterStore.searchField ? 'tw-text-gray-800 hover:tw-text-gray-600' :'tw-text-white tw-pointer-events-none'} 
                            tw-rounded-md tw-p-0.5 tw-ml-1 tw-border-0 disabled:tw-text-gray-400`}
                        onClick={onClearClick}
                    >
                        <XMarkIcon className='tw-w-4 tw-h-4' aria-hidden='true'/>
                    </button>
                    <button
                        className='tw-rounded-md tw-p-1.5 tw-text-gray-800 hover:tw-text-gray-600 disabled:tw-text-gray-400'
                        onClick={handleSubmit(onSearchClick)}
                        disabled={isDisabled}
                        data-tooltip-id={`search-input-tooltip-${selectedFullDataModel?.id}`} data-tooltip-content="Искать"
                    >
                        <MagnifyingGlassIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </button>
                </form>
            </div>
            <Tooltip id={`search-input-tooltip-${selectedFullDataModel?.id}`} place="top-start"/>
        </>
        
    )
}

export default observer(SearchInput)