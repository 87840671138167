import React, { useContext } from 'react'
import { DocumentPlusIcon, PencilSquareIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'


/**
 * Визуальный компонент отображает меню возможных действий над таблицами
 * @param {Object} activeItem Выбранный элемент в списке таблиц
 * @param {Function} onAddItemClick Обработчик клика мыши на кнопке создания новой таблицы
 * [handleAddDataModelClick](./components_main_page_controller_data_model_DataModelListContainer.js.html#line73)
 * @param {Function} onEditItemClick Обработчик клика мыши на кнопке редактирования выбранной таблицы
 * [handleEditDataModelClick](./components_main_page_controller_data_model_DataModelListContainer.js.html#line77)
 * @param {Function} onHideItemClick Обработчик клика мыши на кнопке скрытия выбранной таблицы
 * [handleHideDataModelClick](./components_main_page_controller_data_model_DataModelListContainer.js.html#line81)
 * @returns {HTMLDivElement} Html-разметку ряда кнопок меню
 * 
 * @see [Вызов компонента](./components_main_page_controller_data_model_DataModelListContainer.js.html#line127)
 */
const DataModelMenu = ({activeItem, onAddItemClick, onEditItemClick, onHideItemClick}) => {
    const { userStore } = useContext(Context)
    return (
        <div id='data-model-menu' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
            <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Таблицы</p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600'
                    onClick={onAddItemClick}
                    data-tooltip-id="data-model-tooltip" data-tooltip-content="Создать" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onEditItemClick(activeItem.id)}
                    disabled={!activeItem || !(!activeItem.subgroup_id || activeItem.subgroup_id == userStore.currentSubCompany.id)}
                    data-tooltip-id="data-model-tooltip" data-tooltip-content="Редактировать" data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onHideItemClick(activeItem)}
                    disabled={!activeItem || !(!activeItem.subgroup_id || activeItem.subgroup_id == userStore.currentSubCompany.id)}
                    data-tooltip-id="data-model-tooltip" data-tooltip-content={activeItem && activeItem.hide_mark ? "Показать" : "Скрыть"} data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    { activeItem && activeItem.hide_mark
                        ? <EyeIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        : <EyeSlashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    }
                </button>
            </div>
            <Tooltip id="data-model-tooltip" place="top"/>
        </div>
    )
}

export default observer(DataModelMenu)