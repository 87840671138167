import React, { useState, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"
import { observer } from 'mobx-react-lite'
import { showErrorToast } from '../../../../../../functions/errorHandlers'
import Spinner from '../../../../../../assets/Spinner'
import { Context } from '../../../../../..'
import { setFieldDefaultValue, setFieldValue, setReferenceValue } from '../../../../../../config/constTypes'
import DirectoryContainer from '../../../common/panels/directory/DirectoryContainer'
import DocumentService from '../../../../../../services/DocumentService'
import ObjectsListButtons from '../../../common/panels/object_list/ObjectsListButtons'
import FieldItem from '../../../../../form_fields/FieldItem'
import { updateNestedDataObjects, deleteNestedDataObjects, deleteAllNestedDataObjects } from '../../../../../../functions/nestedModels'
import { processData } from '../../../../../../functions/forms'
import SelectReferencedModel from '../../../common/modals/SelectReferencedModel'
import FormErrorToastPanel from '../../../common/panels/toast/FormErrorToastPanel'

registerLocale("ru", ru)

/**
 * Визуальный компонент отображает форму для создания или редактирования информации об объекте категорирования
 * 
 * @param {Function} onBackClick Обработчик клика мыши для возвращения к предыдущему объекту
 * [ObjectsListButtonsBackClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line39)
 * @param {Function} onForwardClick Обработчик клика мыши для перехода к следующему объекту
 * [ObjectsListButtonsForwardClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line43)
 * @param {Object} stage Текущий активный объект категорирования
 * @param {Object[]} objectsList Список существующих объектов категорирования
 * @param {Function} setLastObjectSaved Сохранение информации о сохранении последнего объекта
 * [setLastObjectSaved](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line25)
 * @param {Function} markObjectAsFinished Сохранение информации о завершении редактирования текущего процесса обработки
 * [markObjectAsFinished](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line47)
 * 
 * @returns {JSXElement} Html-разметку формы создания или редактирования информации об объекте категорирования
 * с использованием визуальных компонентов {@link Spinner}, {@link ObjectsListButtons}, {@link DirectoryContainer} , {@link FieldItem}, 
 * {@link SelectReferencedModel}, {@link FormErrorToastPanel}
 *   
 * @see [Вызов компонента](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line185)
 */
const PData3PartyForm = ({onBackClick, onForwardClick, stage, objectsList, setLastObjectSaved, markObjectAsFinished}) => {   
    const {
        control,
        register,
        handleSubmit,
        setValue,
        resetField,
        formState: { isSubmitting, errors },
        clearErrors
    } = useForm()

    const { personalDataStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [isSavedRecord, setIsSavedRecord] = useState(false)
    const [formFields, setFormFields] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [recordId, setRecordId] = useState('')
    const [formNestedModels, setFormNestedModels] = useState(null)
    const [isDuplicateMode, setIsDuplicateMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [replacedNestedDataObjects, setReplacedNestedDataObjects] = useState([])
    const [isRefSelectOpen, setIsRefSelectOpen] = useState(false)
    const [referencedModels, setReferencedModels] = useState([])
    const [selectedRefModel, setSelectedRefModel] = useState({id: '0', entity_name: ''})
    const [isSelectedRefModelError, setIsSelectedRefModelError] = useState(false)

    const handleSelectClick = (dataModelList, name, field) => {
        if (Array.isArray(dataModelList)) {
            setSelectedName(name)
            setReferencedModels(personalDataStore.activeDataModel.referenced_models.filter(dataModel => dataModel.rule_id === field.rule_id))
            setSelectedDataModelID(null)
            setIsRefSelectOpen(true)
        } else {
            setSelectedName(name)
            setSelectedDataModelID(dataModelList)
            setIsSelectFormOpen(true)
        }
    }

    const handleClearClick = (name) => {
        setValue(name, {format: '', values: []})
        handleRecordChange()
    }

    const handleRecordChange = () => {
        setIsSavedRecord(false)
        setLastObjectSaved(false)
    }

    const handleDoubleClick = (item) => {
        if(selectedName !== 'duplicateRecord'){
            setValue(selectedName, {value: setReferenceValue(item)})
        } else {
            duplicateRecord(item)
        }
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)
        setIsSavedRecord(false)
        setLastObjectSaved(false)
    }

    const handleCloseClick = () => {
        docStore.setIsDetailView(false)
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    const handleCloseModalClick = () => {
        setIsSelectedRefModelError(false)
        setReferencedModels([])
        setSelectedRefModel({id: '0', entity_name: ''})
        setIsRefSelectOpen(false)
    }

    const handleSelectDirectory = (item) => {
        setSelectedRefModel(item)
        setSelectedDataModelID(item.id)
    }

    const handleSubmitClick = () => {
        if(selectedRefModel.id === '0')
            setIsSelectedRefModelError(true)
        else{
            setIsSelectedRefModelError(false)
            setIsRefSelectOpen(false)
            setIsSelectFormOpen(true)
        }
    }

    // Функция заполнения полей значениями по умолчанию в зависимости от типа поля записи
    const setFieldInputDefaultValues = (item) => {
        switch(item.validator_type) {
            case 'one':
            case 'many':
                return resetField('data.' + item.tech_name + '.0', { defaultValue: 
                    (isEditMode || isDuplicateMode) ? item : {format: '', values: []}
                })
            case 'bool':
            return resetField('data.' + item.tech_name, { defaultValue: 
                    (isEditMode || isDuplicateMode) ? setFieldValue(item) : setFieldDefaultValue(item)})
            case 'int':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0})
            case 'float':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0})
            case 'enum':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : null})
            case 'date':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : null})
            default:
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : null})
        }
    }
    
    const handleSaveRecord = async (form) => {
        let error = false

        if(!error) {
            const dataObject = processData(form.data, personalDataStore.activeDataModel.id)

            personalDataStore.nestedModels.forEach(nestedModel => {
                let deletedNestedDataObjects = []

                replacedNestedDataObjects.forEach(replacedNestedModel => {
                    if (replacedNestedModel.techName === nestedModel.tech_name) {
                        deletedNestedDataObjects = replacedNestedModel.dataObjects
                    }
                })

                dataObject.data[nestedModel.tech_name] = {
                    upsert: updateNestedDataObjects(nestedModel),
                    remove: deleteNestedDataObjects(nestedModel).concat(deletedNestedDataObjects),
                }
            })

            try {
                if (isEditMode) {
                    await DocumentService.updateDataObject(recordId, dataObject)
                    await personalDataStore.resetPrintPolicyStage(personalDataStore.project.id)
                } else {                        
                    const response = await DocumentService.createDataObject(dataObject)

                    const project = {}
                    project.data_model_id = 'stages_of_documentation_pdn'
                    project.data = {} 
                   
                    project.data['list_of_third_party_processors'] = {
                        upsert: [{record_id: response.record_id}],
                        remove: []
                    }

                    const savedProject = await DocumentService.updateDataObject(personalDataStore.project.record_id, project)
                    await personalDataStore.resetPrintPolicyStage(savedProject.id)
                }

                toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                setIsSavedRecord(true)

            } catch (error) {
                showErrorToast(error, 'saving', '')
            }
        }
    }

    const duplicateRecord = (item) => {
        //Пометка на удаление сохраненных записей вложенных таблиц дублируемого объекта
        if(personalDataStore.nestedModels.some(nestedModel => nestedModel.dataObjects.some(nestedDataObject => nestedDataObject.status === 'saved'))){
            let replacedArr = []
            personalDataStore.nestedModels.forEach(nestedModel => {
                replacedArr.push({
                    techName: nestedModel.tech_name, 
                    dataObjects: deleteAllNestedDataObjects(nestedModel)
                })
            })
            setReplacedNestedDataObjects(replacedArr)
        }
        
        const fields = Object.values(item.data)
        setIsDuplicateMode(true)
        setFormFields(fields)
        initNestedModels(item.id, true)
    }  
    
    const initNestedModels = async (id, isDuplicate) => {  
        setIsLoading(true)

        const nestedValueRequests = formNestedModels.map(nestedModel => id ? DocumentService.getNestedDataObjects(50, [], [], id, nestedModel.rule_id) : nestedModel)
        Promise.all(nestedValueRequests)
            .then(responses => {
                personalDataStore.setNestedModels(formNestedModels.map((nestedModel, nestedModelIndex) =>  { 
                    return {
                        ...nestedModel,
                        dataObjects: id ? responses[nestedModelIndex].map(value => { return {...value, status: isDuplicate ? 'added' : 'saved'} }) : []
                    }
                }))
            })
            .finally(() => setIsLoading(false))
    }

    const handleChangeNestedTables = (editedValues, editedNestedModel) => {
        personalDataStore.setNestedModels(personalDataStore.nestedModels.map(nestedModel =>  { 
            if (nestedModel.rule_id === editedNestedModel.rule_id) {
                return {
                    ...nestedModel,
                    dataObjects: editedValues,
                    errors: editedNestedModel.errors ? personalDataStore.checkPrimaryFieldErrors(editedNestedModel, editedValues) : false
                }
            }
            return nestedModel
        }))
        clearErrors()
        handleRecordChange()
    }
    useEffect(() => {
        personalDataStore.getActiveDataModel('third_party_processor', setFormNestedModels)
    }, [])

    useEffect(() => {
        if (personalDataStore.activeDataModel && personalDataStore.activeDataModel.id === 'third_party_processor') {
            setIsEditMode(false)
            setIsDuplicateMode(false)
            setIsSavedRecord(false)
            setFormFields(personalDataStore.activeDataModel.fields)
            
            if (personalDataStore.thirdPartyList && personalDataStore.thirdPartyList.length > 0) {
                const foundItem = personalDataStore.thirdPartyList.find(object => 
                    stage.record_id === object.record_id
                )

                if (foundItem) {
                    const fields = Object.values(foundItem.data)
                    setRecordId(foundItem.record_id)
                    setFormFields(fields)
    
                    setIsEditMode(true)
                    setIsSavedRecord(true)
                }
            }
        }
    }, [stage, personalDataStore.activeDataModel])

    useEffect(() => {
        if (formFields) {
            formFields.forEach(field => {
                if (!field.hide && field.type !== 'include')
                    setFieldInputDefaultValues(field)
            })

        }
    }, [formFields])

    useEffect(() => {
        if (formNestedModels) {
            initNestedModels(null, false)

            if (personalDataStore.thirdPartyList && personalDataStore.thirdPartyList.length > 0) {
                personalDataStore.thirdPartyList.forEach(object => {
                    if (stage.record_id === object.record_id) {
                        initNestedModels(stage.id, false)
                    }
                })
            } 
        }
    }, [stage, formNestedModels])

    useEffect(() => {
        setLastObjectSaved(objectsList.every(object => {
            return object.status === "finished"
        }))
    }, [objectsList])

    
    useEffect(() => {
        markObjectAsFinished(isSavedRecord)
    }, [isSavedRecord, stage])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(<FormErrorToastPanel errors={errors.data} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <>
            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-mx-auto tw-py-2'>
                <button 
                    className='tw-rounded-md  tw-mr-2 tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-600 hover:tw-border-gray-600 
                            focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={handleSubmit(handleSaveRecord)}
                >
                    Сохранить
                </button>
                <button 
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-600 hover:tw-border-gray-600 
                            focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={(e) => {e.preventDefault(); handleSelectClick('third_party_processor', 'duplicateRecord')}}
                >
                    Загрузить
                </button>
            </div>
            <div className='tw-grow tw-overflow-auto'>
                <form className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-px-4 tw-py-1'>
                        { !formFields
                            ?
                                <Spinner />
                            :
                                formFields.slice().sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                    if (!fieldItem.hide) {
                                        return  <FieldItem 
                                                    key={index}
                                                    fieldItem={fieldItem}
                                                    nestedModels={personalDataStore.nestedModels}
                                                    selectedNestedValue={personalDataStore.selectedNestedValue}
                                                    errors={errors}
                                                    handleSelectClick={handleSelectClick}
                                                    handleClearClick={handleClearClick}
                                                    handleRecordChange={handleRecordChange}
                                                    isEditMode={isEditMode}
                                                    isDuplicateMode={isDuplicateMode}
                                                    isLoading={isLoading}
                                                    control={control}
                                                    register={register}
                                                    setValue={setValue}
                                                    onSelectNestedValue={e => personalDataStore.setSelectedNestedValue(e)}
                                                    onNestedTableChange={handleChangeNestedTables}
                                                />
                                    } else return null
                                })
                        }
                    </div>                            
                </form>
            </div>
            <ObjectsListButtons
                onBackClick={onBackClick}
                onForwardClick={onForwardClick}
                stage={stage}
                objectsList={objectsList}
                disabled={!isSavedRecord}
                forwardButtonTitle={'Следующий обработчик'}
                backButtonTitle={'Предыдущий обработчик'}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
                isDuplicateObject={selectedName === 'duplicateRecord' ? true : false}
            />
            <SelectReferencedModel
                isVisible={isRefSelectOpen}
                dataModelList={referencedModels}
                selectedItem={selectedRefModel}
                onItemChange={handleSelectDirectory}
                onSubmit={handleSubmitClick}
                onCloseModal={handleCloseModalClick}
                isError={isSelectedRefModelError}
            />
        </>                                                             
    )
}

export default observer(PData3PartyForm)
