import React, { useEffect } from 'react'
import { paths } from "../../config/constsURN"
import { toast } from 'react-toastify'


/**
 * Визуальный компонент отображает страницу с информацией о низком разрешении экрана устройства
 * @returns {HTMLDivElement} Html-разметку окна с информацией о низком разрешении экрана
 * 
 */
const ResolutionPage = () => {
    useEffect( () => {
        const isMobile = window.matchMedia("only screen and (max-width: 800px)").matches
        if (!isMobile) {
            toast.success('Теперь разрешение экрана удовлятворяет минимальным требованиям', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
        }
    }, [])


    return (
        <div className="tw-grid tw-min-h-full tw-place-items-center tw-bg-white tw-rounded-md tw-mt-8 tw-px-6 tw-py-20 sm:tw-py-32 lg:tw-px-8">
            <div className="tw-text-center">
                <h1 className="tw-text-2xl tw-font-bold tw-tracking-tight tw-text-gray-900 md:tw-text-5xl">Низкое разрешение экрана</h1>
                <p className="tw-mt-6 tw-text-base tw-leading-7 tw-text-gray-600 md:tw-text-lg">Извините, но дизайн нашего сайта в настоящее время рассчитан на устройства с высоким разрешением.</p>
                <p className="tw-mt-6 tw-text-base tw-leading-7 tw-text-gray-600 md:tw-text-lg">Если Вы хотите продолжить просматривать сайт на данном устройстве, включите в браузере опцию "Версия для ПК".</p>
                <div className="tw-mt-10 tw-flex tw-items-center tw-justify-center tw-gap-x-6">
                    <a
                        href={paths.MAIN_ROUTE}
                        className="tw-px-3.5 tw-py-2.5 tw-text-sm md:tw-text-lg tw-font-semibold tw-text-white 
                                    tw-shadow-sm tw-rounded-md tw-bg-indigo-600 hover:tw-bg-indigo-500
                                    focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600"
                    >
                        Вернуться на главную
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ResolutionPage