import React, { useContext } from 'react'
import { getReferenceTypeName, getValueTypeName, contentTooltipTimeOut } from '../../../../config/constTypes'
import { Context } from '../../../..'
import { Tooltip } from 'react-tooltip'


/**
 * Визуальный компонент отображает список полей таблицы
 * @param {Object[]} fields Список существующих полей
 * @param {Object} selectedField Выбранное поле в списке полей таблицы
 * @param {Function} onFieldClick Обработчик клика мыши на элементе списка полей
 * [setSelectedField](./components_main_page_controller_data_model_DataModelListContainer.js.html#line27)
 * @returns {HTMLTableElement} Html-разметку списка полей таблицы
 * 
 * @see [Вызов компонента](./components_main_page_controller_data_model_info_DataModelInfo.js.html#line44)
 */
const FieldTable = ({fields, selectedField, onFieldClick}) => {
    const { docStore } = useContext(Context)

    return (
        <>
            <table className='tw-w-full tw-text-sm tw-text-left tw-border-gray-300 tw-table-fixed tw-overflow-auto'>
                <thead>
                    <tr className='tw-h-8 tw-border-b tw-border-gray-300'>
                        <th className='tw-px-4 tw-w-5/12'>Поле</th>
                        <th className='tw-px-4 tw-w-5/12'>Тип</th>
                    </tr>
                </thead>
                <tbody className=''>
                    { fields.length === 0 && 
                        <tr className='tw-h-10 tw-w-full tw-text-sm tw-italic tw-text-left tw-px-4 tw-py-8'>
                            <th className='tw-text-sm tw-font-medium tw-px-4 tw-py-1.5'>Поля отсутствуют</th>
                        </tr>
                    }
                    { fields.sort((a, b) => a.order - b.order).map((field, index) => 
                        <tr 
                            key={index}
                            className={`tw-h-8 tw-border-b tw-truncate
                                        ${selectedField?.field_id === field.field_id 
                                            ? 'tw-text-white tw-bg-gray-500' 
                                            : 'tw-text-gray-900 hover:tw-bg-gray-200 hover:tw-z-10 '
                                        } 
                                        hover:tw-cursor-pointer`}
                            onClick={() => onFieldClick(field)}
                        >
                            <th 
                                className={`${ field.hide ? 'tw-line-through tw-text-red-400' : ''} tw-text-sm tw-font-medium tw-px-4 tw-py-1 tw-truncate`}
                                data-tooltip-id="field-table-tooltip" data-tooltip-content={field.alias} data-tooltip-delay-show={contentTooltipTimeOut}
                            >
                                {field.alias}
                            </th>
                            <th className={`${ field.hide ? 'tw-line-through tw-text-red-400' : ''} tw-text-sm tw-font-medium tw-px-4 tw-py-1 tw-truncate`}>
                                {(field.type === 'reference' || field.type === 'include') ? getReferenceTypeName(docStore.dataModels, field.ref_model_ids) : getValueTypeName(field)}
                            </th>                            
                        </tr>
                    )}                      
                </tbody>
            </table>
            <Tooltip id="field-table-tooltip" place="top"/>
        </>
    )
}

export default FieldTable
