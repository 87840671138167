import React, { useContext } from 'react'
import DataModelListItem from './DataModelListItem'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../..'

/**
 * Визуальный компонент отображает список существующих таблиц
 * 
 * @returns {HTMLDivElement} Html-разметку списка существующих таблиц с использованием визуального компонента {@link DataModelListItem}
 * 
 * @see [Вызов компонента](./components_main_page_controller_category_CategoryListContainer.js.html#line61)
 */
const DataModelList = () => {
    const { docStore } = useContext(Context)

    return (
        <div  id='data-model-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-overflow-auto'>
            {  docStore.dataModels
                .slice()
                .map((dataModel, index) => 
                    <DataModelListItem 
                        key={index}
                        item={dataModel}
                    />
            )}
        </div>
    )
}

export default observer(DataModelList)