import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'
import { Context } from '../../..'
import DialogTab from '../../dialog_tab/DialogTab'
import FormErrorToastPanel from '../../main_page/controller/common/panels/toast/FormErrorToastPanel'

/**
 * Визуальный компонент отображает форму редактирования информации о юр лице
 * 
 * @returns {HTMLFormElement} Html-разметку формы редактирования информации о юр лице
 * с использованием визуальных компонентов {@link DialogTab}, {@link FormErrorToastPanel}
 * 
 * @see [Вызов компонента](./components_company_admin_page_CompanyAdminContainer.js.html#line48)
 */
const SubCompanyForm = () => {
    const { CompanyAdminStore, DialogTabStore } = useContext(Context)
    const regexInn = new RegExp('^[0-9]{10}$|^[0-9]{12}$')
    const regexKpp = new RegExp('^[0-9]{9}$')
    const regexOgrn = new RegExp('^[0-9]{13}$|^[0-9]{15}$')
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm()

    const formFields = [
        {
            tech_name: 'name',
            alias: 'Полное наименование'
        },
        {
            tech_name: 'abbreviation',
            alias: 'Сокращенное наименование'
        },
        {
            tech_name: 'tin',
            alias: 'ИНН'
        },
        {
            tech_name: 'msrn',
            alias: 'ОГРН'
        },
        {
            tech_name: 'rrc',
            alias: 'КПП'
        },
        {
            tech_name: 'owner',
            alias: 'Руководитель'
        },
        {
            tech_name: 'owner_position',
            alias: 'Должность руководителя'
        },
        {
            tech_name: 'registration_address',
            alias: 'Адрес регистрации'
        }
    ]

    const onCancelClick = () => {
        CompanyAdminStore.setIsSubCompanyCreating(false)
        CompanyAdminStore.setIsSubCompanyEditing(false)
    }

    const onSubmitClick = (form) => {
        if (!regexInn.test(form.tin?.trim())) {
            setError('tin', { type: 'invalid', message: ' Поле должно содержать 10 или 12 цифр' })
        } else if (!regexKpp.test(form.rrc?.trim())) {
            setError('rrc', { type: 'invalid', message: ' Поле должно содержать 9 цифр' })
        } else if (!regexOgrn.test(form.msrn?.trim())) {
            setError('msrn', { type: 'invalid', message: ' Поле должно содержать 13 или 15 цифр' })
        } else {
            const subCompany = {}
            formFields.forEach(item =>{
                subCompany[item.tech_name] = form[item.tech_name].trim()
            })

            sessionStorage.setItem('subCompanyForm', JSON.stringify(subCompany))
            CompanyAdminStore.isSubCompanyEditing 
                ? CompanyAdminStore.editSubCompany(subCompany, CompanyAdminStore.selectedSubCompany.id) 
                : CompanyAdminStore.registerSubCompany(subCompany)
        }  
    }

    const onBlockButtonClick = () => {
        DialogTabStore.setParentName('SubCompanyForm')
        DialogTabStore.setDialogTabIsOpen(true)
        if (CompanyAdminStore.selectedSubCompany.is_active) {
            DialogTabStore.setDialogTabTitle("Блокировка юр. лица") 
            DialogTabStore.setDialogTabText("Вы уверены, что хотите заблокировать это юр. лицо?")
        } else {
            DialogTabStore.setDialogTabTitle("Снятие блокировки") 
            DialogTabStore.setDialogTabText("Вы уверены, что хотите разблокировать это юр. лицо?") 
        }
    }

    const handleBlockCompanyClick = () => {
        if (CompanyAdminStore.selectedSubCompany.is_active)
            CompanyAdminStore.editSubCompany({is_active: false}, CompanyAdminStore.selectedSubCompany.id)
        else {
            CompanyAdminStore.editSubCompany({is_active: true}, CompanyAdminStore.selectedSubCompany.id)
        }        
        DialogTabStore.setDialogTabIsOpen(false) 
    }

    useEffect(() => {
        const companyFormStorage = JSON.parse(sessionStorage.getItem('subCompanyForm'))
        if (companyFormStorage) {
            Object.entries(companyFormStorage).map(([key, value], index) => {
                setValue(key, value)
            })
        }
    }, [])

    useEffect(() => {
        if (Object.entries(errors).length > 0 && isSubmitting === false) {
            toast.error(<FormErrorToastPanel errors={errors} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <form id='sub-company-form' className='tw-h-[calc(100%_-_3rem)] tw-overflow-auto tw-flex tw-flex-col'>
            <div className='tw-text-sm tw-font-semibold tw-p-4 tw-text-gray-900'>
                { CompanyAdminStore.isSubCompanyEditing ? 'Редактируемое юр. лицо' : 'Новое юр. лицо' }
            </div>
            <div className='tw-grow'>
                {formFields.map((item, index) => {
                    return <div key={index} className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4">
                                <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-4">
                                    <label htmlFor={item.tech_name}>{item.alias}</label>
                                    <span>
                                        { errors[item.tech_name] && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/> }
                                    </span>
                                </dt>
                                <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                    <input
                                        id={item.tech_name}
                                        className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-2 tw-text-gray-700 
                                            tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm 
                                            sm:tw-leading-6 focus-visible:tw-outline-none focus-visible:tw-ring-2
                                            ${errors[item.tech_name] ? 'tw-ring-red-400' : 'tw-ring-gray-400'}
                                        `}
                                        {...register(item.tech_name, { required: true, value: CompanyAdminStore.isSubCompanyEditing ? CompanyAdminStore.selectedSubCompany[item.tech_name] : '' })}
                                    />
                                </dd>
                            </div>
                    })
                }
            </div>
            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                { CompanyAdminStore.isSubCompanyEditing &&
                    <button className=' tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700 hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600' 
                        type='button'
                        onClick={onBlockButtonClick}
                    >
                        {CompanyAdminStore.selectedSubCompany.is_active ? "Заблокировать" : "Разблокировать"}
                    </button>
                }
                <button 
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={handleSubmit(onSubmitClick)}
                >
                    Сохранить
                </button>
                <button
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={onCancelClick}
                >
                    Отменить
                </button>
            </div>
            <DialogTab 
                parentName='SubCompanyForm'
                dialogTabFunction={handleBlockCompanyClick}
            />
        </form>
    )
}

export default observer(SubCompanyForm)