import React, {useEffect, useState} from 'react'
import { observer } from 'mobx-react-lite'
import { Tab } from '@headlessui/react'
import Spinner from '../../../assets/Spinner'
import RecordNotesTable from './RecordNotesTable'
import { Tooltip } from 'react-tooltip'
import FilterStore from '../../../store/FilterStore'

/**
 * Визуальный компонент отображает вкладку с сообщениями
 * @param {Object} recordNotesStore Экземпляр хранилища информации о сообщениях
 * 
 * @returns {HTMLFormElement} Html-разметку вкладки с сообщениями 
 * с использованием визуальных компонентов {@link RecordNotesTable}, {@link Spinner}
 * 
 * @see [Вызов компонента](./components_tabs_DataObjectTabs.js.html#line130)
 */
const NoteTabPanel = ({recordNotesStore}) => {
    const [filterStore] = useState(() => new FilterStore())

    const object = {fields: [
        {
            alias: "Текст сообщения",
            tech_name: "message",
            validator_type: "string"
        },
        {
            alias: "Дата и время",
            tech_name: "created",
            validator_type: "date"
        }
    ]}
    
    useEffect(() => {       
        if(recordNotesStore.recordID) {
            let columnName = recordNotesStore.columnName ? recordNotesStore.columnName : "created"
            let sortDirection = recordNotesStore.sortDirection ? recordNotesStore.sortDirection : false
            recordNotesStore.fetchNotes(columnName, sortDirection, recordNotesStore.recordID, 0, [])
            filterStore.filterClick(object, false, true)
        }
    }, [recordNotesStore.recordID])

    useEffect(()=>{
        if (recordNotesStore.recordID) {
            let columnName = recordNotesStore.columnName ? recordNotesStore.columnName : "created"
            let sortDirection = recordNotesStore.sortDirection ? recordNotesStore.sortDirection : false
            recordNotesStore.fetchNotes(columnName, sortDirection, recordNotesStore.recordID, 0, filterStore.selectedFilters)
        }
    }, [filterStore.selectedFilters])

    return (
        <Tab.Panel
            className='tab-panel tw-overflow-hidden tw-w-full tw-rounded-b-md tw-rounded-tr-md tw-bg-white tw-px-4 tw-py-2 tw-ring-white 
            tw-ring-opacity-60 tw-ring-offset-2 focus:tw-outline-none focus:tw-ring-offset-0 tw-flex tw-flex-col tw-justify-between tw-h-full'
        >
            {!recordNotesStore.isLoading
                ?
                    <>
                        {!recordNotesStore.error && recordNotesStore.recordID && 
                            <RecordNotesTable object={object} recordNotesStore={recordNotesStore} filterStore={filterStore} />
                        }
                    </>
                :
                    <Spinner/>
            }
            <Tooltip className="tw-break-all tw-max-w-lg" id="record-notes-form-tooltip" classNameArrow="!tw-left-1/2" place="top-start"/>
        </Tab.Panel>
    )
}

export default observer(NoteTabPanel)