import React from 'react'
import StageItem from './StageItem'


/**
 * Визуальный компонент отображает список этапов процесса
 * 
 * @param {Object} list Список этапов процесса 
 * @param {Object} activeStage Текущий этап процесса
 * @param {String} idField Имя поля с номером этапа 
 * @param {Boolean} isHideList Признак скрытия списка этапов 
 * @param {Function} onItemClick Обработчик клика мыши для перехода обратно к произвольному завершенному этапу
 * [handleJumpStageClick](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line98),
 * [handleJumpStageClick](./components_main_page_controller_personal_data_PersonalDataContainer.js.html#line93),
 * [handleJumpStageClick](./components_main_page_controller_education_EducationContainer.js.html#line25)
 * @param {Boolean} isSearch Признак наличия строки поиска
 * 
 * @returns {HTMLDivElement} Html-разметку списка существующих этапов процесса с использованием визуального компонента {@link StageItem}
 * 
 * @see [Вызов компонента](./components_main_page_controller_common_panels_stage_list_StageListContainer.js.html#line73)
 */
const StageList = ({list, activeStage, idField, isHideList, onItemClick, isSearch}) => {
    return (
        <div
            id='stage-list-items'
            className={`${isSearch ? 'tw-h-[calc(100%_-_6rem)]' : 'tw-h-[calc(100%_-_3rem)]'}
                        ${isSearch & !isHideList ? 'lg:tw-h-[calc(100%_-_8rem)]' : 'lg:tw-h-[calc(100%_-_6.5rem)]'}
                        tw-flex tw-flex-col tw-justify-between tw-w-full tw-bg-white tw-overflow-auto`}
        >
            <div>
                { list.map((stage, index) => 
                    <StageItem
                        key={index}
                        item={stage}
                        activeItem={activeStage}
                        idField={idField}
                        isHideList={isHideList}
                        onItemClick={onItemClick}
                    />
                )}
            </div>
        </div>
    )
}

export default StageList