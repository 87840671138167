import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../..'
import ObjectsList from '../../common/panels/object_list/ObjectsList'
import ObjectsListButtons from '../../common/panels/object_list/ObjectsListButtons'
import Spinner from '../../../../../assets/Spinner'
import MaterialsObjectsForm from './MaterialsObjectsForm'
import { Tooltip } from 'react-tooltip'
import SearchInput from '../../../../search/SearchInput'
import FilterStore from '../../../../../store/FilterStore'


/**
 * Визуальный компонент отображает список материалов темы
 * 
 * @returns {JSXElement} Html-разметку списка материалов темы
 * с использованием визуальных компонентов {@link ObjectsList}, {@link MaterialsObjectsForm}, {@link Spinner}, {@link SearchInput}
 * 
 * @see [Вызов компонента EducationContainer](./components_main_page_controller_education_EducationContainer.js.html#line75)
 */
const MaterialsObjectsContainer = () => {
    const { EducationStore } = useContext(Context)
    const [filterStore] = useState(() => new FilterStore())

    const materialDataModel = {
        id: "topic_materials",
        fields: [{
            alias: "Наименование",
            field_id: "name",
            full_name: "Наименование",
            tech_name: "name",
            type: "value",
            validator_type: "string"
        }]
    }

    const handleJumpStageClick = (item) => {
        EducationStore.setMaterialsStage(item)
    }

    const updateDataObjectList = () => {
        EducationStore.loadMaterials(filterStore.searchFilters)
    }

    const handleBackClick = () => {
        EducationStore.setMaterialsStage(EducationStore.materialsList[EducationStore.materialsStage.value - 1])
    }

    const handleForwardClick = async () => {
        EducationStore.setMaterialsStage(EducationStore.materialsList[EducationStore.materialsStage.value + 1])
    }

    useEffect(() => {
        if (EducationStore.topic) {
            EducationStore.loadMaterials(filterStore.searchFilters)
        }
    }, [EducationStore.topic])

    useEffect(() => {
        if (EducationStore.isMaterialProgressUpdate || EducationStore.isTestAttemptUpdate) {
            EducationStore.updateMaterialStatus()
        }
    }, [EducationStore.isMaterialProgressUpdate, EducationStore.isTestAttemptUpdate])

    return (
        <>
            <div className='tw-grow tw-overflow-auto'>
                <div className='tw-grid tw-grid-cols-5 tw-gap-x-2 tw-max-h-full tw-h-full'>
                    { !EducationStore.isMaterialsLoading
                        ?   <>
                                {EducationStore.materialsList.length > 0
                                    ? <>
                                            <div id='stage-list' className='tw-col-span-2 tw-h-full tw-bg-white tw-overflow-hidden tw-pr-2 tw-border-r-2 tw-border-gray-300'>
                                                <div id='stage-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                                                    <p className='tw-text-md tw-font-semibold'>Материалы</p>
                                                </div>
                                                <div className='tw-w-full tw-p-2'>
                                                    <SearchInput
                                                        isDisabled={false}
                                                        filterStore={filterStore}
                                                        selectedFullDataModel={materialDataModel}
                                                        updateDataObjectList={updateDataObjectList}
                                                    />
                                                </div>
                                                <ObjectsList 
                                                    objectsList={EducationStore.materialsList}
                                                    objectsListStage={EducationStore.materialsStage}
                                                    isDelete={false}
                                                    onJumpStageClick={handleJumpStageClick}
                                                    isSearch={true}
                                                />
                                            </div>
                                            <div id='stage-column' className='tw-col-span-3 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-flex tw-flex-col'>
                                                <div id='stage-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                                                    <p
                                                        className='tw-text-md tw-font-semibold tw-truncate'
                                                        data-tooltip-id="material-form-tooltip" data-tooltip-content={EducationStore.materialsStage.name} data-tooltip-delay-show={1000}
                                                    >
                                                        Материал "{EducationStore.materialsStage.name}"
                                                    </p>
                                                </div>
                                                { EducationStore.materialsStage && EducationStore.materialsStage.material && 
                                                    <MaterialsObjectsForm/>
                                                }
                                            </div> 
                                            
                                        </>
                                    :
                                        <p className='tw-text-md tw-font-semibold tw-mt-4 tw-text-center tw-col-span-5'>
                                            Для данной темы нет доступных материалов
                                        </p>
                                }
                            </>
                        :
                            <div className='tw-col-span-5'>
                                <Spinner />
                            </div>               
                    }
                </div> 
            </div>
            <ObjectsListButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={false}
                stage={EducationStore.materialsStage}
                objectsList={EducationStore.materialsList}
                forwardButtonTitle={'Следующий материал'}
                backButtonTitle={'Предыдущий материал'}
            />
            <Tooltip id="material-form-tooltip" place="top-start" className="tw-max-w-xl"/>
        </>
    )
}

export default observer(MaterialsObjectsContainer)