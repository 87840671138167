import React, { useContext } from 'react'
import { PencilSquareIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает меню возможных действий над юр лицом
 * 
 * @returns {HTMLDivElement} Html-разметку ряда кнопок меню
 * 
 * @see [Вызов компонента](./components_company_admin_page_CompanyAdminContainer.js.html#line43)
 */
const SubCompanyMenu = () => {
    const { CompanyAdminStore } = useContext(Context)

    const onEditItemClick = () => {
        CompanyAdminStore.setIsSubCompanyEditing(true)
        CompanyAdminStore.setSelectedUser(null)
    }

    return (
        <div id='sub-company-menu' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
            <p className='tw-text-sm tw-font-semibold tw-w-24 md:tw-text-base sm:tw-w-96'>Информация о юр. лице и пользователях</p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={() => onEditItemClick(CompanyAdminStore.selectedSubCompany.id)}
                    disabled={!CompanyAdminStore.selectedSubCompany  || CompanyAdminStore.isSubCompanyCreating || CompanyAdminStore.isSubCompanyEditing || CompanyAdminStore.isUserCreating || CompanyAdminStore.isUserEditing}
                    data-tooltip-id="sub-company-menu-tooltip" data-tooltip-content="Редактировать юр. лицо" data-tooltip-delay-show={1000}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="sub-company-menu-tooltip" place="top"/>
        </div>
    )
}

export default observer(SubCompanyMenu)