import React, { useContext, useState, useEffect } from 'react'
import { Context } from '../../../../..'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../../../config/constsURN'
import DialogTab from '../../../../dialog_tab/DialogTab'
import StageButtons from '../stage/StageButtons'
import { categorizingDirectories } from '../../../../../config/constCategorizing'
import { observer } from 'mobx-react-lite'
import DocumentService from '../../../../../services/DocumentService'
import DirectoriesTable from '../../common/tables/DirectoriesTable'

/**
 * Визуальный компонент отображает этап проверки заполнения справочников
 * 
 * @returns {JSXElement} Html-разметку этапа проверки заполнения справочников
 * с использованием визуальных компонентов {@link DialogTab}, {@link StageButtons}
 * 
 * @see [Вызов компонента](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line135)
 */
const DirectoriesInfo = () => {
    const { userStore, DialogTabStore, categorizingCIIStore, docStore } = useContext(Context)
    const navigate = useNavigate()

    const [selectedDirectory, setSelectedDirectory] = useState(null)
    const [directories, setDirectories] = useState(categorizingDirectories)
    const [dataModels, setDataModels] = useState(null)

    const handleLinkClick = (directory) => {
        setSelectedDirectory(directory)
        DialogTabStore.setParentName('DirectoriesInfo')
        DialogTabStore.setDialogTabTitle("Уход со страницы") 
        DialogTabStore.setDialogTabText(`Вы уверены, что хотите перейти к редактированию справочника "${directory.entity_name}"?`) 
        DialogTabStore.setDialogTabButtons(["Да", "Нет"])
        DialogTabStore.setDialogTabIsOpen(true) 
    }

    const handleEditRedirect = () => {
        sessionStorage.setItem('activeDataModel', JSON.stringify(selectedDirectory))
        navigate(paths.DATAOBJECT_ROUTE)
    }

    const handleBackClick = () => {
        categorizingCIIStore.goPrevStage()
    }

    const handleForwardClick = async () => {
        if (!categorizingCIIStore.project) {
            const project = {}
            project.data_model_id = 'stages_of_categorization'
            project.data = {}
            project.data['stage_passed__stages_of_categorization'] = true

            const savedProject = await DocumentService.createDataObject(project)
            await categorizingCIIStore.loadSavedProject(savedProject.id)
        }
        categorizingCIIStore.goNextStage()    
    }

    useEffect(() => {
        const dataModelRequests = categorizingDirectories.map(directory => DocumentService.getOneDataModel(directory.id))
        Promise.all(dataModelRequests)
            .then(responses => {
                setDataModels(responses)
            })
    }, [])

    useEffect(() => {
        if (dataModels) {
            let requests = dataModels.map(directory => docStore.getRecordCount(directory))
            Promise.all(requests)
                .then(responses => {
                    setDirectories(dataModels.map((directory, index) =>  { return {
                        ...directory,
                        records: responses[index]
                    }}))
                })    
        }
    }, [dataModels])

    return (
        <>
            <div className='tw-grow tw-overflow-auto'>
                <p className='tw-text-sm tw-p-4'>
                    На данном этапе необходимо проверить заполнение и актуальность справочников, используемых в процессе категорирования КИИ. <br/>
                    При необходимости, справочники можно отредактировать, перейдя по соответствующей ссылке. <br/>
                </p>
                <div className='tw-px-4 sm:tw-px-10'>
                    <DirectoriesTable
                        directories={directories}
                        onLinkClick={handleLinkClick}
                    />
                </div>
                <p className='tw-text-sm tw-p-4 tw-mt-2'>
                    Также необходимо проверить правильность информации об организации. <br/>
                    При необходимости вы можете её 
                    <span
                        className='tw-font-semibold tw-text-sky-700 hover:tw-cursor-pointer hover:tw-text-sky-500 
                                    focus-visible:tw-outline-none focus-visible:tw-text-sky-500'
                        onClick={() => {userStore.setIsUserInfoOpen(true); userStore.setIsUserInfoFormOpen(true)}}
                    > скорректировать</span>
                </p>
                <div className="tw-relative tw-flex-1 tw-px-4 sm:tw-px-6">
                    <dl>
                        <div className="tw-px-2 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Сокращенное название организации</dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.abbreviation}</dd>
                        </div>
                        <div className="tw-px-2 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Полное название организации</dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.name}</dd>
                        </div>
                        <div className="tw-px-2 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ИНН</dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.tin}</dd>
                        </div>
                        <div className="tw-px-2 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ОГРН</dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.msrn}</dd>
                        </div>
                        <div className="tw-px-2 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-500">КПП</dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.rrc}</dd>
                        </div>
                        <div className="tw-px-2 tw-py-1 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Руководитель организации</dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">{userStore.currentSubCompany?.owner}</dd>
                        </div>
                    </dl>                
                </div>
                <DialogTab
                    parentName='DirectoriesInfo'
                    dialogTabFunction={handleEditRedirect}
                />
            </div>
            <StageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={directories.some(directory => directory.records === 0)}
            />
         </>
    )
}

export default observer(DirectoriesInfo)