import { toast } from 'react-toastify'
import AuthService from '../services/AuthService'
import { toastErrorMessages } from '../config/constMessages'

export function errorHandler(response, message) {
    if (response?.status === 401) {
        const access = localStorage.getItem('token')
        if (access) {
            AuthService.logout(true)
        }
        localStorage.setItem('isTokenExpired', true)
        window.location.replace('/login')
    } else {
        toast.error(<div>{message}<br/><br/> Некорректные параметры запроса</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
    }
}

export function getErrorInfo(error, fields) {
    let errorMessage = ''
    let errorObject = {data: {}}
    if (error?.response?.data?.status_code === 400) {
        const dataObjectFields = fields.slice()
        const errors = error.response.data.errors.map(item => {
            const foundField = dataObjectFields.find(field => field.tech_name === item?.additional_info?.field)
            if (foundField) {
                return [
                    foundField?.alias,    // Имя поля
                    (item.status_code === 409 ? 'повторяющееся значение уникального поля' : 'некорректное значение поля')   // Описание ошибки
                ]
            }
            if (item.error_code === 'MM-3002') {
                return ['Запись-родитель', 'некорректная циклическая ссылка']
            }
            return ['Поле записи', 'некорректное значение поля']
        })
        errorMessage = errors.map((item, index) => {return <p key={index}>{'"' + item[0] + '": ' + item[1]}</p>})
        errorObject = {data: errors.reduce((acc, value) => ({...acc, [value[0]]: value[1]}), {})}
    }

    return [errorMessage, errorObject]
}

export function showErrorToast(error, requestType, additionalInfo) {
    const message = toastErrorMessages.find(item => item.type === requestType)
    console.log(requestType, ':', error)
    switch (error?.response?.status) {
        case 400:
            toast.error(<div>{message.mainText}<br/><br/>{message.text400}<br/>{additionalInfo || ''}</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
            break
        case 401:
            if (requestType === 'sign_in')
                toast.error(<div>{message.mainText}<br/><br/>{message.text401}</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
            else
                errorHandler(error.response, message.mainText)
            break
        case 403:
            toast.error(<div>{message.mainText}<br/><br/>{message.text403}</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
            break
        case 404:
            toast.error(<div>{message.mainText}<br/><br/>{message.text404}</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
            break
        case 422:
            toast.error(<div>{message.mainText}<br/><br/>{message.text422}</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
            break
        default:
            toast.error(<div>Возникла непредвиденная ошибка! <br/><br/>{message.text500}</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
    }    
}
