import React, { useContext, useEffect } from 'react'
import PageTitle from '../main_page/controller/common/panels/PageTitle'
import SubCompanyList from './sub_companies_list/SubCompanyList'
import Spinner from '../../assets/Spinner'
import SubCompanyListMenu from './sub_companies_list/SubCompanyListMenu'
import SubCompanyInfo from './sub_company/SubCompanyInfo'
import SubCompanyForm from './sub_company/SubCompanyForm'
import { Context } from '../..'
import SubCompanyMenu from './sub_company/SubCompanyMenu'
import UsersList from './users/users_list/UsersList'
import { observer } from 'mobx-react-lite'


/**
 * Компонент реализует логику создания и редактирования юр лиц и пользователей компании пользователя
 * @returns {JSXElement} Html-разметку редактора юр лиц и пользователей с использованием визуальных компонентов 
 * {@link PageTitle}, {@link SubCompanyList}, {@link SubCompanyListMenu}, {@link SubCompanyInfo}, {@link SubCompanyForm}, {@link SubCompanyMenu}, 
 * {@link UsersList}, {@link Spinner}
 */
const CompanyAdminContainer = () => {
    const { CompanyAdminStore } = useContext(Context)

    useEffect(() => {
        CompanyAdminStore.getMyCompany()
        CompanyAdminStore.getMyCompanyUsers()
    }, [])

    if (CompanyAdminStore.isLoading || !CompanyAdminStore.subCompanies) {
        return <Spinner/>
    }

    return (
        <>
            <PageTitle title={'Администрирование юр. лиц и пользователей'} />
            <section id='company-admin-page' className='tw-grid tw-grid-cols-4 tw-gap-x-8 tw-max-h-full tw-h-full'>

                <div id='sub-company-list' className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <SubCompanyListMenu />
                    <SubCompanyList />
                </div>

                <div id='sub-company-column' className='tw-col-span-2 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <SubCompanyMenu />
                    { CompanyAdminStore.selectedSubCompany && !CompanyAdminStore.isSubCompanyCreating && !CompanyAdminStore.isSubCompanyEditing &&
                        <SubCompanyInfo />
                    }
                    { (CompanyAdminStore.isSubCompanyCreating || CompanyAdminStore.isSubCompanyEditing) && 
                        <SubCompanyForm />
                    }
                </div>
                
                <div id='sub-company-user-column' className='tw-col-span-1 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <div id='company-user-menu' 
                        className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center 
                                    tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'
                    >
                        <p className='tw-text-xs tw-font-semibold tw-w-24 md:tw-text-base sm:tw-w-96'>Информация о пользователях</p>
                    </div>
                    { CompanyAdminStore.selectedSubCompany && 
                        <UsersList />
                    } 
                </div>

            </section>
        </>
    )
}

export default observer(CompanyAdminContainer)