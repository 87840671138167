import React from 'react'
import Spinner from '../../../../../../assets/Spinner'
import StageList from './StageList'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import SearchInput from '../../../../../search/SearchInput'


/**
 * Визуальный компонент отображает список этапов модуля (категорирования КИИ, обработка ПДн и др.)
 * 
 * @param {String} title Заголовок
 * @param {Object} list Список этапов модуля 
 * @param {Object} activeStage Текущий этап модуля
 * @param {String} idField Имя поля с номером этапа 
 * @param {Boolean} isHideList Признак скрытия списка этапов 
 * @param {Function} onHideClick Обработчик клика мыши для перехода к следующему этапу
 * [handleHideStageClick](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line94),
 * [handleHideStageClick](./components_main_page_controller_personal_data_PersonalDataContainer.js.html#line89),
 * [handleHideStageClick](./components_main_page_controller_education_EducationContainer.js.html#line21)
 * @param {Function} onItemClick Обработчик клика мыши для перехода обратно к произвольному завершенному этапу
 * [handleJumpStageClick](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line98),
 * [handleJumpStageClick](./components_main_page_controller_personal_data_PersonalDataContainer.js.html#line93),
 * [handleJumpStageClick](./components_main_page_controller_education_EducationContainer.js.html#line25)
 * @param {Boolean} isSearch Признак наличия строки поиска
 * @param {Boolean} isDisabled Признак неактивности строки поиска
 * @param {Object} filterStore Экземпляр хранилища информации о фильтрах
 * @param {Object} selectedFullDataModel Выбранная таблица
 * @param {Function} updateDataObjectList Функция обновления списка записей
 * [updateDataObjectList](./components_main_page_controller_education_EducationContainer.js.html#line29)
 * @param {Boolean} isLoading Признак загрузки
 * 
 * @returns {JSXElement} Html-разметку списка существующих этапов модуля 
 * с использованием визуальных компонентов {@link Spinner}, {@link StageList}, {@link SearchInput}
 * 
 * @see [Вызов компонента CategorizingContainer,](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line113)
 * [PersonalDataContainer,](./components_main_page_controller_personal_data_PersonalDataContainer.js.html#line108)
 * [EducationContainer](./components_main_page_controller_education_EducationContainer.js.html#line41)
 */
const StageListContainer = ({title, list, activeStage, idField, isHideList, onHideClick, onItemClick, isSearch, isDisabled, filterStore, selectedFullDataModel, 
        updateDataObjectList, isLoading}) => {
    return (
        <>
            <div id='stage-list' 
                className={`${isHideList ? "tw-w-full lg:tw-w-8" : "tw-w-full"} tw-relative tw-h-80 lg:tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-duration-500`}
            >
                <div id='stage-list-header' 
                    className={`tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 
                        tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2  tw-duration-500 ${isHideList && 'lg:tw-pt-16'}`}
                >
                    <p className={`${isHideList ? 'lg:tw-rotate-[-90deg] lg:tw-translate-x-[-1.5rem] lg:tw-translate-y-[-1.7rem]' : ''} 
                                        tw-text-md tw-font-semibold`}
                    >
                        {title}
                    </p>
                </div>

                { !list
                    ?   <Spinner/>
                    :   <>
                            {isSearch && !isHideList &&
                                <div className='tw-w-full tw-p-2'>
                                    <SearchInput
                                        isDisabled={isDisabled}
                                        filterStore={filterStore}
                                        selectedFullDataModel={selectedFullDataModel}
                                        updateDataObjectList={updateDataObjectList}
                                    />
                                </div>
                            }
                            {isLoading 
                                ?   <Spinner/>
                                :   <StageList 
                                        list={list}
                                        activeStage={activeStage}
                                        idField={idField}
                                        isHideList={isHideList}
                                        onItemClick={onItemClick}
                                        isSearch={isSearch}
                                    />
                            }
                        </>
                }
                <button
                    className={`${isHideList ? 'tw-invisible lg:tw-visible tw-opacity-1' : 'tw-h-0 tw-invisible lg:tw-h-8 tw-opacity-0'} 
                                tw-absolute tw-bottom-0 tw-delay-500 tw-duration-500
                                tw-w-full tw-pl-2 tw-py-1
                                tw-text-gray-700 hover:tw-bg-gray-300 focus-visible:tw-outline 
                                focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600
                            `}
                    onClick={() => onHideClick(false)}
                    data-tooltip-id="stage-list" data-tooltip-content="Показать" data-tooltip-delay-show={1000}
                >
                    <ChevronDoubleRightIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                </button>

                <button
                    className={`${isHideList ? 'tw-invisible tw-opacity-0' : 'tw-h-0 tw-invisible lg:tw-h-8 lg:tw-visible tw-opacity-100'} 
                                tw-absolute tw-bottom-0 tw-delay-500 tw-duration-500
                                tw-w-full tw-flex tw-flex-row tw-items-start tw-gap-x-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                                tw-text-gray-700 hover:tw-bg-gray-300 focus-visible:tw-outline
                                focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600
                    `}
                    onClick={() => onHideClick(true)}
                >
                    <ChevronDoubleLeftIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                    <span className='tw-overflow-hidden'>Скрыть</span>
                </button>
            </div>
            <Tooltip id="stage-list" place="top-start" style={{ zIndex: 99 }}/> 
        </>
    )
}

export default StageListContainer