import { Fragment, useContext } from 'react'
import { Context } from '../../..'
import { Menu, Transition } from '@headlessui/react'
import { BellAlertIcon, ChevronDownIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../config/constsURN'
import AuthService from '../../../services/AuthService'
import { observer } from 'mobx-react-lite'
import SubCompaniesMenu from './SubCompaniesMenu'


/**
 * Визуальный компонент отображает выпадающее пользовательское меню
 * 
 * @returns {JSXElement} Визуальный компонент Menu из библиотеки {@link https://headlessui.com/|@headlessui}
 * с использованием визуального компонента {@link SubCompaniesMenu}
 * 
 * @see [Вызов компонента](./components_header_Header.js.html#line52)
 */
function UserMenu() {
    const { userStore } = useContext(Context)
    const navigate = useNavigate()

    const onLogOutClick = async () => {
        await AuthService.logout(true)
        userStore.clearUser()
        navigate(paths.MAIN_ROUTE)
    }

    return (
        <Menu as="div" className="tw-relative lg:tw-ml-3">
            <div>
                <Menu.Button className="tw-inline-flex tw-w-full tw-justify-center tw-items-center tw-gap-x-0.5 lg:tw-gap-x-1.5 tw-rounded-md 
                    tw-bg-gray-800 tw-px-3 tw-py-3 tw-text-sm tw-font-semibold tw-text-white  hover:tw-bg-gray-600"
                >
                    { !userStore.isFullUserInfo &&
                        <BellAlertIcon className="tw-w-5 tw-h-5 tw-text-yellow-400"/>
                    }
                    <span className='tw-w-6'>
                        <UserCircleIcon className="tw-w-6 tw-h-6"/>
                    </span>
                    <div className='tw-flex tw-flex-col tw-text-xs tw-max-w-[10rem]'>
                        <span className='tw-truncate tw-w-full'>
                            {userStore.user.first_name} {userStore.user.last_name}
                        </span>
                        <span className='tw-truncate tw-w-full'>
                            {userStore.currentSubCompany.abbreviation}
                        </span>
                    </div>
                    <span className='tw-w-5'>
                        <ChevronDownIcon className="tw-w-5 tw-h-5"/>
                    </span> 
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="tw-transition tw-ease-out tw-duration-100"
                enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                enterTo="tw-transform tw-opacity-100 tw-scale-100"
                leave="tw-transition tw-ease-in tw-duration-75"
                leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                leaveTo="tw-transform tw-opacity-0 tw-scale-95"
            >
                <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-72 tw-origin-top-right tw-rounded-md tw-bg-white tw-py-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                    <Menu.Item>
                        {({ active }) => (
                            <button
                                className={`${active ? 'tw-bg-gray-300' : ''} ${userStore.isFullUserInfo ? 'tw-text-gray-700' : 'tw-text-red-500'} tw-block tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-text-left`}
                                onClick={() => userStore.setIsUserInfoOpen(true)}
                            >
                                Профиль
                            </button>
                        )}
                    </Menu.Item>
                     <Menu.Item>
                        {({ active }) => (
                            <div
                                className={`${active ? 'tw-bg-gray-300' : ''} tw-text-gray-700 tw-block tw-text-sm tw-text-left`}
                            >
                                <SubCompaniesMenu/>
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <button
                                className={`${active ? 'tw-bg-gray-300' : ''} tw-block tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-text-left tw-text-gray-700`}
                                onClick={() => navigate(paths.LICENSE_ROUTE)}
                            >
                                Лицензионное соглашение
                            </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <button
                                className={`${active ? 'tw-bg-gray-300' : ''} tw-block tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-text-left tw-text-gray-700`}
                                onClick={() => navigate(paths.POLICY_ROUTE)}
                            >
                                Политика конфиденциальности
                            </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <button
                                className={`${active ? 'tw-bg-gray-300' : ''} tw-block tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-text-left tw-text-gray-700`}
                                onClick={onLogOutClick}
                            >
                                Выйти
                            </button>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default observer(UserMenu)