import React from 'react'
import { ArrowsPointingOutIcon, MinusIcon, PlusIcon } from '@heroicons/react/20/solid'

/**
 * Визуальный компонент отображает меню управления масштабом страницы документа
 * 
 * @param {Number} scale Значение масштаба страницы документа
 * @param {Function} onScaleChange Обработчик клика мыши на кнопках изменения масштаба
 * [handleScaleChange](./components_main_page_controller_education_viewers_document_EducationPDFViewer.js.html#line77)
 * @param {Function} onFitClick Обработчик клика мыши на кнопке подгонки ширины страницы под размер окна
 * [handleFitScreenClick](./components_main_page_controller_education_viewers_document_EducationPDFViewer.js.html#line85)
 * 
 * @returns {HTMLDivElement} Html-разметку для отображения меню управления масштабом страницы документа
 * 
 * @see [Вызов компонента](./components_main_page_controller_education_viewers_document_EducationPDFViewer.js.html#line133)
 */
const PDFScaleMenu = ({scale, onScaleChange, onFitClick}) => {
    return (
        <div id='scale-menu' className='tw-px-4 tw-h-full tw-flex tw-flex-row tw-justify-end tw-items-center tw-border-l tw-border-gray-300'>
            <button 
                className='tw-mr-3 tw-border tw-text-gray-500 hover:tw-text-gray-800 tw-border-gray-400 hover:tw-border-gray-800
                            disabled:tw-text-gray-300 disabled:tw-border-gray-300 disabled:tw-bg-white
                            focus-visible:tw-text-gray-800 focus-visible:tw-outline focus-visible:tw-outline-2
                            focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-800 focus-visible:tw-rounded-sm'
                type='button'
                onClick={onFitClick}
            >
                <ArrowsPointingOutIcon  className="tw-w-5 tw-h-5" aria-hidden="true"/>
            </button>
            <button 
                className='tw-text-gray-500 hover:tw-text-gray-800 focus-visible:tw-text-gray-800 focus-visible:tw-outline 
                        focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-800 focus-visible:tw-rounded-sm'
                type='button'
                onClick={() => onScaleChange(false)}
            >
                <MinusIcon  className="tw-w-5 tw-h-5" aria-hidden="true"/>
            </button>
            <span className='tw-w-32 tw-text-sm tw-text-center tw-select-none'>Масштаб {scale + '%'}</span>
            <button 
                className='tw-text-gray-500 hover:tw-text-gray-800 focus-visible:tw-text-gray-800 focus-visible:tw-outline 
                        focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-800 focus-visible:tw-rounded-sm'
                type='button'
                onClick={() => onScaleChange(true)}
            >
                <PlusIcon  className="tw-w-5 tw-h-5" aria-hidden="true"/>
            </button>
        </div>
    )
}

export default PDFScaleMenu