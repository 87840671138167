import React, { useContext } from 'react'
import { DocumentPlusIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { Context } from '../../../../..'


/**
 * Визуальный компонент отображает меню возможных действий над печатными формами
 * @param {Object} activeItem Выбранный элемент в списке печатных форм
 * @param {Function} onAddItemClick Обработчик клика мыши на кнопке создания новой печатной формы
 * [handleCreateModeClick](./components_main_page_controller_exporter_ExporterListContainer.js.html#line145)
 * @param {Function} onEditItemClick Обработчик клика мыши на кнопке редактирования выбранной печатной формы
 * [handleEditModeClick](./components_main_page_controller_exporter_ExporterListContainer.js.html#line152)
 * 
 * @returns {HTMLDivElement} Html-разметку ряда кнопок меню
 * 
 * @see [Вызов компонента](./components_main_page_controller_exporter_ExporterListContainer.js.html#line231)
 */
const ExporterMenu = ({activeItem, onAddItemClick, onEditItemClick}) => {
    const { DialogTabStore } = useContext(Context)

    const onDeleteItemClick = () => {
        DialogTabStore.setParentName('ExporterListContainer')
        DialogTabStore.setDialogTabTitle("Удаление шаблона") 
        DialogTabStore.setDialogTabText("Вы уверены, что хотите удалить этот шаблон?")
        DialogTabStore.setDialogTabButtons(["Да", "Нет"])
        DialogTabStore.setDialogTabIsOpen(true)
    }

    return (
        <div id='exporter-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
            <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Шаблоны</p>
            <div className='tw-flex tw-flex-row tw-gap-x-1'>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600'
                    onClick={onAddItemClick}
                    data-tooltip-id="exporter-tooltip" data-tooltip-content="Создать" data-tooltip-delay-show={1000}
                >
                    <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button 
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onEditItemClick}
                    disabled={!activeItem}
                    data-tooltip-id="exporter-tooltip" data-tooltip-content="Редактировать" data-tooltip-delay-show={1000}
                >
                    <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
                <button
                    className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                    onClick={onDeleteItemClick}
                    disabled={!activeItem}
                    data-tooltip-id="exporter-tooltip" data-tooltip-content="Удалить" data-tooltip-delay-show={1000}
                >
                    <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                </button>
            </div>
            <Tooltip id="exporter-tooltip" place="top"/>
        </div>
    )
}

export default ExporterMenu