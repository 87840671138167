import React, { useContext, useEffect, useState } from 'react'
import { paths } from '../../config/constsURN'
import { Context } from '../..'
import { moduleTypes } from '../../config/constTypes'
import ModuleGroup from './controller/summary/ModuleGroup'
import { observer } from 'mobx-react-lite'
import DocumentService from '../../services/DocumentService'
import PageTitle from './controller/common/panels/PageTitle'
import Instructions from './controller/summary/Instructions'
import { showErrorToast } from '../../functions/errorHandlers'
import Footer from '../footer/Footer'
import SignInModuleItem from './controller/summary/SignInModuleItem'
import { toast } from 'react-toastify'
import { serviceMessageTimeOut } from '../../config/constTypes'

const medoedModules = [
    {
        id: '1',
        name: 'Категорирование КИИ',
        description: '',
        depends: [],
        state: 'available',
        link: paths.CATEGORIZING_CII_ROUTE,
        dataModel: ['stages_of_categorization'],
        objectField: 'acts_of_categorization__stages_of_categorization',
    },
    {
        id: '2',
        name: 'Обработка и защита ПДн',
        description: '',
        depends: [],
        state: 'available',
        link: paths.PERSONAL_DATA_ROUTE,
        dataModel: ['stages_of_documentation_pdn'],
        objectField: 'ispdn',
    },
    {
        id: '3',
        name: 'Моделирование угроз',
        description: '',
        depends: [],
        state: 'plan',
        link: paths.ERROR_ROUTE,
        dataModel: [],
        objectField: '',
    },
    {
        id: '4',
        name: 'Повышение осведомленности',
        description: '',
        depends: [],
        state: 'available',
        link: paths.EDUCATION_ROUTE,
        dataModel: ['education_progress', 'test_results'],
        objectField: '',
    },
    {
        id: '5',
        name: 'Учет СКЗИ',
        description: '',
        depends: [],
        state: 'plan',
        link: paths.ERROR_ROUTE,
        dataModel: [],
        objectField: '',
    },
]

const getLastRecord = async (dataModel) => {
    try {
        const filter = JSON.stringify([
            {property: 'data_model_id', value: dataModel, operator: 'eq'},
            {property: 'active', value: true, operator: 'eq'}
        ])
        const sorter = JSON.stringify([
            {property: 'created', desc: true},
        ])
        // загрузка последней записи активных процессов
        const lastRecord = await DocumentService.getLastDataObject(filter, sorter, 'w')
        if (lastRecord)
            return lastRecord

    } catch (error) {
        showErrorToast(error, 'fetching', '')
    }

    return null
}

const checkActiveModule = async (modules, link) => {
    try {
        const foundModule = modules.find(module => module.link === link)
        const savedRecord = await getLastRecord(foundModule.dataModel[0])
        if (savedRecord) {
            const savedObjects = await DocumentService.getNestedDataObjects(50, [], JSON.stringify([{property: 'created', desc: true}]), savedRecord.id, savedRecord.data[foundModule.objectField].rule_id)
            return {link: link, data: savedRecord, objects: savedObjects.length}
        }
    } catch (error) {
        console.log(error)
        toast.error(<div>Некорректная конфигурация для Вашего профиля!<br /><br />
                    Повторите попытку обновления конфигурации.<br />
                    При повторной неудаче обратитесь в службу поддержки Медоед</div>, 
                    { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
    }
    return null
}

const checkActiveEducationModule = async (modules, link) => {
    const foundModule = modules.find(module => module.link === link)
    const savedProgressRecord = await getLastRecord(foundModule.dataModel[0])
    const savedTestAttemptRecord = await getLastRecord(foundModule.dataModel[1])
    let savedRecord

    if (savedProgressRecord) {
        savedRecord = savedProgressRecord
        if (savedTestAttemptRecord && savedProgressRecord.created < savedTestAttemptRecord.created)
            savedRecord = savedTestAttemptRecord
    } else {
        if (savedTestAttemptRecord)
            savedRecord = savedTestAttemptRecord
    }

    if (savedRecord) {
        return {link: link, data: savedRecord, objects: 0}
    }

    return null
}

/**
 * Визуальный компонент отображает стартовую страницы приложения
 *
 * @returns {HTMLDivElement} Html-разметку стартовой страницы приложения
 * с использованием визуальных компонентов {@link PageTitle}, {@link ModuleGroup}, {@link SignInModuleItem}, {@link Footer}, {@link Instructions}
 */
const MainPageContainer = () => {
    const { userStore } = useContext(Context)

    const [moduleGroups, setModuleGroups] = useState(moduleTypes)
    const [lastRecords, setLastRecords] = useState([])

    const sortModules = async (isAuth) => {
        let updatedTypes = []

        if (isAuth) {
            let checkedModules = []
            checkedModules.push(await checkActiveModule(medoedModules, paths.PERSONAL_DATA_ROUTE))
            checkedModules.push(await checkActiveModule(medoedModules, paths.CATEGORIZING_CII_ROUTE))
            checkedModules.push(await checkActiveEducationModule(medoedModules, paths.EDUCATION_ROUTE))
            const activeModulesRecords = checkedModules.filter(item => item !== null)

            const activeModules = medoedModules.filter(module => activeModulesRecords.find(active => active.link === module.link))
            const availableModules = medoedModules.filter(module => ['available'].includes(module.state) && !activeModulesRecords.find(active => active.link === module.link))
            const unavailableModules = medoedModules.filter(module => ['unavailable'].includes(module.state))
            const developedModules = medoedModules.filter(module => ['plan'].includes(module.state))
            updatedTypes = moduleTypes.map(type => {
                switch (type.value) {
                    case 'active': return {...type, modules: activeModules}
                    case 'available': return {...type, modules: availableModules}
                    case 'unavailable': return {...type, modules: unavailableModules}
                    case 'plan': return {...type, modules: developedModules}
                    default: return type
                }
            })
            setLastRecords(activeModulesRecords)
        } else {
            const availableModules = medoedModules.filter(module => ['available', 'unavailable'].includes(module.state))
            const developedModules = medoedModules.filter(module => ['plan'].includes(module.state))
            updatedTypes = moduleTypes
                    .filter(type => ['available', 'plan'].includes(type.value))
                    .map(type => {
                        switch (type.value) {
                            case 'available': return {...type, modules: availableModules}
                            case 'plan': return {...type, modules: developedModules}
                            default: return type
                        }
                    })
        }

        setModuleGroups(updatedTypes)
    }

    useEffect(() => {
        sortModules(userStore.isAuth)
    }, [userStore.isAuth])

    return (
        <>
            <PageTitle title={'Главная'} />
            <div className='tw-flex tw-flex-col lg:tw-h-full tw-mt-4'>
                <div className='tw-mx-auto tw-px-4 tw-pb-8 tw-flex tw-flex-col lg:tw-h-full'>
                    <div className='tw-grow lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-12'>
                        <div className='lg:tw-col-span-2 tw-mt-6 lg:tw-mt-0'>
                            <div className='sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-6 lg:tw-gap-12'>
                                { moduleGroups.map((group, index) =>
                                    <ModuleGroup
                                        key={index}
                                        group={group}
                                        records={lastRecords}
                                    />
                                )}
                            </div>
                        </div>
                        <div className='lg:tw-col-span-1'>
                            { !userStore.isAuth && 
                                <div className='tw-mb-6 lg:tw-mb-12'>
                                    <SignInModuleItem/>
                                </div>
                            }
                            <Instructions/>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default observer(MainPageContainer)
