import React from 'react'
import { getFormattedDate } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает информацию о выбранном шаблоне импорта
 * @param {Object} activeItem Выбранный шаблон импорта
 * @param {String} dataModel Имя таблицы, для которой предназначен шаблон импорта
 * @returns {HTMLDivElement} Html-разметку информации о выбранном шаблоне импорта
 * 
 * @see [Вызов компонента](./components_main_page_controller_importer_ImporterListContainer.js.html#line220)
 */
const ImporterInfo = ({activeItem, dataModel}) => {

    return (
        <div id='data-model-info' className='tw-w-full tw-min-w-fit tw-h-full'>
            <dl>
            <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Название</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">{activeItem.name}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Таблица</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">{dataModel.entity_name}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Правила подстановки</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">
                        { activeItem.fields.map((field, index) => 
                            <div key={index}>
                                <span>Поле "{field.full_name}" &lt;-{' '}</span>
                                <span>{field.columns.length > 1 ? 'Столбцы' : 'Столбец'} [{field.columns.map(item => item.index).join(', ')}]</span>
                            </div>
                        )}
                    </dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">ID</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">{activeItem.id}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Дата создания</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">{getFormattedDate(activeItem.created, 'dd.MM.yyyy')}</dd>
                </div>
                <div className="tw-px-4 tw-py-2 sm:tw-grid sm:tw-grid-cols-4 sm:tw-gap-4 sm:tw-px-6">
                    <dt className="tw-text-sm tw-font-medium tw-text-gray-500">Дата изменения</dt>
                    <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-3 sm:tw-mt-0">{getFormattedDate(activeItem.last_modified, 'dd.MM.yyyy')}</dd>
                </div>
            </dl>                
        </div>
    )
}

export default ImporterInfo