import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../..'
import ImporterList from './list/ImporterList'
import PageTitle from '../common/panels/PageTitle'
import DocumentService from '../../../../services/DocumentService'
import ImporterService from '../../../../services/ImporterService'
import Spinner from '../../../../assets/Spinner'
import ImporterMenu from './menu/ImporterMenu'
import ImporterInfo from './info/ImporterInfo'
import ImporterForm from './form/ImporterForm'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../config/constTypes'
import { showErrorToast } from '../../../../functions/errorHandlers'
import { Tooltip } from 'react-tooltip'
import DialogTab from '../../../dialog_tab/DialogTab'

/**
 * Компонент реализует логику работы с шаблонами импорта
 * 
 * @returns {HTMLDivElement} Html-разметку страницы с использованием визуальных компонентов
 * {@link PageTitle}, {@link ImporterList}, {@link ImporterMenu}, {@link ImporterInfo}, {@link ImporterForm}
 */
const ImporterListContainer = () => {
    const { docStore, FilterStore, DialogTabStore } = useContext(Context)

    const [isLoading, setIsLoading] = useState(true)
    const [importers, setImporters] = useState(null)
    const [selectedImporter, setSelectedImporter] = useState(null)
    const [isCreating, setIsCreating] = useState(false)  
    const [isEditing, setIsEditing] = useState(false)  
    const [importerDataModel, setImporterDataModel] = useState(null)
    const [dataModelsList, setDataModelsList] = useState([])

    const handleItemClick = (importer) => {
        if (!isCreating && !isEditing) {
            // получение полной информации о структуре таблицы для выбранного шаблона импорта
            const foundDataModel = docStore.dataModels.find(dataModel => dataModel.id === importer.meta.data_model_id)
            setImporterDataModel(foundDataModel || {id: importer.meta.data_model_id, entity_name: importer.meta.data_model_id, fields: []})
            // дополнение правил подстановки в шаблоне импорта информацией о полном имени поля и псевдониме для удобного представления пользователю
            const importerFields = importer.fields.map(field => {
                const dataModelField = foundDataModel.fields.find(item => item.tech_name === field.tech_name)
                return {...field,
                    alias: dataModelField.alias || field.tech_name,
                    full_name: dataModelField.full_name || field.tech_name
                }
            })
            setSelectedImporter({...importer, fields: importerFields})
        }
    }

    const getAllImporters = () => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setIsLoading(false)
            setImporters([])
        }, responseTimeOut)

        setIsLoading(true)
        ImporterService
            .getImporters(50)
            .then(data => {
                clearTimeout(noResponse)
                setImporters(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                setImporters([])
            })
            .finally(setIsLoading(false))   
    }

    const handleAddImporterClick = (form) => {
        const importer = {}
        importer.name = form.templateName
        importer.meta = {}
        importer.meta.data_model_id = form.dataModelID
        importer.fields = form.fields
                                .filter(field => field.columns.length)
                                .map(field => { return {
                                        tech_name: field.tech_name,
                                        columns: field.columns.map(column => {return {index: Number(column)}})
                                    }
                                })
        ImporterService
            .createImporter(importer)
            .then(() => {
                getAllImporters()
                setIsCreating(false)
                setIsEditing(false)
                toast.success('Шаблон импорта успешно сохранен', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            })
            .catch(error => {
                showErrorToast(error, 'saving', '')
            })
    }

    const handleEditImporterClick = (form) => {
        const importer = {}
        importer.id = form.importerID
        importer.name = form.templateName
        importer.meta = {}
        importer.meta.data_model_id = form.dataModelID
        importer.fields = form.fields
                                .filter(field => field.columns.length)
                                .map(field => { return {
                                        tech_name: field.tech_name,
                                        columns: field.columns.map(column => {return {index: Number(column)}})
                                    }
                                })
        ImporterService
            .updateImporter(importer)
            .then(() => {
                getAllImporters()
                setSelectedImporter(null)
                setIsCreating(false)
                setIsEditing(false)
                toast.success('Шаблон импорта успешно сохранен', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            })
            .catch(error => {
                showErrorToast(error, 'saving', '')
            })
    }

    const handleCreateModeClick = () => {
        setIsCreating(true)
        setIsEditing(false)
    }

    const handleEditModeClick = () => {
        setIsCreating(false)
        setIsEditing(true)
    }

    const handleCancelClick = () => {
        setIsCreating(false)
        setIsEditing(false)
    }

    const handleDeleteImporterClick = () => {
        DialogTabStore.setDialogTabIsOpen(false)

        const noResponse = setTimeout(() => {
            toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        ImporterService
            .deleteImporter(selectedImporter.id)
            .then(() => {
                clearTimeout(noResponse)
                getAllImporters()
                setSelectedImporter(null)
                setIsCreating(false)
                setIsEditing(false)
                toast.success('Шаблон импорта успешно удален', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'deleting', '')
            })
    }

    const getDataModels = (setDataModelsList) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setIsLoading(false)
            setImporters([])
        }, responseTimeOut)
        setIsLoading(true)
        DocumentService
            .getDataModels(JSON.stringify(FilterStore.selectedFilters))
            .then(data => {
                clearTimeout(noResponse)
                setDataModelsList && setDataModelsList(data)
                docStore.setDataModels(data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
            .finally(setIsLoading(false))
    }

    useEffect( () => {
        getDataModels(setDataModelsList)
        getAllImporters()
    }, [])

    useEffect(()=>{
        getDataModels()
    }, [FilterStore.selectedFilters])

    if (isLoading || !importers) {
        return  <Spinner/>
    }

    return (
        <>
            <PageTitle title={'Шаблоны импорта'} />
            <div id='importer-page' className='tw-grid tw-grid-cols-3 tw-gap-x-8 tw-max-h-full tw-h-full'>
                <div id='importer-list' className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <ImporterMenu
                        activeItem={selectedImporter}
                        onAddItemClick={handleCreateModeClick}
                        onEditItemClick={handleEditModeClick}
                    />
                    <ImporterList
                        list={importers}
                        activeItem={selectedImporter}
                        onItemClick={handleItemClick}
                        dataModelsList={dataModelsList}
                    />
                </div>
                <div id='importer-column' className='tw-col-span-2 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-flex tw-flex-col'>
                    <div id='importer-header' className='tw-h-12 tw-flex tw-items-center tw-justify-between tw-border-b-2 tw-border-gray-400 tw-px-4 tw-py-2'>
                        <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Информация о шаблоне</p>
                    </div>
                    { selectedImporter && !isCreating && !isEditing &&
                        <ImporterInfo
                            activeItem={selectedImporter}
                            dataModel={importerDataModel}
                        />
                    }
                    { (isCreating || isEditing) &&
                        <ImporterForm 
                            editMode={isEditing}
                            importer={selectedImporter}
                            dataModel={importerDataModel}
                            onSubmitClick={isEditing ? handleEditImporterClick: handleAddImporterClick}
                            onCancelClick={handleCancelClick}
                        />
                    }
                </div>
            </div>
            <Tooltip id="importer-editor-tooltip" place="top"/>
            <DialogTab
                parentName='ImporterListContainer'
                dialogTabFunction={handleDeleteImporterClick}
            />
        </>
    )
}

export default observer(ImporterListContainer)