import React, { useState } from 'react'
import { Tab } from '@headlessui/react'
import { DocumentArrowDownIcon, PlayCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'
import Spinner from '../../../assets/Spinner'
import FileSelectButton from '../../main_page/controller/common/buttons/FileSelectButton'
import ViewFileContainer from '../../main_page/controller/data_object/view/ViewFileContainer'
import { handleFileDownload } from '../../../functions/fileHandlers'

function checkFileType(file) {
    const fileTypes = ['pdf', 'mp4', 'webm']
    return fileTypes.includes(file?.metadata?.extension)
}

/**
 * Визуальный компонент отображает файлы выбранной записи 
 * 
 * @param {Object[]} files Список файлов, относящихся к выбранной записи таблицы
 * @param {Boolean} readOnly Признак "только чтение" для файлов
 * @param {Function} onFileSelect Обработчик клика мыши на кнопке выбора файла
 * [handleFileSelect](./functions_fileHandlers.js.html#line41)
 * @param {Function} onFileRemove Обработчик клика мыши на кнопке удаления файла
 * [handleFileRemove](./functions_fileHandlers.js.html#line88)
 * @returns {HTMLDivElement} Html-разметку файлов выбранной записи 
 * с использованием визуальных компонентов {@link FileSelectButton}, {@link Spinner}
 * 
 * @see [Вызов компонента](./components_tabs_DataObjectTabs.js.html#line124)
 */
const FileTabPanel = ({list, readOnly, onFileSelect, onFileRemove}) => {
    const [isViewPanelOpen, setIsViewPanelOpen] = useState(false)
    const [viewedFile, setViewedFile] = useState(null)

    const handleViewPanelCloseClick = () => {
        setIsViewPanelOpen(false)
    }

    const handleFileView = (file) => {
        setViewedFile(file)
        setIsViewPanelOpen(true)
    }

    return (
        <>
            <Tab.Panel
                className='tw-h-full tw-w-full tw-px-4 tw-py-2 tw-ring-white 
                tw-ring-opacity-60 tw-ring-offset-2 focus:tw-outline-none focus:tw-ring-offset-0'
            >
                { !readOnly &&
                    <FileSelectButton
                        onFileSelect={onFileSelect}
                    />
                }
                <ul className=''>
                    {list.map((file, index) => (
                        <div key={index} className='tw-flex tw-flex-row tw-justify-start tw-items-center tw-py-1 tw-border-t'>
                            <li
                                className="tw-relative tw-rounded-md tw-pr-3 tw-py-1 tw-text-sm tw-font-medium tw-leading-5 tw-truncate"
                                data-tooltip-id="file-tab-tooltip" data-tooltip-content={file.metadata.name + '.' + file.metadata.extension} data-tooltip-delay-show={1000}

                            >
                                &#8226;{' '}{file.metadata.name}.{file.metadata.extension}
                            </li>
                            {file.isLoading
                                ?
                                    <Spinner size='small' />
                                :
                                    <div className='tw-flex tw-flex-row tw-items-center tw-gap-x-1 tw-px-1'>
                                        { checkFileType(file) &&
                                            <button 
                                                className='tw-text-gray-400 hover:tw-text-gray-600 focus-visible:tw-text-gray-600 focus-visible:tw-outline 
                                                        focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600 focus-visible:tw-rounded-sm'
                                                data-tooltip-id="file-tab-tooltip" data-tooltip-content="Просмотреть" data-tooltip-delay-show={500}
                                                type='button'
                                                onClick={() => handleFileView(file)}
                                            >
                                                <PlayCircleIcon className="tw-w-6 tw-h-6" aria-hidden="true"/>
                                            </button>
                                        }
                                        <button 
                                            className='tw-text-gray-500 hover:tw-text-gray-800 focus-visible:tw-text-gray-600 focus-visible:tw-outline 
                                                    focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600 focus-visible:tw-rounded-sm'
                                            data-tooltip-id="file-tab-tooltip" data-tooltip-content="Скачать" data-tooltip-delay-show={500}
                                            type='button'
                                            onClick={() => handleFileDownload(file)}
                                        >
                                            <DocumentArrowDownIcon className="tw-w-6 tw-h-6" aria-hidden="true"/>
                                        </button>
                                        { !readOnly &&
                                            <button 
                                                className='tw-text-gray-500 hover:tw-text-red-600 focus-visible:tw-text-red-600 focus-visible:tw-outline 
                                                        focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-red-600 focus-visible:tw-rounded-sm'
                                                data-tooltip-id="file-tab-tooltip" data-tooltip-content="Удалить" data-tooltip-delay-show={500}
                                                type='button'
                                                onClick={() => onFileRemove(file)}
                                            >
                                                <TrashIcon  className="tw-w-6 tw-h-6" aria-hidden="true"/>
                                            </button>
                                        }
                                    </div>
                            }
                        </div>
                    ))}
                </ul>
                <Tooltip id="file-tab-tooltip" place="top-start" className="tw-max-w-xl"/>
            </Tab.Panel>
            <ViewFileContainer
                isOpen={isViewPanelOpen}
                file={viewedFile}
                onCloseClick={handleViewPanelCloseClick}
            />
        </>
    )
}

export default FileTabPanel