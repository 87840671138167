import { Fragment, useContext, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { FunnelIcon } from '@heroicons/react/20/solid'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import EditFilters from './EditFilters'
import { runInAction } from 'mobx'


/**
 * Визуальный компонент отображает меню возможных фильтров
 * 
 * @param {String} column Поле
 * @param {Boolean} author Признак наличия поля автор
 * @param {Object} object Объект с массивом полей
 * @param {Boolean} data Признак нахождения поля в атрибуте data
 * @param {Object} filterStore Экземпляр хранилища информации о применяемых фильтрах
 * 
 * @returns {JSXElement} Визуальный компонент Popover из библиотеки {@link https://headlessui.com/|@headlessui}
 * 
 * @see [Вызов компонента DirectoryFieldsList](./components_main_page_controller_common_panels_DirectoryFieldsList.js.html#line291)
 * [RecordNotesTable,](./components_main_page_controller_data_object_tabs_notes_RecordNotesTable.js.html#line119) 
 * [RecordNotesTable,](./components_main_page_controller_data_object_tabs_notes_RecordNotesTable.js.html#line135)
 * [RecordNotesTable](./components_main_page_controller_data_object_tabs_notes_RecordNotesTable.js.html#line151)
 */
const FilterMenu = ({column, author, object, data, filterStore}) => {
    const { FilterStore } = useContext(Context)
    const filtersStore = filterStore ? filterStore : FilterStore
    
    const [isFiltersFormOpen, setIsFiltersFormOpen] = useState(false)
    const [editColumnName, setEditColumnName] = useState(null)
    const [left, setLeft] = useState(null)

    const columnFilter = filtersStore.filteredFields.find(field => field.name === column.tech_name)

    const onSetFilter = (operator, filterType) => {
        let filteredFields = filtersStore.filteredFields.map(field => {
                if(field.name === column.tech_name && filterType !== 'deleted')
                    runInAction(() => {
                        field.active = true
                    })
                return field
            })
        let filters

        if (column.tech_name === "files") {
            filters = [{
                property: 'system_data["files"]',
                value: [null, []],
                operator: operator
            }]
        } else if (column.tech_name === "author_id") {
            filters = [{
                property: 'author_id',
                value: [null, []],
                operator: operator
            }]
        } else if (filterType === 'deleted') {
            filters = [{
                property: 'system_data.deletion_mark', 
                value: [false, null], 
                operator: operator
            }]
        } else {
            if (column?.validator_type === "one") {
                filters = [{
                    property: 'data["' + column.rule_id + '"].presentations',
                    value: null,
                    operator: operator === 'in' ? 'eq' : 'neq'
                }]
            } else {
                filters = [{
                    property: data? 'data["' + column.tech_name + '"].value' : column.tech_name,
                    value: filterType === "bool"? true: [null, []],
                    operator: operator
                }]
            }
        }
        filtersStore.setSelectedFilters(filters)
        filtersStore.setFilteredFields(filteredFields)
    }

    const onEditFilters = () => {
        if (column.tech_name === "author_id") {
            setEditColumnName("Автор")
        } else {
            let editColumnName = object.fields.filter(item => item.tech_name === column.tech_name)
            setEditColumnName(editColumnName[0].alias)
        }
        setIsFiltersFormOpen(true)
    }

    return (
        <>
            <Popover 
                className='tw-absolute tw-top-1 tw-right-2 tw-inset-y-auto tw-rounded-md tw-p-0.5 tw-border-0 
                            tw-bg-white tw-text-gray-600 hover:tw-text-gray-900 hover:tw-border-gray-200 hover:tw-bg-gray-200'
            >
                <Popover.Button className={`focus-visible:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 
                    focus-visible:tw-ring-opacity-50 focus-visible:tw-rounded-sm ${columnFilter && columnFilter.active 
                        ? 'tw-text-sky-600 hover:tw-text-sky-500' : 'tw-text-gray-600 hover:tw-text-gray-900'}
                    `}
                >
                    <FunnelIcon 
                        onClick={(e) => setLeft(window.event.clientX)}
                        className='tw-h-4 tw-w-4' aria-hidden="true"
                    />
                </Popover.Button>
                <Transition
                    as={Fragment}
                    enter="tw-transition tw-ease-out tw-duration-200"
                    enterFrom="tw-opacity-0 tw-translate-y-1"
                    enterTo="tw-opacity-100 tw-translate-y-0"
                    leave="tw-transition tw-ease-in tw-duration-150"
                    leaveFrom="tw-opacity-100 tw-translate-y-0"
                    leaveTo="tw-opacity-0 tw-translate-y-1"
                >
                    <Popover.Panel style={{left: left}} className={`tw-fixed tw-z-10  tw-mt-3 tw-max-w-sm tw--translate-x-full tw-transform tw-px-4 sm:tw-px-2 lg:tw-max-w-3xl`}>
                        <div className="tw-overflow-hidden tw-rounded-lg tw-shadow-xl tw-ring-1 tw-ring-black tw-ring-opacity-20">
                            <div className="tw-flex tw-flex-col tw-justify-start tw-items-center tw-bg-white tw-px-1 tw-py-2 tw-text-sm tw-font-normal">
                                <button
                                    className="tw-w-full tw-px-2 tw-py-1 hover:tw-bg-gray-200 tw-text-left 
                                            focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-50"
                                    onClick={() => {onSetFilter('in')}}
                                >
                                    Пусто
                                </button>
                                <button
                                    className="tw-w-full tw-px-2 tw-py-1 hover:tw-bg-gray-200 tw-text-left 
                                            focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-50"
                                    onClick={() => {onSetFilter('nin')}}
                                >
                                    Не пусто
                                </button>
                                
                                {column?.validator_type === "bool" &&
                                    <>
                                        <button
                                            className="tw-w-full tw-px-2 tw-py-1 hover:tw-bg-gray-200 tw-text-left 
                                                    focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-50"
                                            onClick={() => {onSetFilter('eq', "bool")}}
                                        >
                                            Верно
                                        </button>
                                        <button
                                            className="tw-w-full tw-px-2 tw-py-1 hover:tw-bg-gray-200 tw-text-left 
                                                    focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-50"
                                            onClick={() => {onSetFilter('neq', "bool")}}
                                        >
                                            Не верно
                                        </button>
                                    </>
                                }

                                <button
                                    className="tw-w-full tw-px-2 tw-py-1 hover:tw-bg-gray-200 tw-text-left 
                                            focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-50"
                                    onClick={() => {onSetFilter('in', "deleted")}}
                                >
                                    Активные
                                </button>
                                <button
                                    className="tw-w-full tw-px-2 tw-py-1 hover:tw-bg-gray-200 tw-text-left 
                                            focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-50"
                                    onClick={() => {onSetFilter('nin', "deleted")}}
                                >
                                    Удаленные
                                </button>
                                    
                                <button
                                    className="tw-w-full tw-px-2 tw-py-1 hover:tw-bg-gray-200 tw-text-left 
                                            focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-50"
                                    onClick={() => {filtersStore.clearSelectedFilter(column.tech_name, column.rule_id)}}
                                >
                                    Сбросить
                                </button>
                                {(column.tech_name !== "files" && column?.validator_type !== "bool") && 
                                    <>
                                        <hr className='tw-w-full tw-my-1 tw-border-gray-300'/>
                                        <button
                                            className="tw-w-full tw-px-2 tw-py-1 hover:tw-bg-gray-200 tw-text-left 
                                                    focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-50"
                                            onClick={onEditFilters}
                                        >
                                            Настроить фильтр...
                                        </button>
                                        <hr/>
                                    </>
                                }
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
            <EditFilters 
                editColumnName = {editColumnName}
                isOpen={isFiltersFormOpen}
                onCloseClick={setIsFiltersFormOpen}
                author={author}
                object={object}
                data={data}
                filterStore={filterStore}
            />
        </>
    )
}

export default observer(FilterMenu)