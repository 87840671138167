import React, { useContext, useRef } from 'react'
import { Context } from '../../../../../..'
import DataObjectInfo from '../../../data_object/info/DataObjectInfo'
import DirectoryFieldsList from './DirectoryFieldsList'
import DataObjectTabs from '../../../../../tabs/DataObjectTabs'
import { observer } from 'mobx-react-lite'
import PanelHorizontalDivider from '../../dividers/PanelHorizontalDivider'

/**
 * Визуальный компонент отображает список записей выбранной таблицы, а так же файлы, сообщения и вложенные таблицы выбранной записи 
 * 
 * @param {Function} onItemClick Обработчик клика мыши на элементе списка
 * [handleDataObjectClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line106)
 * [handleDataObjectClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line123),
 * @param {Function} onItemDoubleClick Обработчик двойного клика мыши на элементе списка
 * [handleObjectSelect](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line316),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line68),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line96),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line62),
 * [handleDoubleClick](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line97),
 * [handleDoubleClick](./components_main_page_controller_field_FieldForm.js.html#line129),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line95),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line59),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line65),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line94),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line72),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line127),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line87),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line59),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line79),
 * [handleDoubleClick](./components_main_page_controller_personal_data_PersonalDataContainer.js.html#line67),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line70),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_form_significanceIndicators_SignificanceIndicatorsForm.js.html#line79),
 * [handleValueChange](./components_tabs_nested_tables_NestedTableFieldsList.js.html#line368)
 * @param {Object} selectedDataObject Выбранная запись таблицы
 * @param {Object} selectedFullDataModel Выбранная таблица
 * @param {Object[]} dataObjects Массив записей таблицы
 * @param {Object} selectedNestedDataModel Выбранная вложенная таблица
 * @param {Object[]} nestedDataModels Массив вложенных таблиц
 * @param {Function} setSelectedNestedDataModel Изменение выбранной вложенной таблицы
 * [setSelectedNestedDataModel](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line50)
 * [setSelectedNestedDataModel](./components_main_page_controller_common_panels_info_DirectoryContainer.js.html#line107),
 * @param {Function} setNestedDataModels Изменение в массиве вложенных таблицы
 * [setNestedDataModels](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line47)
 * [setNestedDataModels](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line104),
 * @param {Function} onSortClick Обработчик клика мыши на элементе изменения сортировки списка
 * [handleSortClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line352)
 * [handleSortClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line212),
 * @param {Function} onFetchData Обработчик события достижения конца списка
 * [setIsFetchingData](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line53)
 * [setIsFetchingData](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line110),
 * @param {Object[]} chosenDataObjectsList Массив выбранных записей
 * @param {Boolean} isNestedLoading Признак загрузки вложенных таблиц
 * @param {Object} filterStore Экземпляр хранилища информации о применяемых фильтрах
 * @param {Object} recordNotesStore Экземпляр хранилища информации о сообщениях
 * @param {Boolean} isChosenObjectDuplicationForbidden Признак запрета повторного добавления записи
 * @param {Function} onHierarchyClick Обработчик клика мыши на элементе раскрытия/закрытия родительской записи
 * [handleHierarchyClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line366)
 * [handleHierarchyClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line226),
 * 
 * @returns {JSXElement} Html-разметку списка записей из таблицы, а так же файлы, сообщения и вложенные таблицы выбранной записи 
 * с использованием визуальных компонентов {@link DataObjectInfo}, {@link DirectoryFieldsList}, {@link PanelHorizontalDivider}, {@link DataObjectTabs}
 * 
 * @see [Вызов компонента DataObjectListContainer,](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line568)
 * [DirectoryContainer](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line474)
 */
const DirectoryPanel = ({onItemClick, onItemDoubleClick, selectedDataObject, selectedFullDataModel, dataObjects, selectedNestedDataModel,
        nestedDataModels, setSelectedNestedDataModel, setNestedDataModels, onSortClick, onFetchData, chosenDataObjectsList, isNestedLoading, 
        filterStore, recordNotesStore, isChosenObjectDuplicationForbidden, onHierarchyClick}) => {

    const {docStore} = useContext(Context)

    const defaultGridHeight = 'calc(100% - 14rem)'
    const defaultTabsHeight = '10rem'

    const savedGridHeight = sessionStorage.getItem('savedGridHeight')
    const savedTabsHeight = sessionStorage.getItem('savedTabsHeight')  
    const gridHeight = savedGridHeight || defaultGridHeight
    const tabsHeight = savedTabsHeight || defaultTabsHeight

    const gridElement = useRef(null)
    const tabsElement = useRef(null)

    return (
        <>
            <div 
                style={{
                    height: gridHeight,
                    backgroundColor: 'white',
                    borderBottomLeftRadius: '0.375rem',
                    borderBottomRightRadius: '0.375rem',
                    overflow: 'hidden',
                }}
                ref={gridElement}
            >
                { dataObjects && selectedFullDataModel && selectedFullDataModel.fields &&
                    ( docStore.isDetailView 
                        ?   
                            <DataObjectInfo
                                selectedDataObject={selectedDataObject}
                            />
                        :
                            <DirectoryFieldsList
                                dataModel={selectedFullDataModel}
                                selectedDataObject={selectedDataObject}
                                dataObjects={dataObjects}
                                onItemClick={onItemClick}
                                onItemDoubleClick={onItemDoubleClick}
                                onSortClick={onSortClick}
                                onFetchData={onFetchData}
                                chosenDataObjectsList={chosenDataObjectsList}
                                filterStore={filterStore}
                                isChosenObjectDuplicationForbidden={isChosenObjectDuplicationForbidden}
                                onHierarchyClick={onHierarchyClick}
                            />
                    )
                }
            </div>
            <PanelHorizontalDivider 
                topElement={gridElement}
                bottomElement={tabsElement}
            />
            <div
                id='data-files-column'
                style={{
                    height: tabsHeight,
                    overflow: 'hidden',
                }}
                ref={tabsElement}
            >
                <DataObjectTabs
                    files={docStore.attachedFiles}
                    readOnly={true}
                    onFileSelect={null}
                    onFileRemove={null}
                    setSelectedNestedDataModel={setSelectedNestedDataModel}
                    nestedDataModels={nestedDataModels}
                    selectedNestedDataModel={selectedNestedDataModel}
                    setNestedDataModels={setNestedDataModels}
                    isDocumentPage={true}
                    isNestedLoading={isNestedLoading}
                    panel={null}
                    recordNotesStore={recordNotesStore}
                />
            </div>
        </>
    )
}

export default observer(DirectoryPanel)