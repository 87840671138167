import React from 'react'
import PageTitle from '../main_page/controller/common/panels/PageTitle'
import { policyText } from './policy'
import parse from 'html-react-parser'

/**
 * Визуальный компонент отображает информацию о политике обработки и конфиденциальности персональных данных пользователей
 * 
 * @returns {JSXElement} Html-разметку для информации о политике обработки и конфиденциальности персональных данных пользователей
 * с использованием визуальных компонентов {@link PageTitle}
 */
const PolicyInfo = () => {
    return (
        <>
            <PageTitle title='ПОЛИТИКА ОБРАБОТКИ И КОНФИДЕНЦИАЛЬНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ'/>
            <div className='tw-px-4'>
                {parse(policyText)}
            </div>
        </>
    )
}

export default PolicyInfo