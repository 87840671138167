import React, { useContext } from 'react'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'react-tooltip'

/**
 * Визуальный компонент отображает кнопки переключения между этапами ПДн
 * @param {Function} onBackClick Обработчик клика мыши для возвращения к предыдущему этапу
 * [handleBackClick](./components_main_page_controller_personal_data_info_PDataDirectoriesInfo.js.html#line42),
 * [handleBackClick](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line194),
 * [handleBackClick](./components_main_page_controller_personal_data_form_PDataPrintOrderForm.js.html#line68),
 * [handleBackClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line33),
 * [handleBackClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line33),
 * [handleBackClick](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line86),
 * [handleBackClick](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line124),
 * [handleBackClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line29),
 * [handleBackClick](./components_main_page_controller_personal_data_form_PDataPrintTaskForm.js.html#line85)
 * @param {Function} onForwardClick Обработчик клика мыши для перехода к следующему этапу
 * [handleForwardClick](./components_main_page_controller_personal_data_info_PDataDirectoriesInfo.js.html#line45),
 * [handleForwardClick](./components_main_page_controller_personal_data_form_PDataEmployeesForm.js.html#line23),
 * [handleForwardClick](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line215),
 * [handleForwardClick](./components_main_page_controller_personal_data_form_PDataPrintOrderForm.js.html#line72),
 * [handleForwardClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line38),
 * [handleForwardClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line38),
 * [handleForwardClick](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line90),
 * [handleForwardClick](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line128),
 * [handleForwardClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line34),
 * [handleForwardClick](./components_main_page_controller_personal_data_form_PDataPrintTaskForm.js.html#line89)
 * @param {Boolean} disabled Признак возможности перехода к следующему этапу процесса обработки ПДн
 * 
 * @returns {HTMLDivElement} Html-разметку кнопок переключения между этапами ПДн
 * 
 * @see [Вызов компонента PDataDirectoriesInfo,](./components_main_page_controller_personal_data_info_PDataDirectoriesInfo.js.html#line137)
 * [PDataOrganizationForm,](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line404)
 * [PDataPrintOrderForm,](./components_main_page_controller_personal_data_form_PDataPrintOrderForm.js.html#line179)
 * [ISPDataListContainer,](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line217)
 * [PDataProcessListContainer,](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line227)
 * [PDataAddQuestionForm,](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line228)
 * [PDataRKNForm,](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line344)
 * [PData3PartyListContainer,](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line209)
 * [PDataPrintTaskForm,](./components_main_page_controller_personal_data_form_PDataPrintTaskForm.js.html#line196)
 * [PDataEmployeesForm](./components_main_page_controller_personal_data_form_PDataEmployeesForm.js.html#line404)
 */
const PDataStageButtons = ({onBackClick, onForwardClick, disabled}) => {
    const { personalDataStore } = useContext(Context)

    return (
        <div className='tw-flex tw-justify-between tw-items-center tw-gap-x-4 tw-w-full tw-mx-auto tw-mt-1 tw-px-8 tw-py-2 tw-border-t tw-border-gray-400'>
            { personalDataStore.stage.data['id_of_stage'].value > 1
            ?
                    <button
                        className='tw-flex tw-flex-row tw-items-start tw-gap-x-2 
                                tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                                hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        onClick={onBackClick}
                    >
                        <ChevronDoubleLeftIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                        <span>Назад</span>
                    </button>
                :
                    <div></div>
            }
            { personalDataStore.stage.data['id_of_stage'].value < personalDataStore.allStages.length
                ?
                    <div className='tw-flex tw-flex-row tw-items-center tw-gap-x-2'>
                        { disabled && personalDataStore.stage.data['hint_for_next_stage'].value &&
                            <QuestionMarkCircleIcon 
                                className="tw-h-6 tw-w-6 tw-text-red-400 focus:tw-rounded-full focus:tw-outline-red-400" aria-hidden="true"
                                data-tooltip-id="stage-help-tooltip" data-tooltip-content={personalDataStore.stage.data['hint_for_next_stage'].value}
                            />
                        }
                        <button 
                            className='tw-flex tw-flex-row tw-items-start tw-gap-x-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  
                                    tw-bg-gray-700 tw-text-white hover:tw-bg-gray-500 disabled:tw-bg-gray-300  disabled:tw-border-gray-300
                                    focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                    disabled={disabled}
                            onClick={(e) => {e.preventDefault(); onForwardClick()}}
                        >
                            <span>Далее</span>
                            <ChevronDoubleRightIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                        </button>
                    </div>
                :
                    <div></div>
            }
            <Tooltip id="stage-help-tooltip" place="top-start"/>
        </div>
    )
}

export default observer(PDataStageButtons)