import { useRef } from 'react'
// Функция создает массив заголовков столбцов таблицы для вывода в компоненте
export function createHeaders(headers) {
    return headers.map((item) => ({
      text: item.alias,
      column: item.column,
      type: item.type,
      ref: useRef()
    }))
}
