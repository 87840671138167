import React, { useContext, useEffect, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline'
import { getReferenceTypeName, getValueTypeName, getFieldDefaultValue, dataModelTypes, menuTooltipTimeOut } from '../../../../../config/constTypes'
import { Context } from '../../../../..'
import DataTypeListBox from '../../common/inputs/DataTypeListBox'
import DataSwitch from '../../common/inputs/DataSwitch'
import FieldMenu from '../../field/menu/FieldMenu'
import FieldForm from '../../field/FieldForm'
import { Tooltip } from 'react-tooltip'
import { observer } from 'mobx-react-lite'
import { isScrolledIntoView } from '../../../../../functions/isScrolledIntoView'


/**
 * Визуальный компонент отображает форму для создания/редактирования таблицы
 * @param {Boolean} editMode Режим изменения таблицы (true - редактирование, false - создание)
 * @param {String} name Имя таблицы
 * @param {Object[]} fields Текущий массив полей таблицы
 * @param {Function} onEditField Обработчик создания нового/редактирования существующего поля
 * [handleFieldEdition](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line23),
 * [handleFieldEdition](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line28)
 * @param {Function} onHideFieldClick Обработчик клика мыши на кнопке скрытия поля
 * [handleHideFieldClick](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line40),
 * [handleHideFieldClick](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line45)
 * @param {Function} onRemoveFieldClick Обработчик клика мыши на кнопке удаления поля
 * [handleRemoveFieldClick](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line78),
 * [handleRemoveFieldClick](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line83)
 * @param {Function} onUpFieldClick Обработчик клика мыши на кнопке поднятия поля вверх в списке
 * [handleUpFieldClick](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line56),
 * [handleUpFieldClick](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line61)
 * @param {Function} onDownFieldClick Обработчик клика мыши на кнопке опускания поля вниз в списке
 * [handleDownFieldClick](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line67),
 * [handleDownFieldClick](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line72)
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке сохранения данных
 * [handleSubmitClick](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line83),
 * [handleSubmitClick](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line88)
 * @param {Function} onCancelClick Обработчик клика мыши на кнопке отмены создания таблицы
 * [handleCancelClick](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line106),
 * [handleCancelClick](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line115)
 * @returns {HTMLFormElement} Html-разметку формы таблицы с именем и списком полей с использованием визуальных компонентов
 * {@link DataTypeListBox}, {@link FieldMenu}, {@link FieldForm}
 * 
 * @see [Вызов компонента AddDataModelContainer,](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line125)
 * [EditDataModelContainer](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line155)
 */
const DataModelForm = ({editMode, dataModel, fields, onEditField, onHideFieldClick, onRemoveFieldClick, onUpFieldClick,
                            onDownFieldClick, onSubmitClick, onCancelClick}) => {
    const { docStore, FieldStore, userStore } = useContext(Context)

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        formState: { errors, isValid, isSubmitting },
    } = useForm()

    register('modelType', { 
        required: false,
        value: dataModel && dataModel.type
                    ?   dataModelTypes.find(item => item.value === dataModel.type)
                    :   dataModelTypes[0],
        shouldUnregister: true,
    })

    const watchType = watch('modelType', 
                            dataModel && dataModel.type
                                ?   dataModelTypes.find(item => item.value === dataModel.type)
                                :   dataModelTypes[0]
    )

    const moveUpDescription = (index) => {
        const currentValue = getValues('data.' + index + '.description')
        const previousValue = getValues('data.' + (index - 1) + '.description')
        setValue('data.' + index + '.description', previousValue)
        setValue('data.' + (index - 1) + '.description', currentValue)
        onUpFieldClick(index)
    }

    const moveDownDescription = (index) => {
        const currentValue = getValues('data.' + index + '.description')
        const nextValue = getValues('data.' + (index + 1) + '.description')
        setValue('data.' + index + '.description', nextValue)
        setValue('data.' + (index + 1) + '.description', currentValue)
        onDownFieldClick(index)
    }

    const keyDown = useCallback((e) => {
        if (e.code === 'ArrowUp' && e.ctrlKey && FieldStore.selectedField.order > 0) {
            moveUpDescription(FieldStore.selectedField.order)
        }
        if (e.code === 'ArrowDown' && e.ctrlKey && FieldStore.selectedField.order < (fields.length - 1)) {
            moveDownDescription(FieldStore.selectedField.order)
        }
    }, [FieldStore.selectedField, fields])

    const removeListeners = useCallback(() => {
        window.removeEventListener("keydown", keyDown)
    }, [keyDown])

    useEffect(() => {
        if (FieldStore.selectedField?.id) {
            const element = document.getElementById(`data-model-column-${FieldStore.selectedField.id}`) 
            if (element && !isScrolledIntoView(element))
                element.scrollIntoView({ behavior: 'smooth' })
        }
    }, [FieldStore.selectedField?.id])

    useEffect(() => {
        if (FieldStore.selectedField) {
            window.addEventListener("keydown", keyDown)
        }
    
        return () => {
            removeListeners()
        }
    }, [FieldStore.selectedField, keyDown, removeListeners])

    !editMode && isSubmitting && !isValid && toast.error('Заполните обязательное поле', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })

    return (
        <div className='tw-h-full tw-rounded-md tw-bg-white'>
            <div id='data-model-editor-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                <p className='tw-text-md tw-font-semibold'>Информация о таблице</p>
                <FieldMenu
                    onHideFieldClick={onHideFieldClick}
                    onRemoveFieldClick={onRemoveFieldClick}
                />
            </div>
            <form className='tw-h-[calc(100%_-_3rem)] tw-overflow-auto'>
                <div className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-block tw-mt-4 tw-px-4'>
                        <div className='tw-flex tw-flex-row tw-w-full tw-items-center'>
                            <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-700 tw-leading-6'>Название</label>
                            {errors.modelName && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                        </div>
                        <input
                            className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none
                                ${errors.modelName ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                            `}
                            {...register('modelName', { required: true, value: editMode ? dataModel.entity_name : ''})}
                        />
                    </div>
                    {! editMode &&
                        <div className='tw-block tw-px-4'>
                            <DataTypeListBox
                                label={'Тип'}
                                itemList={dataModelTypes}
                                selectedItem={watchType}
                                onItemChange={(e) => setValue('modelType', e)}
                                error={errors.validatorType}
                                selector={'value'}
                            />
                            <div className='tw-text-xs tw-italic tw-mt-2 tw-px-2'>
                                <span>Фиксированные поля: </span>
                                <span>
                                    { watchType.fixed_fields.length
                                        ?   watchType.fixed_fields.join(', ')
                                        :   'отсутствуют'
                                    }
                                </span>
                            </div>
                        </div>
                    }
                    { watchType.value === 'directory' &&
                        <div className='tw-block tw-px-4 tw-mt-2'>
                            <Controller
                                name='isHierarchical'
                                control={control}
                                shouldUnregister={true}
                                defaultValue={editMode && dataModel.allow_hierarchy !== undefined ? dataModel.allow_hierarchy : false}
                                render={({field}) =>
                                    <DataSwitch 
                                        isChecked={field.value}
                                        onClick={(e) => field.onChange(e)}
                                        label={'Наличие иерархии записей'}
                                    />
                                }
                            />
                        </div>
                    }
                    <div className='tw-flex tw-flex-col tw-grow tw-mt-2'>
                        <div className='tw-grid tw-grid-cols-12 tw-gap-x-1 tw-items-center tw-text-sm tw-py-2 tw-border-y'>
                            <div className='tw-col-span-3 tw-font-semibold tw-pl-4'>
                                <p>Поле</p>
                            </div>
                            <div className='tw-col-span-1 tw-font-semibold tw-pl-2'>
                                <p>Тип</p>
                            </div>
                            <div className='tw-col-span-1 tw-font-semibold tw-pl-2'>
                                <p className='tw-truncate'>Уникальное</p>
                            </div>
                            <div className='tw-col-span-1 tw-font-semibold tw-pl-2'>
                                <p className='tw-truncate'>Обязательное</p>
                            </div>
                            <div className='tw-col-span-1 tw-font-semibold tw-pl-2'>
                                <p className='tw-truncate'>По умолчанию</p>
                            </div>
                            <div className='tw-col-span-4 tw-font-semibold tw-pl-2'>
                                <p className='tw-truncate'>Описание</p>
                            </div>
                            <div className='tw-col-span-1 tw-font-semibold tw-pl-2 tw-flex tw-flex-row'>
                                <p className='tw-truncate'>Порядок</p>
                            </div>
                        </div>
                        <div className='tw-grow'>
                            {fields.length === 0 && 
                                <div className='tw-text-sm tw-italic tw-text-center tw-py-8'>
                                    Дополнительные поля отсутствуют
                                </div>
                            }
                            {fields.sort((a, b) => a.order - b.order).map((field, index) => 
                                <div 
                                    key={index}
                                    id={`data-model-column-${field.field_id}`}                   
                                    className={`tw-grid tw-grid-cols-12 tw-text-sm tw-py-2 tw-border-b tw-truncate hover:tw-cursor-pointer
                                                ${(FieldStore.selectedField?.field_id === field.field_id)
                                                    ? 'tw-text-white tw-bg-gray-500' 
                                                    : 'tw-text-gray-900 hover:tw-bg-gray-300 hover:tw-z-10'
                                                }`}
                                >
                                    <div
                                        className={`${field.hide ? 'tw-line-through tw-text-red-400' : ''} ${field.id ? 'tw-italic' : ''}
                                                    tw-col-span-3 tw-pl-4 hover:tw-cursor-pointer tw-truncate`}
                                        data-tooltip-id="data-model-form-tooltip" data-tooltip-content={field.alias} data-tooltip-delay-show={menuTooltipTimeOut}
                                        onClick={() => FieldStore.setSelectedField(field)}
                                    >
                                        {field.alias}
                                    </div>
                                    <div
                                        className={`${field.hide ? 'tw-line-through tw-text-red-400' : ''} ${ field.id ? 'tw-italic' : ''}
                                                    tw-col-span-1 tw-pl-2 hover:tw-cursor-pointer tw-truncate`}
                                        onClick={() => FieldStore.setSelectedField(field)}
                                    >
                                        {(field.type === 'reference' || field.type === 'include') ? getReferenceTypeName(docStore.dataModels, field.ref_model_ids) : getValueTypeName(field)}
                                    </div>
                                    <div
                                        className={`${field.hide ? 'tw-line-through tw-text-red-400' : ''} ${ field.id ? 'tw-italic' : ''}
                                                    tw-col-span-1 tw-pl-2 hover:tw-cursor-pointer tw-truncate`}
                                        onClick={() => FieldStore.setSelectedField(field)}
                                    >
                                        {field.unique ? 'Да' : 'Нет'}
                                    </div>
                                    <div
                                        className={`${field.hide ? 'tw-line-through tw-text-red-400' : ''} ${ field.id ? 'tw-italic' : ''}
                                                    tw-col-span-1 tw-pl-2 hover:tw-cursor-pointer tw-truncate`}
                                        onClick={() => FieldStore.setSelectedField(field)}
                                    >
                                        {field.mandatory ? 'Да' : 'Нет'}
                                    </div>
                                    <div
                                        className={`${field.hide ? 'tw-line-through tw-text-red-400' : ''} ${ field.id ? 'tw-italic' : ''}
                                                    tw-col-span-1 tw-pl-2 hover:tw-cursor-pointer tw-truncate`}
                                        onClick={() => FieldStore.setSelectedField(field)}
                                    >
                                        {getFieldDefaultValue(field)}
                                    </div>
                                    <div
                                        className={`${ field.hide ? 'tw-line-through tw-text-red-400' : ''} tw-col-span-4 tw-pl-2 tw-pr-4 hover:tw-cursor-pointer tw-truncate`}
                                    >
                                        <textarea
                                            className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 sm:tw-text-sm 
                                                focus-visible:tw-outline-none tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400
                                            `}
                                            {...register('data.' + index + '.description', { required: false, value: field.description || '' })}
                                        />
                                    </div>
                                    <div className='tw-col-span-1 tw-pl-2'>
                                        { FieldStore.selectedField && FieldStore.selectedField.field_id === field.field_id &&
                                            <button 
                                                className={`tw-ml-0.5 
                                                        ${FieldStore.selectedField.field_id === field.field_id
                                                            ?   'tw-text-white hover:tw-text-gray-100 disabled:tw-text-gray-500'
                                                            :   'tw-text-gray-500 hover:tw-text-gray-800 disabled:tw-text-white'}
                                                        focus-visible:tw-text-white focus-visible:tw-outline focus-visible:tw-outline-2
                                                        focus-visible:tw-outline-offset-2 focus-visible:tw-outline-white focus-visible:tw-rounded-sm
                                                        `}
                                                type='button'
                                                disabled={FieldStore.selectedField.order === 0}
                                                data-tooltip-id="data-model-form-tooltip" data-tooltip-content="Поднять выше (или Ctrl + стрелка вверх)" data-tooltip-delay-show={menuTooltipTimeOut}
                                                onClick={() => moveUpDescription(FieldStore.selectedField.order)}
                                            >
                                                <ArrowUpCircleIcon  className="tw-w-8 tw-h-8" aria-hidden="true"/>
                                            </button>
                                        }
                                        { FieldStore.selectedField && FieldStore.selectedField.field_id === field.field_id &&
                                            <button 
                                                className={`tw-ml-0.5 
                                                        ${FieldStore.selectedField.field_id === field.field_id
                                                            ?   ' tw-text-white hover:tw-text-gray-100 disabled:tw-text-gray-500 '
                                                            :   ' tw-text-gray-500 hover:tw-text-gray-800 disabled:tw-text-white '}
                                                        focus-visible:tw-text-white focus-visible:tw-outline focus-visible:tw-outline-2
                                                        focus-visible:tw-outline-offset-2 focus-visible:tw-outline-white focus-visible:tw-rounded-sm
                                                        `}
                                                type='button'
                                                disabled={FieldStore.selectedField.order === fields.length - 1}
                                                data-tooltip-id="data-model-form-tooltip" data-tooltip-content="Опустить ниже (или Ctrl + стрелка вниз)" data-tooltip-delay-show={menuTooltipTimeOut}
                                                onClick={() => moveDownDescription(FieldStore.selectedField.order)}
                                            >
                                                <ArrowDownCircleIcon  className="tw-w-8 tw-h-8" aria-hidden="true"/>
                                            </button>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                            <button 
                                className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white 
                                        hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600
                                        disabled:tw-border-gray-400 disabled:tw-bg-gray-400'
                                onClick={handleSubmit(onSubmitClick)}
                                disabled={!(!dataModel?.subgroup_id || dataModel?.subgroup_id == userStore.currentSubCompany.id)}
                            >
                                Сохранить
                            </button>
                            <button
                                className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                                        hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                onClick={onCancelClick}
                            >
                                Отменить
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <FieldForm onEditField={onEditField}/>
            <Tooltip id="data-model-form-tooltip" place="top"/>
        </div>
    )
}

export default observer(DataModelForm)
