import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import AppHoneyBadger from './AppHoneyBadger'
import { BrowserRouter } from 'react-router-dom'
import DocumentStore from './store/DocumentStore'
import UserStore from './store/UserStore'
import Env from './services/Env'
import 'react-tooltip/dist/react-tooltip.css'
import RecordNotesStore from './store/RecordNotesStore'
import AdminStore from './store/AdminStore'
import DialogTabStore from './store/DialogTabStore'
import FilterStore from './store/FilterStore'
import FieldStore from './store/FieldStore'
import CategorizingCIIStore from './store/CategorizingCIIStore'
import PersonalDataStore from './store/PersonalDataStore'
import EducationStore from './store/EducationStore'
import CompanyAdminStore from './store/CompanyAdminStore'
export const Context = createContext(null)
export const medoedImage = {}
const docStore = new DocumentStore()

/**
 * Точка входа в приложение. Содержит вызов основного компонента {@link AppHoneyBadger}
 */
new Env().load().then(() => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <BrowserRouter>
            <Context.Provider
                value={ {
                    docStore,
                    userStore: new UserStore(docStore),
                    categorizingCIIStore: new CategorizingCIIStore(),
                    personalDataStore: new PersonalDataStore(),
                    RecordNotesStore: new RecordNotesStore(),
                    AdminStore: new AdminStore(),
                    DialogTabStore: new DialogTabStore(),
                    FilterStore: new FilterStore(),
                    FieldStore: new FieldStore(),
                    EducationStore: new EducationStore(),
                    CompanyAdminStore: new CompanyAdminStore()
                }}
            >
                <AppHoneyBadger />
            </Context.Provider>
        </BrowserRouter>
    );
});



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
