import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { getReferenceValue } from '../../../../../config/constTypes'

/**
 * Визуальный компонент отображает поле выбора записи таблицы из выдвигающегося компонента, реализующего логику работы со структурами таблиц (моделью данных)
 * 
 * @param {Object} value Значение поля 
 * @param {Function} onChange Обработчик клика мыши при изменении значения поля {(e) => field.onChange(e)}
 * @param {Function} onSelectClick Обработчик клика мыши при выборе записи
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line79),
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line48),
 * [handleSelectClick](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line86),
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line52),
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line55),
 * [handleSelectClick](./components_main_page_controller_categorizing_cii_form_significanceIndicators_SignificanceIndicatorsForm.js.html#line74),
 * [handleEditCellClick](./components_tabs_nested_tables_NestedTableFieldsList.js.html#line244),
 * [onReferenceSelect](./components_main_page_controller_field_FieldForm.js.html#line119)
 * @param {Function} onClearClick Обработчик клика мыши для очистки поля
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line85)
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line55)
 * [handleClearClick](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line93)
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line59)
 * [handleClearClick](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line61)
 * [handleClearCellClick](./components_tabs_nested_tables_NestedTableFieldsList.js.html#line235)
 * [onDeleteClick](./components_main_page_controller_categorizing_cii_form_significanceIndicators_SignificanceIndicatorsForm.js.html#line124)
 * [onReferenceClear](./components_main_page_controller_field_FieldForm.js.html#line125)
 * @param {Boolean} isNested Признак нахождения поля в ячейке вложенной таблицы
 * @param {Boolean} isRowSelected Признак выделения строки вложенной таблицы
 * @param {Boolean} isCellSelected Признак выделения ячейки вложенной таблицы
 * @param {Boolean} readOnly Признак доступности поля только для чтения
 * @param {Boolean} isError Признак ошибки
 * 
 * @returns {HTMLDivElement} Html-разметку поля выбора записи таблицы
 * 
 * @see [Вызов компонента DataObjectForm,](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line157)
 * [FieldForm,](./components_main_page_controller_field_FieldForm.js.html#line151)
 * [FormFieldInput,](./components_form_fields_FormFieldInput.js.html#line100)
 * [NestedTableFieldsList,](./components_tabs_nested_tables_NestedTableFieldsList.js.html#line510)
 * [SignificanceIndicatorsForm](./components_main_page_controller_categorizing_cii_form_significanceIndicators_SignificanceIndicatorsForm.js.html#line244)
 */
const DataReferenceInput = ({value, onChange, onSelectClick, onClearClick, isNested, isRowSelected, isCellSelected, readOnly, isError}) => {
    return  (
        <div className={`${isNested ? 'tw-mt-1' : 'tw-rounded-md tw-shadow-sm'} tw-relative`}>
            <input
                type='text'
                className={`${!isNested && `tw-h-8 tw-rounded-md tw-border-0 tw-py-1.5 
                        tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 focus-visible:tw-border-gray-600
                        focus-visible:tw-ring-2 focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                        ${isError ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                    `}
                    tw-text-sm tw-w-full tw-text-gray-900 focus-visible:tw-outline-none tw-px-2 tw-overflow-hidden tw-truncate
                    ${!readOnly && 'tw-pr-8'}
                    ${isRowSelected && 'tw-bg-gray-300'}
                `}
                value={typeof value === 'string' ? value : getReferenceValue(value.value)}
                readOnly={true}
                onChange={onChange}
            />
             
            {!readOnly && isCellSelected &&
                <div 
                    className='tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 hover:tw-cursor-pointer'
                    onClick={value.value?.values?.length ? onClearClick : onSelectClick}
                >
                    { value.value?.values?.length
                        ?   <XMarkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        :   <MagnifyingGlassIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    }
                </div>
            }
        </div>
    )
}

export default DataReferenceInput