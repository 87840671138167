import React from 'react'


/**
 * Визуальный компонент отображает всплывающее уведомление об ошибках в формах
 * @param {Object} errors Массив ошибок
 * @param {Object[]} fields Массив полей
 * 
 * @returns {HTMLDivElement} Html-разметку всплывающего уведомления об ошибках в формах
 * 
 * @see [Вызов компонента CategorizingObjectsForm,](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line461)
 * [CommissionForm,](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line181)
 * [CompanyForm,](./components_admin_page_company_CompanyForm.js.html#line122)
 * [ISPDataForm,](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line348)
 * [OrganizationForm,](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line249)
 * [PData3PartyForm,](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line323)
 * [PDataAddQuestionForm,](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line184)
 * [PDataOrganizationForm,](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line364)
 * [PDataPrintOrderForm,](./components_main_page_controller_personal_data_form_PDataPrintOrderForm.js.html#line125)
 * [PDataPrintTaskForm,](./components_main_page_controller_personal_data_form_PDataPrintTaskForm.js.html#line142)
 * [PDataRKNForm,](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line304)
 * [ProcessesForm,](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line294)
 * [SignificanceIndicatorsForm,](./components_main_page_controller_categorizing_cii_form_significanceIndicators_SignificanceIndicatorsForm.js.html#line303)
 * [SimpleEmployeeForm,](./components_main_page_controller_common_forms_employees_form_SimpleEmployeeForm.js.html#line94)
 * [UserInfoForm,](./components_header_userInfo_UserInfoForm.js.html#line86)
 * [SubCompanyForm,](./components_company_admin_page_sub_company_SubCompanyForm.js.html#line123)
 */
const FormErrorToastPanel = ({errors, fields}) => {
    return (
        <div>
            Проверка значений завершилась неудачно!
            <br/><br/>
            { Object.entries(errors)
                .map(([key, value], index) => {
                    return  <p key={index}>
                                {'"' + fields.find(field => field.tech_name === key)?.alias + '": ' +
                                (value.type === 'required' 
                                    ?   'не заполнено обязательное поле'
                                    :   `некорректное значение поля. ${value.message && value.message.length ? value.message : ''}`
                                )}
                            </p>
                })
            }
        </div>
    )
}

export default FormErrorToastPanel