import React from 'react'
import ObjectsList from './ObjectsList'

/**
 * Визуальный компонент отображает панель со списком объектов
 * 
 * @param {String} label Заголовок списка
 * @param {Object[]} list Список существующих объектов
 * @param {Object} stage Текущий активный объект
 * @param {Boolean} isListSaved Признак сохранения всех объектов в списке
 * @param {Function} onAddClick Обработчик клика мыши на кнопке добавления нового объекта
 * [handleAddObjectClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line129),
 * [handleAddObjectClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line135),
 * [handleAddObjectClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line55)
 * @param {Function} onDeleteClick Обработчик клика мыши на кнопке удаления объекта
 * [handleAskForDeleteClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line77),
 * [handleAskForDeleteClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line81),
 * [handleAskForDeleteClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line72)
 * @param {Function} onJumpStageClick Обработчик клика мыши для перехода к произвольному объекту
 * @param {Boolean} isSearch Признак наличия строки поиска
 * 
 * @returns {JSXElement} Html-разметку панели со списком объектов
 * с использованием визуального компонента {@link ObjectList}
 *   
 * @see [Вызов компонента ISPDataListContainer,](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line176)
 * [PDataProcessListContainer,](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line188)
 * [PData3PartyListContainer](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line169)
 */
const ObjectListPanel = ({label, list, stage, isListSaved, onAddClick, onDeleteClick, onJumpStageClick, isSearch}) => {
    return (
        <div id='object-list-panel' className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden tw-flex tw-flex-col tw-justify-between'>
            <div id='object-list' className='tw-h-[calc(100%_-_3rem)]'>
                <div id='object-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                    <p className='tw-text-md tw-font-semibold'>{label}</p>
                </div>
                { list.length > 0 &&
                    <ObjectsList
                        objectsList={list} 
                        objectsListStage={stage}
                        isDelete={true}
                        onDeleteClick={onDeleteClick}
                        onJumpStageClick={onJumpStageClick}
                        isSearch={isSearch}
                    />
                }
            </div>                                
            <button 
                disabled={!(isListSaved || !list.length)}
                className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700
                            tw-bg-gray-700 tw-text-white hover:tw-bg-gray-600 hover:tw-border-gray-600 tw-w-full 
                            focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2
                            focus-visible:tw-outline-gray-600 disabled:tw-bg-gray-300 disabled:tw-border-gray-300'
                onClick={onAddClick}
            >
                Добавить
            </button>
        </div>
    )
}

export default ObjectListPanel