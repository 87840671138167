import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { successMessageTimeOut } from '../../../../config/constTypes'
import parse from 'html-react-parser'
import DialogTab from '../../../dialog_tab/DialogTab'
import PageTitle from '../common/panels/PageTitle'
import HelpButton from '../common/buttons/HelpButton'
import DirectoryContainer from '../common/panels/directory/DirectoryContainer'
import StageListContainer from '../common/panels/stage_list/StageListContainer'
import PDataDirectoriesInfo from './info/PDataDirectoriesInfo'
import PDataOrganizationForm from './form/PDataOrganizationForm'
import PDataPrintOrderForm from './form/PDataPrintOrderForm'
import PDataProcessListContainer from './form/process_data/PDataProcessListContainer'
import ISPDataListContainer from './form/ISPData/ISPDataListContainer'
import PDataPrintTaskForm from './form/PDataPrintTaskForm'
import PDataAddQuestionForm from './form/PDataAddQuestionForm'
import PDataRKNForm from './form/PDataRKNForm'
import PData3PartyListContainer from './form/3party_handler/PData3PartyListContainer'
import PDataEmployeesForm from './form/PDataEmployeesForm'
import Spinner from '../../../../assets/Spinner'


/**
 * Визуальный компонент реализует логику процесса обработки ПДн
 * 
 * @returns {JSXElement} Html-разметку с процессом обработки ПДн
 * с использованием визуальных компонентов {@link PageTitle}, {@link StageListContainer}, {@link HelpButton}, 
 * {@link PDataDirectoriesInfo}, {@link PDataOrganizationForm}, {@link PDataPrintOrderForm}, {@link ISPDataListContainer}, 
 * {@link PDataProcessListContainer}, {@link PDataAddQuestionForm}, {@link PDataRKNForm}, {@link PData3PartyListContainer}, 
 * {@link PDataPrintTaskForm}, {@link DialogTab}, {@link DirectoryContainer}
 */
const PersonalDataContainer = () => {
    const { personalDataStore, DialogTabStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const initModule = async () => {
        await personalDataStore.loadStages()

        if (docStore.moduleType && docStore.moduleType === 'stages_of_documentation_pdn' && docStore.moduleRecordID) {
            setIsLoading(true)
            await handleDoubleClick({id: docStore.moduleRecordID})
            docStore.setModuleType(null)
            docStore.setModuleRecordID(null)
            setIsLoading(false)

        } else {
            const savedProjects = await personalDataStore.checkSavedProject()
            if (savedProjects > 0) {
                DialogTabStore.setParentName('PersonalDataContainer')
                DialogTabStore.setDialogTabTitle("Продолжение обработки ПДн") 
                DialogTabStore.setDialogTabText(parse(`Обнаружены сохраненные записи процесса обработки ПДн<br/><br/>Вы хотите выбрать запись и продолжить начатый ранее процесс?`))
                DialogTabStore.setDialogTabButtons(["Продолжить", "Начать заново"])
                DialogTabStore.setDialogTabIsOpen(true)
                DialogTabStore.setAllowAreaCloseClick(false)
            }
        }
    }

    const loadSaved = async () => {
        DialogTabStore.setDialogTabIsOpen(false)
        setIsSelectFormOpen(true)
    }

    const handleDoubleClick = async (item) => {
        let lastStageIndex = await personalDataStore.loadSavedProject(item.id)
        setIsSelectFormOpen(false)       
        toast.success('Сохраненная запись успешно загружена', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
        
        const nextStage = personalDataStore.allStages.find(stage => stage.data['id_of_stage'].value === lastStageIndex)
        if (nextStage) {
            personalDataStore.setStage(nextStage)
            personalDataStore.setActualStageIndex(lastStageIndex)
            personalDataStore.setAllStages(personalDataStore.allStages.map(stage => 
                stage.data['id_of_stage'].value < lastStageIndex
                    ?   {...stage, status: 'finished'}
                    :   stage
            ))
        }
    }

    const handleCloseClick = () => {
        setIsSelectFormOpen(false)
        toast.info('Обработка ПДн будет начата заново', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
    }

    const handleHideStageClick = (isVisible) => {
        personalDataStore.setHideStageList(isVisible)
    }

    const handleJumpStageClick = (item) => {
        if (item.status === 'finished') {
            personalDataStore.setStage(item)
            personalDataStore.setActualStageIndex(item.data['id_of_stage'].value)
        }
    }

    useEffect(() => {
        initModule()
    }, [])

    return (
        <>
            <PageTitle title={'Обработка ПДн'}/>
            <div id='personal-data-page' className={`${personalDataStore.hideStageList ? 'lg:tw-grid-cols-[2rem_1fr] lg:tw-gap-x-4' : 'lg:tw-grid-cols-4 lg:tw-gap-x-8'} tw-duration-500 lg:tw-grid lg:tw-max-h-full lg:tw-h-full max-lg:tw-w-full`}>
                <StageListContainer
                    title={'Этапы'}
                    list={personalDataStore.allStages}
                    activeStage={personalDataStore.stage}
                    idField='id_of_stage'
                    isHideList={personalDataStore.hideStageList}
                    onHideClick={handleHideStageClick}
                    onItemClick={handleJumpStageClick}
                    isSearch={false}
                />
                <div id='stage-column' className={`${!personalDataStore.hideStageList && 'lg:tw-col-span-3'} tw-duration-500 tw-bg-white tw-rounded-md tw-mt-8 lg:tw-mt-0 lg:tw-flex lg:tw-flex-col lg:tw-h-full lg:tw-overflow-hidden`}>
                    { isLoading
                        ?   
                            <Spinner />
                        :
                            personalDataStore.allStages && personalDataStore.stage && 
                            <>
                                <div id='stage-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-4 tw-px-4 tw-py-2'>
                                    <div className='tw-font-semibold'>{personalDataStore.stage.data.name.value}</div>
                                    <HelpButton  info={personalDataStore.stage.data['hint_for_stages'].value}/>                                
                                </div>
                                { personalDataStore.stage.data['id_of_stage'].value === 1 &&
                                    <PDataDirectoriesInfo/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 2 &&
                                    <PDataEmployeesForm/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 3 &&
                                    <PDataOrganizationForm/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 4 &&
                                    <PDataPrintOrderForm/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 5 &&
                                    <ISPDataListContainer/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 6 &&
                                    <PDataProcessListContainer/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 7 &&
                                    <PDataAddQuestionForm/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 8 &&
                                    <PDataRKNForm/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 9 &&
                                    <PData3PartyListContainer/>
                                }
                                { personalDataStore.stage.data['id_of_stage'].value === 10 &&
                                    <PDataPrintTaskForm/>
                                }
                            </>
                    }
                </div>
            </div>
            <DialogTab
                parentName='PersonalDataContainer'
                dialogTabFunction={loadSaved}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={'stages_of_documentation_pdn'}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
            />
        </>
    )
}

export default observer(PersonalDataContainer)