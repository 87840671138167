import React, { useContext } from 'react'
import { DocumentPlusIcon, PencilSquareIcon, DocumentDuplicateIcon, EyeIcon, EyeSlashIcon, TrashIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает меню редактора полей
 * 
 * @param {Function} onHideFieldClick Обработчик клика мыши на кнопке скрытия поля
 * [handleHideFieldClick](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line40),
 * [handleHideFieldClick](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line45)
 * @param {Function} onRemoveFieldClick Обработчик клика мыши на кнопке удаления поля
 * [handleRemoveFieldClick](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line78),
 * [handleRemoveFieldClick](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line83)
 * @returns {HTMLDivElement} Html-разметку кнопок меню с использованием визуальных компонентов 
 * 
 * @see [Вызов компонента](./components_main_page_controller_data_model_form_DataModelForm.js.html#line130)
 */
const FieldMenu = ({ onHideFieldClick, onRemoveFieldClick }) => {
    const { FilterStore, FieldStore, docStore } = useContext(Context)

    const onSetCreateMode = () => {
        FieldStore.setSelectedField(FilterStore.dummyField)
        FieldStore.setEditionMode('add')
        FieldStore.setIsFormOpen(true)
    }

    const onSetDuplicateMode = () => {
        if (FieldStore.selectedField.id !== '0') {
            FieldStore.setEditionMode('duplicate')
            FieldStore.setIsFormOpen(true)
            if (FieldStore.selectedField.type === 'reference') {
                const dataModelName = docStore.dataModels.find(dataModel => FieldStore.selectedField.ref_model_ids.includes(dataModel.id))
                FieldStore.setSelectedField({...FieldStore.selectedField, ref_model_name: dataModelName ? dataModelName.entity_name : ''})                
            }
        }
    }

    const onSetEditMode = () => {
        if (FieldStore.selectedField.id !== '0') {
            FieldStore.setEditionMode('edit')
            FieldStore.setIsFormOpen(true)
        }
    }

    return (
        <div className='tw-flex tw-flex-row tw-justify-between tw-items-center tw-space-x-1'>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={onSetCreateMode}
                data-tooltip-id="field-editor-tooltip" data-tooltip-content="Создать поле" data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <DocumentPlusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={onSetDuplicateMode}
                disabled={!FieldStore.selectedField || FieldStore.selectedField.id === '0'}
                data-tooltip-id="field-editor-tooltip" data-tooltip-content="Дублировать поле" data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <DocumentDuplicateIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={onSetEditMode}
                disabled={!FieldStore.selectedField || FieldStore.selectedField.id === '0'}
                data-tooltip-id="field-editor-tooltip" data-tooltip-content="Редактировать поле" data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <PencilSquareIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={() => onHideFieldClick(FieldStore.selectedField)}
                disabled={!FieldStore.selectedField || FieldStore.selectedField.id === '0'}
                data-tooltip-id="field-editor-tooltip" data-tooltip-content={FieldStore.selectedField?.hide ? "Показать поле" : "Скрыть поле"} data-tooltip-delay-show={menuTooltipTimeOut}
                >
                    { FieldStore.selectedField?.hide 
                        ? <EyeIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        : <EyeSlashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    }
            </button>
            <button
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={() => onRemoveFieldClick(FieldStore.selectedField.field_id)}
                disabled={!FieldStore.selectedField || FieldStore.selectedField.id === '0' || !FieldStore.selectedField.id}
                data-tooltip-id="field-editor-tooltip" data-tooltip-content="Удалить поле" data-tooltip-delay-show={menuTooltipTimeOut}
            >
                <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <Tooltip id="field-editor-tooltip" place="top" className="tw-max-w-xl"/>
        </div>
    )
}

export default observer(FieldMenu)
