import React, {useCallback, useEffect, useRef, useState} from 'react'
import { observer } from 'mobx-react-lite'
import RecordNotesRow from './RecordNotesRow'
import RecordNotesSort from './RecordNotesSort'
import FilterMenu from '../../filters/FilterMenu'

/**
 * Визуальный компонент отображает таблицу с сообщениями
 * @param {Object} object Объект с массивом полей
 * @param {Object} recordNotesStore Экземпляр хранилища информации о сообщениях
 * @param {Object} filterStore Экземпляр хранилища информации о применяемых фильтрах
 * 
 * @returns {HTMLFormElement} Html-разметку таблицы с сообщениями 
 * с использованием визуальных компонентов {@link RecordNotesSort}, {@link RecordNotesRow}, {@link FilterMenu}
 * 
 * @see [Вызов компонента](./components_tabs_notes_NoteTabPanel.js.html#line60) */
const RecordNotesTable = ({object, recordNotesStore, filterStore}) => {
    const [inputValue, setInputValue] = useState("")
    const [backButtonDisabled, setBackButtonDisabled] = useState(false)
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
    const [activeColumn, setActiveColumn] = useState(null)
    const [offsetX, setOffsetX] = useState(0)

    const addNote = (e) => {
        e.preventDefault()
        
        if(inputValue.trim()){
            recordNotesStore.addNote(recordNotesStore.recordID, inputValue, filterStore.selectedFilters)
        }
    }

    useEffect(() => {
        if(recordNotesStore.offset >= 26) {
            setBackButtonDisabled(false)
        } else {
            setBackButtonDisabled(true)
        }
    }, [recordNotesStore.offset])

    useEffect(() => {
        if((recordNotesStore.totalCount - recordNotesStore.offset) < 27) {
            setNextButtonDisabled(true)
        } else {
            setNextButtonDisabled(false)
        }
    }, [recordNotesStore.totalCount])

    const textColumn = useRef(null)
    const authorColumn = useRef(null)
    const dateColumn = useRef(null)
    const columns = [{ref: textColumn}, {ref: authorColumn}, {ref: dateColumn}]
    const savedColumnValues = sessionStorage.getItem('storedNotesSizes')
    const sizes = savedColumnValues ? savedColumnValues : "170px 170px 170px"
    const tableElement = useRef(null)
    
    const mouseMove = useCallback((e) => {
        const gridColumns = columns.map((column, index) => {
            if (index === activeColumn) {
                const width = e.clientX - offsetX -  columns[0].ref.current.offsetLeft
                if (width >= 100) {
                    return `${width}px`
                }
            }
            return `${column.ref.current.offsetWidth}px`
        });
        
        const gridTemplateColumns = `${gridColumns.join(" ")}`
        tableElement.current.childNodes[0].childNodes[0].style.gridTemplateColumns = gridTemplateColumns
        tableElement.current.childNodes[1].childNodes.forEach(row =>
            row.style.gridTemplateColumns = gridTemplateColumns)
    }, [activeColumn, columns])
    
    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove)
        window.removeEventListener("mouseup", mouseUp)
    }, [mouseMove])

    const mouseDown = (index) => (e) => {
        setActiveColumn(index)
        const offset = e.clientX - columns[index].ref.current.offsetWidth - columns[0].ref.current.offsetLeft
        setOffsetX(offset)
    }
    const mouseUp = useCallback(() => {
        if (tableElement.current) {
            sessionStorage.setItem('storedNotesSizes', tableElement.current.childNodes[0].childNodes[0].style.gridTemplateColumns)
        }
        removeListeners()
        setActiveColumn(null)
        setOffsetX(0)
    }, [setActiveColumn, setOffsetX, removeListeners]) 

    useEffect(() => {
        if (activeColumn !== null) {
            window.addEventListener("mousemove", mouseMove)
            window.addEventListener("mouseup", mouseUp)
        }
    
        return () => {
            removeListeners()
        }
    }, [activeColumn, mouseMove, mouseUp, removeListeners])

    return (
        <>
            <div className='tw-h-full tw-overflow-auto'>
                <table ref={tableElement} className='tw-w-full tw-text-gray-900 tw-font-medium tw-text-sm'>
                    <thead>
                        <tr style={{
                            display: 'grid',
                            gridTemplateColumns: sizes
                        }}>
                            <th className='tw-group tw-relative tw-truncate tw-px-2 tw-text-left' ref={textColumn}>
                                <RecordNotesSort title={"Текст сообщения"} name={"data['message']"} filterStore={filterStore} recordNotesStore={recordNotesStore}/>
                                <span 
                                    className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-bg-gray-500 tw-w-1 tw-cursor-col-resize tw-opacity-30'
                                    onMouseDown={mouseDown(0)}
                                >
                                </span>
                                {/* <FilterMenu
                                    column={{
                                        alias:"Текст сообщения",
                                        tech_name:"message",
                                        validator_type:"string"
                                    }}
                                    author={true}
                                    object={object}
                                    data={true}
                                    filterStore={filterStore}
                                /> */}
                            </th>
                            <th className='tw-group tw-relative tw-truncate tw-px-2' ref={authorColumn}>
                                <RecordNotesSort title={"Автор"} name={"author_id"} filterStore={filterStore} recordNotesStore={recordNotesStore}/>
                                <span 
                                    className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-bg-gray-500 tw-w-1 tw-cursor-col-resize tw-opacity-30'
                                    onMouseDown={mouseDown(1)}
                                >
                                </span>
                                {/* <FilterMenu
                                    column={{
                                        alias:"Автор",
                                        tech_name:"author_id",
                                        validator_type:"string"
                                    }}
                                    author={true}
                                    object={object}
                                    data={false}
                                    filterStore={filterStore}
                                /> */}
                            </th>
                            <th className='tw-group tw-relative tw-truncate tw-px-2' ref={dateColumn}>
                                <RecordNotesSort title={"Дата и время"} name={"created"} filterStore={filterStore} recordNotesStore={recordNotesStore}/>
                                <span 
                                    className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-bg-gray-500 tw-w-1 tw-cursor-col-resize tw-opacity-30'
                                    onMouseDown={mouseDown(2)}
                                >
                                </span>
                                {/* <FilterMenu
                                    column={{
                                        alias:"Дата и время",
                                        tech_name:"created",
                                        validator_type:"date"
                                    }}
                                    author={true}
                                    object={object}
                                    data={false}
                                    filterStore={filterStore}
                                /> */}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {recordNotesStore.notes.map(note => 
                            <RecordNotesRow sizes={sizes} key={note.id} {...note}/>
                        )}
                    </tbody>
                </table>
            </div>
            
            <div>
                <div className='tw-py-2 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4'>
                    <input 
                        className='tw-rounded-md tw-border-0 tw-col-span-2 tw-px-2 tw-py-1.5 tw-text-gray-900  tw-ring-1 tw-ring-inset 
                            tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                            focus-visible:tw-ring-offset-gray-400'  
                        id='note' 
                        name='note'
                        type='text'
                        placeholder='Ваше сообщение...'
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <button className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  
                            tw-bg-gray-700 tw-text-white disabled:tw-bg-gray-300 hover:tw-bg-gray-500 focus-visible:tw-outline 
                            focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600' 
                        type='submit'
                        onClick={addNote}
                    >
                        Добавить сообщение
                    </button>
                </div>
            </div> 
        </>                  
    )       
}

export default observer(RecordNotesTable)