import React, { useState, useRef, useEffect, useContext, useMemo } from 'react'
import { Context } from '../../../../../..'
import { observer } from 'mobx-react-lite'
import { pdfjs, Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import DialogTab from '../../../../../dialog_tab/DialogTab'
import PDFScaleMenu from './PDFScaleMenu'
import PDFNavigationMenu from './PDFNavigationMenu'

import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import EducationCloseButton from '../common/EducationCloseButton'
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

/**
 * Визуальный компонент отображает содержимое файла документа в формате pdf, прикрепленного к материалу по обучению
 * 
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * [handleViewPanelCloseClick](./components_main_page_controller_education_materials_MaterialsObjectsForm.js.html#line80)
 * 
 * @returns {HTMLDivElement} Html-разметку для отображения содержимого файла документа в формате pdf, прикрепленного к материалу по обучению
 * с использованием визуального компонента {@link DialogTab}, {@link PDFNavigationMenu}, {@link PDFScaleMenu}
 * 
 * @see [Вызов компонента](./components_main_page_controller_education_viewers_EducationViewFileContainer.js.html#line91)
 */
const EducationPDFViewer = ({onCloseClick}) => {
    const { EducationStore, DialogTabStore, userStore } = useContext(Context)
    const [numPages, setNumPages] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [maxPageNumber, setMaxPageNumber] = useState(1)
    const [pageScale, setPageScale] = useState(100)
    const [isChangedSize, setIsChangedSize] = useState(false)

    const frameRef = useRef(null)
    const docRef = useRef(null)

    const options = useMemo(() => ({
        cMapUrl: "/cmaps/",
        cMapPacked: true,
        standardFontDataUrl: "/standard_fonts/",
    }), [])
    
    const dialogTabFunction = async () => {
        DialogTabStore.setDialogTabIsOpen(false)
        setPageNumber(maxPageNumber)
    }

    const handleCloseDialogClick = () => {
        if (EducationStore.viewedFile &&
            maxPageNumber > 1 &&
           (!EducationStore.savedMaterialProgress || (maxPageNumber > EducationStore.savedMaterialProgress.data["material_progress"].value)))
        {
            EducationStore.saveMaterialProgress(maxPageNumber, numPages, userStore.user.id)
        }
        onCloseClick()
    }

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages)
    }

    const handlePageChange = (isIncreased) => {
        const nextPageNumber = isIncreased ? pageNumber + 1 : pageNumber - 1

        if (nextPageNumber > maxPageNumber)
            setMaxPageNumber(nextPageNumber)
        if (nextPageNumber > 0)
            setPageNumber(nextPageNumber)
        if (frameRef?.current?.scrollTop > 0)
            frameRef.current.scrollTop = 0
    }

    const handlePagesRewind = () => {
        setPageNumber(maxPageNumber)
    }

    const handleScaleChange = (isIncreased) => {
        const nextPageScale = isIncreased ? pageScale + 10 : pageScale - 10
        
        if (nextPageScale > 0) {
            setPageScale(nextPageScale)
        }
    }

    const handleFitScreenClick = () => {
        if (frameRef?.current?.offsetWidth > 0 && docRef?.current?.offsetWidth > 0) {
            const ratioWidth = Math.floor(frameRef?.current?.offsetWidth / docRef?.current?.offsetWidth * 1000) / 1000
            const ratioHeight = Math.floor(frameRef?.current?.offsetHeight / docRef?.current?.offsetHeight * 1000) / 1000

            const ratio =   (ratioWidth * docRef?.current?.offsetHeight < frameRef?.current?.offsetHeight) ||
                            (docRef?.current?.offsetWidth < docRef?.current?.offsetHeight)
                                ?   ratioWidth
                                :   ratioHeight
            const fitScale = Math.floor(pageScale * ratio / 10) * 10

            if (Math.abs(pageScale - fitScale) >= 10) {
                setPageScale(fitScale)
                setIsChangedSize(true)
            }
        }
    }

    const onPageLoading = () => {
        if (!isChangedSize) {
            handleFitScreenClick()
        }
    }

    useEffect(() => {
        if (EducationStore.savedMaterialProgress) {
            setMaxPageNumber(EducationStore.savedMaterialProgress.data["material_progress"].value)
            DialogTabStore.setParentName('EducationPDFViewer')
            DialogTabStore.setDialogTabTitle("Продолжение обучения")
            DialogTabStore.setDialogTabText('Вы хотите продолжить чтение со страницы, на которой остановились?')
            DialogTabStore.setDialogTabButtons(["Да", "Нет"])
            DialogTabStore.setDialogTabIsOpen(true)
        }
    }, [EducationStore.savedMaterialProgress])

    return (
        <div id='education-pdf-viewer' className='tw-w-full tw-h-full tw-flex tw-flex-col tw-overflow-hidden'>
            <div id='menu-panel' className='tw-w-full tw-h-12 tw-py-1 tw-flex tw-flex-row tw-justify-start tw-items-center tw-border-b tw-border-gray-300'>
                <EducationCloseButton onCloseClick={handleCloseDialogClick}/>
                { numPages > 1 &&
                    <PDFNavigationMenu
                        pages={numPages}
                        activePage={pageNumber}
                        lastPage={maxPageNumber}
                        onPageChange={handlePageChange}
                        onRewindClick={handlePagesRewind}
                    />
                }
                <PDFScaleMenu
                    scale={pageScale}
                    onScaleChange={handleScaleChange}
                    onFitClick={handleFitScreenClick}
                />
            </div>
            <div id='pdf-panel' className='tw-grow tw-w-full tw-overflow-auto tw-bg-gray-300' ref={frameRef}>
                <Document
                    file={EducationStore.viewedFile}
                    noData='Не удалось загрузить файл.'
                    onLoadError={(error) => console.log(error)}
                    onSourceError={(error) => console.log(error)}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={options}
                    renderMode="canvas"
                    className="tw-w-fit tw-px-2 tw-my-2 tw-mx-auto"
                    inputRef={docRef}
                >
                    <Page
                        className="tw-bg-gray-300 tw-my-auto"
                        key={pageNumber}
                        pageNumber={pageNumber}
                        noData='Страница не найдена.'
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        onLoadSuccess={onPageLoading}
                        scale={pageScale / 100}
                    />
                </Document>
            </div>
            <DialogTab
                parentName='EducationPDFViewer'
                dialogTabFunction={dialogTabFunction}
            />
        </div>
    )
}

export default observer(EducationPDFViewer)