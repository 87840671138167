import React from "react";
import { getFormattedDate } from "../../../config/constTypes";

/**
 * Визуальный компонент отображает строку в таблице с сообщениями
 * @param {Object} note Сообщение
 * @returns {HTMLFormElement} Html-разметку строки в таблице с сообщениями 
 * 
 * @see [Вызов компонента](./components_tabs_notes_RecordNotesTable.js.html#line164) */
const RecordNotesRow = (note) => {

    return (
        <tr key={note.id} style={{
            display: 'grid',
            gridTemplateColumns: note.sizes
        }}>
            <td className='tw-truncate tw-px-2 tw-relative'>
                <span
                    data-tooltip-id="record-notes-form-tooltip"
                    data-tooltip-content={note.data.message}
                    data-tooltip-delay-show={1000}
                >
                    {note.data.message}
                </span>
            </td>

            <td className='tw-truncate tw-px-2'>
                <span
                    data-tooltip-id="record-notes-form-tooltip"
                    data-tooltip-content={note.author ? note.author.first_name + ' ' + note.author.last_name : note.author_id}
                    data-tooltip-delay-show={1000}
                >
                    {note.author ? note.author.email : note.author_id}
                </span>
            </td>

            <td className='tw-truncate tw-px-2'>
                <span
                    data-tooltip-id="record-notes-form-tooltip"
                    data-tooltip-content={getFormattedDate(note.created, 'dd.MM.yyyy HH:mm:ss')}
                    data-tooltip-delay-show={1000}
                >
                   {getFormattedDate(note.created, 'dd.MM.yyyy HH:mm:ss')}
                </span>
            </td>
        </tr>
    )
}

export default RecordNotesRow