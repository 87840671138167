import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../../index'
import DocumentService from '../../../../services/DocumentService'
import DataModelCategoryList from '../data_model/list/DataModelCategoryList'
import { paths } from '../../../../config/constsURN'
import { observer } from 'mobx-react-lite'
import Spinner from '../../../../assets/Spinner'
import PageTitle from '../common/panels/PageTitle'
import { toast } from 'react-toastify'
import DataObjectMenu from './menu/DataObjectMenu'
import PrintDocumentModal from '../exporter/to_file/PrintDocumentModal'
import ImportFromFileModal from '../importer/from_file/ImportFromFileModal'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../config/constTypes'
import { handleFileSelect, handleFileRemove, saveReceivedFile, printDocument } from '../../../../functions/fileHandlers'
import { showErrorToast } from '../../../../functions/errorHandlers'
import DirectoryPanel from '../common/panels/directory/DirectoryPanel'
import { isScrolledIntoView } from '../../../../functions/isScrolledIntoView'
import DuplicateDataObjectContainer from './add/DuplicateDataObjectContainer'
import AddDataObjectContainer from './add/AddDataObjectContainer'
import EditDataObjectContainer from './edit/EditDataObjectContainer'
import { initNestedModels } from '../../../../functions/nestedModels'
import ExporterService from '../../../../services/ExporterService'
import ImporterService from '../../../../services/ImporterService'
import { setSortingField } from '../../../../functions/sortingsAndFilters'
import FilterStore from '../../../../store/FilterStore'
import RecordNotesStore from '../../../../store/RecordNotesStore'


/**
 * Компонент реализует логику работы с записями в таблицах (просмотр, создание, редактирование, импорт, экспорт)
 * @returns  {HTMLDivElement} Html-разметку страницы с использованием визуальных компонентов {@link Spinner},
 * {@link PageTitle}, {@link DataModelCategoryList}, {@link DataObjectMenu}, {@link DirectoryPanel},
 * {@link DuplicateDataObjectContainer}, {@link AddDataObjectContainer}, {@link EditDataObjectContainer},
 * а также визуальных компонентов (модальных окон) {@link PrintDocumentModal} и {@link ImportFromFileModal}
 */
const DataObjectListContainer = () => {
    const navigate = useNavigate()
    const { docStore, userStore } = useContext(Context)
    const [filterStore] = useState(() => new FilterStore())
    const [recordNotesStore] = useState(() => new RecordNotesStore())

    const [isLoading, setIsLoading] = useState(false)
    const [isNestedLoading, setIsNestedLoading] = useState(false)
    const [dataObjects, setDataObjects] = useState([])
    const [selectedFullDataModel, setSelectedFullDataModel] = useState(null)
    const [nestedDataModels, setNestedDataModels] = useState([])
    const [isHistoryPressed, setIsHistoryPressed] = useState(false)
    const [isSortingPressed, setIsSortingPressed] = useState(false)
    const [selectedNestedDataModel, setSelectedNestedDataModel] = useState(null)
    const [sortingDirection, setSortingDirection] = useState('down')
    const [sortingColumn, setSortingColumn] = useState('created')
    const [isFetchingData, setIsFetchingData] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [dataObjectOffset, setDataObjectOffset] = useState(0)

    const [isAddFormOpen, setIsAddFormOpen] = useState(false)
    const [isDuplicateFormOpen, setIsDuplicateFormOpen] = useState(false)
    const [isEditFormOpen, setIsEditFormOpen] = useState(false)
    const [id, setID] = useState(null)
    const [fetchedDataObjects, setFetchedDataObjects] = useState(null)

    const [isExporterSelecting, setIsExporterSelecting] = useState(false)
    const [isPrinting, setIsPrinting] = useState(false)
    const [isImporting, setIsImporting] = useState(false)
    const [importFiles, setImportFiles] = useState([])

    const handleDataModelClick = (dataModel) => {
        if (selectedFullDataModel?.id !== dataModel.id) {
            setSelectedFullDataModel(dataModel)
            setNestedDataModels(initNestedModels(dataModel))
            setSelectedNestedDataModel(null)
            setDataObjects([])
            setDataObjectOffset(0)

            docStore.setSelectedDataObject(null)
            docStore.setAttachedFiles([])
            docStore.setIsHistoryView(false)
            docStore.setIsDetailView(false)
            docStore.setSortingColumn('created')
            docStore.setSortingDirection('down')
            docStore.setIsFetching(false)

            recordNotesStore.setRecordID(null)
            filterStore.setSearchField('')
            filterStore.setSearchFilters([])

            sessionStorage.setItem('activeDataModel', JSON.stringify(dataModel))
            const [savedSortingColumn, savedSortingDirection] = docStore.loadSavedSorting(dataModel.id)
            setSortingColumn(savedSortingColumn)
            setSortingDirection(savedSortingDirection)

            const filters = JSON.parse(sessionStorage.getItem(`${dataModel.id}_filters`))
            const fields = JSON.parse(sessionStorage.getItem(`${dataModel.id}_filterFields`))

            if (filters?.length && fields?.length) {
                filterStore.setSelectedFilters(filters)
                filterStore.setFilteredFields(fields)
                filterStore.setIsFiltering(true)
            } else {
                filterStore.filterClick(dataModel, true, true)
            }
        }
    }

    const handleDataObjectClick = (dataObject) => {
        if(!docStore.selectedDataObject || dataObject.id !== docStore.selectedDataObject?.id) {
            docStore.setSelectedDataObject(dataObject)
            if (dataObject.system_data.files && dataObject.system_data.files.length) {
                docStore.setAttachedFiles(dataObject.system_data.files)
            } else {
                docStore.setAttachedFiles([])
            }
            setNestedDataModels(initNestedModels(selectedFullDataModel))

            recordNotesStore.setRecordID(dataObject.record_id)
        }

        // показ названия таблицы, если она находится за пределами поля видимости
        if (selectedFullDataModel) {
            const element = document.getElementById(selectedFullDataModel.id)
            if (element && !isScrolledIntoView(element))
                element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const handleAddDataObjectClick = async () => {
        const isDataModelLocked = await docStore.checkDataModelLock(selectedFullDataModel.id)
        if (isDataModelLocked) {
            toast.error('Создание записи невозможно: таблица заблокирована для импорта данных!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
        } else {
            setIsAddFormOpen(true)
        }
    }

    const handleDuplicateClick = async (id) => {
        const isDataModelLocked = await docStore.checkDataModelLock(selectedFullDataModel.id)
        if (isDataModelLocked) {
            toast.error('Создание записи невозможно: таблица заблокирована для импорта данных!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
        } else {
            setIsDuplicateFormOpen(true)
            setID(id)
        }
    }

    const handleEditDataObjectClick = async (id) => {
        const isDataModelLocked = await docStore.checkDataModelLock(selectedFullDataModel.id)
        if (isDataModelLocked) {
            toast.error('Редактирование записи невозможно: таблица заблокирована для импорта данных!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   
        } else {
            setIsEditFormOpen(true)
            setID(id)
        }
    }

    const handleViewRecordClick = () => {
        docStore.setIsDetailView(!docStore.isDetailView)
    }

    const handelViewHistoryClick = () => {
        setIsHistoryPressed(true)
        docStore.setIsDetailView(false)
    }

    const handleDeleteDataObjectClick = (item) => {
        const deletionMark = (item.system_data && item.system_data.deletion_mark) ? false : true
        deleteDataObject(item.record_id, deletionMark)
    }

    const handlePrintTableClick = (type) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис экспорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const filter = JSON.stringify([
            {property: 'data_model_id', value: selectedFullDataModel.id, operator: 'eq'},
            {property: 'active', value: true, operator: 'eq'},
        ])

        ExporterService
            .exportDataObjectToTable(type, filter)
            .then((blob) => {
                clearTimeout(noResponse)
                saveReceivedFile(blob, selectedFullDataModel.entity_name + '.' + type)
            })            
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'export', '')
            })
    }

    const handlePrintRecordClick = (type) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис экспорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        let filter
        if (docStore.isHistoryView) {
            filter = JSON.stringify([
                {property: 'record_id', value: docStore.selectedDataObject.record_id, operator: 'eq'},
            ])
        } else {
            filter = JSON.stringify([
                {property: 'data_model_id', value: selectedFullDataModel.id, operator: 'eq'},
                {property: '_key', value: [docStore.selectedDataObject.id], operator: 'in'},
            ])
        }

        ExporterService
            .exportDataObjectToTable(type, filter)
            .then((blob) => {
                clearTimeout(noResponse)
                saveReceivedFile(blob, selectedFullDataModel.entity_name + '.' + type)
            })            
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'export', '')
            })
    }

    // Вызов модального окна печати в PDF/ODT (для выбора печатной формы)
    const handleOpenExportersClick = () => {
        setIsExporterSelecting(true)
    }

    // Экспорт записи в PDF согласно выбранной печатной форме
    const handlePrintDocumentClick = async (template) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис экспорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        setIsPrinting(true)

        try {
            await printDocument(template.id, docStore.selectedDataObject.id, setIsPrinting)
        } catch (error){
            showErrorToast(error, 'export', '')
        } finally {
            clearTimeout(noResponse)
            setIsExporterSelecting(false)
            setIsPrinting(false)
        }
    }

    // Вызов модального окна импорта из файла (для выбора шаблона импорта)
    const handleImportFromFileClick = () => {
        setIsImporting(true)
    }

    // Импорт записей из файла согласно выбранному шаблону
    const handleImportTableClick = (form) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            docStore.setAttachedFiles([])
        }, responseTimeOut)

        const importConfig = {}
        importConfig.source = {}
        importConfig.source.file_id = importFiles[0].id
        importConfig.settings = {}
        importConfig.settings.sheet_name = form.sheetNumber
        importConfig.settings.header = form.headerRowCount
        importConfig.settings.offset = form.startRowNumber
        if (form.endRowNumber < 65535 && form.endRowNumber >= form.startRowNumber )
            importConfig.settings.count = form.endRowNumber - form.startRowNumber + 1

        if (userStore.user.is_staff && importConfig.settings.count > 100) {
            importConfig.settings.count = null
        }
        setIsImporting(false)

        ImporterService
            .importFileData(form.template.id, importConfig)
            .then(data => {
                clearTimeout(noResponse)
                sessionStorage.removeItem('savedImportColumnSizes')
                navigate(paths.IMPORT_PREVIEW_ROUTE + '/' + data.id)
            })            
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'import', '')
            })
            .finally(() => setImportFiles([]))
    }

    const deleteDataObject = (id, mark) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        DocumentService
            .deleteDataObject(id, {system_data: {deletion_mark: mark}})
            .then(() => {
                clearTimeout(noResponse)
                updateDataObjectList(selectedFullDataModel.id)
                setNestedDataModels(initNestedModels(selectedFullDataModel))
                docStore.setSelectedDataObject(null)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'deleting', '')
            })
    }

    const handleImportFromServerClick = () => {
        navigate(paths.IMPORT_DATA_ROUTE + '/' + selectedFullDataModel.id)
    }

    const handleDataObjectDoubleClick = (item) => {
        if (item.active &&  (!item.subgroup_id || item.subgroup_id == userStore.currentSubCompany.id)) {
            handleEditDataObjectClick(item.id)
        } else {
            handleViewRecordClick()
        }
    }

    const handleImportModalClose = () => {
        setIsImporting(false)
        setImportFiles([])
    }
    
    const updateDataObjectList = (dataModelID) => {
        const defaultFilter = [
            {property: 'data_model_id', value: dataModelID, operator: 'eq'},
            {property: 'transaction_id', value: null, operator: 'eq'},
            {property: 'system_data.parent_record_id', value: null, operator: 'eq'},
            {property: 'active', value: true, operator: 'eq'}
        ]

        const filters = JSON.stringify(defaultFilter.concat(filterStore.selectedFilters).concat(filterStore.searchFilters))    
        const sorter = JSON.stringify([
            {property: setSortingField(sortingColumn), desc: sortingDirection !== 'down'},
        ])
        docStore.setSelectedFilters(filters)

        if (selectedFullDataModel)
            setSelectedFullDataModel({...selectedFullDataModel, sortingColumn: sortingColumn, sortingDirection: sortingDirection})
        
        docStore.getDataObjectsByID(filters, sorter, 0, dataObjects, setDataObjects, setTotalCount, setDataObjectOffset)
    }

    const handleObjectContainerCloseClick = () => {
        updateDataObjectList(selectedFullDataModel.id)
        docStore.setSelectedDataObject(null)
        setNestedDataModels(initNestedModels(selectedFullDataModel))

        setIsAddFormOpen(false)
        setIsDuplicateFormOpen(false)
        setIsEditFormOpen(false)
    }

    const handleSortClick = (dataModelID, column) => {
        let direction = 'down'
        if (column === sortingColumn) {
            if (sortingDirection === 'down') {
                direction = 'up'
            }
        }
        setSortingColumn(column)
        setSortingDirection(direction)
        setSelectedFullDataModel({...selectedFullDataModel, sortingColumn: column, sortingDirection: direction})
        docStore.setSelectedSorting(dataModelID, column, direction)
        setIsSortingPressed(true)
    }

    const handleHierarchyClick = async (dataObject) => {
        if (!dataObject.hierarchyVisible) {
            const updatedDataObjects = await docStore.showTreeNode(dataObjects, dataObject, filterStore.selectedFilters, setSortingField(sortingColumn), sortingDirection)
            setDataObjects(updatedDataObjects)
        } else {
            const updatedDataObjects = docStore.hideTreeNode(dataObjects, dataObject)
            setDataObjects(updatedDataObjects)
        }
    }

    useEffect(() => {
        docStore.setShownTypes(['document', 'directory'])
        docStore.setIsShowInfo(false)

        const defaultFilter = []
        const categorySorter = JSON.stringify([
            {property: 'order', desc: false},
        ])      
        docStore.getCategories(defaultFilter, categorySorter)

        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setIsLoading(false)
        }, responseTimeOut)

        let savedDataModel = JSON.parse(sessionStorage.getItem('activeDataModel'))

        const filter = JSON.stringify([
            {property: 'hide_mark', value: false, operator: 'eq'},
        ])
        const dataModelSorter = JSON.stringify([
            {property: 'entity_name', desc: false},
        ])      
        setIsLoading(true)

        DocumentService
            .getDataModels(filter, dataModelSorter)
            .then(data => {
                clearTimeout(noResponse)
                docStore.setDataModels(data)

                if (savedDataModel) {
                    const foundDataModel = data.find(item => item.id === savedDataModel.id)
                    if (foundDataModel && foundDataModel.type !== 'nested') {
                        const [savedSortingColumn, savedSortingDirection] = docStore.loadSavedSorting(foundDataModel.id)
                        setSortingColumn(savedSortingColumn)
                        setSortingDirection(savedSortingDirection)
                        setSelectedFullDataModel({...foundDataModel, sortingColumn: savedSortingColumn, sortingDirection: savedSortingDirection})
                        setNestedDataModels(initNestedModels(foundDataModel))

                        const filters = JSON.parse(sessionStorage.getItem(`${savedDataModel.id}_filters`))
                        const fields = JSON.parse(sessionStorage.getItem(`${savedDataModel.id}_filterFields`))
                        
                        if (filters?.length && fields?.length) {
                            filterStore.setSelectedFilters(filters)
                            filterStore.setFilteredFields(fields)
                            filterStore.setIsFiltering(true)
                        } else {
                            filterStore.filterClick(savedDataModel, true, true)
                        }
                    } else {
                        sessionStorage.removeItem('activeDataModel')
                    }
                } else {
                    setSelectedFullDataModel(null)
                    setNestedDataModels([])
                }
                docStore.setSelectedDataObject(null)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
            .finally(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        if (isHistoryPressed && docStore.selectedDataObject) {
            if (!docStore.isHistoryView) {
                setFetchedDataObjects(dataObjects)
                const historyFilter = JSON.stringify([
                    {property: 'record_id', value: docStore.selectedDataObject.record_id, operator: 'eq'},
                ])
                const defaultSorter = []

                docStore.getDataObjectHistory(historyFilter, defaultSorter, true, setDataObjects)

            } else {
                if (fetchedDataObjects) {
                    setDataObjects(fetchedDataObjects)
                } else {
                    updateDataObjectList(selectedFullDataModel.id)
                    setNestedDataModels(initNestedModels(selectedFullDataModel))
                    docStore.setSelectedDataObject(null)
                }
            }
            docStore.setIsHistoryView(!docStore.isHistoryView)
            setIsHistoryPressed(false)
        }
    }, [isHistoryPressed])

    useEffect(()=>{
        if (isSortingPressed && selectedFullDataModel){
            updateDataObjectList(selectedFullDataModel.id)
            setIsSortingPressed(false)
        }
    }, [sortingColumn, sortingDirection])

    useEffect(()=>{
        if (selectedFullDataModel) {
            sessionStorage.setItem(`${selectedFullDataModel.id}_filters`, JSON.stringify(filterStore.selectedFilters))
            sessionStorage.setItem(`${selectedFullDataModel.id}_filterFields`, JSON.stringify(filterStore.filteredFields))
            
            updateDataObjectList(selectedFullDataModel.id)
            recordNotesStore.setRecordID(null)
        }
    }, [filterStore.selectedFilters])

    useEffect(()=>{
        if (isFetchingData){
            if (dataObjectOffset < totalCount) {
                const sorter = JSON.stringify([
                    {property: setSortingField(sortingColumn), desc: sortingDirection !== 'down'},
                ])

                docStore.getDataObjectsByID(docStore.selectedFilters, sorter, dataObjectOffset, dataObjects, setDataObjects, setTotalCount, setDataObjectOffset)
            } else {
                setIsFetchingData(false)
            }
        }
    }, [isFetchingData])

    useEffect(()=>{
        if (!docStore.isDataObjectLoading && isFetchingData){
            setIsFetchingData(false)
        }
    }, [docStore.isDataObjectLoading])
    
    useEffect(()=>{
        if(docStore.selectedDataObject && !isDuplicateFormOpen && !isAddFormOpen){
            recordNotesStore.setRecordID(docStore.selectedDataObject.record_id)
        } else {
            recordNotesStore.setRecordID(null)
        }

        if (docStore.selectedDataObject && selectedNestedDataModel && !isDuplicateFormOpen && !isAddFormOpen) {
            docStore.getNestedDataObjects(docStore.selectedDataObject, selectedNestedDataModel, setNestedDataModels, setIsNestedLoading)    
        }

        if (!docStore.selectedDataObject && selectedFullDataModel) {
            setNestedDataModels(initNestedModels(selectedFullDataModel))
        }
    }, [docStore.selectedDataObject, isDuplicateFormOpen, isAddFormOpen])

    useEffect(()=>{
        if (docStore.selectedDataObject && selectedNestedDataModel && !isDuplicateFormOpen && !isAddFormOpen) {
            docStore.getNestedDataObjects(docStore.selectedDataObject, selectedNestedDataModel, setNestedDataModels, setIsNestedLoading)    
        }
    }, [selectedNestedDataModel])
    
    if (isLoading || docStore.isDataModelLoading) {
        return  <Spinner/>
    }

    return (
        <>
            <PageTitle title={'Просмотр данных'} />
            <div id='data-object-page' className='tw-grid tw-grid-cols-5 tw-gap-x-8 tw-h-full'>
                <div id='data-model-list' className='tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <div id='data-model-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4 tw-py-2'>
                        <p className='tw-text-md tw-font-semibold'>Таблицы</p>
                    </div>
                    <DataModelCategoryList
                        selectedDataModel={selectedFullDataModel}
                        onItemClick={handleDataModelClick}
                        onItemDoubleClick={handleDataModelClick}
                    />
                </div>
                <div id='data-object-fields' className='tw-col-span-4 tw-h-full tw-overflow-hidden'>
                    <div id='data-object-menu' className='tw-flex tw-flex-row tw-items-center tw-bg-white tw-rounded-t-md
                        tw-border-b-2 tw-border-gray-400 tw-space-x-1 tw-px-4'>
                        <DataObjectMenu
                            onAddItemClick={handleAddDataObjectClick}
                            onDuplicateItemClick={handleDuplicateClick}
                            onEditItemClick={handleEditDataObjectClick}
                            onViewRecordClick={handleViewRecordClick}
                            onItemHistoryClick={handelViewHistoryClick}
                            onDeleteItemClick={handleDeleteDataObjectClick}
                            selectedDataObject={docStore.selectedDataObject}
                            selectedFullDataModel={selectedFullDataModel}
                            handleImportFromFileClick={handleImportFromFileClick}
                            handleImportFromServerClick={handleImportFromServerClick}
                            handlePrintTableClick={handlePrintTableClick}
                            handlePrintRecordClick={handlePrintRecordClick}
                            handleOpenExportersClick={handleOpenExportersClick}
                            isDocumentPage={true}
                            updateDataObjectList={updateDataObjectList}
                            filterStore={filterStore}
                        />
                    </div>
                    { docStore.isStartLoading
                        ?   <Spinner/>
                        :   <DirectoryPanel
                                onItemClick={handleDataObjectClick}
                                onItemDoubleClick={handleDataObjectDoubleClick}
                                selectedDataObject={docStore.selectedDataObject}
                                selectedFullDataModel={selectedFullDataModel}
                                dataObjects={dataObjects}
                                selectedNestedDataModel={selectedNestedDataModel}
                                nestedDataModels={nestedDataModels}
                                setSelectedNestedDataModel={setSelectedNestedDataModel}
                                setNestedDataModels={setNestedDataModels}
                                onSortClick={handleSortClick}
                                onFetchData={setIsFetchingData}
                                isNestedLoading={isNestedLoading}
                                filterStore={filterStore}
                                recordNotesStore={recordNotesStore}
                                isChosenObjectDuplicationForbidden={true}
                                onHierarchyClick={handleHierarchyClick}
                            />
                    }
                </div>
            </div>
            <PrintDocumentModal
                isVisible={isExporterSelecting}
                dataModelID={selectedFullDataModel?.id}
                isPrinting={isPrinting}
                onSubmitClick={handlePrintDocumentClick}
                onCloseModal={() => setIsExporterSelecting(false)}
            />
            <ImportFromFileModal 
                isVisible={isImporting}
                dataModel={selectedFullDataModel}
                files={importFiles}
                onFileSelect={handleFileSelect(importFiles, setImportFiles)}
                onFileRemove={handleFileRemove(importFiles, setImportFiles)}
                onSubmitClick={handleImportTableClick}
                onCloseModal={handleImportModalClose}
            />    
            <AddDataObjectContainer
                isOpen={isAddFormOpen}
                onCloseClick={handleObjectContainerCloseClick}
                selectedFullDataModel={selectedFullDataModel}
                recordNotesStore={recordNotesStore}
            />
            <DuplicateDataObjectContainer
                isOpen={isDuplicateFormOpen} 
                onCloseClick={handleObjectContainerCloseClick}
                id={id}
                selectedFullDataModel={selectedFullDataModel}
                recordNotesStore={recordNotesStore}
            />
            <EditDataObjectContainer
                isOpen={isEditFormOpen} 
                onCloseClick={handleObjectContainerCloseClick}
                id={id}
                selectedFullDataModel={selectedFullDataModel}
                recordNotesStore={recordNotesStore}
            />
        </>
    )
}

export default observer(DataObjectListContainer)
