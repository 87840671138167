import React from 'react'
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

function checkChildRecords(dataObject) {
    let isChildExist = false
    if (dataObject.ref_summaries && dataObject.ref_summaries.length > 0) {
        const foundItem = dataObject.ref_summaries.find(item => item.rule_id === 'parent')
        if (foundItem && foundItem.direct === 'in' && foundItem.total > 0)
            isChildExist = true
    }

    return isChildExist
}

function getMaxVisibleLevel(treeNodes) {
    let maxLevel = 0
    treeNodes.forEach(item => {
        if (item.hierarchyLevel > maxLevel)
            maxLevel = item.hierarchyLevel
    })

    return maxLevel
}

/**
 * Визуальный компонент отображает дерева управления отображением иерархических записей выбранной таблицы.
 * По умолчанию отображаются записи верхнего уровня
 * 
 * @param {Object} dataModel Активная таблица
 * @param {Object[]} treeNodes Массив отображаемых записей активной таблицы
 * @param {Function} onHierarchyClick Обработчик клика мыши на элементе раскрытия/закрытия родительской записи
 * [handleHierarchyClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line366)
 * [handleHierarchyClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line226),
 * 
 * @returns {HTMLDivElement} Html-разметку дерева управления отображением иерархических записей
 * 
 * @see [Вызов компонента](./components_main_page_controller_common_panels_directory_DirectoryFieldsList.js.html#line326)
 */
const DirectoryHierarchyTree = ({dataModel, treeNodes, onHierarchyClick}) => {
    if (!dataModel.allow_hierarchy)
        return null

    return (
        <div className='tw-flex-tw-flex-col'>
            <div className='tw-overflow-hidden'>
                { treeNodes.map((node, index) => {
                    return  <div 
                                key={index}
                                style={{
                                    width: (getMaxVisibleLevel(treeNodes) * 20 + 40) + 'px',
                                    paddingLeft: (node.hierarchyLevel * 20 + 10) + 'px'
                                }}
                                className='tw-h-6 tw-pr-2 tw-py-1 tw-border-b tw-border-r'
                            >
                                {checkChildRecords(node)
                                    ?   <div className='tw-cursor-pointer' onClick={() => onHierarchyClick(node)}>
                                            {node.hierarchyVisible
                                                ?   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                                :   <ChevronRightIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                            }
                                        </div>
                                    :   <div className="tw-ml-1.5 tw-mt-1.5 tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-gray-600"/>
                                    
                                }
                            </div>
                })}
            </div>
        </div>
    )
}

export default DirectoryHierarchyTree