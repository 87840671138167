import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import DataTypeListBox from '../../../inputs/DataTypeListBox'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import FormErrorToastPanel from '../../../panels/toast/FormErrorToastPanel'


/**
 * Визуальный компонент отображает форму создания/редактирования записи о сотруднике
 * @param {Object} employee Выбранный элемент списка (запись)
 * @param {Boolean} isEditMode Признак редактирования существующей записи
 * @param {Object[]} positions Список возможных должностей для сотрудника
 * @param {Object[]} subdivisions Список возможных подразделений для сотрудника
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке сохранения изменений
 * [handleSubmitEmployeeClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line192)
 * @param {Function} onCancelClick Обработчик клика мыши на кнопке отмены редактирования записи
 * [handleCancelEmployeeClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line199)
 * 
 * @returns {HTMLDivElement} Html-разметку формы создания/редактирования записи о сотруднике
 * с использованием визуального компонента {@link DataTypeListBox}, {@link FormErrorToastPanel}
 * 
 * @see [Вызов компонента](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line423)
 */
const SimpleEmployeeForm = ({employee, isEditMode, positions, subdivisions, onSubmitClick, onCancelClick}) => {
    const {
        control,
        register,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors, isSubmitting },
    } = useForm()

    const defaultItem = { id: '0', record_id: '0', name: '' }
    const [selectedPosition, setSelectedPosition] = useState(defaultItem)
    const [selectedSubdivision, setSelectedSubdivision] = useState(defaultItem)
    const [editedPositions, setEditedPositions] = useState(null)
    const [editedSubdivisions, setEditedSubdivisions] = useState(null)

    const formFields = [
        {
            tech_name: 'lastName',
            alias: 'Фамилия'
        },
        {
            tech_name: 'firstName',
            alias: 'Имя'
        },
        {
            tech_name: 'employeeName',
            alias: 'Упоминать сотрудника в документах как'
        },
        {
            tech_name: 'position',
            alias: 'Должность'
        },
        {
            tech_name: 'subdivision',
            alias: 'Подразделение'
        },
        {
            tech_name: 'emailAddress',
            alias: 'Электронная почта'
        }
    ]

    useEffect(() => {
        const processedPositions = positions.map(item => { return {...item, name: item.data.name.value} })
        const processedSubdivisions = subdivisions.map(item => { return {...item, name: item.data.name.value} })
        setEditedPositions(processedPositions)
        setEditedSubdivisions(processedSubdivisions)
        if (isEditMode && employee) {
            const employeePosition =    employee.data['employee_position__employees'].value && 
                                        employee.data['employee_position__employees'].value.values.length 
                                            ?   employee.data['employee_position__employees'].value.values[0].record_id
                                            :   '0'
            const foundPosition = processedPositions.find(item => item.record_id === employeePosition)
            if (foundPosition)
                setSelectedPosition(foundPosition)
            
            const employeeSubdivision = employee.data['employee_subdivision__employees'].value && 
                                        employee.data['employee_subdivision__employees'].value.values.length 
                                            ?   employee.data['employee_subdivision__employees'].value.values[0].record_id
                                            :   '0'
            const foundSubdivision = processedSubdivisions.find(item => item.record_id === employeeSubdivision)
            if (foundSubdivision)
                setSelectedSubdivision(foundSubdivision)
        }
    }, [])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(<FormErrorToastPanel errors={errors} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <form>
            <div className='tw-w-full tw-px-4 tw-flex tw-flex-col'>
                <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-mt-2'>
                    <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900 tw-leading-6'>Фамилия</label>
                    {errors.lastName && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                </div>
                <input
                    type='text'
                    className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                        ${errors.lastName ? 'tw-ring-red-400 focus-visible:tw-ring-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-gray-400'}
                    `}
                    {...register('lastName', { 
                        required: true, 
                        value: (isEditMode && employee) ? employee.data['employee_last_name__employees'].value : '',
                        validate: v => v.trim() !== '',
                    })} 
                />
                <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-mt-2'>
                    <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900 tw-leading-6'>Имя</label>
                    {errors.firstName && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                </div>
                <input
                    type='text'
                    className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                        ${errors.firstName ? 'tw-ring-red-400 focus-visible:tw-ring-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-gray-400'}
                    `}
                    {...register('firstName', { 
                        required: true, 
                        value: (isEditMode && employee) ? employee.data['employee_first_name__employees'].value : '',
                        validate: v => v.trim() !== '',
                    })} 
                />
                <label className='tw-text-sm tw-font-medium tw-px-2 tw-mt-2 tw-text-gray-900 tw-leading-6'>Отчество</label>
                <input
                    type='text'
                    className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                        tw-ring-gray-400 focus-visible:tw-ring-gray-400
                    `}
                    {...register('secondName', { 
                        value: (isEditMode && employee) ? employee.data['employee_surname__employees'].value : '',
                    })} 
                />
                <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-mt-2'>
                    <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900 tw-leading-6'>Упоминать сотрудника в документах как</label>
                    {errors.employeeName && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                </div>
                <input
                    type='text'
                    className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                        ${errors.employeeName ? 'tw-ring-red-400 focus-visible:tw-ring-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-gray-400'}
                    `}
                    {...register('employeeName', { 
                        value: (isEditMode && employee) ? employee.data['name'].value : '',
                        validate: v => v.trim() !== '',
                    })} 
                />
                {editedPositions &&
                    <Controller
                        name={'position'}
                        control={control}
                        defaultValue={(isEditMode && selectedPosition) ? selectedPosition.record_id : '0'}
                        rules={ {validate: v => v !== '0'} }
                        render={({field}) =>
                            <DataTypeListBox
                                label={'Должность'}
                                itemList={editedPositions}
                                selectedItem={selectedPosition}
                                onItemChange={(e) => { setSelectedPosition(e); setValue('position', e.record_id); clearErrors('position') }}
                                error={errors.position}
                                selector={'id'}
                            />}
                    />
                }
                {editedSubdivisions &&
                    <Controller
                        name={'subdivision'}
                        control={control}
                        defaultValue={(isEditMode && selectedSubdivision) ? selectedSubdivision.record_id : '0'}
                        rules={ {validate: v => v !== '0'} }
                        render={({field}) =>
                            <DataTypeListBox
                                label={'Подразделение'}
                                itemList={editedSubdivisions}
                                selectedItem={selectedSubdivision}
                                onItemChange={(e) => { setSelectedSubdivision(e); setValue('subdivision', e.record_id); clearErrors('subdivision') }}
                                error={errors.subdivision}
                                selector={'id'}
                            />}
                    />
                }
                <label className='tw-text-sm tw-font-medium tw-px-2 tw-mt-2 tw-text-gray-900 tw-leading-6'>Телефон</label>
                <input
                    type='text'
                    className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                        tw-ring-gray-400 focus-visible:tw-ring-gray-400
                    `}
                    {...register('phoneNumber', { 
                        value: (isEditMode && employee) ? employee.data['num_phone__employees'].value : '',
                    })} 
                />
                <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-mt-2'>
                    <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900 tw-leading-6'>Электронная почта</label>
                    {errors.emailAddress && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                </div>
                <input
                    type='email'
                    className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                        ${errors.emailAddress ? 'tw-ring-red-400 focus-visible:tw-ring-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-gray-400'}
                    `}
                    {...register('emailAddress', { 
                        pattern: /.+@.+\..+/i,
                        value: (isEditMode && employee) ? employee.data['email_employee__employees'].value : '',
                    })} 
                />
                <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-mt-2 tw-py-4'>
                    <button 
                        className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        onClick={handleSubmit(onSubmitClick)}
                    >
                        Сохранить
                    </button>
                    <button
                        type='button'
                        className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-900
                                hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        onClick={onCancelClick}
                    >
                        Отменить
                    </button>
                </div> 
            </div>
        </form>
    )
}

export default SimpleEmployeeForm