import { useContext } from 'react'
import { Context } from '../../../../..'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import DataModelType from './DataModelType'
import { observer } from 'mobx-react-lite'


/**
 * Визуальный компонент отображает список существующих типов таблиц
 * @param {Object} category Категория таблиц
 * @param {Object} selectedDataModel Выбранная таблица
 * @param {Function} onItemClick Обработчик клика мыши на таблице
 * [handleDataModelClick](./components_main_page_controller_data_model_DataModelListContainer.js.html#line64) и
 * [handleDataModelClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line68)
 * @param {Function} onItemDoubleClick Обработчик двойного клика мыши на таблице
 * [handleDataModelClick](./components_main_page_controller_data_model_DataModelListContainer.js.html#line64) и
 * [handleDataModelClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line68)
 * @returns {HTMLDivElement} Html-разметку списка существующих типов таблиц с использованием визуального компонента {@link DataModelType}
 * 
 * @see [Вызов компонента DataModelCategoryList,](./components_main_page_controller_data_model_list_DataModelCategoryList.js.html#line26)
 * [DataModelCategoryList](./components_main_page_controller_data_model_list_DataModelCategoryList.js.html#line34)
 */
const DataModelCategory = ({category, selectedDataModel, onItemClick, onItemDoubleClick}) => {
    const { docStore } = useContext(Context)
    
    const filteredDataModels = category.id === '0'
                ?   docStore.dataModels.filter(dataModel => !dataModel.categories.length)
                :   docStore.dataModels.filter(dataModel => dataModel.category_ids.includes(category.id))

    return (
        <div className="tw-w-full tw-pt-0.5">
            <Disclosure defaultOpen={selectedDataModel && filteredDataModels.some(dataModel => dataModel.id === selectedDataModel.id)}>
            {({ open }) => (
                <>
                    <Disclosure.Button className="tw-flex tw-w-full tw-justify-between tw-bg-gray-300 tw-px-4 tw-border-y 
                            tw-py-1 tw-text-left tw-text-sm tw-font-medium tw-text-gray-900 hover:tw-bg-gray-200 tw-border-gray-300 
                            focus:tw-outline-none focus-visible:tw-ring focus-visible:tw-ring-gray-500 focus-visible:tw-ring-opacity-75">
                        <span>{category.name}</span>
                        <ChevronUpIcon
                            className={`${open ? 'tw-rotate-180 tw-transform' : ''} tw-h-5 tw-w-5 tw-text-gray-500`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="tw-pl-4 tw-pt-0.5 tw-pb-2">
                        { docStore.shownTypes.map((typeItem, index) =>
                            <DataModelType
                                key={index}
                                list={filteredDataModels}
                                type={typeItem}
                                onItemClick={onItemClick}
                                onItemDoubleClick={onItemDoubleClick}
                                selectedDataModel={selectedDataModel}
                            />
                        )}
                    </Disclosure.Panel>
                </>
            )}
            </Disclosure>
        </div>
    )
}

export default observer(DataModelCategory)