import React from 'react'
import ObjectsListItem from './ObjectsListItem'
import { Tooltip } from 'react-tooltip'


/**
 * Визуальный компонент отображает список объектов
 * @param {Object[]} objectsList Список существующих объектов
 * @param {Object} objectsListStage Текущий активный объект
 * @param {Boolean} isDelete Признак возможности удаления объектов
 * @param {Function} onDeleteClick Обработчик клика мыши для удаления объектов
 * [handleAskForDeleteClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line77),
 * [handleAskForDeleteClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line81),
 * [handleAskForDeleteClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line72)
 * @param {Function} onJumpStageClick Обработчик клика мыши для перехода к произвольному объекту
 * [handleJumpStageClick](./components_main_page_controller_education_materials_MaterialsObjectsContainer.js.html#line25)
 * [handleJumpStageClick](./components_main_page_controller_education_materials_tests_QuestionsListContainer.js.html#line24)
 * @param {Boolean} isSearch Признак наличия строки поиска
 * 
 * @returns {HTMLDivElement} Html-разметку списка существующих объектов
 * с использованием визуального компонента {@link ObjectsListItem}
 * 
 * @see [Вызов компонента CategorizingObjectsContainer,](./components_main_page_controller_categorizing_cii_form_categorizingObjects_CategorizingObjectsContainer.js.html#line92)
 * [MaterialsObjectsContainer](./components_main_page_controller_education_materials_MaterialsObjectsContainer.js.html#line79)
 * [QuestionsListContainer](./components_main_page_controller_education_materials_tests_QuestionsListContainer.js.html#line43)
 * [ObjectListPanel,](./components_main_page_controller_common_panels_object_list_ObjectListPanel.js.html#line37)
 */
const ObjectsList = ({objectsList, objectsListStage, isDelete, onDeleteClick, onJumpStageClick, isSearch}) => {
    return (
        <>
            <div  id='stage-list-items' className={`${isSearch ? 'tw-h-[calc(100%_-_6rem)]' : 'tw-h-[calc(100%_-_3rem)]' } tw-flex tw-flex-col tw-w-full tw-bg-white tw-overflow-auto`}>
                { objectsList.map((object, index) => 
                    <ObjectsListItem
                        key={index}
                        item={object}
                        stage={objectsListStage}
                        isDelete={isDelete}
                        onDeleteClick={onDeleteClick}
                        onJumpStageClick={onJumpStageClick}
                    />
                )}
            </div>
            <Tooltip id="object-list-tooltip" place="top-start" className="tw-max-w-xl" style={{ zIndex: 99 }}/>
        </>
    )
}

export default ObjectsList