import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useContext } from 'react'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает окно диалога
 * @param {String} parentName Имя родителя
 * @param {Function} dialogTabFunction Обработчик клика мыши по кнопке согласия
 * [handleSaveAndCloseClick](./components_main_page_controller_data_object_add_AddDataObjectContainer.js.html#line118),
 * [loadSaved](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line63),
 * [deleteCategory](./components_main_page_controller_category_menu_CategoryMenu.js.html#line30),
 * [handleBlockCompanyClick](./components_admin_page_company_CompanyForm.js.html#line102),
 * [handleBlockUserClick](./components_admin_page_company_CompanyUserInfo.js.html#line35),
 * [handleEditRedirect](./components_main_page_controller_categorizing_cii_info_DirectoriesInfo.js.html#line37),
 * [handleSaveAndCloseClick](./components_main_page_controller_data_object_add_DuplicateDataObjectContainer.js.html#line121),
 * [handleSaveAndCloseClick](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line151),
 * [dialogTabFunction](./components_main_page_controller_education_viewers_EducationPDFViewer.js.html#line43),
 * [dialogTabFunction](./components_main_page_controller_education_viewers_EducationVideoPlayer.js.html#line33),
 * [handleDeleteObjectClick](./components_main_page_controller_common_forms_employees_EmployeesDataForm.js.html#line134),
 * [handleDeleteExporterClick](./components_main_page_controller_exporter_ExporterListContainer.js.html#line163),
 * [handleDeleteImporterClick](./components_main_page_controller_importer_ImporterListContainer.js.html#line141),
 * [handleDeleteObjectClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line97),
 * [dialogTabFunction](./components_main_page_controller_education_materials_MaterialsObjectsForm.js.html#line55),
 * [handleDeleteObjectClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line92),
 * [handleEditRedirect](./components_main_page_controller_personal_data_info_PDataDirectoriesInfo.js.html#line37),
 * [handleDeleteObjectClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line101),
 * [handleAddItem](./components_main_page_controller_common_panels_DirectoryFieldsList.js.html#line211),
 * [loadSaved](./components_main_page_controller_personal_data_PersonalDataContainer.js.html#line62)
 * @param {Object} dialogTabStore Экземпляр хранилища информации
 *
 * @returns {JSXElement} Визуальный компонент Dialog из библиотеки {@link https://headlessui.com/|@headlessui}
 * 
 * @see [Вызов компонента AddDataObjectContainer,](./components_main_page_controller_data_object_add_AddDataObjectContainer.js.html#line248)
 * [CategorizingContainer,](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line171)
 * [CategoryMenu,](./components_main_page_controller_category_menu_CategoryMenu.js.html#line97)
 * [CompanyForm,](./components_admin_page_company_CompanyForm.js.html#line180)
 * [CompanyUserInfo,](./components_admin_page_company_CompanyUserInfo.js.html#line107) 
 * [DirectoriesInfo,](./components_main_page_controller_categorizing_cii_info_DirectoriesInfo.js.html#line130)
 * [DirectoryFieldsList,](./components_main_page_controller_common_panels_DirectoryFieldsList.js.html#line408)
 * [DuplicateDataObjectContainer,](./components_main_page_controller_data_object_add_DuplicateDataObjectContainer.js.html#line255)
 * [EditDataObjectContainer,](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line294)
 * [EducationPDFViewer,](./components_main_page_controller_education_viewers_EducationPDFViewer.js.html#line163)
 * [EducationVideoPlayer,](./components_main_page_controller_education_viewers_EducationVideoPlayer.js.html#line146)
 * [EmployeesDataForm,](./components_main_page_controller_common_forms_employees_EmployeesDataForm.js.html#line445)
 * [ExporterListContainer,](./components_main_page_controller_exporter_ExporterListContainer.js.html#line262)
 * [ImporterListContainer,](./components_main_page_controller_importer_ImporterListContainer.js.html#line237)
 * [ISPDataListContainer,](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line229)
 * [MaterialsObjectsForm,](./components_main_page_controller_education_materials_MaterialsObjectsForm.js.html#line319)
 * [PData3PartyListContainer,](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line214)
 * [PDataDirectoriesInfo,](./components_main_page_controller_personal_data_info_PDataDirectoriesInfo.js.html#line132)
 * [PDataProcessListContainer,](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line239)
 * [PersonalDataContainer](./components_main_page_controller_personal_data_PersonalDataContainer.js.html#line163)
 * [SubCompanyForm,](./components_company_admin_page_sub_company_SubCompanyForm.js.html#line181)
 */
const DialogTab = ({parentName, dialogTabFunction, dialogTabStore}) => {
    const { DialogTabStore } = useContext(Context)
    const dialogStore = dialogTabStore ? dialogTabStore : DialogTabStore

    const dialogTabCloseClick = () => {
        dialogStore.setDialogTabIsOpen(false)
    }

    const [submitButtonText, cancelButtonText] = dialogStore.dialogTabButtons

    return (
        <Transition appear show={dialogStore.dialogTabIsOpen && dialogStore.parentName === parentName} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={() => {dialogStore.allowAreaCloseClick && dialogTabCloseClick()}}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-25" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-y-auto">
                    <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4 tw-text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="tw-ease-out tw-duration-300"
                            enterFrom="tw-opacity-0 tw-scale-95"
                            enterTo="tw-opacity-100 tw-scale-100"
                            leave="tw-ease-in tw-duration-200"
                            leaveFrom="tw-opacity-100 tw-scale-100"
                            leaveTo="tw-opacity-0 tw-scale-95"
                        >
                            <Dialog.Panel className="tw-w-full tw-max-w-md tw-transform tw-overflow-hidden tw-rounded-2xl tw-bg-white tw-p-6 tw-text-left tw-align-middle tw-shadow-xl tw-transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                                >
                                    {dialogStore.dialogTabTitle}
                                </Dialog.Title>

                                <div className="tw-mt-4">
                                    <p className="tw-text-sm tw-text-gray-500">
                                        {dialogStore.dialogTabText}
                                    </p>
                                </div>

                                <div className='tw-mt-4 tw-flex tw-justify-center'>
                                    <button className='tw-w-32 tw-m-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white disabled:tw-bg-gray-300 disabled:tw-border-gray-300  hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                        type='button'
                                        onClick={dialogTabFunction}
                                    >
                                        {submitButtonText}
                                    </button>
                                    <button className='tw-w-32 tw-m-2 tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700 hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                        type='button'
                                        onClick={dialogTabCloseClick}
                                    >
                                        {cancelButtonText}
                                    </button>
                            </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default observer(DialogTab)
