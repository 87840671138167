// Функция проверяет видимость элемента на экране
export function isScrolledIntoView(element) {
    const rect = element.getBoundingClientRect();
    const elementTop = rect.top;
    const elementBottom = rect.bottom;

    // Только полностью видимый элемент возвращает true:
    const isVisible = (elementTop >= 0) && (elementBottom <= window.innerHeight);
    // Частично видимый элемент возвращает true:
    // const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
}
