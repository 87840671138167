import React, { useContext } from 'react'
import { Tooltip } from 'react-tooltip'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'
import { Context } from '../../../../..'
import { CheckCircleIcon, MinusIcon } from '@heroicons/react/20/solid'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает название одной таблицы для списка существующих таблиц
 * @param {Object} item Текущий элемент списка таблиц
 * 
 * @returns {HTMLDivElement} Html-разметку с названием таблицы
 * 
 * @see [Вызов компонента](./components_main_page_controller_category_data_model_DataModelList.js.html#line21)
 */
const DataModelListItem = ({item}) => {
    const { docStore, userStore } = useContext(Context)

    const isDataModelChecked = docStore.categoryDataModels.includes(item.id)

    let className = 'tw-relative tw-pl-12 tw-text-sm tw-w-full tw-border-b hover:tw-cursor-pointer'

    if (docStore.selectedDataModel && docStore.selectedDataModel.id === item.id && !docStore.selectedCategory) {
        className += ` tw-text-white tw-bg-gray-500`
    } else {
        className += ` tw-text-gray-900 tw-bg-white hover:tw-bg-gray-200 hover:tw-border-gray-300`
    }

    const onClick = (dataModel) => {
        docStore.setSelectedDataModel(dataModel)
        docStore.setDataModelCategories(dataModel.categories.map(item => item.id))
        docStore.setSelectedCategory(null)
    }

    const onLinkClick = (item) => {
        const originalCategories = item.category_ids
        const editedCategories = originalCategories.includes(docStore.selectedCategory.id)
                        ?   originalCategories.filter((category) => category !== docStore.selectedCategory.id)
                        :   originalCategories.concat([docStore.selectedCategory.id])

        docStore.setSelectedDataModel(item)
        docStore.updateDataModelCategories(item, editedCategories)
    }

    return (
        <>
            <div
                id={item.id}
                className={className}
            >
                { docStore.selectedCategory && (!item.subgroup_id || item.subgroup_id == userStore.currentSubCompany.id) &&
                    <div
                        className='tw-text-gray-500 tw-absolute tw-left-3 tw-py-1'
                        onClick={() => onLinkClick(item)}
                    >
                        { isDataModelChecked
                            ?   <CheckCircleIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                            :   <MinusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        }
                    </div>
                }
                <div
                    className='tw-grid tw-grid-cols-5'
                    onClick={() => onClick(item)}
                >
                <div className={`${item.hide_mark ? 'tw-line-through tw-text-red-400' : ''} tw-col-span-4 tw-py-1`}>
                    {item.entity_name}
                </div>
                <div className='tw-col-span-1 tw-justify-self-end tw-self-start tw-px-2 tw-flex tw-flex-row tw-gap-x-2 tw-py-1'>
                    <span 
                        className='tw-px-2 tw-py-0.5 tw-w-6 tw-h-6 tw-rounded-md tw-text-gray-900 tw-bg-gray-300 tw-font-bold'
                        data-tooltip-id="data-model-item-tooltip" data-tooltip-content={item.type === 'directory' ? 'Справочник' : 'Документ'} data-tooltip-delay-show={menuTooltipTimeOut}
                    >
                        {item.type === 'document'
                            ?   'Д'
                            :   item.type === 'nested'
                                ?   'В'
                                :   'С'
                        }
                    </span>
                    {' '}
                    <span
                        className='tw-px-2 tw-py-0.5 tw-w-6 tw-h-6 tw-rounded-md tw-text-gray-900 tw-bg-gray-300 tw-font-bold'
                        data-tooltip-id="data-model-item-tooltip" data-tooltip-content={item.built_in ? 'Из конфигурации системы' : 'Пользовательский'} data-tooltip-delay-show={menuTooltipTimeOut}
                    >
                        {item.built_in ? 'К' : 'П'}
                    </span>
                </div>
                </div>
            </div>
            <Tooltip id="data-model-item-tooltip" place="top"/>
        </>
    )
}

export default observer(DataModelListItem)