import React, { useEffect, useState, useContext, Fragment, useRef } from 'react'
import { Context } from '../../../../..'
import DocumentService from '../../../../../services/DocumentService'
import DataObjectForm from '../form/DataObjectForm'
import DataObjectTabs from '../../../../tabs/DataObjectTabs'
import PageTitle from '../../common/panels/PageTitle'
import Spinner from '../../../../../assets/Spinner'
import { toast } from 'react-toastify'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../../config/constTypes'
import { handleFileRemove, handleFileSelect } from '../../../../../functions/fileHandlers'
import { getErrorInfo, showErrorToast } from '../../../../../functions/errorHandlers'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PanelVerticalDivider from '../../common/dividers/PanelVerticalDivider'
import { observer } from 'mobx-react-lite'
import { addNestedDataObjects } from '../../../../../functions/nestedModels'
import DialogTab from '../../../../dialog_tab/DialogTab'
import parse from 'html-react-parser'
import DialogTabStore from '../../../../../store/DialogTabStore'


/**
 * Компонент реализует логику создания новой записи путем дублирования существующей записи в таблице
 * 
 * @param {Boolean} isOpen Признак видимости (отображения) модального окна
 * @param {Function} onCloseClick Обработчик клика мыши для закрытия окна
 * [handleObjectContainerCloseClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line342),
 * [handleObjectContainerCloseClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line202)
 * @param {Object} id ID записи
 * @param {Object} selectedFullDataModel Выбранная таблица
 * @param {Object} recordNotesStore Экземпляр хранилища информации о сообщениях
 * 
 * @returns {HTMLDivElement} Html-разметку для создания новой записи 
 * с использованием визуальных компонентов {@link Spinner}, {@link PageTitle}, {@link DataObjectForm}, {@link DataObjectTabs}, {@link DialogTab},
 * {@link PanelVerticalDivider}
 * 
 * @see [Вызов компонента DataObjectListContainer,](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line611)
 * [DirectoryContainer](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line501)
 */
const DuplicateDataObjectContainer = ({isOpen, onCloseClick, id, selectedFullDataModel, recordNotesStore}) => {
    const { docStore } = useContext(Context)
    const [dialogTabStore] = useState(() => new DialogTabStore())

    const [isLoading, setIsLoading] = useState(true)
    const [isObjLoading, setIsObjLoading] = useState(false)
    const [selectedDataObject, setSelectedDataObject] = useState(null)
    const [errorInfo, setErrorInfo] = useState({})
    const [attachedFiles, setAttachedFiles] = useState([])
    const formElement = useRef(null)
    const panelElement = useRef(null)

    const [nestedDataModels, setNestedDataModels] = useState([])
    const [selectedNestedDataModel, setSelectedNestedDataModel] = useState(null)

    const minLeftColumnSize = 400
    const minRightColumnSize = 300
    const defaultColumnSizes = '66.666667% 33.333333%'
    const savedColumnSizes = sessionStorage.getItem('savedFormSizes')   
    const columnSizes = savedColumnSizes || defaultColumnSizes

    const handleSubmitClick = async (form) => {
        setIsObjLoading(true)
        const noResponse = setTimeout(() => {
            setIsObjLoading(false)
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const dataObject = {}
        dataObject.data = {}
        for (const [key, value] of Object.entries(form.data)) {
            if (Array.isArray(value)) {
                if (value[0]?.value?.values?.length)
                    dataObject.data[key] = [value[0].value.values[0].record_id]
                else 
                    dataObject.data[key] = []
            } else {
                if (value !== undefined && value !== null && value !== '')
                    dataObject.data[key] = value.id || value
            }
        }
        if (attachedFiles.length) {
            dataObject.system_data = {}
            dataObject.system_data.files = attachedFiles.map(file => { return { id: file.id }})    
        }
        if (form.system_data && form.system_data.parent_record_id && form.system_data.parent_record_id.value?.values?.length) {
            if (!dataObject.system_data)
                dataObject.system_data = {}
            dataObject.system_data.parent_record_id = form.system_data.parent_record_id.value.values[0].record_id
        }
        dataObject.data_model_id = selectedDataObject.data_model_id

        nestedDataModels.forEach(nestedDataModel => {
            dataObject.data[nestedDataModel.tech_name] = { upsert: addNestedDataObjects(nestedDataModel)}
        })

        DocumentService
            .createDataObject(dataObject)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Информация успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                setIsObjLoading(false)
                onCloseClick(false)
            })
            .catch(error => {
                clearTimeout(noResponse)
                setIsObjLoading(false)
                const [errorMessage, errorInfoObject] = getErrorInfo(error, Object.values(selectedDataObject.data))
                setErrorInfo(errorInfoObject)
                showErrorToast(error, 'saving', errorMessage)
            })
    }

    const handleCloseDialogClick = () => {
        dialogTabStore.setParentName('DuplicateDataObjectContainer')
        dialogTabStore.setDialogTabTitle("Завершение создания записи") 
        dialogTabStore.setDialogTabText(parse(`Вы хотите закрыть форму создания записи без сохранения?<br/><br/>В противном случае нажмите "Отменить" и сохраните изменения`))
        dialogTabStore.setDialogTabButtons(["Закрыть", "Отменить"])
        dialogTabStore.setDialogTabIsOpen(true)
    }

    const handleSaveAndCloseClick = () => {
        dialogTabStore.setDialogTabIsOpen(false)
        onCloseClick()
    }

    useEffect(() => {
        if (isOpen && selectedFullDataModel) {
            setIsLoading(true)
            const noResponse = setTimeout(() => {
                toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                setIsLoading(false)
                setSelectedDataObject({id:'0', entity_name: '', data: {}})
            }, responseTimeOut)

            DocumentService
                .getOneDataObject(id)
                .then(dataObject => {
                    clearTimeout(noResponse)
                    setSelectedDataObject(dataObject)
                    docStore.getAllNestedDataObjects(dataObject, setNestedDataModels, true)    
                    setIsLoading(false)
                })
                .catch(error => {
                    clearTimeout(noResponse)
                    setIsLoading(false)
                    showErrorToast(error, 'fetching', '')
                    setSelectedDataObject({id:'0', entity_name: '', data: {}})
                })
        } else {
            setAttachedFiles([])
        }
    }, [isOpen])  

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={handleCloseDialogClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[90vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                        <button
                                            type="button"
                                            className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                            onClick={handleCloseDialogClick}
                                        >
                                            <span className="tw-sr-only">Закрыть</span>
                                            <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                        </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-bg-gray-200 tw-p-4 tw-shadow-xl">
                                        {isLoading 
                                            ?   <Spinner/> 
                                            :   <>
                                                    <PageTitle title={`Новая запись таблицы "${selectedDataObject.entity_name}"`} />
                                                    <div
                                                        id='duplicate-data-object-page'
                                                            style={{
                                                            display: 'grid', 
                                                            gridTemplateColumns: columnSizes,
                                                            height: 'calc(100% - 4em)'
                                                        }}
                                                        ref={panelElement}
                                                    >
                                                        <div id='data-form-column' className='tw-h-full tw-overflow-hidden' ref={formElement}>
                                                            <DataObjectForm
                                                                editMode={true}
                                                                dataObjectFields={Object.values(selectedDataObject.data)}
                                                                onSubmitClick={handleSubmitClick}
                                                                userErrors={errorInfo}
                                                                showExportButton={false}
                                                                onPrintRecordClick={null}
                                                                onPrintDocumentClick={null}
                                                                isLoading={isObjLoading}
                                                                dataModel={selectedFullDataModel}
                                                                parent={selectedDataObject.system_data.parent}
                                                            />
                                                        </div>
                                                        <div id='data-files-column' 
                                                            className='tw-h-full tw-overflow-hidden tw-relative tw-pl-4'
                                                        >
                                                            <PanelVerticalDivider 
                                                                leftElement={formElement}
                                                                panel={panelElement}
                                                                minLeftSize={minLeftColumnSize}
                                                                minRightSize={minRightColumnSize}
                                                            />
                                                            <DataObjectTabs
                                                                files={attachedFiles}
                                                                readOnly={false}
                                                                onFileSelect={handleFileSelect(attachedFiles, setAttachedFiles)}
                                                                onFileRemove={handleFileRemove(attachedFiles, setAttachedFiles)}
                                                                selectedNestedDataModel={selectedNestedDataModel}
                                                                nestedDataModels={nestedDataModels}
                                                                setSelectedNestedDataModel={setSelectedNestedDataModel}
                                                                setNestedDataModels={setNestedDataModels}
                                                                isDocumentPage={true}
                                                                isNestedLoading={false}
                                                                panel={panelElement}
                                                                recordNotesStore={recordNotesStore}
                                                            />
                                                        </div>
                                                    </div>
                                                    <DialogTab 
                                                        parentName='DuplicateDataObjectContainer'
                                                        dialogTabFunction={handleSaveAndCloseClick}
                                                        dialogTabStore={dialogTabStore}
                                                    />
                                                </>
                                        }
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default observer(DuplicateDataObjectContainer)
