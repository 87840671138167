import React, { useState, useRef, useEffect, useCallback, useContext } from 'react'
import { getFieldValue } from '../../../../../../config/constTypes'
import { Tooltip } from 'react-tooltip'
import { ChevronDoubleUpIcon, ChevronDownIcon, ChevronUpIcon, PaperClipIcon } from '@heroicons/react/20/solid'
import { createFieldsListHeaders } from '../../../../../../functions/createFieldsListHeaders'
import FilterMenu from '../../../../../filters/FilterMenu'
import { observer } from 'mobx-react-lite'
import DialogTab from '../../../../../dialog_tab/DialogTab'
import { Context } from '../../../../../..'
import { toast } from 'react-toastify'
import DirectoryHierarchyTree from './DirectoryHierarchyTree'
import DirectoryHierarchyHeader from './DirectoryHierarchyHeader'


function checkDataObjectSelection(dataObject, dataObjectList) {
    // определение выбран ли уже объект (при множественном выборе)
    let isSelected = false
    dataObjectList?.forEach(item => {
        if(item.status !== 'deleted'){
            Object.entries(item.data).forEach(([key, value]) => {
                if(value?.type === 'reference'){
                    value?.ref_model_ids?.forEach(model => {
                        if (model === dataObject.data_model_id) {
                            if(value?.value?.values?.length > 0){
                                if(dataObject.record_id === value?.value?.values[0].record_id){
                                    isSelected = true
                                }
                            }
                        }
                    })
                }
            })
        }
    })

    return isSelected
}

/**
 * Визуальный компонент отображает список записей выбранной таблицы.
 * По умолчанию записи сортируются по дате создания (от более ранних к более поздним)
 * 
 * @param {Object} dataModel Активная таблица
 * @param {Object[]} dataObjects Массив записей активной таблицы
 * @param {Object} selectedDataObject Активная запись таблицы
 * @param {Function} onItemClick Обработчик клика мыши на элементе списка
 * [handleDataObjectClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line106)
 * [handleDataObjectClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line123),
 * @param {Function} onItemDoubleClick Обработчик двойного клика мыши на элементе списка
 * [handleObjectSelect](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line316),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line68),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line96),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line62),
 * [handleDoubleClick](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line97),
 * [handleDoubleClick](./components_main_page_controller_field_FieldForm.js.html#line129),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line95),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line59),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line65),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line94),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line72),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line127),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line87),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line59),
 * [handleDoubleClick](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line79),
 * [handleDoubleClick](./components_main_page_controller_personal_data_PersonalDataContainer.js.html#line67),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line70),
 * [handleDoubleClick](./components_main_page_controller_categorizing_cii_form_significanceIndicators_SignificanceIndicatorsForm.js.html#line79),
 * [handleValueChange](./components_tabs_nested_tables_NestedTableFieldsList.js.html#line368)
 * @param {Function} onSortClick Обработчик клика мыши на элементе изменения сортировки списка
 * [handleSortClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line352)
 * [handleSortClick](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line212),
 * @param {Function} onFetchData Обработчик события достижения конца списка
 * [setIsFetchingData](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line53)
 * [setIsFetchingData](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line110),
 * @param {Object[]} chosenDataObjectsList Массив выбранных записей
 * @param {Object} filterStore Экземпляр хранилища информации о применяемых фильтрах
 * @param {Boolean} isChosenObjectDuplicationForbidden Признак запрета повторного добавления записи
 * 
 * @returns {HTMLDivElement} Html-разметку списка записей из таблицы
 * с использованием визуальных компонентов {@link FilterMenu}, {@link DialogTab}
 * 
 * @see [Вызов компонента](./components_main_page_controller_common_panels_directory_DirectoryPanel.js.html#line103)
 */
const DirectoryFieldsList = ({dataModel, dataObjects, selectedDataObject, onItemClick, onItemDoubleClick, onSortClick, onFetchData, 
                                chosenDataObjectsList, filterStore, isChosenObjectDuplicationForbidden, onHierarchyClick}) => {
    const { docStore, DialogTabStore } = useContext(Context)

    const [activeColumn, setActiveColumn] = useState(null)
    const [activeRow, setActiveRow] = useState(null)
    const [offsetX, setOffsetX] = useState(0)
    const [isMinScrollPosition, setIsMinScrollPosition] = useState(true)
    const [isVerticalScroll, setIsVerticalScroll] = useState(false)
    const [chosenObject, setChosenObject] = useState(null)
    let prevScrollLeft = 0
    
    const tableHeaderElement = useRef(null)
    const tableBodyElement = useRef(null)
    const frameElement = useRef(null)
    const headerElement = useRef(null)
    
    const headers = dataModel.fields
                        .slice()
                        .filter(field => !field.hide && field.type !== 'include')
                        .sort((a, b) => a.order - b.order)

    let defaultColumnValues = headers.map(item => 200)

    headers.push({alias: 'Файлы', tech_name: 'files', validator_type: 'string'})
    headers.push({alias: 'Автор', tech_name: 'author_id', validator_type: 'string'})

    defaultColumnValues.push(100, 200)

    const savedColumnValues = JSON.parse(localStorage.getItem('columns_' + dataModel.id))
    let columnValues
    if (savedColumnValues && savedColumnValues.length === defaultColumnValues.length) {
        columnValues = savedColumnValues
    } else {
        columnValues = defaultColumnValues
        if (savedColumnValues)
            localStorage.removeItem('columns_' + dataModel.id)
    }
    const columnSizes = columnValues.map(item => item ? item + 'px' : '100px').join(' ')

    const mouseDown = (index) => (e) => {
        setActiveColumn(index)
        const offset = e.clientX - columnValues[index]
        setOffsetX(offset)
    }
    
    const mouseMove = useCallback((e) => {
        const minCellWidth = 100

        const gridColumns = headers.map((column, index) => {
            if (index === activeColumn) {
                const width = e.clientX - offsetX
                if (width >= minCellWidth) {
                    return width
                }
            }
            return columnValues[index] ? columnValues[index] : minCellWidth
        })
        
        columnValues = gridColumns
        const gridTemplateColumns = `${gridColumns.map(item => item + 'px').join(" ")}`
        tableHeaderElement.current.style.gridTemplateColumns = gridTemplateColumns
        tableBodyElement.current.childNodes[0].childNodes.forEach(row =>
            row.style.gridTemplateColumns = gridTemplateColumns
        )
    }, [activeColumn, headers, offsetX])
    
    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove)
        window.removeEventListener("mouseup", mouseUp)
    }, [mouseMove])
    
    const mouseUp = useCallback(() => {
        if (tableBodyElement.current) {
            localStorage.setItem('columns_' + dataModel.id, JSON.stringify(columnValues))
        }
        removeListeners()
        setActiveColumn(null)
        setOffsetX(0)
    }, [setActiveColumn, setOffsetX, removeListeners])

    const handleMouseEnter = (id) => {
        setActiveRow(id)
    }
    
    const handleMouseLeave = () => {
        setActiveRow(null)
    }

    const processTableScroll = (element) => {
        if (element) {
            headerElement.current.scrollLeft = element.scrollLeft
            setIsMinScrollPosition(frameElement.current.scrollTop === 0)
        }
    }

    const scrollTableUp = () => {
        if (frameElement) {
            frameElement.current.scrollTop = 0
        }
    }
    
    const onDoubleClick = (object, isChosenDataObject) => {
        if (object.active && !object.system_data.deletion_mark) {
            if (isChosenDataObject) {
                if (isChosenObjectDuplicationForbidden) {
                    toast.error('Повторное добавление записи! Вы не можете добавить эту запись ещё раз', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                } else {
                    DialogTabStore.setParentName('DirectoryFieldsList')
                    DialogTabStore.setDialogTabTitle("Повторное добавление записи") 
                    DialogTabStore.setDialogTabText("Вы уверены, что хотите добавить эту запись ещё раз?")
                    DialogTabStore.setDialogTabButtons(["Да", "Нет"])
                    DialogTabStore.setDialogTabIsOpen(true)
                    setChosenObject(object)
                }
            } else {
                onItemDoubleClick(object)
                if (chosenDataObjectsList)
                    toast.success('Запись успешно добавлена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
            }
        } else {
            toast.error('Архивную или помеченную на удаление запись выбрать нельзя!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }

    const handleAddItem = () => {
        onItemDoubleClick(chosenObject)
        DialogTabStore.setDialogTabIsOpen(false)
    }

    useEffect(() => {
        const handleScroll = (e) => {
            if (prevScrollLeft ===  e.target.scrollLeft){
                if ((e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 100) {
                    onFetchData(true)
                }
            } else {
                prevScrollLeft = e.target.scrollLeft
            }
        }
        const element = frameElement.current
        element.addEventListener('scroll', handleScroll)

        return function () {
            element.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (activeColumn !== null) {
            window.addEventListener("mousemove", mouseMove)
            window.addEventListener("mouseup", mouseUp)
        }
    
        return () => {
            removeListeners()
        }
    }, [activeColumn, mouseMove, mouseUp, removeListeners])

    useEffect(() => {
        const element = frameElement.current
        setIsVerticalScroll(element.scrollHeight !== element.clientHeight)
    }, [dataObjects])

    return (
        <>
            <div className='tw-w-full tw-flex tw-flex-row tw-h-8'>
                <div className='tw-w-full tw-flex tw-flex-row tw-overflow-hidden' ref={headerElement}>
                    { !docStore.isHistoryView &&
                        <DirectoryHierarchyHeader
                            dataModel={dataModel}
                            treeNodes={dataObjects}
                        />
                    }
                    <div
                        style={{
                            display: 'grid', 
                            gridTemplateColumns: columnSizes,
                            borderBottomWidth: '1px',
                            flexGrow: '1',
                        }}
                        ref={tableHeaderElement}
                    >
                        { headers.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className='tw-group tw-overflow-hidden tw-truncate tw-text-left tw-text-sm tw-font-semibold tw-pl-4 tw-pr-6 tw-py-1 
                                            tw-relative tw-border-r tw-border-gray-300 '
                                >
                                    { item.validator_type !== 'one' && item.validator_type !== 'many' &&
                                        <span
                                            className={`tw-absolute tw-left-0 tw-inset-y-auto group-hover:tw-opacity-50 
                                                ${dataModel.sortingColumn ? dataModel.sortingColumn === item.tech_name ? 'tw-opacity-100' : 'tw-opacity-0' 
                                                    : 'tw-opacity-50' }
                                            `}
                                            onClick={() => onSortClick(dataModel.id, item.tech_name)}
                                        >
                                            { dataModel.sortingDirection === 'up'
                                                ?   <ChevronUpIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                                :   <ChevronDownIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                                            }
                                        </span>
                                    }
                                    <span 
                                        data-tooltip-id="field-list-tooltip" data-tooltip-content={item.alias} data-tooltip-delay-show={1000}
                                    >
                                        {item.alias}
                                    </span>
                                    {item.tech_name !== 'author_id' && 
                                        <FilterMenu
                                            column={item}
                                            author={true}
                                            object={dataModel}
                                            data={true}
                                            filterStore={filterStore}
                                        />
                                    }
                                    <span 
                                        className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-w-1 tw-cursor-col-resize'
                                        onMouseDown={mouseDown(index)}
                                    >
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </div>
                { isVerticalScroll &&
                    <button
                        type='button'
                        className='tw-w-4 tw-px-0 tw-py-1 tw-bg-gray-100 hover:tw-bg-gray-300 disabled:tw-text-gray-400 disabled:tw-bg-gray-100'
                        onClick={scrollTableUp}
                        disabled={isMinScrollPosition}
                    >
                        <ChevronDoubleUpIcon className='tw-w-4 tw-h-4' aria-hidden='true'/>
                    </button>
                }
            </div>
            <div
                className='tw-h-[calc(100%_-_2rem)] tw-overflow-auto tw-flex tw-flex-row'
                onScroll={(e) => processTableScroll(e.target)}
                ref={frameElement}
            >
                { !docStore.isHistoryView &&
                    <DirectoryHierarchyTree
                        dataModel={dataModel}
                        treeNodes={dataObjects}
                        onHierarchyClick={onHierarchyClick}
                    />
                }
                <table ref={tableBodyElement} className='tw-w-full'>
                    <tbody>
                        {/* невидимая строка для отображения полосы прокрутки, даже если нет записей */}
                        {dataObjects.length === 0 && 
                            <tr 
                                style={{
                                    display: 'grid', 
                                    gridTemplateColumns: columnSizes,
                                    height: '24px',
                                    borderBottomWidth: '1px',
                                    visibility: 'hidden',
                                    pointerEvents: 'none'
                                }} 
                            ></tr>
                        }
                        { dataObjects.map((object, index) => {
                            const isChosenDataObject = checkDataObjectSelection(object, chosenDataObjectsList)

                            return <tr 
                                    key={index}
                                    style={{
                                        display: 'grid', 
                                        gridTemplateColumns: columnSizes, 
                                        height: '24px',
                                        borderBottomWidth: '1px',
                                        cursor: 'pointer',
                                        background: selectedDataObject && object.id === selectedDataObject.id 
                                                ? 'rgb(107 114 128)' 
                                                : object.id === activeRow 
                                                    ? 'rgb(209 213 219)'
                                                    : isChosenDataObject 
                                                        ? 'rgb(229 231 235)'
                                                        : 'white',
                                        color: selectedDataObject && object.id === selectedDataObject.id ? 'white' : 'black',
                                        textDecorationLine: object.system_data && object.system_data.deletion_mark ? 'line-through' : 'none',
                                    }}
                                    onClick={() => onItemClick(object)}
                                    onDoubleClick={() => onDoubleClick(object, isChosenDataObject)}
                                    onMouseEnter={() => handleMouseEnter(object.id)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    { Object.values(object.data)
                                        .sort((a, b) => a.order - b.order)
                                        .filter(field => !field.hide && field.type !== 'include')
                                        .map((field, i) => {
                                            const value = getFieldValue(field)
                                            return (
                                                <td 
                                                    key={i}
                                                    className={`${field.modified ? 'tw-bg-red-300' : ''} 
                                                                tw-text-sm tw-py-0.5 tw-px-4 tw-overflow-hidden tw-truncate tw-border-r tw-border-gray-300
                                                            `}
                                                    data-tooltip-id="field-list-tooltip" data-tooltip-content={value} data-tooltip-delay-show={1000}
                                                >
                                                    {value}
                                                </td>
                                            )
                                        })
                                    }
                                    <td className={`${object.system_data.modified ? 'tw-bg-red-300' : ''} 
                                                    tw-text-sm tw-pt-1 tw-px-4 tw-overflow-hidden tw-truncate tw-border-r tw-border-gray-300`}
                                    >
                                        {object.system_data.files && object.system_data.files.length
                                            ?   <PaperClipIcon className='tw-w-4 tw-h-4' aria-hidden='true'/>
                                            :   null
                                        }
                                    </td>
                                    <td className='tw-text-sm tw-py-0.5 tw-px-4 tw-overflow-hidden tw-truncate tw-border-r tw-border-gray-300'>
                                        {object.author ? object.author.email : object.author_id}
                                    </td>
                                </tr>
                            })}
                    </tbody>
                </table>
            </div>
            <Tooltip id="field-list-tooltip" place="top-start" className='tw-max-w-xl' style={{ zIndex: 99 }}/>
            <DialogTab
                parentName='DirectoryFieldsList'
                dialogTabFunction={handleAddItem}
            />
        </>
    )
}

export default observer(DirectoryFieldsList)