import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../../index'
import DataModelCategoryList from './list/DataModelCategoryList'
import DataModelInfo from './info/DataModelInfo'
import DataModelFieldInfo from './info/DataModelFieldInfo'
import { paths } from '../../../../config/constsURN'
import DocumentService from '../../../../services/DocumentService'
import { showErrorToast } from '../../../../functions/errorHandlers'
import Spinner from '../../../../assets/Spinner'
import { observer } from 'mobx-react-lite'
import PageTitle from '../common/panels/PageTitle'
import DataModelMenu from './menu/DataModelMenu'
import { toast } from 'react-toastify'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../config/constTypes'

/**
 * Компонент реализует логику работы со структурами таблиц (моделью данных)
 * @returns {HTMLDivElement} Html-разметку страницы с использованием визуальных компонентов
 * {@link PageTitle}, {@link DataModelCategoryList}, {@link DataModelMenu}, {@link DataModelInfo} и {@link DataModelFieldInfo}
 */
const DataModelListContainer = () => {
    const navigate = useNavigate()
    const { docStore } = useContext(Context)

    const [activeDataModel, setActiveDataModel] = useState(null)
    const [selectedField, setSelectedField] = useState(null)  

    const getAllDataModels = () => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            docStore.setIsDataModelLoading(false)
        }, responseTimeOut)

        const savedDataModel = JSON.parse(sessionStorage.getItem('activeDataModel'))
        const sorter = JSON.stringify([
            {property: 'entity_name', desc: false},
        ])
        docStore.setIsDataModelLoading(true)

        DocumentService
            .getDataModels([], sorter, 'w')
            .then(data => {
                clearTimeout(noResponse)
                docStore.setDataModels(data)
                if (savedDataModel) {
                    const foundDataModel = data.find(item => item.id === savedDataModel.id)
                    if (foundDataModel) {
                        docStore.setSelectedDataModel(foundDataModel)
                        setActiveDataModel(foundDataModel)
                    } else {
                        sessionStorage.removeItem('activeDataModel')
                    }
                }
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
                docStore.setDataModels([])
            })
            .finally(() => docStore.setIsDataModelLoading(false))
    }

    const handleDataModelClick = (item) => {
        if (activeDataModel?.id !== item.id) {
            sessionStorage.setItem('activeDataModel', JSON.stringify(item))
            docStore.setSelectedDataModel(item)
            setActiveDataModel(item)
            setSelectedField(null)
        }
    }

    const handleAddDataModelClick = () => {
        navigate(paths.ADD_DATAMODEL_ROUTE)
    }

    const handleEditDataModelClick = () => {
        navigate(paths.EDIT_DATAMODEL_ROUTE + '/' + activeDataModel.id)
    }

    const handleHideDataModelClick = (dataModel) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const editedDataModel = {}
        editedDataModel.id = dataModel.id
        editedDataModel.entity_name = dataModel.entity_name
        editedDataModel.hide_mark = !dataModel.hide_mark
        editedDataModel.type = dataModel.type
        
        DocumentService
            .editDataModel(dataModel.id, editedDataModel)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Таблица успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                getAllDataModels()
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    useEffect(() => {
        docStore.setShownTypes(['document', 'directory', 'nested'])
        docStore.setIsShowInfo(true)

        const defaultFilter = []
        const categorySorter = JSON.stringify([
            {property: 'order', desc: false},
        ])      
        docStore.getCategories(defaultFilter, categorySorter)

        getAllDataModels()
    }, [])

    if (docStore.isDataModelLoading) {
        return  <Spinner/>
    }

    return (
        <>
            <PageTitle title={'Структуры данных'} />
            <div id='data-model-page' className='tw-grid tw-grid-cols-7 tw-gap-x-8 tw-max-h-full tw-h-full'>
                <div id='data-model-list' className='tw-col-span-2 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <DataModelMenu 
                        activeItem={activeDataModel}
                        onAddItemClick={handleAddDataModelClick}
                        onEditItemClick={handleEditDataModelClick}
                        onHideItemClick={handleHideDataModelClick}
                    />
                    <DataModelCategoryList
                        selectedDataModel={docStore.selectedDataModel}
                        onItemClick={handleDataModelClick}
                        onItemDoubleClick={handleDataModelClick}
                    />
                </div>
                <div id='data-model-column' className='tw-col-span-3 tw-h-full tw-bg-white tw-rounded-md tw-overflow-hidden'>
                    <div id='data-model-header' className='tw-h-12 tw-flex tw-items-center tw-border-b-2 tw-border-gray-400 tw-px-4 tw-py-2'>
                        <p className='tw-text-md tw-font-semibold tw-w-24 sm:tw-w-96'>Информация о таблице</p>
                    </div>
                    { activeDataModel &&
                        <DataModelInfo 
                            dataModel={activeDataModel}
                            selectedField={selectedField}
                            onFieldClick={setSelectedField}
                        />
                    }
                </div>
                <div id='data-model-field-column' className='tw-col-span-2 tw-h-full'>
                    { selectedField && 
                        <DataModelFieldInfo
                            field={selectedField}
                        />
                    }
                </div>
            </div>           
        </>
    )
}

export default observer(DataModelListContainer)