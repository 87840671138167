import React from 'react'
import ModuleItem from './ModuleItem'
import defineModuleColors from './defineModuleColors'
import ActiveModuleItem from './ActiveModuleItem'


/**
 * Визуальный компонент отображает группу модулей МЕДОЕДа
 * @param {Object} group Группа модулей
 * @param {Object[]} records Существующие записи процессов
 *
 * @returns {HTMLDivElement} Html-разметку группы модулей МЕДОЕДа
 *
 * @see [Вызов компонента MainPageContainer](./components_main_page_MainPageContainer.js.html#line172)
 */
const ModuleGroup = ({group, records}) => {
    const theme = defineModuleColors(group.theme)

    return (
        <div className={`tw-mb-6 sm:tw-mb-0 tw-flex tw-flex-col tw-w-full tw-h-60 tw-rounded-md tw-bg-white`}>
            <div className={`tw-text-sm tw-text-left tw-font-semibold tw-text-white tw-w-full tw-pl-4 tw-py-1 tw-rounded-t-md ${theme.borderColor} ${theme.captionColor} tw-border-b-2`}>
                {group.name}
            </div>
            <div className='tw-px-4 tw-py-2 tw-overflow-auto'>
                {group.modules.length > 0 ?
                    <>
                        {group.modules.map((module, index) => {
                            return group.value === 'active'
                                ?   <ActiveModuleItem
                                        key={index}
                                        module={module}
                                        colorTheme={theme}
                                        records={records}
                                    />
                                :   <ModuleItem
                                        key={index}
                                        module={module}
                                        colorTheme={theme}
                                        label={group.actionText}
                                        link={group.link}
                                    />
                        })}
                    </>
                    : <p className="tw-py-4 tw-text-sm tw-font-semibold tw-text-center tw-text-gray-900">{group.placeholder}</p>
                }
            </div>
        </div>
    )
}

export default ModuleGroup
