import React, { useContext, useEffect, useState, Fragment } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../../../index'
import DocumentService from '../../../../../services/DocumentService'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import { paths } from '../../../../../config/constsURN'
import DataModelForm from '../form/DataModelForm'
import Spinner from '../../../../../assets/Spinner'
import { toast } from 'react-toastify'
import PageTitle from '../../common/panels/PageTitle'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../../config/constTypes'


/**
 * Компонент реализует логику редактирования существующей таблицы
 * @returns {HTMLDivElement} Html-разметку редактора таблицы с использованием визуальных компонентов 
 * {@link PageTitle}, {@link Spinner}, {@link DataModelForm}
 */
const EditDataModelContainer = () => {
    const navigate = useNavigate()
    const { docStore, FieldStore } = useContext(Context)

    const [dataModel, setDataModel] = useState('')
    const [dataModelFields, setDataModelFields] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const { id } = useParams()
   
    const handleFieldEdition = (newField) => {
        if (newField) {
            const foundField = dataModelFields.find(field => field.field_id === newField.id)
            if (foundField) {
                setDataModelFields(dataModelFields.map(field =>
                    field.field_id === foundField.field_id
                        ?   {...newField, field_id: newField.id, hide: field.hide, order: field.order}
                        :   field
                ))
            } else {
                setDataModelFields([...dataModelFields, {...newField, field_id: newField.id, hide: false, order: dataModelFields.length}])
            }
        }

        FieldStore.setSelectedField({...newField, field_id: newField.id})
    }

    const handleHideFieldClick = (selectedField) => {
        if (!selectedField.mandatory) {
            setDataModelFields(dataModelFields.map(field => {
                if (field.field_id !== selectedField.field_id) {
                    return field 
                } else {
                    FieldStore.setSelectedField({...field, hide: !field.hide})
                    return {...field, hide: !field.hide}
                }
            }))
        } else {
            FieldStore.setSelectedField(selectedField)
            toast.error(`${selectedField.hide ? 'Данное обязательное поле открыть' : 'Обязательное поле скрыть'} нельзя!`, { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })   
        }
    }

    const handleUpFieldClick = (position) => {
        setDataModelFields(dataModelFields.map(field => 
            (field.order === position)
            ?   {...field, order: field.order - 1}
            :   (field.order === position - 1)
                ?   {...field, order: field.order + 1}
                :   field
        ))
        FieldStore.setSelectedField({...FieldStore.selectedField, order: FieldStore.selectedField.order - 1})
    }

    const handleDownFieldClick = (position) => {
        setDataModelFields(dataModelFields.map(field =>
            (field.order === position)
            ?   {...field, order: field.order + 1}
            :   (field.order === position + 1)
                ?   {...field, order: field.order - 1}
                :   field
        ))
        FieldStore.setSelectedField({...FieldStore.selectedField, order: FieldStore.selectedField.order + 1})
    }

    const handleRemoveFieldClick = (fieldID) => {
        setDataModelFields(dataModelFields.filter(field => field.id !== fieldID).map((field, index) => {return {...field, order: index}}))
        FieldStore.setSelectedField(null)
    }

    const handleSubmitClick = (form) => {
        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        const editedDataModel = {}
        editedDataModel.entity_name = form.modelName
        editedDataModel.fields = dataModelFields.map((field, index) => {
            return { field_id: field.field_id, hide: field.hide, order: index, description: form.data[index].description }
        })
        editedDataModel.type = form.modelType.value
        if (form.modelType.value === 'directory')
            editedDataModel.allow_hierarchy = form.isHierarchical

        DocumentService
            .editDataModel(id, editedDataModel)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Таблица успешно сохранена', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                navigate(paths.DATAMODEL_ROUTE)
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }

    const handleCancelClick = () => navigate(paths.DATAMODEL_ROUTE)

    useEffect( () => {
        const dataModelFilter = JSON.stringify([
            {property: 'hide_mark', value: false, operator: 'eq'},
        ])
        const dataModelSorter = JSON.stringify([
            {property: 'entity_name', desc: false},
        ])      

        docStore.getDataModels(dataModelFilter, dataModelSorter, 'w')

        const noResponse = setTimeout(() => {
            toast.error('Сервис менеджера таблиц не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            setIsLoading(false)
        }, responseTimeOut)

        setIsLoading(true)
        DocumentService
            .getOneDataModel(id)
            .then(data => {
                clearTimeout(noResponse)
                setDataModel(data)
                setDataModelFields(data.fields.sort((a, b) => a.order - b.order).map((field, index) => { return {...field, order: index} }))
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
            .finally(setIsLoading(false))
    }, [])

    if (isLoading || dataModelFields === null) {
        return <Spinner/>
    }

    return (
        <Fragment>
            <PageTitle title='Редактирование таблицы'/>
            <div id='data-model-edit-page' className='tw-w-full tw-h-full tw-overflow-hidden tw-relative tw-pl-4'>
                <DataModelForm
                    editMode={true}
                    dataModel={dataModel}
                    fields={dataModelFields}
                    onEditField={handleFieldEdition}
                    onHideFieldClick={handleHideFieldClick}
                    onRemoveFieldClick={handleRemoveFieldClick}
                    onUpFieldClick={handleUpFieldClick}
                    onDownFieldClick={handleDownFieldClick}
                    onSubmitClick={handleSubmitClick}
                    onCancelClick={handleCancelClick}
                />
            </div>
        </Fragment>
    )
}

export default EditDataModelContainer
