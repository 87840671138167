import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { successMessageTimeOut } from '../../../../config/constTypes'
import parse from 'html-react-parser'
import PageTitle from '../common/panels/PageTitle'
import StageListContainer from '../common/panels/stage_list/StageListContainer'
import DirectoriesInfo from './info/DirectoriesInfo'
import EmployeesForm from './form/EmployeesForm'
import CommissionForm from './form/CommissionForm'
import ProcessesForm from './form/ProcessesForm'
import SignificanceIndicatorsForm from './form/significanceIndicators/SignificanceIndicatorsForm'
import ResourcesForm from './form/ResourcesForm'
import SpheresForm from './form/SpheresForm'
import PrintListForm from './form/PrintListForm'
import OrganizationForm from './form/OrganizationForm'
import CategorizingObjectsContainer from './form/categorizingObjects/CategorizingObjectsContainer'
import HelpButton from '../common/buttons/HelpButton'
import PrintActForm from './form/PrintActForm'
import DialogTab from '../../../dialog_tab/DialogTab'
import DirectoryContainer from '../common/panels/directory/DirectoryContainer'
import Spinner from '../../../../assets/Spinner'


/**
 * Визуальный компонент реализует логику процесса категорирования КИИ
 * 
 * @returns {JSXElement} Html-разметку с процессом категорирования КИИ 
 * с использованием визуальных компонентов {@link PageTitle}, {@link StageListContainer}, 
 * {@link DirectoriesInfo}, {@link CommissionForm}, {@link ProcessesForm}, {@link ResourcesForm}, {@link SpheresForm}, {@link PrintListForm}, 
 * {@link SignificanceIndicatorsForm}, {@link OrganizationForm}, {@link CategorizingObjectsContainer}, {@link PrintActForm}, 
 * {@link DialogTab}, {@link DirectoryContainer}, {@link HelpButton}, {@link EmployeesDataForm}
 */
const CategorizingContainer = () => {
    const { categorizingCIIStore, DialogTabStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const initModule = async () => {
        await categorizingCIIStore.loadStages()

        if (docStore.moduleType && docStore.moduleType === 'stages_of_categorization' && docStore.moduleRecordID) {
            setIsLoading(true)
            await handleDoubleClick({id: docStore.moduleRecordID})
            docStore.setModuleType(null)
            docStore.setModuleRecordID(null)
            setIsLoading(false)

        } else {
            const savedProjects = await categorizingCIIStore.checkSavedProject()
            if (savedProjects > 0) {
                DialogTabStore.setParentName('CategorizingContainer')
                DialogTabStore.setDialogTabTitle("Продолжение категорирования КИИ") 
                DialogTabStore.setDialogTabText(parse(`Обнаружены сохраненные записи категорирования КИИ<br/><br/>Вы хотите выбрать запись и продолжить начатое ранее категорирование?`))
                DialogTabStore.setDialogTabButtons(["Продолжить", "Начать заново"])
                DialogTabStore.setDialogTabIsOpen(true)
                DialogTabStore.setAllowAreaCloseClick(false)
            }
        }
    }

    const loadSaved = async () => {
        DialogTabStore.setDialogTabIsOpen(false)
        setIsSelectFormOpen(true)
    }

    const handleDoubleClick = async (item) => {
        let lastStageIndex = await categorizingCIIStore.loadSavedProject(item.id)
        setIsSelectFormOpen(false)       
        toast.success('Сохраненная запись успешно загружена', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
        
        // для этапа связывания объектов КИИ, если еще не был сохранен перечень под категорирование, нужно вернуться на этап выбора объектов КИИ
        if (lastStageIndex === 5 && !categorizingCIIStore.linkedList.values.length)
            lastStageIndex = 4

        const nextStage = categorizingCIIStore.allStages.find(stage => stage.data['id_of_stage__guide_stages_of_categ'].value === lastStageIndex)
        if (nextStage) {
            categorizingCIIStore.setStage(nextStage)
            categorizingCIIStore.setActualStageIndex(lastStageIndex)
            categorizingCIIStore.setAllStages(categorizingCIIStore.allStages.map(stage => 
                stage.data['id_of_stage__guide_stages_of_categ'].value < lastStageIndex
                    ?   {...stage, status: 'finished'}
                    :   stage
            ))
        }
    }

    const handleCloseClick = () => {
        setIsSelectFormOpen(false)
        toast.info('Категорирование КИИ будет начато заново', { position: toast.POSITION.TOP_CENTER, autoClose: successMessageTimeOut })
    }

    const handleHideStageClick = (isVisible) => {
        categorizingCIIStore.setHideStageList(isVisible)
    }

    const handleJumpStageClick = (item) => {
        if (item.status === 'finished') {
            categorizingCIIStore.setStage(item)
            categorizingCIIStore.setActualStageIndex(item.data['id_of_stage__guide_stages_of_categ'].value)
        }
    }

    useEffect(() => {
        initModule()
    }, [])

    return (
        <>
            <PageTitle title={'Категорирование КИИ'}/>
            <div id='categorizing-cii-page' className={`${categorizingCIIStore.hideStageList ? 'lg:tw-grid-cols-[2rem_1fr] lg:tw-gap-x-4' : 'lg:tw-grid-cols-4 lg:tw-gap-x-8'} tw-duration-500 lg:tw-grid lg:tw-max-h-full lg:tw-h-full max-lg:tw-w-full`}>
                <StageListContainer
                    title={'Этапы'}
                    list={categorizingCIIStore.allStages}
                    activeStage={categorizingCIIStore.stage}
                    idField='id_of_stage__guide_stages_of_categ'
                    isHideList={categorizingCIIStore.hideStageList}
                    onHideClick={handleHideStageClick}
                    onItemClick={handleJumpStageClick}
                    isSearch={false}
                />
                <div id='stage-column' className={`${!categorizingCIIStore.hideStageList && 'lg:tw-col-span-3'} tw-duration-500 tw-bg-white tw-rounded-md tw-mt-8 lg:tw-mt-0 lg:tw-flex lg:tw-flex-col lg:tw-h-full lg:tw-overflow-hidden`}>
                    { isLoading
                        ?   
                            <Spinner />
                        :
                            categorizingCIIStore.allStages && categorizingCIIStore.stage && 
                            <>
                                <div id='stage-list-header' className='tw-h-12 tw-flex tw-flex-row tw-justify-between tw-items-center tw-border-b-2 tw-border-gray-400 tw-space-x-4 tw-px-4 tw-py-2'>
                                    <div className='tw-font-semibold'>{categorizingCIIStore.stage.data.name.value}</div>
                                    <HelpButton  info={categorizingCIIStore.stage.data['hint_for_stages__guide_stages_of_categ'].value}/>
                                </div>
                                { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value === 1 &&
                                    <DirectoriesInfo/>
                                }
                                { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value === 2 && 
                                    <EmployeesForm/>
                                }
                                { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value === 3 && 
                                    <CommissionForm/>
                                }
                                { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value === 4 && 
                                    <ProcessesForm/>
                                }
                                { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value === 5 && 
                                    <ResourcesForm/>
                                }
                                { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value === 6 && 
                                    <SpheresForm/>
                                }
                                { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value === 7 && 
                                    <PrintListForm/>
                                }
                                { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value === 8 &&
                                    <SignificanceIndicatorsForm/>
                                }
                                { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value === 9 &&
                                    <OrganizationForm/>
                                }
                                { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value === 10 &&
                                    <CategorizingObjectsContainer/>
                                }                           
                                { categorizingCIIStore.stage.data['id_of_stage__guide_stages_of_categ'].value === 11 &&
                                    <PrintActForm/>
                                }                           
                            </>
                    }
                </div>
            </div>
            <DialogTab 
                parentName='CategorizingContainer'
                dialogTabFunction={loadSaved}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={'stages_of_categorization'}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
            />
        </>
    )
}

export default observer(CategorizingContainer)