import React from 'react'
import Header from './Header'
import UserInfoContainer from './userInfo/UserInfoContainer'

/**
 * Компонент содержит заголовок приложения
 * @returns {JSXElement} Визуальные компоненты {@link Header} и {@link UserInfoContainer}
 * 
 * @see [Вызов компонента](./AppHoneyBadger.js.html#line97)
 */
const HeaderContainer = () => {
    return (
        <>
            <Header />
            <UserInfoContainer />
        </>
    )    
}

export default HeaderContainer