import React from 'react'
import { TrashIcon, FilmIcon, DocumentTextIcon, PencilIcon } from '@heroicons/react/24/outline'

function getItemFileType(item) {
    if (item && item.material && item.material.data["material_type"]) {
        return item.material.data["material_type"].value
    }

    return null
}

/**
 * Визуальный компонент отображает название одного объекта из списка
 * @param {Object} item Объект 
 * @param {Object} stage Текущий активный объект
 * @param {Boolean} isDelete Признак возможности удаления объектов
 * @param {Function} onDeleteClick Обработчик клика мыши для удаления объектов
 * [handleAskForDeleteClick](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line77),
 * [handleAskForDeleteClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line81),
 * [handleAskForDeleteClick](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line72)
 * @param {Function} onJumpStageClick Обработчик клика мыши для перехода к произвольному объекту
 * [handleJumpStageClick](./components_main_page_controller_education_materials_MaterialsObjectsContainer.js.html#line25)
 * [handleJumpStageClick](./components_main_page_controller_education_materials_tests_QuestionsListContainer.js.html#line24)
 * 
 * @returns {HTMLDivElement} Html-разметку с названием объекта из списка
 * 
 * @see [Вызов компонента](./components_main_page_controller_common_panels_object_list_ObjectsList.js.html#line33)
 */
const ObjectsListItem = ({item, stage, isDelete, onDeleteClick, onJumpStageClick}) => {
    const iconType = getItemFileType(item)

    return (
        <div
            onClick={(e) => {e.preventDefault(); onJumpStageClick(item)}} 
            className={`tw-text-sm tw-border-b tw-select-none tw-flex tw-justify-start tw-items-center tw-pr-1
                        ${ stage.value === item.value 
                            ? ' tw-text-white tw-bg-gray-500'
                            : item.status === 'finished'
                                ? ' tw-text-gray-900 tw-bg-teal-500'
                                : item.status === 'viewed'
                                    ? ' tw-text-gray-900 tw-bg-gray-300'
                                    : ' tw-text-gray-900 tw-bg-white'
                        }
            `}
        >
            <div
                className='tw-grow tw-pl-2 tw-pr-2 tw-py-2 tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-x-2 tw-overflow-hidden'
                data-tooltip-id="object-list-tooltip" data-tooltip-content={item.name} data-tooltip-delay-show={1000}
            >
                <span>
                    { iconType === 'Видео' && <FilmIcon className="tw-w-5 tw-h-5"/>}
                    { iconType === 'Документ' && <DocumentTextIcon className="tw-w-5 tw-h-5"/>}
                    { iconType === 'Тест' && <PencilIcon className="tw-w-5 tw-h-5"/>}
                </span>
                <span className='tw-truncate'>{item.name}</span>
            </div>
            { isDelete &&
                <div className='tw-w-5 tw-h-5'>
                    <TrashIcon
                        className={`tw-w-5 tw-h-5 hover:tw-cursor-pointer ${item.status === 'finished' ? 'hover:tw-text-white' : 'hover:tw-text-black'}`}
                        data-tooltip-id="object-list-tooltip" data-tooltip-content="Удалить" data-tooltip-delay-show={500}
                        onClick={() => onDeleteClick(item)}
                    />
                </div>
            }
        </div>
    )
}

export default ObjectsListItem