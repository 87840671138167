import { makeAutoObservable, runInAction } from "mobx"
import { responseTimeOut, serviceMessageTimeOut } from "../config/constTypes"
import NotesService from "../services/NotesService"
import { toast } from "react-toastify"
import { showErrorToast } from "../functions/errorHandlers"

/**
 * Класс реализует хранилище информации о полученных от сервера сообщениях
 * @class
 * @property {Object[]} notes  Массив записей
 * @property {Boolean} isLoading Загрузка
 * @property {Boolean} error Ошибка
 * @property {String} columnName Столбец, по которому осуществляется сортировка
 * @property {Boolean} sortDirection Направление сортировки
 * @property {Number} offset Смещение при получении сообщений
 * @property {Number} totalCount Общее количество сообщений
 * @property {String} recordID id записи
 */
class RecordNotesStore {
    notes=[]
    isLoading = false
    error = false
    columnName= "created"
    sortDirection= false
    offset = 0
    totalCount=0
    recordID = null

    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    */
    constructor(){
        makeAutoObservable(this) 
    }

    /**
     * Метод осуществляет добавление сообщения
     * @method
     * 
     * @param {String} note Сообщение
     * @param {String} id  id записи
     * @param {Object[]} filter  Массив фильтров
     */
    addNote(id, note, filter){
        const noResponse = setTimeout(() => {
            toast.error('Сервис сообщений не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            runInAction(() => {
                this.isLoading=false
                this.error=true
            })
        }, responseTimeOut)
        
        NotesService
            .addNote(id, note)
            .then(() => {
                clearTimeout(noResponse)
                runInAction(() => {
                    this.isLoading=false
                    this.fetchNotes(this.columnName, this.sortDirection, this.recordID, this.offset, filter)
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
                runInAction(() => {
                    this.isLoading=false
                    this.error=true
                })
            })
    }

    /**
    * Метод осуществляет получение отсортированного массива сообщений
    * @method
    * 
    * @param {String} field Поле сортировки
    * @param {Boolean} desc  Направление сортировки
    * @param {String} id  id записи
    * @param {Number} offset  Смещение при получении сообщений
    * @param {Object[]} filter  Массив фильтров
    */
    async fetchNotes(field, desc, id, offset, filter) {
        if(this.recordID) {
            this.isLoading=true

            if(offset === 0) {
                this.offset = 0
            }

            const noResponse = setTimeout(() => {
                toast.error('Сервис сообщений не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                runInAction(() => {
                    this.isLoading=false
                    this.error=true
                })
            }, responseTimeOut)

            NotesService
                .getNotes(field, desc, id, offset, filter) 
                .then(([data, totalCount])  => {
                    clearTimeout(noResponse)
                    runInAction(() => {
                        this.isLoading=false
                        this.notes=data
                        this.totalCount = totalCount
                    })
                })
                .catch(error => {
                    clearTimeout(noResponse)
                    showErrorToast(error, 'fetching', '')
                    runInAction(() => {
                        this.isLoading=false
                        this.error=true
                    })
                })
        }
    }

    /**
    * Метод осуществляет сохранение информации о сортировке сообщений
    * @method
    * 
    * @param {String} name Столбец, по которому осуществляется сортировка
    * @param {Boolean} sort Направление сортировки
    */
    setSort(name, sort) {
        this.columnName = name;
        this.sortDirection = sort;
    }
    
    /**
    * Метод осуществляет сохранение информации о смещении
    * @method
    * 
    * @param {Boolean} increase Положительное или отрицательное смещение
    */
    setOffset(increase) {
        if(increase) {
            this.offset += 26
        } else {
            this.offset -= 26
        }
    }

    /**
    * Метод осуществляет сохранение информации об id записи
    * @method
    * 
    * @param {String} id id записи
    */
    setRecordID(id) {
        this.recordID = id
    }
}

export default RecordNotesStore