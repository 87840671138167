import { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { paths } from '../../../config/constsURN'
import { BellAlertIcon, ArrowUpCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * Визуальный компонент отображает выпадающее меню "Администрирование"
 * @returns {JSXElement} Визуальный компонент Menu из библиотеки {@link https://headlessui.com/|@headlessui}
 * 
 * @see [Вызов компонента](./components_header_Header.js.html#line51)
 */
function AdminMenu() {
    const { userStore } = useContext(Context)
    const navigate = useNavigate()

    const handleConfigurationCheck = () => {
        userStore.getUserConfiguration(true)
    }

    const handleConfigurationUpdate = () => {
        navigate(paths.CONFIGURATION_UPDATE_ROUTE)
    }

    return (
        <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
            <div>
                <Menu.Button className="tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-md tw-text-white tw-px-3 tw-py-3 tw-text-sm tw-font-semibold tw-bg-gray-800 tw-shadow-sm  tw-ring-gray-300 hover:tw-bg-gray-600">
                    { userStore.isUpdateFlag && 
                        <BellAlertIcon className="tw-w-5 tw-h-5 tw-text-yellow-400"/>
                    }
                    Администрирование
                    <ChevronDownIcon className="tw-w-5 tw-h-5"/>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="tw-transition tw-ease-out tw-duration-100"
                enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                enterTo="tw-transform tw-opacity-100 tw-scale-100"
                leave="tw-transition tw-ease-in tw-duration-75"
                leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                leaveTo="tw-transform tw-opacity-0 tw-scale-95"
            >
                <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-72 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                    <div className="tw-py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href={paths.DATAMODEL_ROUTE}
                                    className={classNames(
                                        active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                        'tw-block tw-px-4 tw-py-2 tw-text-sm'
                                    )}
                                >
                                    Структуры данных
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href={paths.IMPORTER_ROUTE}
                                    className={classNames(
                                        active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                                        'tw-block tw-px-4 tw-py-2 tw-text-sm'
                                    )}
                                >
                                    Шаблоны импорта
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href={paths.EXPORTER_ROUTE}
                                    className={classNames(
                                        active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                        'tw-block tw-px-4 tw-py-2 tw-text-sm'
                                    )}
                                >
                                    Печатные формы
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href={paths.CATEGORY_ROUTE}
                                    className={classNames(
                                        active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                        'tw-block tw-px-4 tw-py-2 tw-text-sm'
                                    )}
                                >
                                    Категории
                                </a>
                            )}
                        </Menu.Item>
                        <hr/>
                        { userStore.user.is_staff &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href={paths.ADMIN_ROUTE}
                                        className={classNames(
                                            active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                            'tw-block tw-px-4 tw-py-2 tw-text-sm'
                                        )}
                                    >
                                        Компании и пользователи
                                    </a>
                                )}
                            </Menu.Item>
                        }
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href={paths.COMPANY_ADMIN_ROUTE}
                                    className={classNames(
                                        active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                        'tw-block tw-px-4 tw-py-2 tw-text-sm'
                                    )}
                                >
                                    Юр. лица и пользователи
                                </a>
                            )}
                        </Menu.Item>
                        <hr/>
                        <Menu.Item>
                                {({ active }) => (
                                    <div className={classNames(
                                        active ? 'tw-bg-gray-200' : '',
                                        userStore.isUpdateFlag ? 'tw-text-red-500' : 'tw-text-gray-400',
                                        'tw-flex tw-flex-row tw-items-center')}
                                    >
                                        <div className='tw-block tw-pl-4 tw-pr-2 tw-py-2 tw-text-sm tw-text-left'>
                                            { userStore.configuration 
                                                ? 'Конфигурация: ' + (userStore.isUpdateFlag ? 'не актуальная' : 'актуальная')
                                                : 'Конфигурация: нет данных'
                                            }
                                        </div>
                                    </div>
                                )}
                        </Menu.Item>
                        {/* <Menu.Item>
                            {({ active }) => (
                                <a
                                    href={paths.CONFIGURATION_ROUTE}
                                    className={classNames(
                                        active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                        'tw-block tw-px-4 tw-py-2 tw-text-sm'
                                    )}
                                >
                                   О версиях конфигурации
                                </a>
                            )}
                        </Menu.Item> */}
                        <Menu.Item>
                                {({ active }) => (
                                    <div className={classNames(
                                        active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                        'tw-flex tw-flex-row tw-items-center hover:tw-cursor-pointer')}
                                    >
                                        <button
                                            className='tw-block tw-pl-4 tw-pr-2 tw-py-2 tw-text-sm tw-text-left disabled:tw-text-gray-300'                                      
                                            onClick={userStore.isUpdateFlag ? handleConfigurationUpdate : handleConfigurationCheck}
                                        >
                                            { userStore.isUpdateFlag 
                                                    ? 'Обновление конфигурации' 
                                                    : 'Проверить наличие обновлений'
                                            }
                                        </button>
                                        { userStore.isUpdateFlag &&
                                            <ArrowUpCircleIcon  className="tw-w-5 tw-h-5 tw-text-green-600"/>
                                        }
                                    </div>
                                )}
                        </Menu.Item>
                        { userStore.user.is_staff &&
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href={paths.CONFIGURATION_UPLOAD_ROUTE}
                                        className={classNames(
                                            active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                            'tw-block tw-px-4 tw-py-2 tw-text-sm'
                                        )}
                                    >
                                        Загрузить пакет с конфигурацией
                                    </a>
                                )}
                            </Menu.Item>
                        }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default observer(AdminMenu)