import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'


/**
 * Визуальный компонент отображает форму создания/редактирования записи о должности и подразделении
 * @param {Object} selectedItem Выбранный элемент списка (запись)
 * @param {Boolean} isEditMode Признак редактирования существующей записи
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке сохранения изменений
 * [handleSubmitPositionClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line227),
 * [handleSubmitSubdivisionClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line262)
 * @param {Function} onCancelClick Обработчик клика мыши на кнопке отмены редактирования записи
 * [handleCancelPositionClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line234),
 * [handleCancelSubdivisionClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line269)
 * 
 * @returns {HTMLDivElement} Html-разметку формы создания/редактирования записи о должности и подразделении
 * 
 * @see [Вызов компонента EmployeesDataForm,](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line359)
 * [EmployeesDataForm](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line391)
 */
const SimpleItemForm = ({fieldName, selectedItem, isEditMode, onSubmitClick, onCancelClick}) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm()

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(
                <div>
                    Проверка значений завершилась неудачно!
                    <br/><br/>
                    <p>Наименование: не заполнено обязательное поле</p>
                </div>,
            { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <form>
            <div className='tw-w-full tw-mt-2 tw-px-4 tw-flex tw-flex-col tw-border-b tw-border-gray-400'>
                <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-mt-2'>
                    <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900 tw-leading-6'>Наименование</label>
                    {errors[fieldName] && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                </div>
                <input
                    type='text'
                    className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                        ${errors[fieldName] ? 'tw-ring-red-400 focus-visible:tw-ring-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-gray-400'}
                    `}
                    {...register(fieldName, { 
                        value: (isEditMode && selectedItem) ? selectedItem.data['name'].value : '',
                        validate: v => v.trim() !== '',
                    })} 
                />
                <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-mt-2 tw-py-4'>
                    <button 
                        className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        onClick={handleSubmit(onSubmitClick)}
                    >
                        Сохранить
                    </button>
                    <button
                        type='button'
                        className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-900
                                hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                        onClick={onCancelClick}
                    >
                        Отменить
                    </button>
                </div> 
            </div>
        </form>
    )
}

export default SimpleItemForm