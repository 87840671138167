import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { observer } from "mobx-react-lite";

/**
 * Визуальный компонент отвечает за сортировку строк в таблице с сообщениями
 * @param {String} title Заголовок столбца сортировки
 * @param {String} name Название столбца сортировки
 * @param {Object} filterStore Экземпляр хранилища информации о применяемых фильтрах
 * @param {Object} recordNotesStore Экземпляр хранилища информации о сообщениях
 * 
 * @returns {HTMLFormElement} Html-разметку заголовок столбца и порядок сортировки строк в таблице с сообщениями
 * 
 * @see [Вызов компонента RecordNotesTable,](./components_tabs_notes_RecordNotesTable.js.html#line113)
 * [RecordNotesTable](./components_tabs_notes_RecordNotesTable.js.html#line129)
 * [и RecordNotesTable](./components_tabs_notes_RecordNotesTable.js.html#line145)
 * */
const RecordNotesSort = ({title, name, filterStore, recordNotesStore}) => {
    const changeSortValue = ()=> {
        let sortValue = recordNotesStore.sortDirection ? false : true;
    
        recordNotesStore.fetchNotes(name, sortValue, recordNotesStore.recordID, recordNotesStore.offset, filterStore.selectedFilters)
        recordNotesStore.setSort(name, sortValue)
    }

    return (
        <div onClick={changeSortValue} className="tw-cursor-pointer tw-flex" >
            {recordNotesStore.sortDirection
                ?  <ChevronUpIcon 
                        className={`tw-w-5 tw-h-5 group-hover:tw-opacity-50 
                            ${recordNotesStore.columnName ? recordNotesStore.columnName === name ? 'tw-opacity-100' : 'tw-opacity-0' : 'tw-opacity-50'}
                        `} aria-hidden='true'
                    />
                :  <ChevronDownIcon 
                        className={`tw-w-5 tw-h-5 group-hover:tw-opacity-50 
                            ${recordNotesStore.columnName ? recordNotesStore.columnName === name ? 'tw-opacity-100' : 'tw-opacity-0' : 'tw-opacity-50'}
                        `} aria-hidden='true'
                    />
            }
            {title}
        </div>
    )
}

export default observer(RecordNotesSort)