import { $exportHost } from "../http/http"
import { documentAPI } from "../config/constsURN"
import { getHeaderFileName } from "../functions/getHeaderFileName"


/**
 * Класс реализует API для работы с документами (таблицами)
 * @class
 */
class ExporterService {

    /**
     * Отправляет запрос на получение списка существующих печатных форм
     * @static
     * @param {Number} count Лимит на количество возвращаемых печатных форм
     * @returns {Object[]} Массив печатных форм
     */
    static async getExporters(count = 10) {
        const {data} = await $exportHost.get(documentAPI.EXPORTER_API + '/search', {params: {count, total: true}})
        return data
    }

    /**
     * Отправляет запрос на получение одной печатной формы
     * @static
     * @param {String} id ID печатной формы
     * @returns {Object} Печатная форма
     */
    static async getOneExporter(id) {
        const {data} = await $exportHost.get(documentAPI.EXPORTER_API + '/' + id)
        return data
    }
    
    /**
     * Отправляет запрос на создание одной печатной формы
     * @static
     * @param {Object} exporter Печатная форма
     * @returns {String} ID печатной формы
     */
    static async createExporter(exporter) {
        const {data} = await $exportHost.post(documentAPI.EXPORTER_API, exporter)
        return data
    }

    /**
     * Отправляет запрос на обновление одной печатной формы
     * @static
     * @param {Object} exporter Печатная форма
     * @returns {String} ID печатной формы
     */
    static async updateExporter(exporter) {
        const {data} = await $exportHost.put(documentAPI.EXPORTER_API + '/' + exporter.id, exporter)
        return data
    }

    /**
     * Отправляет запрос на удаление одной печатной формы
     * @static
     * @param {String} id ID печатной формы
     * @returns null
     */
    static async deleteExporter(id) {
        const {data} = await $exportHost.delete(documentAPI.EXPORTER_API + '/' + id)
        return data
    }
    
    /**
     * Отправляет запрос на экспорт записей таблицы в файл формата xlsx или ods
     * @static
     * @param {String} type Тип формата файла
     * @param {Object} filters Фильтры экспорта
     * @returns {Blob} Результирующий файл
     */
    static async exportDataObjectToTable(type, filters) {
        const {data} = await $exportHost.get(documentAPI.EXPORTER_RUN_API + '/excel/' + type, { responseType: 'blob', params: {filter: filters}, headers: {'Timezone': (new Date()).getTimezoneOffset() * (-60)} })
        return data
    }

    /**
     * Отправляет запрос на экспорт записи таблицы в файл формата pdf или odt
     * @static
     * @param {String} template_id ID печатной формы
     * @param {String} dataModel_id ID таблицы
     * @returns {Blob} Результирующий файл
     */
    static async exportDataObjectToDocument(template_id, dataModel_id) {
        const response = await $exportHost.get(documentAPI.EXPORTER_RUN_API + '/templates/' + template_id + '/data-objects/' + dataModel_id, { responseType: 'blob', headers: {'Timezone': (new Date()).getTimezoneOffset() * (-60)} })
        if (response) {
            const fileName = getHeaderFileName(response.headers)

            return [response.data, fileName]
        }

        return response
    }

    /**
     * Отправляет запрос на экспорт данных в несколько документов, упакованных в файл формата zip
     * @static
     * @param {String} type Тип комплекта документов
     * @param {Object} records Набор данных для печати
     * @returns {Blob} Результирующий файл
     * @returns {String} Имя файла
     */
    static async exportDataToZip(type, records) {
        const response = await $exportHost.post(documentAPI.EXPORTER_ZIP_API + '/' + type + '/export', records, { responseType: 'blob', headers: {'Timezone': (new Date()).getTimezoneOffset() * (-60)} })
        if (response) {
            const fileName = getHeaderFileName(response.headers)

            return [response.data, fileName]
        }
        
        return response
    }
}

export default ExporterService