import { Popover, Transition } from '@headlessui/react'
import React, { Fragment, useContext } from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import SubCompaniesPanel from './SubCompaniesPanel'

/**
 * Визуальный компонент отображает меню смены юр лица пользователя
 * @returns {JSXElement} Визуальный компонент Popover из библиотеки {@link https://headlessui.com/|@headlessui}
 * с использованием визуального компонента {@link SubCompaniesPanel}
 * 
 * @see [Вызов компонента](./components_header/menu/UserMenu.js.html#line70)
 */
function SubCompaniesMenu() {
    const { userStore } = useContext(Context)

    const getMySubCompanies = (open) => {
        if (!open)
            userStore.getMySubCompanies()
    }

    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button className={'tw-text-left tw-w-full tw-px-4 tw-py-2'}
                        onClick={() => {getMySubCompanies(open)}}
                    >
                        <span>Сменить юр. лицо</span>
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="tw-transition tw-ease-out tw-duration-200"
                        enterFrom="tw-opacity-0"
                        enterTo="tw-opacity-100"
                        leave="tw-transition tw-ease-in tw-duration-150"
                        leaveFrom="tw-opacity-100"
                        leaveTo="tw-opacity-0"
                    >
                        <Popover.Panel className="tw-absolute tw-z-10 tw-w-screen tw-max-h-[calc(100vh - 9rem)] tw-overflow-auto tw-max-w-sm -tw-translate-x-full 
                            -tw-translate-y-8 tw-transform tw-py-2 tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none tw-rounded-md"
                        >
                            <SubCompaniesPanel/>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}

export default observer(SubCompaniesMenu)
