import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { paths } from '../../../config/constsURN'
import { ChevronDownIcon } from '@heroicons/react/20/solid'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * Визуальный компонент отображает выпадающее меню "Сервисы"
 * @returns {JSXElement} Визуальный компонент Menu из библиотеки {@link https://headlessui.com/|@headlessui}
 * 
 * @see [Вызов компонента](./components_header_Header.js.html#line40)
 */
function ModuleMenu() {
    return (
        <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
            <div>
                <Menu.Button className="tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-md tw-text-white tw-px-3 tw-py-3 tw-text-sm tw-font-semibold tw-bg-gray-800 tw-shadow-sm  tw-ring-gray-300 hover:tw-bg-gray-600">
                    Модули
                    <ChevronDownIcon className="tw-w-5 tw-h-5"/>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="tw-transition tw-ease-out tw-duration-100"
                enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                enterTo="tw-transform tw-opacity-100 tw-scale-100"
                leave="tw-transition tw-ease-in tw-duration-75"
                leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                leaveTo="tw-transform tw-opacity-0 tw-scale-95"
            >
                <Menu.Items className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-80 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
                    <div className="tw-py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href={paths.CATEGORIZING_CII_ROUTE}
                                    className={classNames(
                                        active ? 'tw-bg-gray-200 tw-text-gray-900' : 'tw-text-gray-700',
                                        'tw-block tw-px-4 tw-py-2 tw-text-sm'
                                    )}
                                >
                                    <span>Категорирование КИИ </span><span className='tw-italic'>&middot; [Beta]</span>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href={paths.PERSONAL_DATA_ROUTE}
                                    className={classNames(
                                        active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                                        'tw-block tw-px-4 tw-py-2 tw-text-sm'
                                    )}
                                >
                                    <span>Обработка и защита ПДн </span><span className='tw-italic'>&middot; [Beta]</span>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href={paths.EDUCATION_ROUTE}
                                    className={classNames(
                                        active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700',
                                        'tw-block tw-px-4 tw-py-2 tw-text-sm'
                                    )}
                                >
                                    <span>Повышение осведомленности </span><span className='tw-italic'>&middot; [Alpha]</span>
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default ModuleMenu
