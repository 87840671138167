import React, { useContext } from 'react'
import DataModelCategory from './DataModelCategory'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'


/**
 * Визуальный компонент отображает список существующих категорий таблиц
 * @param {Object} selectedDataModel Выбранная таблица
 * @param {Function} onItemClick Обработчик клика мыши на таблице
 * [handleDataModelClick](./components_main_page_controller_data_model_DataModelListContainer.js.html#line64) и
 * [handleDataModelClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line68)
 * @param {Function} onItemDoubleClick Обработчик двойного клика мыши на таблице
 * [handleDataModelClick](./components_main_page_controller_data_model_DataModelListContainer.js.html#line64) и
 * [handleDataModelClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line68)
 * @returns {HTMLDivElement} Html-разметку списка существующих категорий таблиц с использованием визуального компонента {@link DataModelCategory}
 * 
 * @see [Вызов компонента DataModelListContainer,](./components_main_page_controller_data_model_DataModelListContainer.js.html#line133)
 * [DataObjectListContainer](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line538)
 */
const DataModelCategoryList = ({selectedDataModel, onItemClick, onItemDoubleClick}) => {
    const { docStore } = useContext(Context)
    return (
        <div  id='categories-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-overflow-auto'>
            { docStore.categories.map((categoryItem, index) => 
                <DataModelCategory
                    key={index}
                    category={categoryItem}
                    selectedDataModel={selectedDataModel}
                    onItemClick={onItemClick}
                    onItemDoubleClick={onItemDoubleClick}
                />
            )}
            <DataModelCategory
                category={{id: '0', name: 'Без категории', order: (docStore.categories.length + 1)}}
                selectedDataModel={selectedDataModel}
                onItemClick={onItemClick}
                onItemDoubleClick={onItemDoubleClick}
            />
        </div>
    )
}

export default observer(DataModelCategoryList)