import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../../..'
import TestAttemptsRow from './TestAttemptsRow'
import TestAttemptsSort from './TestAttemptsSort'

/**
 * Визуальный компонент отображает таблицу результатов попыток теста
 * 
 * @returns {HTMLFormElement} Html-разметку таблицы результатов попыток теста
 * с использованием визуальных компонентов {@link TestAttemptsRow}, {@link TestAttemptsSort}
 * 
 * @see [Вызов компонента TestAttempts](./components_main_page_controller_education_materials_testAttempts_TestAttempts.js.html#line39)
*/
const TestAttemptsTable = () => {
    const { EducationStore } = useContext(Context)

    const [activeColumn, setActiveColumn] = useState(null)
    const [offsetX, setOffsetX] = useState(0)
    const dateColumn = useRef(null)
    const authorColumn = useRef(null)
    const resultColumn = useRef(null)
    const columns = [{ref: dateColumn}, {ref: resultColumn}, {ref: authorColumn}]
    const savedColumnValues = sessionStorage.getItem('storedAttemptsSizes')
    const sizes = savedColumnValues ? savedColumnValues : "170px 170px 170px"
    const tableElement = useRef(null)
    
    const mouseMove = useCallback((e) => {
        const gridColumns = columns.map((column, index) => {
            if (index === activeColumn) {
                const width = e.clientX - offsetX -  columns[0].ref.current.offsetLeft
                if (width >= 100) {
                    return `${width}px`
                }
            }
            return `${column.ref.current.offsetWidth}px`
        });
        
        const gridTemplateColumns = `${gridColumns.join(" ")}`
        tableElement.current.childNodes[0].childNodes[0].style.gridTemplateColumns = gridTemplateColumns
        tableElement.current.childNodes[1].childNodes.forEach(row =>
            row.style.gridTemplateColumns = gridTemplateColumns)
    }, [activeColumn, columns])
    
    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove)
        window.removeEventListener("mouseup", mouseUp)
    }, [mouseMove])

    const mouseDown = (index) => (e) => {
        setActiveColumn(index)
        const offset = e.clientX - columns[index].ref.current.offsetWidth - columns[0].ref.current.offsetLeft
        setOffsetX(offset)
    }
    const mouseUp = useCallback(() => {
        if (tableElement.current) {
            sessionStorage.setItem('storedAttemptsSizes', tableElement.current.childNodes[0].childNodes[0].style.gridTemplateColumns)
        }
        removeListeners()
        setActiveColumn(null)
        setOffsetX(0)
    }, [setActiveColumn, setOffsetX, removeListeners]) 

    useEffect(() => {
        if (activeColumn !== null) {
            window.addEventListener("mousemove", mouseMove)
            window.addEventListener("mouseup", mouseUp)
        }
    
        return () => {
            removeListeners()
        }
    }, [activeColumn, mouseMove, mouseUp, removeListeners])

    return (
        <>
            <div className='tw-h-full tw-overflow-auto'>
                <table ref={tableElement} className='tw-w-full tw-text-gray-900 tw-font-medium tw-text-sm'>
                    <thead>
                        <tr style={{
                            display: 'grid',
                            gridTemplateColumns: sizes
                        }}>
                            <th className='tw-group tw-relative tw-truncate tw-px-2' ref={dateColumn}>
                                <TestAttemptsSort title={"Дата и время"} name={"created"}/>
                                <span 
                                    className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-bg-gray-500 tw-w-1 tw-cursor-col-resize tw-opacity-30'
                                    onMouseDown={mouseDown(0)}
                                >
                                </span>
                            </th>
                            <th className='tw-group tw-relative tw-truncate tw-px-2' ref={resultColumn}>
                                <TestAttemptsSort title={"Результат"} name={"data['test_result']"}/>
                                <span 
                                    className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-bg-gray-500 tw-w-1 tw-cursor-col-resize tw-opacity-30'
                                    onMouseDown={mouseDown(1)}
                                >
                                </span>
                            </th>
                            <th className='tw-group tw-relative tw-truncate tw-px-2' ref={authorColumn}>
                                {"Пользователь"}
                                <span 
                                    className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-rounded-md tw-bg-gray-500 tw-w-1 tw-cursor-col-resize tw-opacity-30'
                                    onMouseDown={mouseDown(2)}
                                >
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {EducationStore.attemptsList.map(item => 
                            <TestAttemptsRow sizes={sizes} key={item.id} {...item}/>
                        )}
                    </tbody>
                </table>
            </div>
        </>                  
    )       
}

export default observer(TestAttemptsTable)