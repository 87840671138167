import React, { useContext, useState, useEffect } from 'react'
import {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"
import { observer } from 'mobx-react-lite'
import { Context } from '../../../../..'
import FormFieldName from '../../../../form_fields/FormFieldName'
import TestContainer from './tests/TestContainer'
import DialogTab from '../../../../dialog_tab/DialogTab'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import TestAttempts from './testAttempts/TestAttempts'
import EducationViewFileContainer from '../viewers/EducationViewFileContainer'
import MaterialProgress from './material_progress/MaterialProgress'

registerLocale("ru", ru)

/**
 * Визуальный компонент отображает информацию о материале темы
 *
 * @returns {JSXElement} Html-разметку информации о материале темы
 * с использованием визуальных компонентов {@link FormFieldName}, {@link ObjectsListButtons}, {@link TestContainer}, {@link DialogTab},
 * {@link TestAttempts}, {@link EducationViewFileContainer}, {@link MaterialProgress}
 *   
 * @see [Вызов компонента MaterialsObjectsContainer](./components_main_page_controller_education_materials_MaterialsObjectsContainer.js.html#line96)
 */
const MaterialsObjectsForm = () => {
    const { EducationStore, DialogTabStore, userStore } = useContext(Context)
    const [isTestOpen, setIsTestOpen] = useState(false)
    const [isTestViewed, setIsTestViewed] = useState(false)
    const [isViewPanelOpen, setIsViewPanelOpen] = useState(false)
    const isTestMaterial = EducationStore.materialsStage.material.data['material_type'].value === 'Тест'

    const {
        register,
        unregister,
        getValues,
    } = useForm()

    const onMaterialButtonClick = () => {
        if (isTestMaterial) {
            DialogTabStore.setParentName('MaterialsObjectsForm')
            DialogTabStore.setDialogTabTitle("Тестирование")
            DialogTabStore.setDialogTabText('Вы уверены, что хотите начать тестирование?')
            DialogTabStore.setDialogTabButtons(["Начать", "Отмена"])
            DialogTabStore.setDialogTabIsOpen(true)
        } else {
            if (checkMaterialContent())
                setIsViewPanelOpen(true)
            else
                toast.error('Информация по данному материалу не найдена!', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })   
        }
    }

    const dialogTabFunction = async () => {
        DialogTabStore.setDialogTabIsOpen(false)
        if (isTestOpen) {
            if (EducationStore.questionsList.length) {
                saveTestResults(true)
            } else {
                setIsTestOpen(false)
            }
        } else {
            if (EducationStore.materialsStage.material.data['test_link'].value.values[0]?.id) {
                setIsTestOpen(true)
            } else {
                toast.error('Ссылка на тест не была предоставлена', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
            }
        }
    }

    const onCloseTestClick = () => {
        DialogTabStore.setParentName('MaterialsObjectsForm')
        DialogTabStore.setDialogTabTitle("Завершить тестирование") 
        DialogTabStore.setDialogTabText('Вы уверены, что хотите завершить тестирование?')
        DialogTabStore.setDialogTabButtons(["Завершить", "Отмена"])
        DialogTabStore.setDialogTabIsOpen(true)
    }

    const handleViewPanelCloseClick = () => {
        setIsViewPanelOpen(false)
    }

    const saveTestResults = (isSaveTest) => {
        const form = getValues()
        let answers = [],
            isAnswered = false

        if (EducationStore.questionsStage.question.data['is_multiple_select'].value) {
            EducationStore.questionsStage.options.forEach(item => {
                Object.entries(form).forEach(([key, value]) => {
                    if (key === item.id) {
                        if (value) {
                            isAnswered = true
                            answers.push({...item, isAnswer: true})
                        } else {
                            answers.push({...item, isAnswer: false})
                        } 
                    }
                })
                unregister(item.id)
            })
        } else {
            EducationStore.questionsStage.options.forEach(item => {
                Object.entries(form).forEach(([key, value]) => {
                    if (value) {
                        isAnswered = true
                        if (item.id === value) {  
                            answers.push({...item, isAnswer: true})
                        } else {
                            answers.push({...item, isAnswer: false})
                        }
                    } else {
                        answers.push({...item, isAnswer: false})
                    }           
                })
            })
            unregister(EducationStore.questionsStage.id)
        }

        const updatedQuestionsList = EducationStore.questionsList.map(item => (            
            (EducationStore.questionsStage.id === item.id) 
                ?   {...item, options: answers, status: isAnswered ? 'viewed' : 'empty'}
                :   {...item}
        ))        
        EducationStore.setQuestionsList(updatedQuestionsList)

        if (isSaveTest) {
           saveTest(updatedQuestionsList)
        }
    }

    const checkTestPassed = (correctAnswers, totalQuestions) => {
        return correctAnswers === totalQuestions
    }

    const saveTest = async (questionsList) => {
        const testLink = EducationStore.materialsStage.material.data['test_link'].value.values[0].record_id 

        const [result, correctAnswers] = EducationStore.processAnswers(questionsList)

        const dataObject = {}
        dataObject.data_model_id = "test_results"
        dataObject.data = {}
        dataObject.data.date = Date.now()
        dataObject.data['test_link'] = [testLink]
        dataObject.data['test_result'] = `Верных ${correctAnswers} из ${questionsList.length}`
        dataObject.data['is_test_passed'] = checkTestPassed(correctAnswers, questionsList.length)
        dataObject.data['topic_material'] = [EducationStore.materialsStage.record_id]       
        dataObject.data['questions_results'] = { upsert: result }

        const isSaved = await EducationStore.saveAnswers(dataObject, testLink, userStore.user.id)
        if (isSaved) {
            setIsTestOpen(false)    
        }
    }

    const checkMaterialContent = () => {
        return  !isTestMaterial &&
                (EducationStore.materialsStage.material.system_data?.files?.length ||
                (EducationStore.materialsStage.material.data['material_video_link'].value &&
                EducationStore.materialsStage.material.data['material_video_link'].value.trim() !== ''))
    }

    useEffect(() => {
        const isDone =  EducationStore.questionsList &&
                        (EducationStore.questionsList.length > 0) &&
                        EducationStore.questionsList.every(item => item.status === 'viewed')
        if (isDone && !isTestViewed) {
            toast.info('Для сохранения результатов теста нажмите кнопку "Завершить тест"', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
            setIsTestViewed(isDone)
        }
    }, [EducationStore.questionsList])

    return (
        <>
            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-mx-auto tw-py-2'>
                <button 
                    className='tw-rounded-md  tw-mr-2 tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-600 hover:tw-border-gray-600 disabled:tw-bg-gray-300 disabled:tw-border-gray-300
                            focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={onMaterialButtonClick}
                    disabled={EducationStore.isTestAttemptsLoading}
                >
                    {isTestMaterial
                        ? 'Начать тестирование'
                        : 'Просмотр'
                    }
                </button>
            </div>
            <div className='tw-grow tw-overflow-auto'>
                <div className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-px-4 tw-py-1'>
                        <div className='tw-my-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-py-2'>
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-justify-between">
                                <FormFieldName 
                                    item={{...EducationStore.materialsStage.material.data['material_type'], mandatory: false}}
                                    errors={[]}
                                />
                            </dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                <p>{EducationStore.materialsStage.material.data['material_type'].value}</p>
                            </dd>                                    
                        </div>
                        <div className='tw-my-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 tw-py-2'>
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-justify-between">
                                <FormFieldName 
                                    item={{...EducationStore.materialsStage.material.data['material_description'], mandatory: false}}
                                    errors={[]}
                                />
                            </dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 tw-break-words">
                                <p>{EducationStore.materialsStage.material.data['material_description'].value}</p>
                            </dd>                                    
                        </div>

                        {EducationStore.materialsStage.material.data['test_link'].value.values[0]?.id
                            ? <TestAttempts/>
                            : <MaterialProgress/>
                        }
                    </div>                            
                </div>
            </div>
            <TestContainer
                isOpen={isTestOpen}
                onCloseClick={onCloseTestClick}
                register={register}
                saveTestResults={saveTestResults}
                isDone={isTestViewed}
            />
            <DialogTab 
                parentName='MaterialsObjectsForm'
                dialogTabFunction={dialogTabFunction}
            />
            <EducationViewFileContainer
                isOpen={isViewPanelOpen}
                onCloseClick={handleViewPanelCloseClick}
            />
        </>                                                             
    )
}

export default observer(MaterialsObjectsForm)
