import React, { useContext } from 'react'
import CategoryItem from './CategoryItem'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'

/**
 * Визуальный компонент отображает список существующих категорий
 * 
 * @returns {HTMLDivElement} Html-разметку списка существующих категорий с использованием визуального компонента {@link CategoryItem}
 * 
 * @see [Вызов компонента](./components_main_page_controller_category_CategoryListContainer.js.html#line67)
 */
const CategoryList = () => {
    const { docStore } = useContext(Context)

    return (
        <div  id='data-model-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-overflow-auto'>
            { docStore.categories
                .slice()
                .sort((a, b) => a.order - b.order)
                .map((category, index) => 
                    <CategoryItem 
                        key={index}
                        item={category}
                    />
            )}
        </div>
    )
}

export default observer(CategoryList)