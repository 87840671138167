import { $dataHost } from "../http/http"
import { documentAPI } from "../config/constsURN"


/**
 * Класс реализует API для работы с документами (таблицами)
 * @class
 */
class DocumentService {

    /**
     * Отправляет запрос на получение всех записей из таблицы
     * @static
     * @param {Number} count Лимит на количество возвращаемых записей
     * @param {Object[]} filters Массив фильтров
     * @param {Object[]} sorters Массив правил сортировки
     * @param {String} mode Тип доступа к записи (r - только чтение, w - чтение и запись)
     * @returns {Object[]} Массив записей из таблиц
     */
    static async getAllDataObjects(count = 50, filters, sorters, mode) {
        let data = []
        let response
        let offset = 0

        do {
            response = await $dataHost.get(documentAPI.DATAOBJECT_API + '/search', {params: {offset, count, total: true, filter: filters, sort: sorters, mode: mode}})
            data = data.concat(response.data)
            offset += count
        } while (response?.data?.length === count)

        return data
    }
        
    /**
     * Отправляет запрос на получение порции записей из таблицы
     * @static
     * @param {Number} count Лимит на количество возвращаемых записей
     * @param {Object[]} filters Массив фильтров
     * @param {Object[]} sorters Массив правил сортировки
     * @param {String} mode Тип доступа к записи (r - только чтение, w - чтение и запись)
     * @returns {Object[]} Массив записей из таблиц
     */
    static async getDataObjects(count = 50, offset, filters, sorters, mode) {
        const response = await $dataHost.get(documentAPI.DATAOBJECT_API + '/search', {params: {offset, count, total: true, filter: filters, sort: sorters, mode: mode}})
        if (response) {
            const totalCount = response.headers["total-count"]

            return [response.data, totalCount]
        }

        return [[], 0]
    }
        
    /**
     * Отправляет запрос на получение последней созданной записи из таблицы
     * @static
     * @param {Object[]} filters Массив фильтров
     * @param {Object[]} sorters Массив правил сортировки
     * @param {String} mode Тип доступа к записи (r - только чтение, w - чтение и запись)
     * @returns {Object} Последняя созданная запись из таблицы
     */
    static async getLastDataObject(filters, sorters, mode) {
        const response = await $dataHost.get(documentAPI.DATAOBJECT_API + '/search', {params: {offset: 0, count: 5, total: true, filter: filters, sort: sorters, mode: mode}})
        if (response && response.data && response.data.length) {
            return response.data[0]
        }

        return null
    }
        
    /**
     * Отправляет запрос на получение общего количества записей в таблице
     * @static
     * @param {Object[]} filters Массив фильтров
     * @returns {Object[]} Общее количество записей в таблице
     */
    static async getDataObjectCount(filters) {
        const offset = 0
        const count = 2

        const response = await $dataHost.get(documentAPI.DATAOBJECT_API + '/search', {params: {offset, count, total: true, filter: filters}})
        const totalCount = response.headers["total-count"]
        return totalCount
    }

    /**
     * Отправляет запрос на получение всех записей из вложенной таблицы
     * @static
     * @param {Number} count Лимит на количество возвращаемых записей
     * @param {Object[]} filters Массив фильтров
     * @param {Object[]} sorters Массив правил сортировки
     * @param {String} id ID записи основной таблицы, к которой относятся записи вложенной таблицы
     * @param {String} rule_id ID связи основной таблицы с конкретной вложенной таблицей
     * @returns {Object[]} Массив записей из таблиц
     */
    static async getNestedDataObjects(count = 50, filters, sorters, id, rule_id) {
        let data = []
        let response
        let offset = 0

        do {
            response = await $dataHost.get(documentAPI.DATAOBJECT_API + '/' + id + '/rule/' + rule_id, {params: {offset, count, total: true, filter: filters, sort: sorters}})
            data = data.concat(response.data)
            offset += count
        } while (response?.data?.length === count)

        return data
    }
        
    /**
     * Отправляет запрос на получение порции записей из таблицы
     * @static
     * @param {Number} count Лимит на количество возвращаемых записей
     * @param {Object[]} filters Массив фильтров
     * @param {Object[]} sorters Массив правил сортировки
     * @param {String} id ID родительской записи таблицы, к которой относятся дочерние записи таблицы
     * @returns {Object[]} Массив записей из таблиц
     */
    static async getChildDataObjects(count = 50, filters, sorters, id) {
        let data = []
        let response
        let offset = 0

        do {
            response = await $dataHost.get(documentAPI.DATAOBJECT_API + '/' + id + '/rule/parent', {params: {direct: 'in', offset, count, total: true, filter: filters, sort: sorters}})
            data = data.concat(response.data)
            offset += count
        } while (response?.data?.length === count)

        return data
    }
        
    /**
     * Отправляет запрос на получение одной записи из выбранной таблицы
     * @static
     * @param {String} id ID записи
     * @returns {Object} Запись из таблицы
     */
    static async getOneDataObject(id) {
        const {data} = await $dataHost.get(documentAPI.DATAOBJECT_API + '/' + id)
        return data
    }
    
    /**
     * Отправляет запрос на создание одной записи в выбранной таблице
     * @static
     * @param {Object} document Данные записи
     * @returns {Object} Созданная запись
     */
    static async createDataObject(document) {
        const {data} = await $dataHost.post(documentAPI.DATAOBJECT_RECORD_API, document)
        return data
    }

    /**
     * Отправляет запрос на обновление одной записи в выбранной таблице
     * @static
     * @param {String} record_id ID записи
     * @param {Object} document Данные записи
     * @returns {String} ID записи
     */
    static async updateDataObject(record_id, document) {
        const {data} = await $dataHost.put(documentAPI.DATAOBJECT_RECORD_API + '/' + record_id, document)
        return data
    }

    /**
     * Отправляет запрос на установку/снятие метки удаления одной записи в выбранной таблице
     * @static
     * @param {String} id ID записи
     * @param {Boolean} mark Метка удаления (true - удалить, false - снять удаление)
     * @returns {String} ID записи
     */
    static async deleteDataObject(id, mark) {
        const {data} = await $dataHost.put(documentAPI.DATAOBJECT_RECORD_API + '/' + id, mark)
        return data
    }

    /**
     * Отправляет запрос на получение списка всех существующих таблиц
     * @static
     * @param {Object[]} filters Массив фильтров
     * @param {Object[]} sorters Массив правил сортировки
     * @param {String} mode Тип доступа к записи (r - только чтение, w - чтение и запись)
     * @returns {Object[]} Массив таблиц
     */
    static async getDataModels(filters, sorters, mode) {
        let data = []
        let response
        let offset = 0,
            count = 50

        do {
            response = await $dataHost.get(documentAPI.DATAMODEL_API + '/search', {params: {offset, count, total: true, filter: filters, sort: sorters, mode: mode}})
            data = data.concat(response.data)
            offset += count
        } while (response?.data?.length === count)

        return data
    }

    /**
     * Отправляет запрос на получение одной выбранной таблицы
     * @param {String} id ID таблицы
     * @returns {Object} Структура таблицы
     */
    static async getOneDataModel(id) {
        const {data} = await $dataHost.get(documentAPI.DATAMODEL_API + '/' + id)
        return data
    }
    
    /**
     * Отправляет запрос на проверку выбранной таблицы на предмет блокировки для изменений
     * @param {String} id ID таблицы
     * @returns {Boolean} Признак блокировки таблицы
     */
    static async checkDataModelLock(id) {
        const {data} = await $dataHost.get(documentAPI.DATAMODEL_API + '/' + id)
        return (data.transactions && data.transactions.length)
    }
    
    /**
     * Отправляет запрос на создание одной таблицы
     * @static
     * @param {Object} dataModel Данные о структуре таблицы
     * @returns {Object} ID созданной таблицы
     */
    static async createDataModel(dataModel) {
        const {data} = await $dataHost.post(documentAPI.DATAMODEL_API, dataModel)
        return data
    }

    /**
     * Отправляет запрос на обновление структуры выбранной таблицы
     * @static
     * @param {String} id ID таблицы
     * @param {String[]} dataModel Массив ID добавляемых полей
     * @returns {null} Пустой ответ
     */
    static async editDataModel(id, dataModel) {
        const {data} = await $dataHost.put(documentAPI.DATAMODEL_API + '/' + id, dataModel)
        return data
    }

    /**
     * Отправляет запрос на установку/снятие метки скрытия одной таблицы
     * @static
     * @param {String} id ID таблицы
     * @param {Boolean} mark Метка скрытия (true - скрыть, false - показать)
     * @returns {null} Пустой ответ
     */
    static async hideDataModel(id, mark) {
        const {data} = await $dataHost.put(documentAPI.DATAMODEL_API + '/' + id, mark)
        return data
    }


    // Методы работы с полями

    /**
     * Отправляет запрос на создание одного поля
     * @static
     * @param {Object} field Данные поля
     * @returns {String} ID поля
     */
    static async createField(field) {
        const {data} = await $dataHost.post(documentAPI.FIELD_API, field)
        return data
    }

    /**
     * Отправляет запрос на получение списка существующих полей
     * @static
     * @param {Object[]} filter Массив фильтров
     * @returns {Object[]} Массив полей
     */
    static async getFields(filter) {
        let data = []
        let response
        let offset = 0
        let count = 50

        do {
            response = await $dataHost.get(documentAPI.FIELD_API + '/search', {params: {offset, count, total: true, filter: filter}})
            data = data.concat(response.data)
            offset += count
        } while (response?.data?.length === count)

        return data
    }

    /**
     * Отправляет запрос на получение существующего поля
     * @static
     * @param {String} id ID поля
     * @returns {Object} Поле
     */
    static async getOneField(id) {
        const { data } = await $dataHost.get(documentAPI.FIELD_API + '/' + id)

        return data
    }

    /**
     * Отправляет запрос на обновление одного поля
     * @static
     * @param {String} id ID поля
     * @param {Object} field Данные поля
     * @returns {null} Пустой ответ
     */
    static async updateField(id, field) {
        const {data} = await $dataHost.put(documentAPI.FIELD_API + '/' + id, field)
        return data
    }

}

export default DocumentService