// функция сравнивает две версии записи и формирует признак отличия для отличающихся значений полей
export function checkVersionsModification(recordVersions) {
    const versions = recordVersions.sort((a, b) => ('' + a.created).localeCompare(b.created)) // сортировка версий записи по дате изменения

    // формирование признаков изменения для каждого поля записи для каждой версии
    let previousVersion = versions[0]
    let i = 1
    while (i < versions.length) {
        for (const [key, value] of Object.entries(versions[i].data)) {
            if (value.type === 'reference') { 
                // для типа "Ссылка" сравнение массивов
                const oldRefValue = previousVersion.data[key].value.values.map(item => item.id).join(', ')
                const currentRefValue = value.value.values.map(item => item.id).join(', ')
                value.modified = currentRefValue !== oldRefValue
            } else {
                // всех остальных типов полей
                value.modified = value.value !== previousVersion.data[key].value
            }
        }
        // сравнение приложенных файлов
        const oldFiles = previousVersion.system_data.files ? previousVersion.system_data.files.map(item => item.id).join(',') : ''
        const currentFiles = versions[i].system_data.files ? versions[i].system_data.files.map(item => item.id).join(',') : ''
        versions[i].system_data.modified = oldFiles !== currentFiles
        
        previousVersion = versions[i]
        i++
    }
    return versions
}
