import { PrinterIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'


/**
 * Визуальный компонент отображает кнопку с выпадающим меню "Экспорт"
 * @param {Boolean} notPrintRecord Признак недоступности пунктов меню экспорта выбранной записи таблицы
 * @param {Function} onPrintDocumentClick Обработчик клика мыши на кнопке экспорта выбранной записи в файл .PDF
 * [handleOpenExportersClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line222) и 
 * [handleOpenExportersClick](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line120)
 * @returns {JSXElement} Визуальный компонент Menu из библиотеки {@link https://headlessui.com/|@headlessui}
 * 
 * @see [Вызов компонента DataObjectMenu](./components_main_page_controller_data_object__menu_DataObjectMenu.js.html#line154)
 * [и DataObjectForm](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line135)
 */
function DataPrintMenu({notPrintRecord, onPrintDocumentClick}) {
    return (
        <>
            <button
                type='button'
                className='tw-rounded-md tw-p-1.5 tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600 disabled:tw-bg-gray-400'
                onClick={onPrintDocumentClick}
                disabled={notPrintRecord}
                data-tooltip-id="print-tooltip"
                data-tooltip-content="Скачать PDF/ODT"
            >
                <PrinterIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
            </button>
            <Tooltip id="print-tooltip" place="top"/>
        </>
    )
}

export default DataPrintMenu