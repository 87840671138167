import React from 'react'
import ImporterItem from './ImporterItem'


/**
 * Визуальный компонент отображает список существующих шаблонов импорта
 * @param {Object[]} list Список существующих шаблонов импорта
 * @param {Object} activeItem Выбранный элемент в списке шаблонов импорта
 * @param {Function} onItemClick Обработчик клика мыши на элементе списка
 * [handleItemClick](./components_main_page_controller_importer_ImporterListContainer.js.html#line35)
 * @param {Object[]} dataModelsList Список таблиц
 * 
 * @returns {HTMLDivElement} Html-разметку списка существующих шаблонов импорта с использованием визуального компонента {@link ImporterItem}
 * 
 * @see [Вызов компонента](./components_main_page_controller_importer_ImporterListContainer.js.html#line208)
 */
const ImporterList = ({list, activeItem, onItemClick, dataModelsList}) => {
    return (
        <div  id='importer-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-bg-white tw-overflow-auto'>
            { list.map((importer, index) => 
                <ImporterItem 
                    key={index}
                    item={importer}
                    activeItem={activeItem}
                    onClick={onItemClick}
                    dataModelsList={dataModelsList}
                />
            )}
        </div>
    )
}

export default ImporterList