/**
 * Визуальный компонент отображает загрузочную анимацию при ожидании ответа от сервера
 * @param {String} size Размер
 * 
 * @returns {HTMLDivElement} Элемент в виде дуги, вращающейся по кругу
 * 
 * @see [Вызов компонента CommissionForm,](./components_main_page_controller_categorizing_cii_form_CommissionForm.js.html#line193)
 * [SpheresForm,](./components_main_page_controller_categorizing_cii_form_SpheresForm.js.html#line223)
 * [SpheresForm,](./components_main_page_controller_categorizing_cii_form_SpheresForm.js.html#line254)
 * [NestedTableTabPanel,](./components_tabs_nested_tables_NestedTableTabPanel.js.html#line73)
 * [NoteTabPanel,](./components_tabs_notes_NoteTabPanel.js.html#line61)
 * [OrganizationForm,](./components_main_page_controller_categorizing_cii_form_OrganizationForm.js.html#line276)
 * [PrintListForm,](./components_main_page_controller_categorizing_cii_form_PrintListForm.js.html#line95)
 * [PrintDocumentModal,](./components_main_page_controller_exporter_to_file_PrintDocumentModal.js.html#line90)
 * [ProcessesForm,](./components_main_page_controller_categorizing_cii_form_ProcessesForm.js.html#line322)
 * [ResourcesForm,](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line448)
 * [ResourcesForm,](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line485)
 * [DirectoryContainer,](./components_main_page_controller_common_panels_directory_DirectoryContainer.js.html#line442)
 * [StageListContainer,](./components_main_page_controller_common_stage_list_StageListContainer.js.html#line59)
 * [StageListContainer,](./components_main_page_controller_common_stage_list_StageListContainer.js.html#line72)
 * [CategoryListContainer,](./components_main_page_controller_category_CategoryListContainer.js.html#line60)
 * [ExporterForm,](./components_main_page_controller_exporter_form_ExporterForm.js.html#line162)
 * [ExporterListContainer,](./components_main_page_controller_exporter_ExporterListContainer.js.html#line217)
 * [EditDataModelContainer,](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line149)
 * [EditDataObjectContainer,](./components_main_page_controller_data_object_edit_EditDataObjectContainer.js.html#line235)
 * [ImporterListContainer,](./components_main_page_controller_importer_ImporterListContainer.js.html#line195)
 * [ImportPreviewContainer,](./components_main_page_controller_importer_preview_ImportPreviewContainer.js.html#line162)
 * [ImportFromFileModal,](./components_main_page_controller_importer_from_file_ImportFromFileModal.js.html#line123)
 * [ImportFromFileModal,](./components_main_page_controller_importer_from_file_ImportFromFileModal.js.html#line176)
 * [ImportDataViewContainer,](./components_main_page_controller_importer_from_server_ImportDataViewContainer.js.html#line56)
 * [DataObjectListContainer,](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line499)
 * [DataObjectListContainer,](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line539)
 * [DataModelListContainer,](./components_main_page_controller_data_model_DataModelListContainer.js.html#line118)
 * [DuplicateDataObjectContainer,](./components_main_page_controller_data_object_add_DuplicateDataObjectContainer.js.html#line201)
 * [FieldEditor,](./components_main_page_controller_field_FieldEditor.js.html#line36)
 * [FileTabPanel,](./components_tabs_files_FileTabPanel.js.html#line40)
 * [AppHoneyBadger,](./AppHoneyBadger.js.html#line100)
 * [AdminContainer,](./components_admin_page_AdminContainer.js.html#line32)
 * [AddDataObjectContainer,](./components_main_page_controller_data_object_add_AddDataObjectContainer.js.html#line195)
 * [CategorizingObjectsContainer,](./components_main_page_controller_categorizing_cii_form_categorizingObjects_CategorizingObjectsContainer.js.html#line115)
 * [CategorizingObjectsForm,](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line501)
 * [CategorizingObjectsForm,](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsForm.js.html#line538)
 * [CategorizingObjectsFormItem,](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsFormItem.js.html#line279)
 * [UserInfoContainer,](./components_header_userInfo_UserInfoContainer.js.html#line100)
 * [ActivateUser,](./components_sign_page_activate_user_ActivateUser.js.html#line72)
 * [RegistrationFormContainer,](./components_sign_page_registration_RegistrationFormContainer.js.html#line65)
 * [ISPDataListContainer,](./components_main_page_controller_personal_data_form_ISPData_ISPDataListContainer.js.html#line209)
 * [ISPDataForm,](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line380)
 * [ISPDataForm,](./components_main_page_controller_personal_data_form_ISPData_ISPDataForm.js.html#line419)
 * [PDataOrganizationForm,](./components_main_page_controller_personal_data_form_PDataOrganizationForm.js.html#line376)
 * [PDataPrintOrderForm,](./components_main_page_controller_personal_data_form_PDataPrintOrderForm.js.html#line148)
 * [PDataPrintOrderForm,](./components_main_page_controller_personal_data_form_PDataPrintOrderForm.js.html#line173)
 * [PDataProcessListContainer,](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line220)
 * [PDataProcessForm,](./components_main_page_controller_personal_data_form_process_data_PDataProcessForm.js.html#line351)
 * [PDataAddQuestionForm,](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line196)
 * [PDataRKNForm,](./components_main_page_controller_personal_data_form_PDataRKNForm.js.html#line316)
 * [PData3PartyListContainer,](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyListContainer.js.html#line201)
 * [PData3PartyForm,](./components_main_page_controller_personal_data_form_3party_handler_PData3PartyForm.js.html#line353)
 * [PDataPrintTaskForm,](./components_main_page_controller_personal_data_form_PDataPrintTaskForm.js.html#line165)
 * [PDataPrintTaskForm,](./components_main_page_controller_personal_data_form_PDataPrintTaskForm.js.html#line190)
 * [SelectReferencedModel,](./components_main_page_controller_common_modals_SelectReferencedModel.js.html#line42)
 * [FieldItem,](./components_form_fields_FieldItem.js.html#line140)
 * [MaterialsObjectsContainer,](./components_main_page_controller_education_materials_MaterialsObjectsContainer.js.html#line98)
 * [TestAttempts,](./components_main_page_controller_education_materials_testAttempts_TestAttempts.js.html#line49)
 * [QuestionsListContainer,](./components_main_page_controller_education_materials_tests_QuestionsListContainer.js.html#line69)
 * [MaterialProgress,](./components_main_page_controller_education_materials_material_progress_MaterialProgress.js.html#line49)
 * [CompanyItem](./components_main_page_controller_admin_page_companies_list_CompanyItem.js.html#line55)
 */
const Spinner = ({size}) => {
    switch (size) {
        case 'small':
            return <div className="tw-m-2 tw-w-8 tw-h-8 tw-rounded-full tw-border-4 tw-border-gray-400 tw-border-t-gray-900 tw-animate-spin"></div>
    
        default:
            return <div className="tw-mx-auto tw-mt-12 tw-w-20 tw-h-20 tw-rounded-full tw-border-8 tw-border-gray-400 tw-border-t-gray-900 tw-animate-spin"></div>
    }
}

export default Spinner