import React, { useContext } from 'react'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import { paths } from '../../../../config/constsURN'
import { getFormattedDate } from '../../../../config/constTypes'
import { useNavigate } from 'react-router-dom'

function getModuleInfo(module, records, isAuth) {
    let text = ''
    let id = '0'
    let recordDataModel = ''
    const lastRecord = records.find(record => module.dataModel.includes(record.data.data_model_id))
    if (lastRecord) {
        const fields = Object.values(lastRecord.data.data)
        const stageField = fields.find(item => item.tech_name.includes('current_stage'))
        const stage = stageField ? ', этап ' + stageField.value : ''
        const objects = lastRecord.objects ? ', объектов: ' + lastRecord.objects : ''
        text = 'Запись от ' + getFormattedDate(lastRecord.data.created, 'dd.MM.yyyy') + stage + objects
        id = lastRecord.data.id
        recordDataModel = lastRecord.data.data_model_id
    }
    const link = isAuth ? module.link : paths.LOGIN_ROUTE

    return [text, link, id, recordDataModel]
}

/**
 * Визуальный компонент отображает активный модуль МЕДОЕДа (в котором пользователь выполнял какие-то действия)
 * @param {Object} module Модуль
 * @param {Object} colorTheme Цветовая схема
 * @param {Object[]} records Существующие записи процессов
 *
 * @returns {HTMLDivElement} Html-разметку активного модуля МЕДОЕДа
 *
 * @see [Вызов компонента ModuleGroup](./components_main_page_controller_summary_ModuleGroup.js.html#line29)
 */
const ActiveModuleItem = ({module, colorTheme, records}) => {
    const { userStore, docStore } = useContext(Context)
    const navigate = useNavigate()

    const [recordText, linkText, recordID, recordDataModel] = getModuleInfo(module, records, userStore.isAuth)

    const handleItemClick = async () => {
        await docStore.setModuleType(recordDataModel)
        await docStore.setModuleRecordID(recordID)
        navigate(linkText)
    }

    return (
        <div
            className={`tw-flex tw-flex-col tw-px-2 tw-pt-0.5 tw-pb-1 tw-mt-2 tw-rounded-md tw-border-2 tw-text-left
                        ${colorTheme.borderColor} tw-bg-gray-50 hover:tw-cursor-pointer hover:tw-bg-gray-200`}
            onClick={handleItemClick}
        >
            <div className={`tw-text-sm tw-font-semibold ${colorTheme.textColor}`}>{module.name}</div>
            <div className={`tw-text-sm ${colorTheme.textColor} tw-py-0.5`}>
                {recordText.length ? recordText : 'Сохраненная запись не найдена'}
            </div>
        </div>
    )
}

export default observer(ActiveModuleItem)
