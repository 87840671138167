import React, { useState, useContext, useEffect, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"
import { observer } from 'mobx-react-lite'
import { showErrorToast } from '../../../../../../functions/errorHandlers'
import Spinner from '../../../../../../assets/Spinner'
import { Context } from '../../../../../..'
import { setFieldDefaultValue, setFieldValue, setReferenceValue } from '../../../../../../config/constTypes'
import DirectoryContainer from '../../../common/panels/directory/DirectoryContainer'
import DocumentService from '../../../../../../services/DocumentService'
import ObjectsListButtons from '../../../common/panels/object_list/ObjectsListButtons'
import FieldItem from '../../../../../form_fields/FieldItem'
import { updateNestedDataObjects, deleteNestedDataObjects, deleteAllNestedDataObjects } from '../../../../../../functions/nestedModels'
import { processData } from '../../../../../../functions/forms'
import { personalDataProcessCategories } from '../../../../../../config/constPersonalData'
import FieldCategory from '../../../../../form_fields/FieldCategory'

registerLocale("ru", ru)

/**
 * Визуальный компонент отображает форму для редактирования информации о процессе обработки (направлении деятельности)
 * 
 * @param {Function} onBackClick Обработчик клика мыши для возвращения к предыдущему объекту
 * [ObjectsListButtonsBackClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line43) 
 * @param {Function} onForwardClick Обработчик клика мыши для перехода к следующему объекту
 * [ObjectsListButtonsForwardClick](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line47) 
 * @param {Object} stage Текущий активный процесс обработки
 * @param {Object[]} objectsList Список существующих процессов обработки
 * @param {Function} setLastObjectSaved Сохранение информации о сохранении последнего процесса обработки
 * [setLastObjectSaved](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line28)
 * @param {Function} markObjectAsFinished Сохранение информации о завершении редактирования текущего процесса обработки
 * [markObjectAsFinished](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line51)
 * 
 * @returns {JSXElement} Html-разметку формы редактирования информации о процессе обработки (направлении деятельности)
 * с использованием визуальных компонентов {@link Spinner}, {@link ObjectsListButtons}, {@link DirectoryContainer} , {@link FieldItem}
 *   
 * @see [Вызов компонента](./components_main_page_controller_personal_data_form_process_data_PDataProcessListContainer.js.html#line204)
 */
const PDataProcessForm = ({onBackClick, onForwardClick, stage, objectsList, setLastObjectSaved, markObjectAsFinished}) => {   
    const {
        control,
        register,
        handleSubmit,
        setValue,
        resetField,
        formState: { isSubmitting, isValid, errors },
        clearErrors
    } = useForm()

    const { personalDataStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [isSavedRecord, setIsSavedRecord] = useState(false)
    const [formFields, setFormFields] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [recordId, setRecordId] = useState('')
    const [formNestedModels, setFormNestedModels] = useState(null)
    const [isDuplicateMode, setIsDuplicateMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [replacedNestedDataObjects, setReplacedNestedDataObjects] = useState([])

    register('data.area_of_activity', { 
        required: false,
        shouldUnregister: true,
    })

    const handleSelectClick = (dataModel, name) => {
        setSelectedDataModelID(dataModel)
        setSelectedName(name)
        setIsSelectFormOpen(true)
    }

    const handleClearClick = (name) => {
        setValue(name, {format: '', values: []})
        handleRecordChange()
    }

    const handleRecordChange = () => {
        setIsSavedRecord(false)
        setLastObjectSaved(false)
    }

    const handleDoubleClick = (item) => {
        if(selectedName !== 'duplicateRecord'){
            setValue(selectedName, {value: setReferenceValue(item)})
        } else {
            duplicateRecord(item)
        }
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)
        setIsSavedRecord(false)
        setLastObjectSaved(false)
    }

    const handleCloseClick = () => {
        docStore.setIsDetailView(false)
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    // Функция заполнения полей значениями по умолчанию в зависимости от типа поля записи
    const setFieldInputDefaultValues = (item) => {
        switch(item.validator_type) {
            case 'one':
            case 'many':
                return resetField('data.' + item.tech_name + '.0', { defaultValue: 
                    (isEditMode || isDuplicateMode) ? item : {format: '', values: []}
                })
            case 'bool':
            return resetField('data.' + item.tech_name, { defaultValue: 
                    (isEditMode || isDuplicateMode) ? setFieldValue(item) : setFieldDefaultValue(item)})
            case 'int':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0})
            case 'float':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : item.default || 0})
            case 'enum':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : null})
            case 'date':
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : null})
            default:
                return resetField('data.' + item.tech_name, { defaultValue: (isEditMode || isDuplicateMode) ? setFieldValue(item) : null})
        }
    }
    
    const handleSaveRecord = async (form) => {
        let error = false
        let errorCategories = []

        personalDataProcessCategories.forEach(category => {
            const isCheckedField = category.fields.some(field => form.data[field]) || !category.mandatory
            if (!isCheckedField) {
                error = true
                errorCategories.push(category.name)
            }
        })

        if (error) {
            toast.error(`Не выбрано ни одного значения в "${errorCategories.join('", "')}"`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })

        } else {
            const dataObject = processData(form.data, personalDataStore.activeDataModel.id)

            dataObject.data['area_of_activity'] = [stage.object_record_id]
            
            personalDataStore.nestedModels.forEach(nestedModel => {
                let deletedNestedDataObjects = []

                replacedNestedDataObjects.forEach(replacedNestedModel => {
                    if (replacedNestedModel.techName === nestedModel.tech_name) {
                        deletedNestedDataObjects = replacedNestedModel.dataObjects
                    }
                })

                dataObject.data[nestedModel.tech_name] = {
                    upsert: updateNestedDataObjects(nestedModel),
                    remove: deleteNestedDataObjects(nestedModel).concat(deletedNestedDataObjects),
                }
            })

            try {
                if (isEditMode) {
                    await DocumentService.updateDataObject(recordId, dataObject)
                    await personalDataStore.resetPrintPolicyStage(personalDataStore.project.id)
                } else {                        
                    const response = await DocumentService.createDataObject(dataObject)

                    const project = {}
                    project.data_model_id = 'stages_of_documentation_pdn'
                    project.data = {} 
                   
                    project.data['process_of_lines_areas_of_activity'] = {
                        upsert: [{record_id: response.record_id}],
                        remove: []
                    }
            
                    const savedProject = await DocumentService.updateDataObject(personalDataStore.project.record_id, project)
                    await personalDataStore.resetPrintPolicyStage(savedProject.id)
                }

                toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                setIsSavedRecord(true)

            } catch (error) {
                showErrorToast(error, 'saving', '')
            }
        }
    }

    const duplicateRecord = (item) => {
        //Пометка на удаление сохраненных записей вложенных таблиц дублируемого объекта
        if (personalDataStore.nestedModels.some(nestedModel => nestedModel.dataObjects.some(nestedDataObject => nestedDataObject.status === 'saved'))) {
            let replacedArr = []
            personalDataStore.nestedModels.forEach(nestedModel => {
                replacedArr.push({
                    techName: nestedModel.tech_name, 
                    dataObjects: deleteAllNestedDataObjects(nestedModel)
                })
            })
            setReplacedNestedDataObjects(replacedArr)
        }
        
        const fields = Object.values(item.data)
        setIsDuplicateMode(true)
        setFormFields(fields)
        initNestedModels(item.id, true)
    }  
    
    const initNestedModels = async (id, isDuplicate) => {  
        setIsLoading(true)

        const nestedValueRequests = formNestedModels.map(nestedModel => id ? DocumentService.getNestedDataObjects(50, [], [], id, nestedModel.rule_id) : nestedModel)
        Promise.all(nestedValueRequests)
            .then(responses => {
                personalDataStore.setNestedModels(formNestedModels.map((nestedModel, nestedModelIndex) =>  { 
                    return {
                        ...nestedModel,
                        dataObjects: id ? responses[nestedModelIndex].map(value => { return {...value, status: isDuplicate ? 'added' : 'saved'} }) : []
                    }
                }))
            })
            .finally(() => setIsLoading(false))
    }

    const handleChangeNestedTables = (editedValues, editedNestedModel) => {
        personalDataStore.setNestedModels(personalDataStore.nestedModels.map(nestedModel =>  { 
            if (nestedModel.rule_id === editedNestedModel.rule_id) {
                return {
                    ...nestedModel,
                    dataObjects: editedValues,
                    errors: editedNestedModel.errors ? personalDataStore.checkPrimaryFieldErrors(editedNestedModel, editedValues) : false
                }
            }
            return nestedModel
        }))
        clearErrors()
        handleRecordChange()
    }
    useEffect(() => {
        personalDataStore.getActiveDataModel('processing_process', setFormNestedModels)
    }, [])

    useEffect(() => {
        if (personalDataStore.activeDataModel && personalDataStore.activeDataModel.id === 'processing_process') {
            setIsEditMode(false)
            setIsDuplicateMode(false)
            setIsSavedRecord(false)
            setFormFields(personalDataStore.activeDataModel.fields)
            
            if (personalDataStore.processList && personalDataStore.processList.length > 0) {
                const foundAct = personalDataStore.processList.find(object => 
                    stage.record_id === object.record_id
                )

                if (foundAct) {
                    const fields = Object.values(foundAct.data)
                    setRecordId(foundAct.record_id)
                    setFormFields(fields)
    
                    setIsEditMode(true)
                    setIsSavedRecord(true)
                }
            }
        }
    }, [stage, personalDataStore.activeDataModel])

    useEffect(() => {
        if (formFields) {
            formFields.forEach(field => {
                if (field.field_id !== 'area_of_activity' && !field.hide && field.type !== 'include')
                    setFieldInputDefaultValues(field)
            })

            setValue('data.area_of_activity', [stage.object_record_id])
        }
    }, [formFields])

    useEffect(() => {
        if (formNestedModels) {
            initNestedModels(null, false)

            if (personalDataStore.processList && personalDataStore.processList.length > 0) {
                personalDataStore.processList.forEach(object => {
                    if (stage.record_id === object.record_id) {
                        initNestedModels(stage.id, false)
                    }
                })
            } 
        }
    }, [stage, formNestedModels])

    useEffect(() => {
        setLastObjectSaved(objectsList.every(object => {
            return object.status === "finished"
        }))
    }, [objectsList])

    
    useEffect(() => {
        markObjectAsFinished(isSavedRecord)
    }, [isSavedRecord, stage])

    isSubmitting && (!isValid || errors.data) && 
        toast.error('Не заполнено обязательное поле!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })

    return (
        <>
            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-mx-auto tw-py-2'>
                <button 
                    className='tw-rounded-md  tw-mr-2 tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-600 hover:tw-border-gray-600 
                            focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={handleSubmit(handleSaveRecord)}
                >
                    Сохранить
                </button>
                <button 
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-600 hover:tw-border-gray-600 
                            focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={(e) => {e.preventDefault(); handleSelectClick('processing_process', 'duplicateRecord')}}
                >
                    Загрузить
                </button>
            </div>
            <div className='tw-grow tw-overflow-auto'>
                <form className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-px-4 tw-py-1'>
                        { !formFields
                            ?
                                <Spinner />
                            :
                                formFields.slice().sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                    if (fieldItem.field_id !== 'area_of_activity' && !fieldItem.hide) {
                                        return  <Fragment key={index}>
                                                    <FieldCategory
                                                        categories={personalDataProcessCategories}
                                                        fieldItem={fieldItem}
                                                    />
                                                    <FieldItem 
                                                        fieldItem={fieldItem}
                                                        nestedModels={personalDataStore.nestedModels}
                                                        selectedNestedValue={personalDataStore.selectedNestedValue}
                                                        errors={errors}
                                                        handleSelectClick={handleSelectClick}
                                                        handleClearClick={handleClearClick}
                                                        handleRecordChange={handleRecordChange}
                                                        isEditMode={isEditMode}
                                                        isDuplicateMode={isDuplicateMode}
                                                        isLoading={isLoading}
                                                        control={control}
                                                        register={register}
                                                        setValue={setValue}
                                                        onSelectNestedValue={e => personalDataStore.setSelectedNestedValue(e)}
                                                        onNestedTableChange={handleChangeNestedTables}
                                                    />
                                                </Fragment>
                                    } else return null
                                })
                        }
                    </div>                            
                </form>
            </div>
            <ObjectsListButtons
                onBackClick={onBackClick}
                onForwardClick={onForwardClick}
                stage={stage}
                objectsList={objectsList}
                disabled={!isSavedRecord}
                forwardButtonTitle={'Следующий процесс'}
                backButtonTitle={'Предыдущий процесс'}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
                isDuplicateObject={selectedName === 'duplicateRecord' ? true : false}
            />
        </>                                                             
    )
}

export default observer(PDataProcessForm)
