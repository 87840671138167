import React from 'react'
import { observer } from 'mobx-react-lite'
import { CheckCircleIcon, MinusIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { getReferenceValue } from '../../../../../config/constTypes'
import { getFieldTechName } from '../../../../../functions/getFieldTechName'


/**
 * Визуальный компонент отображает элемент списка связываемых сущностей (для списка из выбранных пользователем записей)
 * 
 * @param {Object} item Текущий элемент списка 
 * @param {Object} selectedItem Выбранный элемент списка
 * @param {Object} linkingItem Элемент, связанный с выбранным элементом списка
 * @param {String} linkField Имя поля, по которому выполняется связывание
 * @param {Function} onClick Обработчик клика мыши на элементе списка
 * [handleProcessClick](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line45),
 * [handleResourceClick](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line53),
 * [handleResourceClick](./components_main_page_controller_categorizing_cii_form_SpheresForm.js.html#line40),
 * [handleSphereClick](./components_main_page_controller_categorizing_cii_form_SpheresForm.js.html#line33)
 * @param {Function} onLinkClick Обработчик клика мыши на элементе списка, связанном с выбранным элементом списка
 * [handleLinkingClick](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line61),
 * [handleLinkingClick](./components_main_page_controller_categorizing_cii_form_SpheresForm.js.html#line48)
 * @param {Object[]} keyFieldNames Массив названий требуемых полей
 * 
 * @returns {HTMLDivElement} Html-разметку с элементом списка связываемых объектов
 * 
 * @see [Вызов компонента](./components_main_page_controller_categorizing_cii_list_LinkedList.js.html#line36)
 */
const LinkedItem = ({item, selectedItem, linkingItem, linkField, onClick, onLinkClick, keyFieldNames}) => {
    const foundFieldTechName = getFieldTechName(Object.values(item.data), keyFieldNames)

    const isItemChecked = linkingItem && (linkingItem[linkField] ? linkingItem[linkField].includes(item.value_record_id) : item[linkField].includes(linkingItem.value_record_id))

    let className = 'tw-relative tw-pl-12 tw-text-sm tw-select-none tw-border-b tw-border-gray-400'

    if (selectedItem && selectedItem.id === item.id) {
        className += ' tw-text-white tw-bg-gray-500 hover:tw-cursor-pointer'
    } else {
        className += ` tw-text-gray-900 tw-bg-white hover:tw-bg-gray-200 hover:tw-border-gray-300 hover:tw-shadow-lg
                        hover:tw-border-x hover:tw-border-b-0 hover:tw-cursor-pointer`
    }

    return (
        <div
            id={item.id}
            className={className}
        >
            { linkingItem &&
                <div
                    className='tw-text-gray-500 tw-absolute tw-left-3 tw-py-1.5'
                    onClick={() => onLinkClick(item.value_record_id)}
                >
                    { isItemChecked
                        ?   <CheckCircleIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        :   <MinusIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    }
                </div>
            }
            { !linkingItem && item.errors && item[linkField] && !item[linkField].length &&
                <div className='tw-absolute tw-left-3 tw-py-1.5'>
                    <ExclamationCircleIcon className='tw-w-5 tw-h-5 tw-text-red-500' aria-hidden='true'/>
                </div>
            }
            <div
                className='tw-w-full tw-py-1.5'
                onClick={() => onClick(item)}
            >
                { foundFieldTechName 
                    ? typeof item.data[foundFieldTechName].value === 'string' ? item.data[foundFieldTechName].value : getReferenceValue(item.data[foundFieldTechName].value) 
                    : 'некорректные данные'
                } 
            </div>
        </div>
    )
}

export default observer(LinkedItem)