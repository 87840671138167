import { makeAutoObservable } from "mobx"

/**
 * Класс реализует хранилище информации о диалоговых окнах
 * @class
 * 
 * @property {Boolean} dialogTabIsOpen Состояние окна
 * @property {String} dialogTabTitle Заголовок окна
 * @property {String} dialogTabText Текст окна
 * @property {Boolean} allowAreaCloseClick Возможность закрыть окно кликом за его пределами
 */
class DialogTabStore {
    dialogTabIsOpen = false
    dialogTabTitle = ""
    dialogTabText = ""
    allowAreaCloseClick = true
    parentName = ""
    dialogTabButtons = ["Да", "Нет"]

    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    */
    constructor(){
        makeAutoObservable(this) 
    }

    /**
     * Метод осуществляет сохранение информации о состоянии окна
     * @method
     * 
     * @param {Boolean} value Информация о состоянии окна
     */
    setDialogTabIsOpen(value) {
        this.dialogTabIsOpen = value
    }

    /**
     * Метод осуществляет сохранение заголовка окна
     * @method
     * 
     * @param {String} title Заголовок окна
     */
    setDialogTabTitle(title) {
        this.dialogTabTitle = title
    }

    /**
     * Метод осуществляет сохранение текста окна
     * @method
     * 
     * @param {String} text Текст окна
     */
    setDialogTabText(text) {
        this.dialogTabText = text
    }

    /**
     * Метод осуществляет сохранение информации о возможности закрыть окно кликом за его пределами
     * @method
     * 
     * @param {Boolean} value Информация о возможности закрыть окно кликом за его пределами
     */
    setAllowAreaCloseClick(value) {
        this.allowAreaCloseClick = value
    }

    /**
     * Метод осуществляет сохранение информации о родителе, вызвавшем окно
     * @method
     * 
     * @param {String} parentName Информация о родителе, вызвавшем окно
     */
    setParentName(parentName) {
        this.parentName = parentName
    }

    /**
     * Метод осуществляет сохранение информации о надписях на кнопках
     * @method
     * 
     * @param {String[]} buttons Массив надписей на кнопках
     */
    setDialogTabButtons(buttons) {
        this.dialogTabButtons = buttons
    }
}

export default DialogTabStore
