import React from 'react'
import ExporterItem from './ExporterItem'


/**
 * Визуальный компонент отображает список существующих печатных форм
 * @param {Object[]} list Список существующих печатных форм
 * @param {Object} activeItem Выбранный элемент в списке печатных форм
 * @param {Function} onItemClick Обработчик клика мыши на элементе списка
 * [handleItemClick](./components_main_page_controller_exporter_ExporterListContainer.js.html#line38)
 * @param {Object[]} dataModelsList Список таблиц
 * 
 * @returns {HTMLDivElement} Html-разметку списка существующих печатных форм с использованием визуального компонента {@link ExporterItem}
 * 
 * @see [Вызов компонента](./components_main_page_controller_exporter_ExporterListContainer.js.html#line236)
 */
const ExporterList = ({list, activeItem, onItemClick, dataModelsList}) => {
    return (
        <div  id='exporter-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_3rem)] tw-w-full tw-bg-white tw-overflow-auto'>
            { list.map((exporter, index) => 
                <ExporterItem 
                    key={index}
                    item={exporter}
                    activeItem={activeItem}
                    onClick={onItemClick}
                    dataModelsList={dataModelsList}
                />
            )}
        </div>
    )
}

export default ExporterList