import React from "react";
import { getFormattedDate } from "../../../../../../config/constTypes";

/**
 * Визуальный компонент отображает строку в таблице результатами попыток теста
 * @param {Object} item Попытка
 * @returns {HTMLFormElement} Html-разметку строки в таблице с сообщениями 
 * 
 * @see [Вызов компонента TestAttemptsTable](./components_main_page_controller_education_materials_testAttempts_TestAttemptsTable.js.html#line112)
*/
const TestAttemptsRow = (item) => {
    return (
        <tr key={item.id} style={{
            display: 'grid',
            gridTemplateColumns: item.sizes
        }}>
            <td className='tw-truncate tw-px-2 tw-relative tw-text-center'>
                <span
                    data-tooltip-id="material-form-tooltip"
                    data-tooltip-content={getFormattedDate(item.data.date.value, 'dd.MM.yyyy HH:mm:ss')}
                    data-tooltip-delay-show={1000}
                >
                   {getFormattedDate(item.data.date.value, 'dd.MM.yyyy HH:mm:ss')}
                </span>
            </td>

            <td className='tw-truncate tw-px-2 tw-text-center'>
                <span
                    data-tooltip-id="material-form-tooltip"
                    data-tooltip-content={item.data['test_result'].value}
                    data-tooltip-delay-show={1000}
                >
                    {item.data['test_result'].value}
                </span>
            </td>

            <td className='tw-truncate tw-px-2 tw-text-center'>
                <span
                    data-tooltip-id="material-form-tooltip"
                    data-tooltip-content={item.author.first_name + ' ' + item.author.last_name + ' (' + item.author.email + ')'}
                    data-tooltip-delay-show={1000}
                >
                    {item.author.first_name + ' ' + item.author.last_name + ' (' + item.author.email + ')'}
                </span>
            </td>
        </tr>
    )
}

export default TestAttemptsRow