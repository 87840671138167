import React from 'react'

/**
 * Визуальный компонент отображает кнопку выбора файла
 * 
 * @param {Function} onFileSelect Обработчик клика мыши на кнопке выбора файла
 * [handleFileSelect](./functions_fileHandlers.js.html#line41)
 * 
 * @returns {HTMLDivElement} Html-разметку кнопки выбора файла 
 * 
 * @see [Вызов компонента ConfigurationUpload,](./components_configuration_page_ConfigurationUpload.js.html#line68)
 * [ExporterForm,](./components_main_page_controller_exporter_form_ExporterForm.js.html#line152)
 * [FileTabPanel,](./components_main_page_controller_data_object_tabs_files_FileTabPanel.js.html#line49)
 * [ImportFromFileModal](./components_main_page_controller_importer_from_file_ImportFromFileModal.js.html#line168)
 */
const FileSelectButton = ({onFileSelect}) => {
    return (
        <div className='tw-h-10 tw-flex tw-flex-row tw-justify-start tw-items-center tw-p-2 tw-mb-2'>
            <label 
                htmlFor='file-input'
                className='tw-text-sm tw-border-2 tw-rounded-full tw-px-4 tw-py-1.5 tw-select-none 
                            tw-text-gray-600 tw-border-gray-300 tw-bg-white hover:tw-bg-gray-100 disabled:tw-bg-gray-400'
            >
                Выбрать файл...
            </label>
            <input type='file' id='file-input' onChange={onFileSelect} hidden/>
        </div>
    )
}

export default FileSelectButton