import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import { fieldTypes, getFieldType, setFieldDefaultValue, setReferenceValue } from '../../../../config/constTypes'
import { CalendarIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import DataSwitch from '../common/inputs/DataSwitch'
import DataTypeListBox from '../common/inputs/DataTypeListBox'
import DataModelComboBox from '../common/inputs/DataModelComboBox'
import FieldOptionInput from './FieldOptionInput'
import DataEnumListBox from '../common/inputs/DataEnumListBox'
import ReactDatePicker, {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"; // the locale you want
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import DataReferenceInput from '../common/inputs/DataReferenceInput'
import DirectoryContainer from '../common/panels/directory/DirectoryContainer'

registerLocale("ru", ru); // register it with the name you want


/**
 * Визуальный компонент отображает форму для создания нового или редактирования существующего поля
 * 
 * @param {Function} onEditField Обработчик создания нового/редактирования существующего поля
 * [handleFieldEdition](./components_main_page_controller_data_model_add_AddDataModelContainer.js.html#line23),
 * [handleFieldEdition](./components_main_page_controller_data_model_edit_EditDataModelContainer.js.html#line28)
 * @returns {HTMLFormElement} Html-разметку формы создаваемого поля
 * с использованием визуальных компонентов {@link DataTypeListBox}, {@link DataSwitch}, {@link DataReferenceInput}, {@link DataEnumListBox},
 * {@link DataModelComboBox}, {@link FieldOptionInput}, {@link DirectoryContainer}
 *  
 * @see [Вызов компонента](./components_main_page_controller_data_model_form_DataModelForm.js.html#line333)
 */
const FieldForm = ({onEditField}) => { 
    const { FieldStore, FilterStore, docStore } = useContext(Context)

    const [selectedType, setSelectedType] = useState(
        (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate')
        ? getFieldType(FieldStore.selectedField)
        : {id: '0', name: '', value: ''}
    )
    const [selectedDataModel, setSelectedDataModel] = useState({id: '0', entity_name: ''})
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)

    const {
        control,
        register,
        unregister,
        watch,
        setValue,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm()

    register('fieldType', { 
        required: true, 
        value: (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') ? FieldStore.selectedField.type : ''
    })
    register('validatorType', { 
        required: true, 
        value: (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') ? FieldStore.selectedField.validator_type : '', 
        validate: value => value !== ''
    })
    register('dataModelID', {
        value: (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') ? FieldStore.selectedField.ref_model_ids : [], 
        validate: value => {
            return (watchFieldType !== 'reference' && watchFieldType !== 'include') || (Array.isArray(value) && value.length > 0)
        }
    })
    register('dateOption', { 
        required: false,  
        value: ((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.options) ? FieldStore.selectedField.options.format : 'MM/dd/yyyy'
    })
    register('enumOption', { 
        required: false,  
        value: ((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.options) ? FieldStore.selectedField.options.allowed_values : [],
        validate: v => watchValidatorType !== 'enum' || v.length !== 0
    })
    register('floatOption', { 
        required: false,
        value: ((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.options) ? FieldStore.selectedField.options.number_decimal_places : 2, 
        min: 1,
        max: 14,
        valueAsNumber: true,
        validate: value => watchValidatorType !== 'float' || !Number.isNaN(value)
    })

    const watchFloatValue = watch('floatOption', ((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.options) ? FieldStore.selectedField.options.number_decimal_places : 2)
    const watchEnumValue = watch('enumOption', ((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.options) ? FieldStore.selectedField.options.allowed_values : [])
    const watchUnique = watch('unique', (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') ? FieldStore.selectedField.unique : false)
    const watchMandatory = watch('mandatory', (FieldStore.editionMode === 'duplicate') ? FieldStore.selectedField.mandatory : false)
    const watchFieldType = watch('fieldType')
    const watchValidatorType = watch('validatorType')
    const watchIsDefault = watch('isDefaultValue', (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField?.default)
    const watchIsManualName = watch('isManualName', false)
    
    const handleDataModelChange = (item) => {
        setValue('dataModelID', [item.id], { shouldValidate: true })
        setSelectedDataModel(item)
    }

    const handleTypeChange = (item) => {
        setSelectedType(item)
        unregister('defaultValue')
        setValue('fieldType', item.type)
        setValue('validatorType', item.tech_name, { shouldValidate: true })
        setValue('defaultValue', item.default)
        if (item.value === 'date' || item.value === 'datetime')
            setValue('dateOption', item.option.format)
        if (item.value === 'float')
            setValue('floatOption', item.option.format)
        setValue('unique', false)
    }

    const onReferenceSelect = (dataModelList, name) => {
        if (Array.isArray(dataModelList)) {
            setSelectedName(name)
            setSelectedDataModelID(dataModelList[0])
            setIsSelectFormOpen(true)
        } else {
            setSelectedName(name)
            setSelectedDataModelID(dataModelList)
            setIsSelectFormOpen(true)
        }
    }

    const onReferenceClear = (name) => {
        setValue(name, {format: '', values: []})
    }

    const handleDoubleClick = (item) => {
        setValue(selectedName, {value: setReferenceValue(item)})
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)
    }

    const handleCloseDirectoryClick = () => {
        docStore.setIsDetailView(false)
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    const selectDefaultValueInput = (type) => {
        switch(type) {
            case 'one':
                return <Controller
                            name={'defaultValue'}
                            control={control}
                            shouldUnregister={true}
                            defaultValue={((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField?.default) 
                                ? FieldStore.selectedField.default : {format: '', values: []}}
                            render={({field}) =>
                                <DataReferenceInput
                                    value={field.value}
                                    onChange={(e) => {field.onChange(e)}}
                                    onSelectClick={() => onReferenceSelect(selectedDataModel.id, 'defaultValue')}
                                    onClearClick={() => onReferenceClear('defaultValue')}
                                    isNested={false}
                                    isRowSelected={false}
                                    isCellSelected={true}
                                    readOnly={false}
                                />}
                        />
            case 'bool':
                return  <Controller
                            name='defaultValue'
                            control={control}
                            shouldUnregister={true}
                            defaultValue={((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField?.default) ? FieldStore.selectedField.default : false}
                            render={({field}) =>
                                <DataSwitch 
                                    isChecked={field.value}
                                    onClick={(e) => field.onChange(e)}
                                    label='Значение:'
                                />
                            }
                        />
            case 'int':
                return  <input
                            type='number'
                            className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                                tw-ring-gray-400 focus-visible:tw-ring-gray-400
                            `}
                            {...register('defaultValue', {
                                required: false,
                                value: ((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.default !== undefined) ? FieldStore.selectedField.default : 0,
                                shouldUnregister: true,
                                valueAsNumber: true,
                                validate: v => Number.isInteger(v),
                            })}
                        />
            case 'float':
                return <input
                            type='number'
                            className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                                tw-ring-gray-400 focus-visible:tw-ring-gray-400
                            `}
                            step={ 1 / (10 ** watchFloatValue)}
                            {...register('defaultValue', {
                                required: false,
                                value: ((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.default !== undefined) ? FieldStore.selectedField.default : 0,
                                shouldUnregister: true,
                                valueAsNumber: true,
                                validate: v => (v - Number(v.toFixed(watchFloatValue)) === 0),  // проверка соответствия количества знаков после запятой
                            })}
                        />
            case 'enum':
                return  <Controller
                            name='defaultValue'
                            control={control}
                            shouldUnregister={true}
                            rules={ {validate: v => watchEnumValue.includes(v) || v === ''}}
                            defaultValue={((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField?.default) ? FieldStore.selectedField.default : ''}
                            render={({field}) =>
                                <DataEnumListBox 
                                    itemList={watchEnumValue}
                                    selectedItem={field.value}
                                    onItemChange={(e) => field.onChange(e)}
                                />
                            }
                        />
            case 'date':
                return <div className='tw-flex tw-flex-row tw-justify-start tw-items-center tw-w-52 tw-py-1'>
                        <Controller
                        name={'defaultValue'}
                        control={control}
                        shouldUnregister={true}
                        rules={ { valueAsDate: true} }
                        defaultValue={((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField?.default) ? setFieldDefaultValue(FieldStore.selectedField) : null}
                        render={({field}) =>
                            <ReactDatePicker
                                className={`tw-w-52 tw-border tw-rounded-md tw-text-sm tw-text-gray-900 tw-p-1
                                    focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white 
                                    focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2 tw-border-gray-400 focus-visible:tw-ring-offset-gray-400
                                `}
                                calendarStartDay={1}
                                calendarIcon={<CalendarIcon/>}
                                dateFormat={'dd.MM.yyyy'}
                                locale='ru'
                                timeCaption='Время'
                                selected={field.value}
                                onChange={(e) => field.onChange(e)}
                            />
                        }
                    />
                    <button 
                        type='button'
                        className='tw-rounded-md tw-p-0.5 tw-ml-1 tw-border-0 tw-text-gray-600 tw-bg-white hover:tw-text-gray-900 hover:tw-border-gray-200 hover:tw-bg-gray-200'
                        onClick={() => {setValue('defaultValue', null)}}
                    >
                        <XMarkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </button>
                </div>
            case 'datetime':
                return <div className='tw-flex tw-flex-row tw-justify-start tw-items-center tw-w-52 tw-py-1'>
                        <Controller
                        name={'defaultValue'}
                        control={control}
                        shouldUnregister={true}
                        rules={ { valueAsDate: true} }
                        defaultValue={((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField?.default) ? setFieldDefaultValue(FieldStore.selectedField) : null}
                        render={({field}) =>
                            <ReactDatePicker
                                className={`tw-w-52 tw-border tw-rounded-md tw-text-sm tw-text-gray-900 tw-p-1
                                    focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white 
                                    focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2 tw-border-gray-400 focus-visible:tw-ring-offset-gray-400
                                `}
                                calendarStartDay={1}
                                calendarIcon={<CalendarIcon/>}
                                dateFormat='dd.MM.yyyy HH:mm:ss'
                                timeFormat='HH:mm:ss'
                                locale='ru'
                                timeCaption='Время'
                                showTimeSelect={true}
                                selected={field.value}
                                onChange={(e) => field.onChange(e)}
                            />
                        }
                    />
                    <button 
                        type='button'
                        className='tw-rounded-md tw-p-0.5 tw-ml-1 tw-border-0 tw-text-gray-600 tw-bg-white hover:tw-text-gray-900 hover:tw-border-gray-200 hover:tw-bg-gray-200'
                        onClick={() => {setValue('defaultValue', null)}}
                    >
                        <XMarkIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                    </button>
                </div>
            case 'string': 
                return  <Controller
                            name='defaultValue'
                            control={control}
                            shouldUnregister={true}
                            defaultValue={((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField?.default) ? FieldStore.selectedField.default : ''}
                            render={({field}) =>
                                <input
                                    type='text'
                                    className='tw-w-full tw-h-8 tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm focus-visible:tw-outline-none
                                        focus-visible:tw-border-gray-600 focus-visible:tw-ring-2 focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75
                                        focus-visible:tw-ring-offset-2 tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'
                                    {...field}
                                />
                            }
                        />
            default:
                return  null
        }
    }

    const onCancelClick = () => {
        FieldStore.setEditionMode('none')
        FieldStore.setSelectedField(FieldStore.dummyField)
        FieldStore.setIsFormOpen(false)
    }

    const onSubmitCreateClick = async (form) => {
        const newField = {}
        newField.full_name = form.isManualName ? form.fullName : form.alias + ' (' + String(Date.now()) + ')'
        newField.alias = form.alias
        newField.validator_type = form.validatorType
        newField.type = form.fieldType

        if (newField.type === 'reference' || newField.type === 'include') {
            newField.ref_model_ids = form.dataModelID
        }
        if (newField.type !== 'include')
            newField.mandatory = form.mandatory
        if (newField.type !== 'include')
            newField.unique = form.unique
        if (!form.unique && form.isDefaultValue && form.defaultValue !== undefined && form.defaultValue !== '') {
            if (newField.type === 'reference') {
                if (form.defaultValue.record_id)
                    newField.default = [form.defaultValue.record_id]
            } else
                newField.default = form.defaultValue
        }
        if (form.validatorType === 'float') {
            newField.options = { number_decimal_places: form.floatOption }
        }
        if (form.validatorType === 'date') {
            newField.options = { format: form.dateOption }
        }
        if (form.validatorType === 'enum') {
            newField.options = { allowed_values: form.enumOption }
        }

        const fieldID = await FieldStore.createField(newField, JSON.stringify(FilterStore.selectedFilters))
        const createdField = await FieldStore.getFieldByID(fieldID)
        onEditField(createdField)
        onCancelClick()
    }

    const onSubmitEditClick = async (form) => {
        const editedField = {}
        editedField.full_name = form.fullName
        editedField.alias = form.alias
        if (form.isDefaultValue) {
            if  (form.defaultValue !== undefined && 
                (form.defaultValue !== '' || form.validatorType === 'string')) {
                    if (form.validatorType === 'one') 
                        editedField.default = [form.defaultValue.record_id]
                    else if (form.defaultValue === '')
                        editedField.default = null
                    else 
                        editedField.default = form.defaultValue
            }
        } else {
            editedField.default = null
        }
        switch (form.validatorType) {
            case 'enum':
                editedField.options = {allowed_values: form.enumOption}
                break
            case 'float': 
                editedField.options = {number_decimal_places: form.floatOption}
                break
            case 'date': 
                editedField.options = {format: form.dateOption}
                break
            default: 
                break
        }
            
        const fieldID = await FieldStore.updateField(editedField, JSON.stringify(FilterStore.selectedFilters))
        const updatedField = await FieldStore.getFieldByID(fieldID)
        onEditField(updatedField)
        onCancelClick()
    }

    useEffect(() => {
        if(FieldStore.isFormOpen){
            setValue('fieldType', 
                (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') ? FieldStore.selectedField.type : ''
            )
            setValue('validatorType', 
                (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') ? FieldStore.selectedField.validator_type : ''
            )
            setValue('dataModelID', 
                (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') ? FieldStore.selectedField.ref_model_ids : []
            )
            setValue('dateOption',  
                ((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.options) ? FieldStore.selectedField.options.format : 'MM/dd/yyyy'
            )
            setValue('enumOption', 
                ((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.options) ? FieldStore.selectedField.options.allowed_values : []
            )
            setValue('floatOption',
                ((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.options) ? FieldStore.selectedField.options.number_decimal_places : 2
            )
            setValue('fullName',
                (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') ? FieldStore.selectedField.full_name : ''
            )
            setValue('alias', 
                (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') ? FieldStore.selectedField.alias : ''
            )
            setValue('unique', 
                FieldStore.editionMode === 'duplicate' ? watchUnique : false
            )
            setValue('mandatory',
                FieldStore.editionMode === 'duplicate' ? watchMandatory : false
            )
            setValue('isManualName', false)
            setSelectedDataModel(
                (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.type === 'reference'
                ? {id: FieldStore.selectedField?.ref_model_ids, entity_name: FieldStore.selectedField?.ref_model_name}
                : {id: [], entity_name: ''}
            )
            setSelectedType(
                (FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate')
                ? getFieldType(FieldStore.selectedField)
                : {id: '0', name: '', value: ''}
            )
        }
    }, [FieldStore.isFormOpen])

    isSubmitting && !isValid && toast.error('Заполните или исправьте отмеченные поля!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })   

    return (
        <Transition.Root show={FieldStore.isFormOpen} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" onClose={onCancelClick}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-500"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-500"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-overflow-hidden">
                    <div className="tw-absolute tw-inset-0 tw-overflow-hidden">
                        <div className="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex  tw-pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                enterFrom="tw-translate-x-full"
                                enterTo="tw-translate-x-0"
                                leave="tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700"
                                leaveFrom="tw-translate-x-0"
                                leaveTo="tw-translate-x-full"
                            >
                                <Dialog.Panel  className="tw-pointer-events-auto tw-relative tw-w-[50vw]"> 
                                    <Transition.Child
                                        as={Fragment}
                                        enter="tw-ease-in-out duration-500"
                                        enterFrom="tw-opacity-0"
                                        enterTo="tw-opacity-100"
                                        leave="tw-ease-in-out tw-duration-500"
                                        leaveFrom="tw-opacity-100"
                                        leaveTo="tw-opacity-0"
                                    >
                                        <div className="tw-absolute tw-left-0 tw-top-0 tw--ml-8 tw-flex tw-pr-2 tw-pt-4 sm:tw--ml-10 sm:tw-pr-4">
                                        <button
                                            type="button"
                                            className="tw-rounded-md hover:tw-text-gray-300 tw-text-white hover:tw-bg-gray-500 focus:tw-outline-none tw-ring-2 tw-ring-white"
                                            onClick={onCancelClick}
                                        >
                                            <span className="tw-sr-only">Закрыть</span>
                                            <XMarkIcon className="tw-h-6 tw-w-6" aria-hidden="true" />
                                        </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-bg-white tw-p-6 tw-shadow-xl">
                                        <form>
                                            <div className="tw-h-full tw-flex tw-flex-col tw-w-full tw-rounded-md tw-mt-2 tw-px-4">
                                                <div className='tw-text-sm tw-font-semibold tw-py-2 tw-pl-2 tw-text-gray-900'>
                                                    {FieldStore.editionMode === 'edit' ? 'Редактируемые значения поля' : 'Новое поле'}
                                                </div>
                                                <div className='tw-block tw-mt-2'>
                                                    <div className='tw-flex tw-flex-row tw-w-full tw-items-center'>
                                                        <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900 tw-leading-6'>Название</label>
                                                        {errors.alias && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                                                    </div>
                                                    <input
                                                        className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                                            tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm
                                                            focus-visible:tw-outline-none focus-visible:tw-border-gray-600 focus-visible:tw-ring-2 
                                                            focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                                                            ${errors.alias ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                                        `}
                                                        {...register('alias', { required: true })}
                                                    />
                                                </div>
                                                { FieldStore.editionMode !== 'edit' &&
                                                    <DataTypeListBox
                                                        label={'Тип'}
                                                        itemList={fieldTypes}
                                                        selectedItem={selectedType}
                                                        onItemChange={handleTypeChange}
                                                        error={errors.validatorType}
                                                        selector={'value'}
                                                    />
                                                }
                                                { (watchFieldType === 'reference') && FieldStore.editionMode !== 'edit' &&
                                                    <DataModelComboBox
                                                        label='на таблицу'
                                                        selectedItem={selectedDataModel}
                                                        onItemChange={handleDataModelChange}
                                                        error={errors.dataModelID}
                                                    />
                                                }
                                                { (watchFieldType === 'include') && FieldStore.editionMode !== 'edit' &&
                                                    <DataModelComboBox
                                                        label='Таблица'
                                                        selectedItem={selectedDataModel}
                                                        error={errors.dataModelID}
                                                        typeFilter={['directory', 'document', 'nested']}
                                                        onItemChange={handleDataModelChange}
                                                    />
                                                }
                                                <div className='tw-block tw-mt-2'>
                                                    <FieldOptionInput 
                                                        type={selectedType.value}
                                                        initValue={FieldStore.selectedField ? FieldStore.selectedField.options : []}
                                                        setFloatOption={(options) => setValue('floatOption', options, { shouldValidate: true })}
                                                        setEnumOption={(options) => setValue('enumOption', options, { shouldValidate: true })}
                                                        error={errors}
                                                    />
                                                </div>
                                                { (watchFieldType !== 'include') && FieldStore.editionMode !== 'edit' &&
                                                    <div className='tw-py-2'>
                                                        <Controller
                                                            name={'unique'}
                                                            control={control}
                                                            render={({field}) =>
                                                                <DataSwitch 
                                                                    isChecked={field.value}
                                                                    onClick={(e) => field.onChange(e)}
                                                                    label={'Уникальные значения'}
                                                                />}
                                                        />
                                                    </div>
                                                }
                                                { (watchFieldType !== 'include') && FieldStore.editionMode !== 'edit' && 
                                                    <div className='tw-py-2'>
                                                        <Controller
                                                            name={'mandatory'}
                                                            control={control}
                                                            render={({field}) =>
                                                                <DataSwitch 
                                                                    isChecked={field.value}
                                                                    onClick={(e) => field.onChange(e)}
                                                                    label={'Обязательное заполнение'}
                                                                />}
                                                        />
                                                    </div>
                                                }
                                                { !watchUnique && watchValidatorType && (watchFieldType !== 'include') && 
                                                    <div className='tw-block tw-mt-1'>
                                                        <Controller
                                                            name='isDefaultValue'
                                                            control={control}
                                                            shouldUnregister={true}
                                                            defaultValue={((FieldStore.editionMode === 'edit' || FieldStore.editionMode === 'duplicate') && FieldStore.selectedField.default !== undefined)}
                                                            render={({field}) =>
                                                                <DataSwitch 
                                                                    isChecked={field.value}
                                                                    onClick={(e) => field.onChange(e)}
                                                                    label={'Наличие значения по умолчанию'}
                                                                />
                                                            }
                                                        />
                                                        { !['bool', 'many'].includes(watchValidatorType) &&
                                                            <div className='tw-flex tw-flex-row tw-w-full tw-items-center'>
                                                                {errors.defaultValue && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                { watchIsDefault && 
                                                    <div className='tw-block tw-mt-3'>
                                                        {selectDefaultValueInput(selectedType.value)}
                                                    </div>
                                                }
                                                <div className=' tw-mt-2 tw-py-2 tw-border-t'>
                                                    <Controller
                                                        name={'isManualName'}
                                                        control={control}
                                                        render={({field}) =>
                                                            <DataSwitch 
                                                                isChecked={field.value}
                                                                onClick={(e) => field.onChange(e)}
                                                                label={'Задать название для печатной формы'}
                                                            />}
                                                    />
                                                </div>
                                                { watchIsManualName &&
                                                    <div className='tw-block tw-mt-2'>
                                                        <div className='tw-flex tw-flex-row tw-w-full tw-items-center'>
                                                            <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-900 tw-leading-6'>Название для печатной формы</label>
                                                            {errors.fullName && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                                                        </div>
                                                        <input 
                                                            className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm
                                                                focus-visible:tw-outline-none focus-visible:tw-border-gray-600 focus-visible:tw-ring-2 
                                                                focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                                                                ${errors.fullName ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                                            `}
                                                            {...register('fullName', { required: true })}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-mt-2 tw-py-4'>
                                                <button 
                                                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                                            hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                                    onClick={FieldStore.editionMode === 'edit'? handleSubmit(onSubmitEditClick) : handleSubmit(onSubmitCreateClick)}
                                                >
                                                    Сохранить
                                                </button>
                                                <button
                                                    type='button'
                                                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-900
                                                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                                                    onClick={onCancelClick}
                                                >
                                                    Отменить
                                                </button>
                                            </div>                        
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>

                    <DirectoryContainer
                        isOpen={isSelectFormOpen}
                        selectedDataModel={selectedDataModelID}
                        onDoubleClick={handleDoubleClick}
                        onCloseClick={handleCloseDirectoryClick}
                    />
                </div>
            </Dialog>
        </Transition.Root> 
    )
}

export default observer(FieldForm)
