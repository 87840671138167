import React, { useContext } from 'react'
import { Tooltip } from 'react-tooltip'
import { menuTooltipTimeOut } from '../../../../../config/constTypes'
import { Context } from '../../../../..'
import { observer } from 'mobx-react-lite'


/**
 * Визуальный компонент отображает название одной таблицы для списка существующих таблиц
 * @param {Object} item Текущий элемент списка таблиц 
 * @param {Object} selectedDataModel Выбранная таблица
 * @param {Function} onClick Обработчик клика мыши на текущем элементе
 * [handleDataModelClick](./components_main_page_controller_data_model_DataModelListContainer.js.html#line64)
 * [handleDataModelClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line68)
 * @param {Function} onDoubleClick Обработчик двойного клика мыши на текущем элементе
 * [handleDataModelClick](./components_main_page_controller_data_model_DataModelListContainer.js.html#line64)
 * [handleDataModelClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line68)
 * @returns {HTMLDivElement} Html-разметку с названием таблицы, ее типом и принадлежности к конфигурации системы 
 * 
 * @see [Вызов компонента](./components_main_page_controller_data_model_list_DataModelType.js.html#line45)
 */
const DataModelItem = ({item, selectedDataModel, onClick, onDoubleClick}) => {
    const { docStore } = useContext(Context)

    let className = 'tw-flex tw-flex-row tw-items-center tw-text-sm tw-py-1 tw-w-full tw-mb-0.5 tw-border tw-border-gray-300 tw-rounded-md hover:tw-cursor-pointer'

    if (selectedDataModel && selectedDataModel.id === item.id) {
        className += ` tw-text-white tw-bg-gray-500`
    } else {
        className += ` tw-text-gray-900 tw-bg-white hover:tw-bg-gray-200 hover:tw-border-gray-300 hover:tw-border-x`
    }

    return (
        <>
            <div
                id={item.id}
                className={className}
                onClick={() => onClick(item)}
                onDoubleClick={() => onDoubleClick(item)}
            >
                <div className={`${item.hide_mark ? 'tw-line-through tw-text-red-400' : ''} tw-grow tw-px-4`}>
                    {item.entity_name}
                </div>
                { docStore.isShowInfo &&
                    <div className='tw-flex tw-flex-row tw-justify-self-end tw-self-start tw-gap-x-2 tw-px-2'>
                        <span 
                            className='tw-px-2 tw-py-0.5 tw-w-6 tw-h-6 tw-rounded-md tw-text-gray-900 tw-bg-gray-300 tw-font-bold'
                            data-tooltip-id="data-model-item-tooltip"
                            data-tooltip-content={item.type === 'document' ? 'Документ' : item.type === 'nested' ? 'Вложенная' : 'Справочник'}
                            data-tooltip-delay-show={menuTooltipTimeOut}
                        >
                            {item.type === 'document'
                                ?   'Д'
                                :   item.type === 'nested'
                                    ?   'В'
                                    :   'С'
                            }
                        </span>
                        {' '}
                        <span
                            className='tw-px-2 tw-py-0.5 tw-w-6 tw-h-6 tw-rounded-md tw-text-gray-900 tw-bg-gray-300 tw-font-bold'
                            data-tooltip-id="data-model-item-tooltip" data-tooltip-content={item.built_in ? 'Из конфигурации системы' : 'Пользовательский'} data-tooltip-delay-show={menuTooltipTimeOut}
                        >
                            {item.built_in ? 'К' : 'П'}
                        </span>
                    </div>
                }
            </div>
            <Tooltip id="data-model-item-tooltip" place="top"/>
        </>
    )
}

export default observer(DataModelItem)