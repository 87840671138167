import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../..'
import { toast } from 'react-toastify'
import StageButtons from '../stage/StageButtons'
import { observer } from 'mobx-react-lite'
import Spinner from '../../../../../assets/Spinner'
import LinkedList from '../list/LinkedList'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import DirectoryContainer from '../../common/panels/directory/DirectoryContainer'
import DocumentService from '../../../../../services/DocumentService'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import NestedModelMenu from '../../../../tabs/nested_tables/NestedModelMenu'
import { createEmptyFieldData, createNestedDataObject, deleteNestedDataObjects, updateNestedDataObjects } from '../../../../../functions/nestedModels'
import FormFieldName from '../../../../form_fields/FormFieldName'
import { getFieldTechName } from '../../../../../functions/getFieldTechName'
import HelpButton from '../../common/buttons/HelpButton'
import { linkListText } from '../info/linkList'
import { setReferenceValue } from '../../../../../config/constTypes'


/**
 * Визуальный компонент отображает этап выбора объектов КИИ из ресурсов доступа
 * 
 * @returns {JSXElement} Html-разметку этапа выбора объектов КИИ из ресурсов доступа 
 * с использованием визуальных компонентов {@link Spinner}, {@link NestedModelMenu}, {@link LinkedList}, {@link StageButtons}, 
 * {@link DirectoryContainer}, {@link FormFieldName}
 * 
 * @see [Вызов компонента](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line147)
 */
const ResourcesForm = () => {   
    const { categorizingCIIStore } = useContext(Context)

    const [filteredProcessList, setFilteredProcessList] = useState([])
    const [selectedProcess, setSelectedProcess] = useState(null)
    const [selectedResource, setSelectedResource] = useState(null)
    const [isObjectError, setIsObjectError] = useState(false)
    const [isActError, setIsActError] = useState(false)
    const [isRefFormOpen, setIsRefFormOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [actNumberField, setActNumberField] = useState(null)
    const [objectField, setObjectField] = useState(null)
    const [processField, setProcessField] = useState(null)
    const [isChangedRecord, setIsChangedRecord] = useState(true)

    const handleProcessClick = (process) => {
        if (selectedProcess?.id !== process.id) {
            setSelectedProcess(process)
            setSelectedResource(null)
            categorizingCIIStore.setSelectedNestedValue(null)
        }
    }

    const handleResourceClick = (resource) => {
        if (selectedResource?.id !== resource.id) {
            setSelectedResource(resource)
            setSelectedProcess(null)
            categorizingCIIStore.setSelectedNestedValue(resource)
        }
    }

    const handleLinkingClick = (value_record_id) => {
        // Клик на объекте КИИ, выбран процесс
        if (selectedProcess) {
            const foundResource = categorizingCIIStore.resourceList.dataObjects.find(item => item.value_record_id === value_record_id)
            if (foundResource) {
                const editedLinks = (foundResource.links.includes(selectedProcess.value_record_id))
                    ?   foundResource.links.filter(item => item !== selectedProcess.value_record_id)
                    :   [...foundResource.links, selectedProcess.value_record_id]

                categorizingCIIStore.setResourceList(
                    {...categorizingCIIStore.resourceList,
                        dataObjects: categorizingCIIStore.resourceList.dataObjects.map(resource => 
                            resource.id !== foundResource.id
                                ?   resource
                                :   {...resource, links: editedLinks}
                        )
                    }
                )
            }
        }

        // Клик на процессе, выбран объект КИИ
        if (selectedResource) {
            const originalLinks = selectedResource.links
            const editedLinks = (originalLinks.includes(value_record_id))
                ?   originalLinks.filter(item => item !== value_record_id)
                :   [...originalLinks, value_record_id]

            categorizingCIIStore.setResourceList(
                {...categorizingCIIStore.resourceList,
                    dataObjects: categorizingCIIStore.resourceList.dataObjects.map(resource => 
                        resource.id !== selectedResource.id
                            ?   resource
                            :   {...resource, links: editedLinks}
                    )
                }
            )
            setSelectedResource({...selectedResource, links: editedLinks})
        }
        setIsChangedRecord(true)
    }

    const getLinkChanges = () => {
        // упрощение перечня сохраненных записей вложенной таблицы "Связь с процессами"
        const originalTable = categorizingCIIStore.linkingTable
            ?
                categorizingCIIStore.linkingTable.map(item => {
                    return {
                        id: item.id,
                        record_id: item.record_id,
                        data: {
                            'object__communic_wt_proces': item.data['object__communic_wt_proces'].value.values[0].record_id,
                            'process_to_communc_processes__communic_wt_proces': item.data['process_to_communc_processes__communic_wt_proces'].value.values[0]?.record_id
                        }

                    }
                })
            :   []
        
        // формирование текущего перечня связей объектов КИИ и процессов
        let currentLinks = []
        categorizingCIIStore.resourceList.dataObjects
            .filter(item => item.status !== 'deleted')
            .forEach(item => {
                item.links.forEach(value_record_id => { 
                    const foundProcess = categorizingCIIStore.processList.filter(process => process.status !== 'deleted').find(process => process.value_record_id === value_record_id)
                    if (foundProcess) {
                        currentLinks = [
                            ...currentLinks, 
                            {
                                id: item.value_record_id + '_' + value_record_id,
                                data: {
                                    'object__communic_wt_proces': [item.value_record_id],
                                    'process_to_communc_processes__communic_wt_proces': [value_record_id]
                                }
                            }
                        ]
                    }
                })
            })
        let currentTable = currentLinks.flat()

        // проверка (отметка) каждой сохраненной записи на наличие в текущем перечне связей объектов КИИ и процессов
        originalTable.forEach(item => {
            const foundLink = currentTable.find(link => 
                link.data['object__communic_wt_proces'].includes(item.data['object__communic_wt_proces']) &&
                link.data['process_to_communc_processes__communic_wt_proces'].includes(item.data['process_to_communc_processes__communic_wt_proces'])
            )
            if (foundLink) {
                currentTable = currentTable.filter(link => link.id !== foundLink.id)
                item.status = 'saved'
            } else {
                item.status = 'deleted'
            }
        })

        return {
            upsert: currentTable.map(item => { return {data: item.data}}),
            remove: originalTable.filter(item => item.status === 'deleted').map(item => { return {record_id: item.record_id}})
        }
    }

    const handleBackClick = () => {
        categorizingCIIStore.goPrevStage()
    }

    const handleForwardClick = async () => {
        setIsLoading(true)
        // проверка номера акта
        const isEmptyNumber = actNumberField.value.trim() === ''
        setIsActError(isEmptyNumber)
        // проверка повторяющихся значений
        const isDuplicatedValues = categorizingCIIStore.checkValueErrors(categorizingCIIStore.resourceList.dataObjects, 'multiple_object__an_object_vt2', 'reference')
        setIsObjectError(isDuplicatedValues)
        // проверка отсутствия связей с процессами
        const resourcesWithErrors = categorizingCIIStore.resourceList.dataObjects.map(resource => {
            return {...resource,
                        errors: resource.status !== 'deleted' && resource.links.length === 0
            }
        })
        const isObjectEror = resourcesWithErrors.some(resource => resource.errors)

        if (isEmptyNumber) {
            setIsLoading(false)
            toast.error(<div>Номер акта не должен быть пустым!<br/></div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })

        } else if (isDuplicatedValues) {
            setIsLoading(false)
            toast.error(<div>Перечень объектов КИИ не должен быть пустым,<br/>а также не должен содержать повторяющиеся или пустые значения!</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })

        } else if (isObjectEror) {
            setIsLoading(false)
            toast.error('Отмеченный объект КИИ не связан ни с одним процессом!', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
            categorizingCIIStore.setResourceList({...categorizingCIIStore.resourceList, dataObjects: resourcesWithErrors })
            setSelectedProcess(null)
            setSelectedResource(null)

        } else {    // переход к следующему этапу
            // сброс признаков ошибок для объектов КИИ
            categorizingCIIStore.setResourceList(
                {...categorizingCIIStore.resourceList, 
                    dataObjects: categorizingCIIStore.resourceList.dataObjects.map(resource => { return {...resource, errors: false} })
                }
            )
            // сохранение перечня под категорирование в части связи объектов КИИ и процессов
            const dataObject = {}
            dataObject.data_model_id = 'list_for_categorization_2'
            dataObject.data = {}
            dataObject.data['commission__list_for_categorization_2'] = [categorizingCIIStore.commissionID.values[0].record_id]
            dataObject.data['process_for_lists__list_for_categorization_2'] = [categorizingCIIStore.selectedMethod.values[0].record_id]
            dataObject.data['communicate_with_processes__list_for_categorization_2'] = getLinkChanges()
            dataObject.data['an_object_vt__list_for_categorization_2'] = {
                upsert: updateNestedDataObjects(categorizingCIIStore.resourceList),
                remove: deleteNestedDataObjects(categorizingCIIStore.resourceList)
            }
            dataObject.data['act_number__list_for_categorization_2'] = actNumberField.value

            try {
                if (isChangedRecord) {
                    let response
                    if (categorizingCIIStore.linkedList && categorizingCIIStore.linkedList.values.length > 0) {
                        response = await DocumentService.updateDataObject(categorizingCIIStore.linkedList.values[0].record_id, dataObject)
    
                        if (categorizingCIIStore.linkedListPrintDate) {
                            // блокировка кнопки "Далее" для этапа "Печать перечня под категорирование"
                            categorizingCIIStore.setLinkedListPrintDate(null)
                            await categorizingCIIStore.updateProject('date_of_print_st_6__stages_of_categorization', null, true)
                        } else
                        await categorizingCIIStore.loadSavedProject(categorizingCIIStore.project.id)

                    } else {
                        response = await DocumentService.createDataObject(dataObject)   
                        await categorizingCIIStore.updateProject('conn_of_objects_process_for_stages__stages_of_categorization', [response.record_id], true)
                    }
    
                    toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })    
                }
                categorizingCIIStore.goNextStage()

            } catch (error) {
                setIsLoading(false)
                showErrorToast(error, 'saving', '')
            }
        }
    }

    const getCriticalProcesses = () => {
        const filteredList = categorizingCIIStore?.processList.filter(process => {
            const criticalField = (Object.values(process.data)).find(field => field.tech_name.indexOf('critical') >= 0)
            return criticalField.value
        })
        return filteredList
    }

    const checkProcessList = () => {
        // определение ID критичных процессов в перечне сохраненных процессов
        const criticalProcesses = getCriticalProcesses()
        const keyFieldNames = [
            'tech_process__techn_process',
            'business_proces__business_process_2',
            'process__types_of_activitess',
            'multiple_object__an_object_vt2',
        ]
        const savedProcessIDs = criticalProcesses.map(item => {
            const primaryField = getFieldTechName(Object.values(item.data), keyFieldNames)
            if (primaryField)
                return item.data[primaryField].value.values[0]?.record_id
            return null
        })
        .filter(item => item !== null)
        // получение набора ID процессов, которые связаны с объектами КИИ
        const linkedProcessIDs = new Set(categorizingCIIStore.linkingTable.map(item => item.data['process_to_communc_processes__communic_wt_proces'].value.values[0]?.record_id))
        // поиск ID процесса, который связан с объектом КИИ, но отсутствует в списке сохраненных процессов
        let isNotValid = false
        linkedProcessIDs.forEach(item => {
            if (!savedProcessIDs.includes(item))
                isNotValid = true
        })

        return isNotValid
    }

    const fixObjectLinks = () => {
        const criticalProcessIDs = getCriticalProcesses().map(item => item.value_record_id)
        // удаление ссылок на отсутствующие процессы
        const editedValues = categorizingCIIStore.resourceList.dataObjects.map(item => { 
            return {...item, links: item.links.filter(link => criticalProcessIDs.includes(link))}
        })
        categorizingCIIStore.setResourceList({...categorizingCIIStore.resourceList, dataObjects: editedValues})
    }

    const checkStageResult = () => {
        const result = !categorizingCIIStore.resourceList || 
                        categorizingCIIStore.resourceList.dataObjects.length === 0 ||
                        categorizingCIIStore.resourceList.dataObjects.some(item => !item.links.length && item.status !== 'deleted')
        return result
    }

    const handleObjectListChange = (editedValues, editedNestedModel) => {
        categorizingCIIStore.setResourceList(
                {...categorizingCIIStore.resourceList, 
                    dataObjects: editedValues.map(item => { return {...item, links: item.links || [], spheres: item.spheres || []} })
                }
        )
        setSelectedResource(null)
        setSelectedProcess(null)
        setIsChangedRecord(true)
        if (isObjectError)
            setIsObjectError(categorizingCIIStore.checkValueErrors(categorizingCIIStore.resourceList.dataObjects, 'multiple_object__an_object_vt2', 'reference'))

        if (editedValues.some(item => !item.data['multiple_object__an_object_vt2'].value.values.length)) {
            setIsRefFormOpen(true)
        }
    }

    const handleObjectSelect = (dataObject) => {
        const emptyDataObject = categorizingCIIStore.resourceList.dataObjects.find(item => !item.data['multiple_object__an_object_vt2'].value.values.length)
        const editedDataObject = JSON.parse(JSON.stringify(emptyDataObject))   // object deep copy
        if (emptyDataObject) {
            editedDataObject.data['multiple_object__an_object_vt2'].value = setReferenceValue(dataObject)
            editedDataObject.value_record_id = dataObject.record_id
        }

        categorizingCIIStore.setResourceList(
            {...categorizingCIIStore.resourceList, 
                dataObjects: categorizingCIIStore.resourceList.dataObjects.map(dataObject => 
                            dataObject.id !== editedDataObject.id
                                ?   dataObject
                                :   editedDataObject
                        )
            }
        )

        const  dataField = createEmptyFieldData(categorizingCIIStore.resourceList)

        const editedNestedModel = categorizingCIIStore.resourceList.dataObjects.concat([
            createNestedDataObject(dataField, categorizingCIIStore.resourceList.dataObjects.length, categorizingCIIStore.resourceList)
        ])

        categorizingCIIStore.setResourceList(
            {...categorizingCIIStore.resourceList, 
                dataObjects: editedNestedModel.map(item => { return {...item, links: item.links || [], spheres: item.spheres || []} })
            }
        )
    }

    const handleCloseClick = () => {
        categorizingCIIStore.setResourceList(
            {...categorizingCIIStore.resourceList,
                dataObjects: categorizingCIIStore.resourceList.dataObjects.filter(item => item.data['multiple_object__an_object_vt2'].value.values.length)
            }
        )
        setIsRefFormOpen(false)
    }


    useEffect(() => {
        if (categorizingCIIStore.linkedList && categorizingCIIStore.linkedList.values.length > 0 && categorizingCIIStore.linkingTable) {
            setIsChangedRecord(false)

            const isProcessesChanged = checkProcessList()           
            if (isProcessesChanged)
                fixObjectLinks()
        }

        setActNumberField(categorizingCIIStore.resourceList.actNumber)
        setObjectField(categorizingCIIStore.resourceList.fields.find(field => field.tech_name === 'an_object_vt__list_for_categorization_2'))
        setProcessField(categorizingCIIStore.resourceList.fields.find(field => field.tech_name === 'process_for_lists__list_for_categorization_2'))
        setFilteredProcessList(getCriticalProcesses())       
    }, [])

    return (
        <>
            <div className='tw-grow tw-overflow-hidden'>
                <div className="tw-px-4 tw-py-6 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-4">
                    { actNumberField &&
                        <>
                            <dt className="tw-text-sm tw-font-medium tw-text-gray-900 tw-flex tw-flex-row tw-items-center tw-gap-x-1">
                                <FormFieldName
                                    item={actNumberField}
                                    errors={isActError ? {data: {act_number__list_for_categorization_2: { type: 'required' }}} : {}}
                                />
                            </dt>
                            <dd className="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-mt-0">
                                <input
                                    type='text'
                                    className={`tw-w-full tw-h-8 tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-900 focus-visible:tw-outline-none
                                        tw-ring-1 tw-ring-inset tw-ring-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm
                                        ${isActError ? 'tw-ring-red-400 focus-visible:tw-ring-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-gray-400'}
                                    `}
                                    defaultValue={actNumberField.value || ''}
                                    onChange={(e) => {setIsChangedRecord(true); setActNumberField({...actNumberField, value: e.target.value})}}
                                />
                            </dd>
                        </>
                    }
                </div>
                <div id='stage-5-page' className='tw-grid tw-grid-cols-2 tw-gap-x-2 tw-max-h-full tw-h-full tw-py-1 tw-border-t tw-border-gray-400'>
                    <div id='objects-column' className='tw-h-full tw-overflow-hidden tw-px-2 tw-border-r tw-border-gray-400'>
                        <div className='tw-mt-2.5 tw-w-full tw-border-b tw-border-gray-400'>
                            <div className='tw-absolute tw-px-2 tw-py-1 tw-flex tw-flex-row tw-items-center tw-gap-x-1'>
                                <span className='tw-text-sm tw-font-semibold'>Объекты КИИ</span>
                                { objectField &&
                                    <span
                                        data-tooltip-id="object-cii-form" 
                                        data-tooltip-content={isObjectError 
                                            ?   'Перечень объектов КИИ не должен быть пустым, а также не должен содержать повторяющиеся или пустые значения!' 
                                            :   objectField.description}
                                        data-tooltip-delay-show={500}
                                    >
                                        <InformationCircleIcon 
                                            className={`${isObjectError ? 'tw-text-red-500' : 'tw-text-gray-500'} tw-h-5 tw-w-5 tw-cursor-help`} 
                                            aria-hidden="true"
                                        />
                                    </span>
                                }
                                <span className='tw-ml-2'><HelpButton  info={linkListText} caption='Перечни' align='start' isFixedSize={true}/></span>
                            </div>

                            <NestedModelMenu
                                nestedDataModels={[categorizingCIIStore.resourceList]}
                                selectedNestedDataModel={categorizingCIIStore.resourceList}
                                selectedNestedDataObject={categorizingCIIStore.selectedNestedValue}
                                setSelectedNestedDataObject={e => categorizingCIIStore.setSelectedNestedValue(e)}
                                isDocumentPage={false}
                                onChange={handleObjectListChange}
                            />
                        </div>
                        { !categorizingCIIStore.resourceList
                            ?
                                <Spinner />
                            :
                                <>
                                    {!categorizingCIIStore.resourceList.fields.length && 
                                        <div className='tw-mt-2 tw-text-center tw-text-red-500 tw-font-semibold'>Не удалось загрузить требуемую информацию</div>
                                    }
                                    <div  id='data-model-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_8rem)] tw-w-full tw-overflow-auto'>
                                        <LinkedList
                                            list={categorizingCIIStore.resourceList.dataObjects}
                                            selectedItem={selectedResource}
                                            linkingItem={selectedProcess}
                                            linkField='links'
                                            onItemClick={handleResourceClick}
                                            onLinkClick={handleLinkingClick}
                                            keyFieldNames={[
                                                'tech_process__techn_process',
                                                'business_proces__business_process_2',
                                                'process__types_of_activitess',
                                                'multiple_object__an_object_vt2',
                                            ]}
                                        />
                                    </div>
                                </>
                        }
                    </div>
                    <div id='processes-column' className='tw-h-full tw-overflow-hidden tw-pr-2 tw-border-gray-400'>
                        <div className='tw-h-12 tw-flex tw-items-center tw-gap-x-1 tw-border-b-2 tw-border-gray-400 tw-px-2 tw-py-2'>
                            <span className='tw-text-sm tw-font-semibold'>Процессы</span>
                            { processField &&
                                <span
                                    data-tooltip-id="object-cii-form" data-tooltip-content={processField.description} data-tooltip-delay-show={250}
                                >
                                    <InformationCircleIcon className="tw-h-5 tw-w-5 tw-text-gray-500 tw-cursor-help" aria-hidden="true"/>
                                </span>
                            }
                        </div>
                        { categorizingCIIStore.isDataModelLoading
                            ?   <Spinner/>
                            :   <div  id='data-model-list-items' className='tw-flex tw-flex-col tw-h-[calc(100%_-_8rem)] tw-w-full tw-overflow-auto'>
                                    <LinkedList
                                        list={filteredProcessList}
                                        selectedItem={selectedProcess}
                                        linkingItem={selectedResource}
                                        linkField='links'
                                        onItemClick={handleProcessClick}
                                        onLinkClick={handleLinkingClick}
                                        keyFieldNames={[
                                            'tech_process__techn_process',
                                            'business_proces__business_process_2',
                                            'process__types_of_activitess',
                                            'multiple_object__an_object_vt2',
                                        ]}
                                    />
                                </div>
                        }
                    </div>
                </div>
            </div>
            <StageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleForwardClick}
                disabled={checkStageResult() || isLoading}
            />
            <DirectoryContainer
                isOpen={isRefFormOpen}
                selectedDataModel={'access_resources'}
                onDoubleClick={handleObjectSelect}
                onCloseClick={handleCloseClick}
                chosenDataObjectsList={categorizingCIIStore.resourceList.dataObjects}
                isChosenObjectDuplicationForbidden={true}
                isMultiSelect={true}
            />
            <Tooltip id="object-cii-form" place="top" className='tw-max-w-xl'/>
        </>                                                             
    )
}

export default observer(ResourcesForm)
