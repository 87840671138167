import { makeAutoObservable, runInAction } from 'mobx'
import AuthService from '../services/AuthService'
import { responseTimeOut, serviceMessageTimeOut } from '../config/constTypes'
import { toast } from 'react-toastify'
import DocumentService from '../services/DocumentService'
import ConfigurationService from '../services/ConfigurationService'
import { showErrorToast } from '../functions/errorHandlers'

/**
 * Класс реализует хранилище информации об авторизированном пользователе
 * @class
 * 
 * @property {Boolean} isAuth Признак наличия авторизованного пользователя
 * @property {Object} user Информация об авторизованном пользователе
 * @property {Object} configuration Текущая и последняя версии системы
 * @property {Boolean} isUpdateFlag Признак наличия новой версии конфигурации
 * @property {Boolean} isLoading Загрузка
 * @property {Boolean} isUserInfoOpen Информация о видимости профиля пользователя
 * @property {Boolean} isUserInfoFormOpen Информация о видимости формы редактирования профиля пользователя
 * @property {Boolean} isRegistrationSuccess Информация об успешной регистрации пользователя
 * @property {Boolean} isFullUserInfo Информация о полностью заполненном профиле пользователя
 * @property {Boolean} isConfigurationLoading Информация о процессе загрузки конфигурации
 * @property {Boolean} isEditUserSuccess Информация об успешном редактировании информации о пользователе
 * @property {Boolean} isEditCompanySuccess Информация об успешном редактировании информации о компании
 * @property {Number} updateTimerID ID таймера отсчета интервала времени для обновления конфигурации
 * @property {Object[]} userFormFields Поля информации об авторизованном пользователе
 * @property {Object[]} userSubCompanies Информация о юр лицах пользователя
 * @property {Object} currentSubCompany Информация о текущем юр лице пользователя
 * @property {Boolean} isEditSubCompanySuccess Информация об успешном редактировании информации о юр.лице
 */
class UserStore {
    isAuth = false
    user = {}
    configuration = null
    isUpdateFlag = false
    isLoading = false
    isUserInfoOpen = false
    isUserInfoFormOpen = false
    isRegistrationSuccess = false
    isFullUserInfo = true
    isConfigurationLoading = false
    isEditUserSuccess = false
    isEditCompanySuccess = false
    isLowResolution = false
    updateTimerID = null
    userFormFields = [
        {
            tech_name: 'first_name',
            alias: 'Имя',
            mandatory: true
        },
        {
            tech_name: 'last_name',
            alias: 'Фамилия',
            mandatory: true
        },
        {
            tech_name: 'username',
            alias: 'Логин',
            mandatory: true
        },
        {
            tech_name: 'phone_number',
            alias: 'Телефон',
            mandatory: true
        },
        {
            tech_name: 'company_name',
            alias: 'Полное название организации',
            mandatory: true
        },
        {
            tech_name: 'sub_abbreviation',
            alias: 'Сокращенное название юр. лица',
            mandatory: true
        },
        {
            tech_name: 'sub_name',
            alias: 'Полное название юр. лица',
            mandatory: true
        },
        {
            tech_name: 'sub_tin',
            alias: 'ИНН юр. лица',
            mandatory: true
        },
        {
            tech_name: 'sub_rrc',
            alias: 'КПП юр. лица',
            mandatory: true
        },
        {
            tech_name: 'sub_msrn',
            alias: 'ОГРН юр. лица',
            mandatory: true
        },
        {
            tech_name: 'sub_owner',
            alias: 'Руководитель юр. лица',
            mandatory: true
        },
        {
            tech_name: 'sub_owner_position',
            alias: 'Должность руководителя',
            mandatory: true
        },
        {
            tech_name: 'sub_registration_address',
            alias: 'Адрес регистрации юр. лица',
            mandatory: true
        }
    ]
    userSubCompanies = []
    currentSubCompany = {}
    isEditSubCompanySuccess = false 

    DocStore; 
    /**
    * Конструктор с указанием, что все свойства класса observable
    * @constructor
    * @param {Object} docStore Класс реализует хранилище информации о полученных от сервера таблицах
    */
    constructor(docStore){
        makeAutoObservable(this)
        this.DocStore = docStore
    }

    /**
     * Метод очищает сохраненную информацию об авторизированном пользователе
     * @method
     */
    clearUser() {
        this.user = {}
        this.setAuth(false)
    }

    /**
     * Метод сохраняет информацию об авторизированном пользователе
     * @method
     * 
     * @param {Object} user Информация об авторизированном пользователе
     */
    setUser(user) {
        this.user = user
        let isFullUserInfo = true

        this.userFormFields.forEach(field => {
            if (field.tech_name.includes('company')) {
                if (!user?.company[field?.tech_name.replace('company_', '')])
                    isFullUserInfo = false
            } else {
                if (!field.tech_name.includes('sub') && !user[field?.tech_name])
                    isFullUserInfo = false
            }
        })
        
        if(!isFullUserInfo)
            toast.warning('У вас есть незаполненная информация в профиле пользователя', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })

        this.isFullUserInfo = isFullUserInfo
    }

    /**
     * Метод устанавливает признак наличия авторизированного пользователя 
     * (определяется по наличию валидного токена в локальном хранилище)
     * @method
     * 
     * @param {Boolean} bool - true - авторизован, false - нет
     */
    setAuth(bool) {
        this.isAuth = bool
    }

    /**
     * Метод сохраняет информацию о конфигурации системы
     * @method
     * 
     * @param {Object} configuration Текущая и последняя версии системы
     */
    setConfiguration(configuration) {
        this.configuration = configuration
    }

    /**
     * Метод устанавливает признак необходимости обновления конфигурации системы 
     * @method
     * 
     * @param {Boolean} bool - true - необходимо обновление, false - нет
     */
    setIsUpdateFlag(bool) {
        this.isUpdateFlag = bool
    }

    /**
     * Метод осуществляет сохранение информации о видимости профиля пользователя  
     * @method
     * 
     * @param {Boolean} bool Информация о видимости профиля пользователя
     */
    setIsUserInfoOpen(bool) {
        this.isUserInfoOpen = bool
    }

    /**
     * Метод осуществляет сохранение информации о видимости формы редактирования профиля пользователя  
     * @method
     * 
     * @param {Boolean} bool Информация о видимости формы редактирования профиля пользователя
     */
    setIsUserInfoFormOpen(bool) {
        sessionStorage.removeItem('editMyCompany')
        sessionStorage.removeItem('editUserMe')
        sessionStorage.removeItem('editMySubCompany')
        this.isUserInfoFormOpen = bool
    }
    
    /**
     * Метод осуществляет сохранение информации об успешной регистрации пользователя
     * @method
     * 
     * @param {Boolean} bool Информация об успешной регистрации пользователя
     */
    setIsRegistrationSuccess(bool) {
        this.isRegistrationSuccess = bool
    }

    /**
     * Метод осуществляет сохранение информации о процессе загрузки конфигурации
     * @method
     * 
     * @param {Boolean} bool Информация о процессе загрузки конфигурации
     */
    setIsConfigurationLoading(bool) {
        this.isConfigurationLoading = bool
    }

    /**
     * Метод осуществляет сохранение информации об успешном редактировании компании зарегистрированного пользователя
     * @method
     * 
     * @param {Boolean} bool Информация об успешном редактировании компании
     */
     setIsEditCompanySuccess(bool) {
        this.isEditCompanySuccess = bool
    }

    /**
     * Метод осуществляет сохранение информации об успешном редактировании зарегистрированного пользователя
     * @method
     * 
     * @param {Boolean} bool Информация об успешном редактировании пользователя
     */
    setIsEditUserSuccess(bool) {
        this.isEditUserSuccess = bool
    }
    
    /**
     * Метод осуществляет сохранение информации о низком разрешении экрана устройства
     * @method
     * 
     * @param {Boolean} bool Информация о низком разрешении экрана устройства
     */
    setIsLowResolution(bool) {
        this.isLowResolution = bool
    }

     /**
     * Метод осуществляет сохранение информации о юр лицах пользователя
     * @method
     * 
     * @param {Object[]} subCompanies Информация о юр лицах пользователя
     */
     setUserSubCompanies(subCompanies) {
        this.userSubCompanies = subCompanies
    }
     
    /**
     * Метод осуществляет сохранение информации об успешном редактировании юр. лица зарегистрированного пользователя
     * @method
     * 
     * @param {Boolean} bool Информация об успешном редактировании юр. лица
     */
     setIsEditSubCompanySuccess(bool) {
        this.isEditSubCompanySuccess = bool
    }

    /**
     * Метод осуществляет сохранение информации о загрузке
     * @method
     * 
     * @param {Boolean} bool Информация о загрузке
     */
    setIsLoading(bool) {
        this.isLoading = bool
    }

    /**
     * Метод осуществляет сохранение информации о текущем юр лице пользователя
     * @method
     * 
     * @param {Object} subCompany Информация о текущем юр лице пользователя
     */
    setCurrentSubCompany(subCompany) {
        let isFullUserInfo = true

        if (subCompany) {
            this.userFormFields.forEach(field => {
                if (field.tech_name.includes('sub')) {
                    if (!subCompany[field?.tech_name.replace('sub_', '')])
                        this.isFullUserInfo = false 
                }
            })
        }
        
        if(!isFullUserInfo)
            toast.warning('У вас есть незаполненная информация в профиле пользователя', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })

        this.isFullUserInfo = isFullUserInfo
        this.currentSubCompany = subCompany ? subCompany : {}
    }
    
    /**
     * Метод осуществляет сохранение ID таймера отсчета интервала времени для обновления конфигурации
     * @method
     * 
     * @param {Number} id ID таймера
     */
    setUpdateTimerID(id) {
        this.updateTimerID = id
    }
    
    /**
     * Метод осуществляет обновление информации об авторизованном пользователе
     * @method
     */
    async getUserMe () {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsLoading(false)
        }, responseTimeOut)
        
        AuthService
            .getUser()
            .then(response => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                this.setUser(response.data)
            })
            .catch(error => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                if (error.response && error.response.status !== 401) {
                    showErrorToast(error, 'fetching', '')
                }
            })
    }

    /**
     * Метод осуществляет получение информации о юр лицах авторизованного пользователя
     * @method
     */
    async getMySubCompanies () {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsLoading(false)
        }, responseTimeOut)

        AuthService
            .getMySubCompanies()
            .then(response => {
                clearTimeout(noResponse)
                this.setIsLoading(false)
                this.setUserSubCompanies(response.data.filter(item => item.is_active))
                this.setCurrentSubCompany(response.data.find(item => item.id === this.user.curr_sub_company_id))
            })
            .catch(error => {
                this.setIsLoading(false)
                clearTimeout(noResponse)
                showErrorToast(error, 'fetching', '')
            })
    }

    /**
     * Метод осуществляет смену пароля пользователя
     * @method
     * 
     * @param {Object} data Объект, содержащий информацию о старом и новом пароле пользователя
     */
    async changePassword (data) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsLoading(false)
        }, responseTimeOut)
        
        AuthService
            .changePassword(data)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Пароль успешно изменен', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                this.setIsLoading(false)
            })
            .catch(error => {
                clearTimeout(noResponse)
                switch (error?.response?.status) {
                    case 400:
                        if(error?.response?.data?.message === 'The entered password does not match the old password'){
                            toast.error(`Ошибка при попытке смены пароля! Проверьте корректность заполненных данных. Текущий пароль 
                                не совпадает с введенным`, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                        } else if (error?.response?.data?.message.includes("Attribute 'new_password' should`t match the old password")){
                            toast.error(`Ошибка при попытке смены пароля! Проверьте корректность заполненных данных. Новый пароль не 
                                должен совпадать со старым`, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                        } else {
                            toast.error(`Ошибка при попытке смены пароля! Проверьте корректность заполненных данных`, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                        }
                        break
                    case 403:
                        toast.error(`Ошибка при попытке смены пароля! Доступ запрещен`, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                        break
                    case 404:
                        toast.error(`Ошибка при попытке смены пароля! Данные не найдены`, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                        break
                    default:
                        toast.error(`Ошибка при попытке смены пароля! Возникла непредвиденная ошибка!`, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                }
                this.setIsLoading(false)
            })
    }

    /**
     * Метод осуществляет редактирование авторизованного пользователя
     * @method
     * 
     * @param {Object} user Информация о пользователе
     */
    async editUserMe (user) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsLoading(false)
            runInAction(() => {
                this.isEditUserSuccess = false
            })
        }, responseTimeOut)

        AuthService
            .editUserMe(user)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Информация о пользователе изменена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                this.setIsLoading(false)
                runInAction(() => {
                    this.isEditUserSuccess = true
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error('Ошибка при изменении информации о пользователе! Такой пользователь уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                this.setIsLoading(false)
                runInAction(() => {
                    this.isEditUserSuccess = false
                })
            })
    }

    /**
     * Метод осуществляет создание сотрудника руководителя компании
     * @method
     * 
     * @param {String} name Имя руководителя
     * @param {String} position_record_id Ссылка на должность руководителя
     */
    async createOwner (name, position_record_id) {
        const dataObject = {}
        dataObject.data_model_id = 'employees'
        dataObject.data = {name: name, employee_position__employees: [position_record_id]}

        const response = await DocumentService.createDataObject(dataObject)
        
        return response
    }

     /**
     * Метод осуществляет редактирование сотрудника руководителя компании
     * @method
     * 
     * @param {String} record_id Ссылка на сотрудника руководителя
     * @param {String} name Имя руководителя
     * @param {String} position_record_id Ссылка на должность руководителя
     */
    async editOwner (record_id, name, position_record_id) {
        const dataObject = {}

        if (position_record_id) {
            dataObject.data = {name: name, employee_position__employees: [position_record_id]}
        } else {
            dataObject.data = {name: name}
        }

        const response = await DocumentService.updateDataObject(record_id, dataObject)

        return response
    }

     /**
     * Метод осуществляет создание должности руководителя компании
     * @method
     * 
     * @param {String} name Название должности
     */
    async createOwnerPosition (name) {
        const dataObject = {}
        dataObject.data_model_id = 'positions'
        dataObject.data = {name: name}

        const response = await DocumentService.createDataObject(dataObject)

        return response
    }

    /**
     * Метод осуществляет редактирование должности руководителя компании
     * @method
     * 
     * @param {String} record_id Ссылка на должность руководителя
     * @param {String} name Имя руководителя
     */
    editOwnerPosition (record_id, name) {
        const dataObject = {}
        dataObject.data = {name: name}

        DocumentService.updateDataObject(record_id, dataObject)
    }

    /**
     * Метод осуществляет изменение информации о сотруднике руководителя компании
     * @method
     * 
     * @param {Object[]} employees Массив сотрудников
     * @param {Object} company Информация о компании
     * @param {String} currentOwnerPosition Должность руководителя
     */
    async changeOwner (employees, company, currentOwnerPosition) {
        if (employees.length) {
            //Сотрудник был найден

            //Проверка на наличие должности у сотрудника
            if (employees[0].data.employee_position__employees.value.values.length) {
                //Проверка на соответствие новой позиции, позиции найденного пользователя
                if (employees[0].data.employee_position__employees.value.values[0].name === company['owner_position']) {
                    this.editOwner(employees[0].record_id, company['owner'])
                } else {
                    await this.editOwner(employees[0].record_id, company['owner'])
                    .then(() => {
                        this.editOwnerPosition(employees[0].data.employee_position__employees.value.values[0].record_id, company['owner_position'])
                    })
                }
            } else {
                const filters = JSON.stringify([
                    {property: 'data_model_id', value: 'positions', operator: 'eq'},
                    {property: 'transaction_id', value: null, operator: 'eq'},
                    {property: 'active', value: true, operator: 'eq'},
                    {property: 'data[\'name\'].value', value: currentOwnerPosition ? currentOwnerPosition : '', operator: 'eq'}
                ])

                this.DocStore.getDataObjectsByID(filters, [], 0, [], (positions) => this.changeOwnerPosition(positions, company, employees), () => {}, () => {})
            }
        } else {
            //Сотрудник не был найден
            const filters = JSON.stringify([
                {property: 'data_model_id', value: 'positions', operator: 'eq'},
                {property: 'transaction_id', value: null, operator: 'eq'},
                {property: 'active', value: true, operator: 'eq'},
                {property: 'data[\'name\'].value', value: currentOwnerPosition ? currentOwnerPosition : '', operator: 'eq'}
            ])

            this.DocStore.getDataObjectsByID(filters, [], 0, [], (positions) => this.changeOwnerPosition(positions, company), () => {}, () => {})
        }
    }

     /**
     * Метод осуществляет изменение информации о позиции руководителя компании
     * @method
     * 
     * @param {Object[]} positions Массив должностей
     * @param {Object} company Информация о компании
     * @param {Object[]} employees Массив сотрудников
     */
    async changeOwnerPosition (positions, company, employees) {
        if (positions.length) {
            //Позиция была найдена
            //Проверка на наличие сотрудника
            if (employees) {
                await this.editOwner(employees[0].record_id, company['owner'], positions[0].record_id)
                .then(() => {
                    //Проверка на совпадение текущей позиции с новой
                    if (positions[0].data.name.value !== company['owner_position']) {
                        this.editOwnerPosition(positions[0].record_id, company['owner_position'])
                    }
                })
            } else {
                await this.createOwner(company['owner'], positions[0].record_id)
                .then(() => {
                    //Проверка на совпадение текущей позиции с новой
                    if (positions[0].data.name.value !== company['owner_position']) {
                        this.editOwnerPosition(positions[0].record_id, company['owner_position'])
                    }
                })
            }
        } else {
            //Позиция не была найдена
            await this.createOwnerPosition(company['owner_position'])
            .then((data) => {
                if (employees) {
                    this.editOwner(employees[0].record_id, company['owner'], data.record_id)
                } else {
                    this.createOwner(company['owner'], data.record_id)
                }
            })
        }
    }

     /**
     * Метод осуществляет редактирование компании авторизованного пользователя
     * @method
     * 
     * @param {Object} company Информация о компании 
     */
     async editMyCompany (company) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsLoading(false)
            runInAction(() => {
                this.isEditCompanySuccess = false
            })
        }, responseTimeOut)

        AuthService
            .editMyCompany(company)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Информация о компании изменена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                this.setIsLoading(false)
                runInAction(() => {
                    this.isEditCompanySuccess = true
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error('Ошибка при изменении информации о компании! Такая компания уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                this.setIsLoading(false)
                runInAction(() => {
                    this.isEditCompanySuccess = false
                })
            })
    }

    /**
     * Метод осуществляет редактирование юр лица авторизованного пользователя
     * @method
     * 
     * @param {Object} subCompany Информация о юр лице
     */
    async editMySubCompany (subCompany, id) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsLoading(false)
            runInAction(() => {
                this.isEditSubCompanySuccess = false
            })
        }, responseTimeOut)

        AuthService
            .editMySubCompany(subCompany, id)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Информация о юр. лице изменена', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                this.setIsLoading(false)
                runInAction(() => {
                    this.isEditSubCompanySuccess = true
                    
                    const currentOwner = this.currentSubCompany?.owner
                    const currentOwnerPosition = this.currentSubCompany?.owner_position

                    const filters = JSON.stringify([
                        {property: 'data_model_id', value: 'employees', operator: 'eq'},
                        {property: 'transaction_id', value: null, operator: 'eq'},
                        {property: 'active', value: true, operator: 'eq'},
                        {property: 'data[\'name\'].value', value: currentOwner ? currentOwner : '', operator: 'eq'}
                    ])

                    this.DocStore.getDataObjectsByID(filters, [], 0, [], (employees) => this.changeOwner(employees, subCompany, currentOwnerPosition), () => {}, () => {})
                })
            })
            .catch(error => {
                clearTimeout(noResponse)
                if (error?.response?.status === 409) {
                    toast.error('Ошибка при изменении информации о юр. лице! Такое юр. лицо уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                } else {
                    showErrorToast(error, 'saving', '')
                }
                runInAction(() => {
                    this.isLoading=false
                    this.isEditSubCompanySuccess = false
                })
            })
    }

    /**
     * Метод осуществляет самостоятельную регистрацию пользователя в системе
     * @method
     * 
     * @param {Object} user Информация о пользователе 
     */
    async userRegistration (user) {
        this.setIsLoading(true)

        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsLoading(false)
        }, responseTimeOut)

        AuthService
            .userRegistration(user)
            .then(() => {
                clearTimeout(noResponse)
                toast.success('Регистрация прошла успешно', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                sessionStorage.removeItem('userRegistration')
                this.setIsLoading(false)
                this.setIsRegistrationSuccess(true)
            })
            .catch(error => {
                clearTimeout(noResponse)
                console.log(error?.response)
                switch (error?.response?.status) {
                    case 400:
                        toast.error('Ошибка при регистрации! Проверьте корректность заполненных данных', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                        break
                    case 403:
                        toast.error('Ошибка при регистрации! Доступ запрещен', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                        break
                    case 409:
                        error?.response.data.errors.map(error => {
                            if(error.description === "Company already exists")
                                toast.error('Ошибка при регистрации! Такая компания уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                            else if(error.description === "User already exists")
                                toast.error('Ошибка при регистрации! Такой пользователь уже существует', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                        })
                        break
                    case 429:
                        toast.error('Ошибка при регистрации! К сожалению, вы достигли лимита. Попробуйте позже', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                        break
                    default:
                        let status, statusText
                        if (error.response) {
                            status = 'Код ошибки: ' +  (error?.response?.status || 'Не определен')
                            statusText = 'Текст: ' + (error?.response?.statusText || 'Не определен')
                        } else {
                            status = 'Отсутствует ответ от сервера.'
                            statusText = 'Проверьте настройки сети'
                        }
                        toast.error(<div>Ошибка при регистрации! Возникла непредвиденная ошибка!<br/><br/>{status}<br/>{statusText}</div>, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                }
                this.setIsLoading(false)
            })
    }

    /**
     * Метод запрашивает информацию о конфигурации пользователя
     * @method
     * 
     */
    async getUserConfiguration(isCheckedByUser) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис конфигурации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        ConfigurationService
            .getConfiguration()
            .then(configuration => {
                clearTimeout(noResponse)
                this.setConfiguration(configuration.data)
                this.setIsUpdateFlag(configuration.data.can_update)
                if (this.updateTimerID) {
                    if (configuration.data.status === 'successfully' || configuration.data.status === 'failed') {
                        const isUpdated = sessionStorage.getItem('isConfigurationUpdated')
                        if (isUpdated) {
                            if (configuration.data.status === 'successfully')
                                toast.info('Конфигурация обновлена до последней версии', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                            else
                                toast.error(<div>Произошла ошибка при обновлении конфигурации!<br /><br />
                                            Обновите страницу браузера и повторите попытку обновления.<br />
                                            При повторной неудаче обратитесь в службу поддержки Медоед</div>,
                                            { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
                        }                        
                        sessionStorage.removeItem('isConfigurationUpdated')
    
                        clearInterval(this.updateTimerID)
                        this.setUpdateTimerID(null)
                    }

                } else {
                    if (configuration.data.can_update) {
                        toast.warning(<div>Обновите конфигурацию до последней версии! <br /> Меню "Администрирование-&gt;Обновление конфигурации"</div>, { position: toast.POSITION.TOP_CENTER })
                    } else {
                        if (isCheckedByUser && configuration.data.status === 'successfully')
                            toast.info('Конфигурация обновлена до последней версии', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })                        
                    }

                }
            })
            .catch(() => {
                clearTimeout(noResponse)
                toast.error('Ошибка получения данных о конфигурации системы!', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })
            })
    }

    /**
     * Метод запускает обновление конфигурации пользователя
     * @method
     * 
     */
    async updateUserConfiguration() {
        this.setIsConfigurationLoading(true)

        const noResponse = setTimeout(() => {
            toast.error('Сервис конфигурации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
            this.setIsConfigurationLoading(false)
        }, responseTimeOut)

        let timerID = setInterval(() => this.getUserConfiguration(false), 1500)
        this.setUpdateTimerID(timerID)

        ConfigurationService
            .updateConfiguration(true)
            .then(() => {
                clearTimeout(noResponse)
                sessionStorage.setItem('isConfigurationUpdated', true)
                this.getUserConfiguration(false)
            })
            .catch(() => {
                clearTimeout(noResponse)
                toast.error('Ошибка при обновлении конфигурации', { position: toast.POSITION.TOP_CENTER, autoClose: 1500 })   
            })
            .finally(() => this.setIsConfigurationLoading(false))
    }

    /**
     * Метод формирует статус конфигурации модуля для вывода пользователю
     * @method
     * @param {String} status Статус конфигурации модуля
     */
    getModuleStatus(status) {
        switch (status) {
            case 'failed':
                return 'Обновление завершилось с ошибкой'
            case 'in_progress':
                return 'Выполняется обновление...'
            case 'successfully':
                return 'Успешно обновлен'
            default:
                return 'Состояние не определено'
        }
    }


    /**
     * Метод изменяет информацию о текущем юр лице пользователя
     * @method
     * @param {Number} id ID юр лица
     */
    async changeCurrentSubCompany(id) {
        const noResponse = setTimeout(() => {
            toast.error('Сервис аутентификации не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
        }, responseTimeOut)

        AuthService
            .changeCurrentSubCompany(id)
            .then(response => {
                clearTimeout(noResponse)
                localStorage.setItem('token', response.data.access)
                localStorage.setItem('refresh', response.data.refresh)
                runInAction(() => {
                    this.user.curr_sub_company_id = id
                })
                this.setCurrentSubCompany(this.userSubCompanies.find(item => item.id === id))
                toast.success('Смена текущего юр. лица прошла успешно', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                window.location.reload(true)
            })
            .catch((error) => {
                clearTimeout(noResponse)
                showErrorToast(error, 'saving', '')
            })
    }
}

export default UserStore