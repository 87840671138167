import React, { useState, useContext, useEffect } from 'react'
import { Context } from '../../../../..'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { setReferenceValue } from '../../../../../config/constTypes'
import {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import ru from "date-fns/locale/ru"
import { Tooltip } from 'react-tooltip'
import DirectoryContainer from '../../common/panels/directory/DirectoryContainer'
import StageButtons from '../stage/StageButtons'
import { observer } from 'mobx-react-lite'
import DocumentService from '../../../../../services/DocumentService'
import Spinner from '../../../../../assets/Spinner'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import { processData } from '../../../../../functions/forms'
import { deleteAllNestedDataObjects, deleteNestedDataObjects, updateNestedDataObjects } from '../../../../../functions/nestedModels'
import FieldItem from '../../../../form_fields/FieldItem'
import { checkNestedTables } from '../../../../../functions/checkNestedTables'
import FormErrorToastPanel from '../../common/panels/toast/FormErrorToastPanel'

registerLocale("ru", ru)

/**
 * Визуальный компонент отображает этап создания или редактирования перечня процессов
 * 
 * @returns {JSXElement} Html-разметку этапа создания или редактирования перечня процессов
 * с использованием визуальных компонентов {@link Spinner}, {@link StageButtons}, 
 * {@link DirectoryContainer}, {@link FieldItem}, {@link FormErrorToastPanel}
 * 
 * @see [Вызов компонента](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line144)
 */
const ProcessesForm = () => {   
    const {
        control,
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        formState: { errors, isSubmitting },
    } = useForm()

    const { categorizingCIIStore, docStore } = useContext(Context)
    const [isSelectFormOpen, setIsSelectFormOpen] = useState(false)
    const [selectedDataModelID, setSelectedDataModelID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [isSavedRecord, setIsSavedRecord] = useState(false)
    const [formFields, setFormFields] = useState(null)
    const [formNestedModels, setFormNestedModels] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [selectedMethod, setSelectedMethod] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleSelectClick = (dataModel, name) => {
        setSelectedDataModelID(dataModel)
        setSelectedName(name)
        setIsSelectFormOpen(true)
    }

    const handleClearClick = (name) => {
        setValue(name, {format: '', values: []})
        setIsSavedRecord(false)

        if (name.indexOf('method') > 0) {
            setSelectedMethod(null)
        }
    }

    const handleDoubleClick = (item) => {
        setValue(selectedName, {value: setReferenceValue(item)})
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
        setSelectedName(null)
        setIsSavedRecord(false)

        if (selectedName.indexOf('method') > 0) {
            setSelectedMethod(setReferenceValue(item))
        }
    }

    const handleCloseClick = () => {
        docStore.setIsDetailView(false)
        setIsSelectFormOpen(false)
        setSelectedDataModelID(null)
    }

    const handleBackClick = () => {
        if ((!isSavedRecord) && categorizingCIIStore.selectedMethod.values.length > 0) {
            categorizingCIIStore.loadProcesses(categorizingCIIStore.selectedMethod.values[0].id)
        }
        categorizingCIIStore.goPrevStage()
    }

    const handleForwardClick = async (form) => {
        setIsLoading(true)

        // проверка наличия заполненного списка процессов и хотя бы одного критичного процесса
        let isNotEmptyProcessList = false
        let isCriticalProcess = false
        categorizingCIIStore.processes
            .filter(nestedModel => !nestedModel.hidden)
            .forEach(nestedModel => {
                nestedModel.dataObjects
                    .filter(process => process.status !== 'deleted')
                    .forEach(process => {
                        if (nestedModel.dataObjects.filter(item => item.status !== 'deleted').length !== 0)
                            isNotEmptyProcessList = true
                        const criticalField = (Object.values(process.data)).find(field => field.tech_name.indexOf('critical') >= 0)
                        if (criticalField.value)
                            isCriticalProcess = true
                    })
            })
        // проверка отсутствия повторяющихся и пустых значений
        const nestedModelsWithErrors = checkNestedTables(categorizingCIIStore.processes, formFields, false, setError, 
            'Включенные таблицы не должны содержать повторяющиеся или пустые значения!')
        categorizingCIIStore.setProcesses(nestedModelsWithErrors)

        if (nestedModelsWithErrors.some(nestedModel => nestedModel.errors)) {
            setIsLoading(false)
        } else if (!isNotEmptyProcessList) {
            toast.error(<div>Должен быть заполнена хотя бы одна таблица процессов</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 }) 
            setIsLoading(false)
        } else if (!isCriticalProcess) {
            toast.error(<div>Должен быть указан хотя бы один критичный процесс</div>, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 }) 
            setIsLoading(false)

        } else {
            const dataObject = processData(form.data, categorizingCIIStore.activeDataModel.id)

            categorizingCIIStore.processes.forEach(nestedModel => {
                if (nestedModel.hidden) {
                    dataObject.data[nestedModel.tech_name] = {
                        remove: deleteAllNestedDataObjects(nestedModel)
                    }
                } else {
                    dataObject.data[nestedModel.tech_name] = { 
                        upsert: updateNestedDataObjects(nestedModel),
                        remove: deleteNestedDataObjects(nestedModel)
                    }
                }
            })

            try {
                if (!isSavedRecord) {
                    if (categorizingCIIStore.selectedMethod && categorizingCIIStore.selectedMethod.values.length > 0) {
                        await DocumentService.updateDataObject(categorizingCIIStore.selectedMethod.values[0].record_id, dataObject)

                        if (categorizingCIIStore.linkedListPrintDate) {
                            // блокировка кнопки "Далее" для этапа "Печать перечня под категорирование"
                            categorizingCIIStore.setLinkedListPrintDate(null)
                            await categorizingCIIStore.updateProject('date_of_print_st_6__stages_of_categorization', null, true)
                        } else
                            await categorizingCIIStore.loadSavedProject(categorizingCIIStore.project.id)

                    } else {
                        const response = await DocumentService.createDataObject(dataObject)
                        await categorizingCIIStore.updateProject('categorization_method_for_stages__stages_of_categorization', [response.record_id], true)
                    }

                    toast.success('Данные успешно сохранены!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })
                }

                // формирование единого списка процессов/видов деятельности
                const processList = categorizingCIIStore.processes
                    .filter(nestedModel => !nestedModel.hidden)         // выбрать таблицы процессов согласно способу категорирования
                    .map(nestedModel => [...nestedModel.dataObjects])        // оставить только записи процессов
                    .flat()                                             // в виде единого плоского массива
                    .map(item => { return {...item, value_record_id: categorizingCIIStore.getLinkRecordID(item)}})  // добавить на верхний уровень record_id процесса

                categorizingCIIStore.setProcessList(processList)

                categorizingCIIStore.setProcesses(null)
                
                categorizingCIIStore.goNextStage()  

            } catch (error) {
                setIsLoading(false)
                showErrorToast(error, 'saving', '')
            }
        }
    }
    
    const checkStageResult = () => {
        const result = !selectedMethod 
        return result
    }

    const handleChangeProcesses = (editedValues, editedNestedModel) => {
        categorizingCIIStore.setProcesses(categorizingCIIStore.processes.map(nestedModel =>
            nestedModel.id === editedNestedModel.id && nestedModel.rule_id === editedNestedModel.rule_id
                ?   {...nestedModel, 
                        dataObjects: editedValues,
                        errors: editedNestedModel.errors ? categorizingCIIStore.checkPrimaryFieldErrors(editedNestedModel, editedValues) : false
                    }
                :   nestedModel
        ))
        setIsSavedRecord(false)

        if (errors.data)
            clearErrors('data.'+ editedNestedModel.tech_name)
    }
    
    const hideFields = (fields, method) => {
        return fields.map(fieldItem => { 
            return {...fieldItem,
                    hidden: fieldItem.tech_name === 'commission__list_of_processes' || 
                            (method && 
                                ((fieldItem.tech_name.indexOf('_activities__') > 0 && method.values[0].name.indexOf('процесс') > 0) ||
                                (fieldItem.tech_name.indexOf('_process__') > 0 && method.values[0].name.indexOf('деятельности') > 0))
                            ) ||
                            (!method && fieldItem.type === 'include') 
            }
        })
    }

    const loadSavedRecord = async (id) => {
        try {
            let savedSelectedMethod = null
            const savedRecord = await DocumentService.getOneDataObject(id)
            const savedFieldValues = Object.values(savedRecord.data)

            savedFieldValues.forEach(field => {
                if (field.tech_name.indexOf('method') > 0) {
                    savedSelectedMethod = field.value
                    setSelectedMethod(savedSelectedMethod)
                }
            })
   
            setFormFields(hideFields(savedFieldValues, savedSelectedMethod))
            setIsEditMode(true)
            setIsSavedRecord(true)
        } catch (error) {
            showErrorToast(error, 'fetching', '') 
            setFormFields(categorizingCIIStore.activeDataModel.fields)
        }

        return null
    }
    
    // загрузка структуры таблицы и вложенных таблиц процессов
    useEffect(() => {
        categorizingCIIStore.setIsDataLoading(true)
        categorizingCIIStore.getActiveDataModel('list_of_processes', setFormNestedModels)
    }, [])
    
    // загрузка записи основной таблицы
    useEffect(() => {
        if (categorizingCIIStore.activeDataModel && categorizingCIIStore.activeDataModel.id === 'list_of_processes') {
            if (categorizingCIIStore.selectedMethod && categorizingCIIStore.selectedMethod.values.length > 0) {              
                loadSavedRecord(categorizingCIIStore.selectedMethod.values[0].id)
            } else {
                setFormFields(categorizingCIIStore.activeDataModel.fields)
            }
        }
    }, [categorizingCIIStore.activeDataModel])
    
    // скрытие  вложенных таблиц процессов согласно выбранному способу категорирования
    useEffect(() => {
        if (formFields && formNestedModels) {
            const hiddenFields = hideFields(formFields, selectedMethod)
            setFormFields(hiddenFields)
            if (selectedMethod) {
                if (categorizingCIIStore.selectedMethod && categorizingCIIStore.selectedMethod.values.length > 0) {
                    categorizingCIIStore.setProcesses(formNestedModels.map((nestedModel, index) =>  {
                        const foundField = hiddenFields.find(field => field.rule_id === nestedModel.rule_id)
                        return {
                            ...nestedModel,
                            dataObjects: categorizingCIIStore.processValues[index].dataObjects,
                            hidden: foundField && foundField.hidden
                        }
                    }))

                } else {
                    categorizingCIIStore.setProcesses(formNestedModels.map(nestedModel => {
                        const foundField = hiddenFields.find(field => field.rule_id === nestedModel.rule_id)
                        return  {
                            ...nestedModel,
                            dataObjects: [],
                            hidden: foundField && foundField.hidden
                        }
                    }))
                }
            } else {
                categorizingCIIStore.setProcesses(formNestedModels.map(nestedModel => { return {...nestedModel, dataObjects: [], hidden: true} }))
            }
            setValue('data.commission__list_of_processes.0', categorizingCIIStore.commissionID)
        }
        categorizingCIIStore.setIsDataLoading(false)
    }, [selectedMethod, formNestedModels])

    useEffect(() => {
        if(Object.entries(errors).length > 0 && isSubmitting === false){
            toast.error(<FormErrorToastPanel errors={errors.data} fields={formFields}/>,
                        { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        }
    }, [errors, isSubmitting])

    return (
        <>
            <div className='tw-grow tw-overflow-auto tw-mt-2'>
                <form className='tw-flex tw-flex-col tw-h-full'>
                    <div className='tw-px-4 tw-py-1'>
                        { !formFields || !categorizingCIIStore.processes || categorizingCIIStore.isDataLoading
                            ?
                                <Spinner />
                            :
                                formFields.slice().sort((a, b) => a.order - b.order).map((fieldItem, index) => {
                                    if (!fieldItem.hide && !fieldItem.hidden) {
                                        return  <FieldItem
                                                    key={index}
                                                    fieldItem={fieldItem}
                                                    nestedModels={categorizingCIIStore.processes}
                                                    selectedNestedValue={categorizingCIIStore.selectedNestedValue}
                                                    errors={errors}
                                                    handleSelectClick={handleSelectClick}
                                                    handleClearClick={handleClearClick}
                                                    handleRecordChange={setIsSavedRecord}
                                                    isEditMode={isEditMode}
                                                    isDuplicateMode={false}
                                                    isLoading={isLoading}
                                                    control={control}
                                                    register={register}
                                                    setValue={setValue}
                                                    onSelectNestedValue={e => categorizingCIIStore.setSelectedNestedValue(e)}
                                                    onNestedTableChange={handleChangeProcesses}
                                                    isChosenObjectDuplicationForbidden={true}
                                                />
                                    } else return null
                                })
                        }
                    </div>
                </form>
            </div>
            <StageButtons
                onBackClick={handleBackClick}
                onForwardClick={handleSubmit(handleForwardClick)}
                disabled={checkStageResult() || isLoading}
            />
            <DirectoryContainer 
                isOpen={isSelectFormOpen}
                selectedDataModel={selectedDataModelID}
                onDoubleClick={handleDoubleClick}
                onCloseClick={handleCloseClick}
            />
            <Tooltip id="processes-form-tooltip" place="top" className='tw-max-w-xl'/>
        </>                                                             
    )
}

export default observer(ProcessesForm)
