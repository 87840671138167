import React, { useState } from 'react'
import { ArrowDownTrayIcon, TrashIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'


/**
 * Визуальный компонент отображает поле для создания и редактирования опций перечисляемого ("Список") и вещественного типов 
 * @param {String} type Выбранный тип поля
 * @param {Object} initValue Признак режима редактирования существующего поля
 * @param {Function} setFloatOption Действие при изменении значения опции вещественного типа
 * [arrow function](./components_main_page_controller_field_FieldForm.js.html#line538)
 * @param {Function} setEnumOption Действие при изменении значения опции перечисляемого типа
 * [arrow function](./components_main_page_controller_field_FieldForm.js.html#line539)
 * @param {Object} error Ошибка при валидации выбранного значения (для визуализации)
 * @returns {HTMLFormElement} Html-разметку поля для создания и редактирования опций
 * 
 * @see [Вызов компонента](./components_main_page_controller_field_FieldForm.js.html#line535)
 */
const FieldOptionInput = ({type, initValue, setFloatOption, setEnumOption, error}) => {

    const [enumValues, setEnumValues] = useState(type === 'enum' && initValue?.allowed_values ? initValue.allowed_values : [])
    const [newEnumValue, setNewEnumValue] = useState('')
    const [selectedEnumValue, setSelectedEnumValue] = useState(null)
    const [fValue, setFValue] = useState(type === 'float' && initValue?.number_decimal_places ? initValue.number_decimal_places : 2)

    const handleFloatValueChange = (e) => {
        setFloatOption(Number(e.target.value))
        setFValue(Number(e.target.value))
    }
    const handleEnumValueChange = (e) => setNewEnumValue(e.target.value)

    const handleAddEnumValue = () => {
        setEnumOption([...enumValues, newEnumValue])
        setEnumValues([...enumValues, newEnumValue])
        setNewEnumValue('')
        setSelectedEnumValue(null)
    }

    const handleRemoveEnumValue = () => {
        setEnumOption(enumValues.filter(value => value !== selectedEnumValue))
        setEnumValues(enumValues.filter(value => value !== selectedEnumValue))
        setSelectedEnumValue(null)
    }


    return (
        <>
            { type === 'float' &&
                <>
                    <div className='tw-flex tw-flex-row tw-items-center'>
                        <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-700 tw-leading-6'>Точность (знаков после запятой)</label>
                        {error.floatOption && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                    </div>
                    <input
                        type='number'
                        min={1}
                        max={14}
                        value={fValue}
                        className={`tw-w-full tw-rounded-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm
                                focus-visible:tw-outline-none focus-visible:tw-border-gray-600 focus-visible:tw-ring-2 
                                focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                                ${error.enumOption ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                            `} 
                        onChange={handleFloatValueChange}
                    />
                </>
            }
            { type === 'enum' &&
                <>
                    <div className='tw-flex tw-flex-row tw-items-center'>
                        <label className='tw-text-sm tw-font-medium tw-px-2 tw-text-gray-700 tw-leading-6'>Доступные значения</label>
                        {error.enumOption && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                    </div>
                    <div className='tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center tw-mt-1'>
                        <input
                            type='text'
                                className={`tw-w-full tw-rounded-tl-md tw-border-0 tw-px-2 tw-py-1.5 tw-text-gray-900 
                                    tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 tw-text-sm
                                    focus-visible:tw-outline-none focus-visible:tw-border-gray-600 focus-visible:tw-ring-2 
                                    focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75 focus-visible:tw-ring-offset-2
                                    ${error.enumOption ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                `}
                            value={newEnumValue} 
                            onChange={handleEnumValueChange}
                        />
                        <button
                            className='tw-h-8 tw-border tw-border-l-0 tw-p-1.5 tw-bg-white tw-text-gray-900 tw-border-gray-400 hover:tw-bg-gray-200 disabled:tw-text-gray-400'
                            onClick={handleAddEnumValue}
                            disabled={newEnumValue === ''}
                            type='button'
                        >
                            <ArrowDownTrayIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        </button>
                        <button
                            className='tw-h-8 tw-rounded-tr-md tw-border tw-border-l-0 tw-p-1.5 tw-bg-white tw-text-gray-900 tw-border-gray-400 hover:tw-bg-gray-200 disabled:tw-text-gray-400'
                            onClick={handleRemoveEnumValue}
                            disabled={!selectedEnumValue}
                            type='button'
                            >
                                <TrashIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        </button>
                    </div>
                    <div className='tw-w-full tw-h-24 tw-border tw-border-t-0 tw-rounded-b-md tw-border-gray-400 tw-divide-y tw-overflow-auto'>
                        { enumValues.map((value, index) => {
                            return  <div 
                                        key={index}
                                        className={` ${value === selectedEnumValue ? 'tw-bg-gray-400' : ''} tw-text-sm tw-py-1 tw-px-2`}
                                        onClick={(e) => setSelectedEnumValue(value)}
                                    >
                                        {value}
                                    </div>
                        })}
                    </div>
                </>
            }
        </>
    )    
}

export default FieldOptionInput