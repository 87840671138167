import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Context, medoedImage } from '../..'
import logo from '../../assets/logo.png'
import AdminMenu from './menu/AdminMenu'
import { paths } from '../../config/constsURN'
import UserMenu from './menu/UserMenu'
import ModuleMenu from './menu/ModuleMenu'
import { useLocation } from 'react-router-dom'


/**
 * Визуальный компонент отображает заголовок с логотипом, названием приложения и кнопкой авторизированного пользователя
 * 
 * @returns {HTMLDivElement} Html-разметку заголовка приложения, включая компонент Menu из библиотеки {@link https://headlessui.com/|@headlessui}
 * с использованием визуальных компонентов {@link AdminMenu} и {@link UserMenu}
 * 
 * @see [Вызов компонента](./components_header_HeaderContainer.js.html#line14)
 */
const Header = () => {
    const { userStore } = useContext(Context)
    const location = useLocation()
    
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div id='header' className="tw-w-full tw-flex tw-flex-row tw-h-16 tw-items-center tw-justify-between tw-px-2 tw-bg-gray-800">
            <div className="tw-flex tw-flex-row tw-items-center lg:tw-gap-x-2 lg:tw-ml-8">
                <span className='tw-w-16'>
                    <a href='/' target='_self'>
                        <img src={logo} alt={logo} />
                    </a>
                </span>
                <div className='tw-flex tw-flex-col'>
                    <span className='tw-text-white tw-text-3xl tw-font-bold tw-tracking-widest tw-font-serif'><a href='/' target='_self'>MEDOED</a></span>
                    <span className='tw-text-white tw-text-xs tw-tracking-widest'>{medoedImage.name && medoedImage.name !== '' ? medoedImage.name : null}</span>
                </div>
            </div>
            { userStore.isAuth  
                ?   <div className='tw-flex tw-flex-row tw-items-center lg:tw-gap-8'>
                        <ModuleMenu />
                        <a
                            href={paths.DATAOBJECT_ROUTE}
                            className={classNames(
                                'tw-text-sm tw-text-center tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600',
                                'tw-rounded-md tw-px-2 tw-py-3 tw-font-semibold'
                            )}
                            aria-current={'page'}
                        >
                            Просмотр данных
                        </a>
                        <AdminMenu />
                        <UserMenu />
                    </div>
                :   (location.pathname === '/' || location.pathname === '/registration')
                        ?   <a
                                href={paths.LOGIN_ROUTE}
                                className={classNames(
                                    'tw-text-sm tw-font-semibold tw-text-white tw-bg-gray-800 hover:tw-bg-gray-600',
                                    'tw-rounded-md tw-px-3 tw-py-2 lg:tw-mr-10',
                                    'tw-border-2 tw-border-white'
                                )}
                                aria-current={'page'}
                            >
                                Войти
                            </a>
                        :   null
            }
        </div>
    )    
}

export default observer(Header)