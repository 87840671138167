import React from 'react'
import { InformationCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'


/**
 * Визуальный компонент для отображения имени поля формы
 * 
 * @param {Object} item Поле
 * @param {Object[]} errors Список ошибок, обнаруженных при валидации полей формы
 * 
 * @returns {JSXElement} Html-разметку поля для отображения имени поля формы 
 * 
 * @see [Вызов компонента CategorizingObjectsFormItem,](./components_main_page_controller_categorizing_cii_form_categorizingObjects_form_CategorizingObjectsFormItem.js.html#line235)
 * [DataObjectForm,](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line146)
 * [DataObjectForm,](./components_main_page_controller_data_object_form_DataObjectForm.js.html#line178)
 * [FieldItem,](./components_form_fields_FieldItem.js.html#line111)
 * [MaterialsObjectsForm,](./components_main_page_controller_education_materials_MaterialsObjectsForm.js.html#line285)
 * [MaterialsObjectsForm,](./components_main_page_controller_education_materials_MaterialsObjectsForm.js.html#line296)
 * [PDataAddQuestionForm,](./components_main_page_controller_personal_data_form_PDataAddQuestionForm.js.html#line202)
 * [QuestionsForm,](./components_main_page_controller_education_materials_tests_QuestionsForm.js.html#line45)
 * [ResourcesForm](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line384)
 */
const FormFieldName = ({item, errors}) => {
    return (
        <>
            <div className='tw-flex tw-flex-row tw-gap-x-2'>
                <span className='tw-inline'>
                    <span data-tooltip-id="item-name-tooltip" data-tooltip-content={item.alias} data-tooltip-delay-show={1000}>
                        {item.alias}
                    </span>
                    &nbsp;
                    <span className='tw-text-red-400'>{item.mandatory ? ' *' : ''}</span>
                </span>
                <span data-tooltip-id="item-name-tooltip" data-tooltip-html={item.description} data-tooltip-delay-show={500}>
                    { item.description 
                        ?   <InformationCircleIcon
                                className={`tw-h-5 tw-w-5 ${(errors?.data && errors?.data[item.tech_name]) ? 'tw-text-red-500' : 'tw-text-gray-500'} tw-cursor-help`}
                                aria-hidden="true"
                            />
                        :   (errors?.data && errors?.data[item.tech_name]) && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>
                    }
                </span>
            </div>
            <Tooltip id="item-name-tooltip" place="top" className='tw-max-w-xl'/>
        </>
    )
}

export default FormFieldName