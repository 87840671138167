import { Popover } from '@headlessui/react'
import parse from 'html-react-parser'

/**
 * Визуальный компонент отображает справочную информацию по этапу процесса (категорирования КИИ, обработка ПДн и др.)
 * 
 * @param {String} info Информация по этапу процесса
 * @param {String} caption Надпись на кнопке
 * @returns {JSXElement} Визуальный компонент Popover из библиотеки {@link https://headlessui.com/|@headlessui}
 * 
 * @see [Вызов компонента CategorizingContainer,](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line132)
 * [PersonalDataContainer,](./components_main_page_controller_personal_data_PersonalDataContainer.js.html#line127)
 * [ResourcesForm](./components_main_page_controller_categorizing_cii_form_ResourcesForm.js.html#line422)
 */
function HelpButton({info, caption, align, isFixedSize}) {
    return (
        <Popover 
            className='tw-relative tw-rounded-md tw-border-2 tw-px-2 tw-py-0.5 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                    hover:tw-bg-gray-200 focus:tw-outline-none'
        >
            <Popover.Button className='focus:tw-outline-none'>{ caption || 'Справка'}</Popover.Button>

            <Popover.Panel className={`tw-absolute tw-w-screen tw-z-20 tw-bg-white tw-border tw-border-gray-400 tw-rounded-md tw-shadow-xl
                                        ${!align || align === 'end' ?  'tw--translate-x-full' : align === 'start' ? 'tw--translate-x-0' : 'tw--translate-x-1/2'}
                                        ${isFixedSize ? 'tw-h-64 tw-max-w-2xl tw-overflow-auto' : ' tw-max-w-xl'}`}
            >
                <div className='tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-900'>
                    {info ? parse(info) : 'Справочная информация отсутствует'}
                </div>
            </Popover.Panel>
        </Popover>
    )
}

export default HelpButton