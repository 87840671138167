import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../..'
import { Tooltip } from 'react-tooltip'
import StageButtons from '../stage/StageButtons'
import { observer } from 'mobx-react-lite'
import Spinner from '../../../../../assets/Spinner'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import { printDocumentPacket } from '../../../../../functions/fileHandlers'

/**
 * Визуальный компонент отображает этап печати сопроводительных документов
 * 
 * @returns {JSXElement} Html-разметку этапа печати сопроводительных документов
 * с использованием визуальных компонентов {@link StageButtons}
 * 
 * @see [Вызов компонента](./components_main_page_controller_categorizing_cii_CategorizingContainer.js.html#line165)
 */
const PrintActForm = () => {   
    const { categorizingCIIStore } = useContext(Context)
    const [isPrintingDocument, setIsPrintingDocument] = useState(false)

    const handlePrintDocument = async () => {
        try {
            const documents = {}
            documents.templates = {
                "fstek_cover_letter": categorizingCIIStore.linkedList.values[0].id,
                "fstek_registry": categorizingCIIStore.linkedList.values[0].id
            }

            // печать комплекта документов с перечнем под категорирование
            await printDocumentPacket('kii_fstek_result', documents, setIsPrintingDocument)
            
            // фиксация завершения текущего этапа
            categorizingCIIStore.setAllStages(categorizingCIIStore.allStages.map(stage => 
                stage.data['id_of_stage__guide_stages_of_categ'].value === categorizingCIIStore.actualStageIndex
                    ?   {...stage, status: 'finished'}
                    :   stage
            ))

        } catch (error) {
            setIsPrintingDocument(false)
            showErrorToast(error, 'printing', '')
        }
    }

    useEffect(() => {
        // фиксация завершения текущего этапа
        categorizingCIIStore.setAllStages(categorizingCIIStore.allStages.map(stage => 
            stage.data['id_of_stage__guide_stages_of_categ'].value === categorizingCIIStore.actualStageIndex
                ?   {...stage, status: 'finished'}
                :   stage
        ))
    }, [])

    const handleBackClick = () => {
        categorizingCIIStore.goPrevStage()
    }
   
    return (
        <>
            { !isPrintingDocument &&
                <>
                    <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-mx-auto tw-py-2'>
                        <button 
                            className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                                    hover:tw-bg-gray-600 hover:tw-border-gray-600 
                                    focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                            onClick={handlePrintDocument}
                        >
                            Скачать сопроводительные документы
                        </button>
                    </div>
                    <div className='tw-grow tw-overflow-auto'>
                        <div className='tw-px-4 tw-py-1 tw-mt-1'>
                            <p className='tw-text-md tw-my-2'>Для отправки результатов категорирования во ФСТЭК Вам необходимо:</p>
                            <ul className='tw-text-md tw-mt-1 tw-pl-8 tw-list-disc'>
                                <li>распечатать и подписать акты категорирования (форма №236 уже включена в акт);</li>
                                <li>распечатать и подписать реестр и сопроводительное письмо во ФСТЭК;</li>
                                <li>записать акты категорирования на компакт-диск в форматах odt и ods;</li>
                                <li>все вышеперечисленные объекты направить во ФСТЭК России курьером, спецсвязью или наручно в течении 10 рабочих дней.</li>
                            </ul>
                        </div>
                    </div>
                </>                                                             
            }
            { isPrintingDocument &&
                <div className='tw-grow tw-overflow-auto'>
                    <div className='tw-px-4 tw-py-1 tw-mt-1'>
                        <p className='tw-mt-2 tw-text-center tw-text-sm tw-font-semibold'>Выполняется скачивание сопроводительных документов к акту категорирования объектов КИИ...</p>
                        <Spinner/>
                    </div>
                </div>
            }
            <StageButtons
                onBackClick={handleBackClick}
                //onForwardClick={handleForwardClick}
                disabled={true}
            />
            <Tooltip id="print-form-tooltip" place="top"/>
        </>                                                             
    )
}

export default observer(PrintActForm)