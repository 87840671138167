import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ExclamationCircleIcon, CheckIcon, XMarkIcon, AdjustmentsHorizontalIcon } from '@heroicons/react/20/solid'
import DataModelComboBox from '../../common/inputs/DataModelComboBox'
import { Tooltip } from 'react-tooltip'
import { Context } from '../../../../..'


/**
 * Визуальный компонент отображает форму для создания/редактирования шаблона импорта
 * 
 * @param {Boolean} editMode Признак режима редактирования шаблона импорта
 * @param {Object} importer Выбранный для редактирования шаблон импорта
 * @param {Object} dataModel Таблица, в которую осуществляется импорт данных
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке сохранения шаблона импорта
 * [handleAddImporterClick](./components_main_page_controller_importer_ImporterListContainer.js.html#line74) и 
 * [handleEditImporterClick](./components_main_page_controller_importer_ImporterListContainer.js.html#line99)
 * @param {Function} onCancelClick Обработчик клика мыши на кнопке отмены сохранения шаблона импорта
 * [handleCancelClick](./components_main_page_controller_importer_ImporterListContainer.js.html#line136)
 * 
 * @returns {HTMLFormElement} Html-разметку формы редактирования шаблона импорта с использованием визуальных компонентов 
 * {@link DataModelComboBox}
 * 
 * @see [Вызов компонента](./components_main_page_controller_importer_ImporterListContainer.js.html#line226)
 */
const ImporterForm = ({editMode, importer, dataModel, onSubmitClick, onCancelClick}) => {
    const { FilterStore } = useContext(Context)
    const object = {fields: [
        {
            alias: "Название таблицы",
            tech_name: "entity_name",
            validator_type: "string"
        }
    ]}

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors, isValid, isSubmitting },
    } = useForm()

    const [selectedDataModel, setSelectedDataModel] = useState(
        editMode
            ?   dataModel
            :   {id: '0', entity_name: '', fields: []}
    )
    const [editedField, setEditedField] = useState(null)
    const [editedText, setEditedText] = useState('')

    const formatFieldColumns = (fields) => {
        const sortedFields = fields.filter(field => !field.hide).sort((a, b) => a.order - b.order)

        for (const field of sortedFields) {
            const foundField = importer.fields.find(item => item.tech_name === field.tech_name)
            if (foundField)
                field.columns = foundField.columns.map(item => item.index)
                else field.columns = []
        }
        return sortedFields        
    }

    register('importerID', { 
        required: false,
        shouldUnregister: true,
        value: editMode ? importer.id : '0'
    })

    register('dataModelID', { 
        required: true,
        shouldUnregister: true,
        value: editMode ? importer.meta.data_model_id : '0',
        validate: value => value !== '0'
    })

    register('fields', { 
        shouldUnregister: true,
        value:  editMode ? formatFieldColumns(dataModel.fields) : []
    })

    const watchFields = watch('fields', editMode ? formatFieldColumns(dataModel.fields) : [])

    const handleDataModelChange = (item) => {
        setValue('fields', item.fields.filter(field => !field.hide).sort((a, b) => a.order - b.order).map(field => {return {...field, columns: []}}))
        setValue('dataModelID', item.id, { shouldValidate: true })
        setSelectedDataModel(item)
    }

    const handleSaveColumns = (row) => {
        const columns = editedText.split(',')
        if (columns.every(item => Number.isInteger(Number(item)))) {
            setValue('fields', watchFields.map((item, index) => index === row ? {...item, columns: columns} : item))
            setEditedField(null)
            setEditedText('')
        } else {
            toast.error('Должны быть указаны номера колонок через запятую!', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
        }
    }

    const handleColumnsEdit = (row, field) => {
        setEditedText(field.columns.join(', '))
        setEditedField(row)
    }

    useEffect(()=>{
        setSelectedDataModel({id: '0', entity_name: '', fields: []})
    }, [FilterStore.selectedFilters])

    !editMode && isSubmitting && !isValid && toast.error('Заполните обязательное поле', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })

    return (
        <form id='importer-form' className='tw-flex tw-flex-col tw-w-full tw-h-[calc(100%_-_3rem)] tw-overflow-auto'>
            <div className='tw-h-12 tw-text-md tw-font-semibold tw-py-4 tw-px-4'>
                {editMode ? 'Редактирование шаблона' : 'Новый шаблон'}
            </div>
            <div className='tw-grow'>
                <div className='tw-block tw-w-full tw-px-4'>
                    <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-x-2 tw-mt-2'>
                        <label className='tw-text-sm tw-font-medium tw-text-gray-800 tw-leading-6 tw-ml-2'>Название</label>
                        {errors.templateName && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                    </div>
                    <input
                       className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                            tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset 
                            focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none
                            ${errors.templateName ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                        `}
                        {...register('templateName', { 
                            required: true,
                            value: editMode ? importer.name : ''
                        })}
                    />
                </div>
                { !editMode &&
                    <div className='tw-px-4'>
                        <DataModelComboBox
                            label='Таблица'
                            selectedItem={selectedDataModel}
                            error={errors.dataModelID}
                            typeFilter={['document', 'directory']}
                            onItemChange={handleDataModelChange}
                            object={object}
                        />
                    </div>
                }
                <div className='tw-block tw-py-2 tw-px-4'>
                    <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-x-2'>
                        <label className='tw-text-sm tw-font-medium tw-text-gray-800 tw-leading-6 tw-ml-2'>Правила подстановки</label>
                        {errors.templateText && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                    </div>
                    <div className='tw-border tw-rounded-md tw-border-gray-400 tw-px-3 tw-mt-1'>
                        <div className='tw-grid tw-grid-cols-2 tw-text-sm tw-font-semibold tw-py-2.5'>
                            <div>Поле таблицы</div>
                            <div>Столбцы файла</div>
                        </div>
                        { watchFields.map((field, index) => 
                            <div key={index} className='tw-grid tw-grid-cols-2 tw-border-t tw-py-3'>
                                <div className='tw-text-sm'>
                                    {field.alias}
                                </div>
                                <div className='tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-x-2 tw-text-sm'>
                                    { (editedField === index)
                                        ?
                                            <div className='tw-flex tw-flex-row tw-items-center'>
                                                <input
                                                    id='file-columns'
                                                    type='text'
                                                    className='tw-rounded-md tw-border-0 tw-px-1 tw-text-gray-700 
                                                        tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset 
                                                        focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none
                                                       tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'
                                                    value={editedText}
                                                    autoFocus={true}
                                                    onChange={(e) => setEditedText(e.target.value)}
                                                />
                                                <button
                                                    className='tw-ml-2 tw-border-0 tw-rounded-md tw-text-gray-500 hover:tw-text-gray-900 hover:tw-bg-gray-200'
                                                    type='button'
                                                    onClick={() => handleSaveColumns(index)}
                                                    data-tooltip-id="importer-form-tooltip" data-tooltip-content="Применить" data-tooltip-delay-show={1000}
                                                >
                                                    <CheckIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                                                </button>
                                                <button
                                                    className='tw-ml-1 tw-border-0 tw-rounded-md tw-text-gray-500 hover:tw-text-gray-900 hover:tw-bg-gray-200'
                                                    type='button'
                                                    onClick={() => setEditedField(null)}
                                                    data-tooltip-id="importer-form-tooltip" data-tooltip-content="Отменить" data-tooltip-delay-show={1000}
                                                >
                                                    <XMarkIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        :
                                            <span className='tw-truncate'>{field.columns.join(', ')}</span>
                                    }
                                    <button
                                        className='tw-ml-2 tw-p-0.5 tw-rounded-md tw-text-gray-600 hover:tw-text-gray-900 hover:tw-bg-gray-200 disabled:tw-text-gray-300'
                                        type='button'
                                        onClick={() => handleColumnsEdit(index, field)}
                                        disabled={editedField !== null}
                                        data-tooltip-id="importer-form-tooltip" data-tooltip-content="Задать номера колонок через запятую" data-tooltip-delay-show={1000}
                                    >
                                        <AdjustmentsHorizontalIcon className="tw-h-5 tw-w-5" aria-hidden="true"/>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                <button 
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={handleSubmit(onSubmitClick)}
                >
                    Сохранить
                </button>
                <button
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={onCancelClick}
                >
                    Отменить
                </button>
            </div>
            <Tooltip id="importer-form-tooltip" place="top"/>
        </form>
    )
}

export default ImporterForm