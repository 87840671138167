import React, { useContext } from 'react'
import { Context } from '../../../..'
import { observer } from 'mobx-react-lite'
import { paths } from '../../../../config/constsURN'


/**
 * Визуальный компонент отображает модуль МЕДОЕДа
 * @param {Object} module Модуль
 * @param {Object} colorTheme Цветовая схема
 * @param {String} label Текст для кнопки
 * @param {String} link Ссылка
 *
 * @returns {HTMLDivElement} Html-разметку модуля МЕДОЕДа
 *
 * @see [Вызов компонента ModuleGroup](./components_main_page_controller_summary_ModuleGroup.js.html#line35)
 */
const ModuleItem = ({module, colorTheme, label, link}) => {
    const { userStore } = useContext(Context)

    const refLink = !userStore.isAuth
                        ?   paths.LOGIN_ROUTE
                        :   link !== '' ? link : module.link

    return (
        <div className={`tw-flex tw-flex-row tw-justify-between tw-items-center tw-p-2 tw-mt-2 tw-rounded-md tw-border-2 ${colorTheme.borderColor} tw-bg-gray-50`}>
            <div className={`tw-text-sm tw-text-left tw-font-semibold ${colorTheme.textColor}`}>{module.name}</div>
            <a
                className={`tw-text-sm tw-font-semibold ${colorTheme.textColor} tw-px-2 tw-py-0.5 tw-rounded-md tw-border-2 ${colorTheme.borderColor} hover:tw-bg-gray-200`}
                href={refLink} target='_self'
            >
                {label}
            </a>
        </div>
    )
}

export default observer(ModuleItem)
