import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { Context } from '../../../../..'

/**
 * Визуальный компонент отображает форму для создания/редактирования категории
 * 
 * @returns {HTMLFormElement} Html-разметку формы редактирования категории
 * 
 * @see [Вызов компонента](./components_main_page_controller_category_CategoryListContainer.js.html#line70)
 */
const CategoryForm = () => {
    const { docStore } = useContext(Context)

    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm()

    register('categoryID', { 
        required: false,
        shouldUnregister: true,
        value: docStore.isCategoryEditing ? docStore.selectedCategory.id : '0'
    })

    const onSubmitClick = (form) => {
        const category = {}
        category.name = form.categoryName

        docStore.isCategoryEditing ? docStore.editCategory(category) : docStore.createCategory(category) 
    }

    const onCancelClick = () => {
        docStore.setIsCategoryCreating(false)
        docStore.setIsCategoryEditing(false)        
    }

    !docStore.isCategoryEditing && isSubmitting && !isValid && toast.error('Заполните обязательное поле', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })

    return (
        <form id='category-form' className='tw-flex tw-flex-col tw-w-full tw-h-[calc(100%_-_3rem)] tw-overflow-auto'>
            <div className='tw-h-12 tw-text-md tw-font-semibold tw-py-4 tw-px-4'>
                {docStore.isCategoryEditing ? 'Редактирование категории' : 'Новая категория'}
            </div>
            <div className='tw-grow'>
                <div className='tw-block tw-w-full tw-px-4'>
                    <div className='tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-x-2 tw-mt-2'>
                        <label className='tw-text-sm tw-font-medium tw-text-gray-800 tw-leading-6 tw-ml-2'>Название</label>
                        {errors.categoryName && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                    </div>
                    <input
                        className={`tw-w-full tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                            tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset 
                            focus:tw-z-10 sm:tw-text-sm focus-visible:tw-outline-none
                            ${errors.categoryName ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                        `}
                        {...register('categoryName', { 
                            required: true,
                            value: docStore.isCategoryEditing ? docStore.selectedCategory.name : ''
                        })}
                    />
                </div>
            </div>
            <div className='tw-flex tw-justify-center tw-w-full tw-items-center tw-gap-x-4 tw-mx-auto tw-py-4'>
                <button 
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700  tw-bg-gray-700 tw-text-white
                            hover:tw-bg-gray-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={handleSubmit(onSubmitClick)}
                >
                    Сохранить
                </button>
                <button
                    type='button'
                    className='tw-rounded-md tw-border-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-border-gray-700 tw-text-gray-700
                            hover:tw-bg-gray-200 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-600'
                    onClick={onCancelClick}
                >
                    Отменить
                </button>
            </div>
            <Tooltip id="importer-form-tooltip" place="top"/>
        </form>
    )
}

export default CategoryForm