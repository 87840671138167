import React, { useState, useRef } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { ArrowsPointingOutIcon, MinusIcon, PlusIcon } from '@heroicons/react/20/solid'

import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

const options = {
    cMapUrl: "/cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "/standard_fonts/",
}

/**
 * Визуальный компонент отображает содержимое файла документа в формате pdf, прикрепленного к записи
 * 
 * @param {Object} file Файл видео
 * @param {String} fileName Имя файла
 * @param {Function} onError Обработчик ошибки при загрузке страницы документа
 * [handleRenderError](./components_main_page_controller_data_object_view_ViewFileContainer.js.html#line33)
 * 
 * @returns {HTMLDivElement} Html-разметку для отображения содержимого файла документа в формате pdf, прикрепленного к записи
 * 
 * @see [Вызов компонента](./components_main_page_controller_education_viewers_EducationViewFileContainer.js.html#line91)
 */
const PDFViewer = ({file, fileName, onError}) => {
    const [numPages, setNumPages] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [pageScale, setPageScale] = useState(100)

    const frameRef = useRef(null)
    const docRef = useRef(null)

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages)
    }

    const handleScrollDocument = (e) => {
        if (numPages > 1) {
            const currentPage = Math.round(e.target.scrollTop / e.target.scrollHeight * numPages) + 1
            if (currentPage !== pageNumber)
                setPageNumber(currentPage)
        }
    }

    const handleScaleChange = (isIncreased) => {
        const nextPageScale = isIncreased ? pageScale + 10 : pageScale - 10
        
        if (nextPageScale > 0) {
            setPageScale(nextPageScale)
        }
    }

    const handleFitClick = () => {
        if (frameRef?.current?.offsetWidth > 0 && docRef?.current?.offsetWidth > 0) {
            const ratio = Math.round(frameRef?.current?.offsetWidth / docRef?.current?.offsetWidth * 1000) / 1000
            const fitScale = Math.round(pageScale * ratio / 10) * 10
            setPageScale(fitScale)
        }
    }

    return (
        <div id='pdf-viewer' className='tw-flex tw-flex-col tw-h-full tw-w-full'>
            <div className='tw-text-sm tw-text-center tw-h-8 tw-py-1 tw-text-white tw-bg-gray-600'>
                {fileName}
            </div>
            <div className='tw-grow tw-h-full tw-overflow-auto tw-bg-gray-300' ref={frameRef} onScroll={handleScrollDocument}>
                <Document
                    file={file}
                    noData='Не удалось загрузить файл.'
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={options}
                    className="tw-w-fit tw-px-4 tw-mx-auto"
                    inputRef={docRef}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <div className='tw-py-4 tw-bg-gray-300' key={index}>
                            <Page
                                className="tw-bg-gray-300"
                                pageNumber={index + 1}
                                noData='Страница не найдена.'
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                onRenderError={onError}
                                scale={pageScale / 100}
                            />
                        </div>
                    ))}
                </Document>
            </div>
            <div className='tw-text-xs tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-4 tw-border-t tw-border-gray-300'>
                <div className='tw-text-left tw-py-1'>
                        Страница {pageNumber} из {numPages}
                </div>
                <div className='tw-flex tw-flex-row tw-justify-end tw-items-center'>
                    <button 
                        className='tw-mr-3 tw-border tw-text-gray-500 hover:tw-text-gray-800 tw-border-gray-400 hover:tw-border-gray-800
                                focus-visible:tw-text-gray-800 focus-visible:tw-outline focus-visible:tw-outline-2
                                focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-800 focus-visible:tw-rounded-sm'
                        type='button'
                        onClick={handleFitClick}
                    >
                        <ArrowsPointingOutIcon  className="tw-w-4 tw-h-4" aria-hidden="true"/>
                    </button>
                    <button 
                        className='tw-text-gray-500 hover:tw-text-gray-800 focus-visible:tw-text-gray-800 focus-visible:tw-outline 
                                focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-800 focus-visible:tw-rounded-sm'
                        type='button'
                        onClick={() => handleScaleChange(false)}
                    >
                        <MinusIcon  className="tw-w-4 tw-h-4" aria-hidden="true"/>
                    </button>
                    <span className='tw-w-24 tw-text-center tw-select-none'>Масштаб {pageScale + '%'}</span>
                    <button 
                        className='tw-text-gray-500 hover:tw-text-gray-800 focus-visible:tw-text-gray-800 focus-visible:tw-outline 
                                focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-gray-800 focus-visible:tw-rounded-sm'
                        type='button'
                        onClick={() => handleScaleChange(true)}
                    >
                        <PlusIcon  className="tw-w-4 tw-h-4" aria-hidden="true"/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PDFViewer