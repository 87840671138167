import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm, Controller } from 'react-hook-form'
import DocumentService from '../../../../../services/DocumentService'
import ImporterService from '../../../../../services/ImporterService'
import Spinner from '../../../../../assets/Spinner'
import { toast } from 'react-toastify'
import { ExclamationCircleIcon, TrashIcon } from '@heroicons/react/20/solid'
import DataTypeListBox from '../../common/inputs/DataTypeListBox'
import FileSelectButton from '../../common/buttons/FileSelectButton'
import { responseTimeOut, serviceMessageTimeOut } from '../../../../../config/constTypes'
import { showErrorToast } from '../../../../../functions/errorHandlers'
import { Context } from '../../../../..'
  
/**
 * Визуальный компонент отображает модальное окно импорта данных из файла формата ODS/XLSX
 * с выбором шаблона из выпадающего списка существующих шаблонов импорта
 * @param {Boolean} isVisible Признак видимости (отображения) модального окна
 * @param {Object} dataModel Таблица, в которую импортируются записи
 * @param {Object[]} files Файл(ы) с данными
 * @param {Function} onFileSelect Обработчик клика мыши на кнопке выбора файла для загрузки на сервер
 * [handleFileSelect](./functions_fileHandlers.js.html#line35)
 * @param {Function} onFileRemove Обработчик клика мыши на кнопке удаления загруженного на сервер файла
 * [handleFileRemove](./functions_fileHandlers.js.html#line74)
 * @param {Function} onSubmitClick Обработчик клика мыши на кнопке выполнения импорта
 * [handleImportTableClick](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line242)
 * @param {Function} onCloseModal Обработчик клика мыши на кнопке отмены импорта
 * [handleImportModalClose](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line303)
 * @returns {JSXElement} Визуальный компонент Dialog из библиотеки {@link https://headlessui.com/|@headlessui} 
 * с использованием визуальных компонентов {@link DataTypeListBox}, {@link Spinner}, {@link FileSelectButton}
 * 
 * @see [Вызов компонента](./components_main_page_controller_data_object_DataObjectListContainer.js.html#line568)
 */
const ImportFromFileModal = ({isVisible, dataModel, files, onFileSelect, onFileRemove, onSubmitClick, onCloseModal}) => {
    const { userStore } = useContext(Context)
    const {
        control,
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        formState: { errors, isSubmitting, isValid },
    } = useForm()

    const [isLoading, setIsLoading] = useState(false)
    const [importers, setImporters] = useState([])
    const [rowCountValidate, setRowCountValidate] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const focusRef = useRef(null)

    const watchTemplate = watch('template', {id: '0', data: { key1: {value: ''}}})

    const keydownHandler = ({ key }) => {
        switch (key) {
          case 'Escape':
            onCloseModal();
            break;
          default:
        }
      };  

    
    const getDataModelImporters = async (id) => {
        try {
            const isDataModelLocked = await DocumentService.checkDataModelLock(id)

            if (isDataModelLocked) {
                toast.warning('Таблица уже заблокирована процессом импорта!', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                onCloseModal()  // закрытие модального окна импорта данных из файла
            } else {

                const noResponse = setTimeout(() => {
                    toast.error('Сервис импорта не отвечает', { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
                    setIsLoading(false)
                }, responseTimeOut)
                
                const filter = JSON.stringify([
                    {property: 'meta.data_model_id', value: id, operator: 'eq'},
                ])

                setIsLoading(true)
                ImporterService
                    .getAllImporters(filter)
                    .then(data => {
                        clearTimeout(noResponse)
                        setImporters(data)
                    })
                    .catch(error => {
                        clearTimeout(noResponse)
                        showErrorToast(error, 'import', '') 
                    })
                    .finally(setIsLoading(false))
            }
        } catch (error) {
            if (error.response) {
                showErrorToast(error, 'fetching', '') 
            }   else {
                toast.error('Отсутствует соединение с сервером!', { position: toast.POSITION.TOP_CENTER })   
            }
        }    
    }

    
    useEffect(() => {
        if (isVisible) {
            getDataModelImporters(dataModel.id)
        } else {
            setImporters([])
        }
    }, [isVisible])

    useEffect(() => {
        if(errorMessage)
            toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER, autoClose: serviceMessageTimeOut })
    }, [errorMessage])

    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    });


    if (isLoading) {
        return  <Spinner/>
    }

    isSubmitting && !isValid && toast.error('Выберите шаблон импорта и укажите правильную конфигурацию!', { position: toast.POSITION.TOP_CENTER, autoClose: 1000 })   

    return (
        <Transition.Root show={isVisible} as={Fragment}>
            <Dialog as="div" className="tw-relative tw-z-10" initialFocus={focusRef} onClose={onCloseModal}>
                <Transition.Child
                    as={Fragment}
                    enter="tw-ease-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                >
                    <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </Transition.Child>

                <div className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto">
                    <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="tw-ease-out tw-duration-300"
                            enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                            enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leave="tw-ease-in tw-duration-200"
                            leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        >
                            <Dialog.Panel className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-text-left tw-shadow-xl 
                                                    tw-transition-all sm:tw-w-full sm:tw-max-w-md">
                                <form>
                                    <div className="tw-w-full tw-flex tw-flex-col tw-items-start tw-bg-white tw-p-4">
                                        <Dialog.Title as="h3" className="tw-text-lg tw-font-semibold tw-mb-6 tw-text-gray-900">
                                            Импорт данных из файла
                                        </Dialog.Title>
                                        <div className="tw-flex tw-flex-row tw-items-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-px-6">
                                            Файл с данными
                                        </div>
                                        <div className='tw-w-full'>
                                            { !files.length &&
                                                <FileSelectButton
                                                    onFileSelect={onFileSelect}
                                                />
                                            }
                                            <ul>
                                                {files.map((file, index) => (
                                                    <div key={index} className='tw-flex tw-flex-row tw-justify-start tw-items-center tw-border tw-border-gray-400 tw-rounded-md tw-mt-2'>
                                                        {file.isLoading
                                                            ?
                                                                <div className='tw-w-24 tw-pl-6'>
                                                                    <Spinner size='small'/>
                                                                </div>
                                                            :
                                                                <div className='tw-flex tw-justify-center tw-gap-x-4 tw-w-16'>
                                                                    <button 
                                                                        className='tw-text-gray-400 hover:tw-text-red-600 focus-visible:tw-text-red-600 focus-visible:tw-outline 
                                                                                focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-red-600 focus-visible:tw-rounded-sm'
                                                                        type='button'
                                                                        onClick={() => onFileRemove(file)}
                                                                    >
                                                                        <TrashIcon  className="tw-w-6 tw-h-6" aria-hidden="true"/>
                                                                    </button>
                                                                </div>
                                                        }
                                                        <li
                                                            className="tw-relative tw-rounded-md tw-mb-1 tw-px-3 tw-py-1 tw-border-l tw-truncate"
                                                        >
                                                            <h3 className="tw-text-sm tw-font-medium tw-leading-5 tw-py-2 tw-truncate">
                                                                {file.metadata.name}.{file.metadata.extension}
                                                            </h3>
                                                        </li>
                                                    </div>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="tw-flex tw-flex-col tw-w-full tw-mt-4 tw-p-4 tw-border tw-border-gray-400 tw-rounded-md">
                                            <div className='tw-text-sm tw-font-semibold tw-text-gray-900 tw-ml-2'>
                                                Конфигурация импорта
                                            </div>
                                            <div className="tw-flex tw-flex-col tw-w-full tw-my-4 tw-bg-beige tw-rounded-md">
                                                <div className='tw-mt-1'>
                                                    <Controller
                                                        name={'template'}
                                                        control={control}
                                                        rules={ {required: true, validate: value => value.id !== '0'} }
                                                        defaultValue={{id: '0', data: { key1: {value: ''}}}}
                                                        shouldUnregister={true}
                                                        render={({field}) =>
                                                            <DataTypeListBox
                                                                label={'Шаблон'}
                                                                itemList={importers}
                                                                selectedItem={field.value}
                                                                onItemChange={(e) => field.onChange(e)}
                                                                error={errors.template}
                                                                selector='name'
                                                            />}
                                                    />
                                                </div>
                                            </div>
                                            <div className='tw-w-full'>
                                                <div className='tw-w-96 tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-1'>
                                                    <dt className="tw-flex tw-flex-row tw-items-center tw-text-sm tw-font-medium tw-text-gray-900">
                                                        <span>Номер листа</span>
                                                        {errors.sheetNumber && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                                                    </dt>
                                                    <dd className="tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                        <input
                                                            type='number'
                                                            min={1}
                                                            max={50}
                                                            className={`tw-w-24 tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 
                                                                tw-text-sm focus-visible:tw-outline-none
                                                                ${errors.sheetNumber ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' 
                                                                    : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                                            `}
                                                            {...register('sheetNumber', { 
                                                                    required: false,
                                                                    min:1,
                                                                    max:50,
                                                                    value: 1,
                                                                    valueAsNumber: true,
                                                                    validate: v => Number.isInteger(v),
                                                            })}
                                                        />
                                                    </dd>
                                                </div>
                                                <div className='tw-w-96 tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-1'>
                                                    <dt className="tw-flex tw-flex-row tw-items-center tw-text-sm tw-font-medium tw-text-gray-900">
                                                        <span>Заголовок</span>
                                                        {errors.headerRowCount && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                                                    </dt>
                                                    <dd className="tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                        <input
                                                            type='number'
                                                            min={1}
                                                            max={65535}
                                                            className={`tw-w-24 tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 
                                                                tw-text-sm focus-visible:tw-outline-none
                                                                ${errors.headerRowCount ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' 
                                                                    : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                                            `}
                                                            {...register('headerRowCount', { 
                                                                required: false,
                                                                min:1,
                                                                max:65535,
                                                                value: 1,
                                                                valueAsNumber: true,
                                                                validate: v => Number.isInteger(v),
                                                            })}
                                                        />
                                                    </dd>
                                                </div>
                                                <div className='tw-w-96 tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-1'>
                                                    <dt className="tw-flex tw-flex-row tw-items-center tw-text-sm tw-font-medium tw-text-gray-900">
                                                        <span>Начать со строки</span>
                                                        {errors.startRowNumber && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                                                    </dt>
                                                    <dd className="tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                        <input
                                                            type='number'
                                                            min={1}
                                                            max={65535}
                                                            className={`tw-w-24 tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 
                                                                tw-text-sm focus-visible:tw-outline-none
                                                                ${errors.startRowNumber ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' 
                                                                    : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                                            `}
                                                            {...register('startRowNumber', { 
                                                                required: false,
                                                                min:1,
                                                                onChange: v => {setValue('endRowNumber', parseInt(v.target.value) + 99); setRowCountValidate(true)},
                                                                max:65535,
                                                                value: 2,
                                                                valueAsNumber: true,
                                                                validate: v => Number.isInteger(v),
                                                            })}
                                                        />
                                                    </dd>
                                                </div>
                                                <div className='tw-w-96 tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-1'>
                                                    <dt className="tw-flex tw-flex-row tw-items-center tw-text-sm tw-font-medium tw-text-gray-900">
                                                        <span>Закончить строкой</span>
                                                        {errors.endRowNumber && <ExclamationCircleIcon className="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true"/>}
                                                    </dt>
                                                    <dd className="tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                        <input
                                                            type='number'
                                                            min={1}
                                                            className={`tw-w-24 tw-rounded-md tw-border-0 tw-mt-1 tw-px-2 tw-py-1.5 tw-text-gray-700 
                                                                tw-ring-1 tw-ring-inset focus:tw-ring-2 focus:tw-ring-inset focus:tw-z-10 
                                                                tw-text-sm focus-visible:tw-outline-none
                                                                ${errors.endRowNumber ? 'tw-ring-red-400 focus-visible:tw-ring-offset-red-400' 
                                                                    : 'tw-ring-gray-400 focus-visible:tw-ring-offset-gray-400'}
                                                            `}
                                                            {...register('endRowNumber', { 
                                                                required: false,
                                                                onChange: v => {
                                                                    if (userStore.user.is_staff) {
                                                                        if(parseInt(v.target.value) >= (getValues("startRowNumber"))){
                                                                            setValue('endRowNumber', v.target.value)
                                                                            setRowCountValidate(true)
                                                                        } else {
                                                                            setRowCountValidate(false)
                                                                        }
                                                                    } else {
                                                                        if(parseInt(v.target.value) <= (getValues("startRowNumber") + 99)){
                                                                            setValue('endRowNumber', v.target.value)
                                                                            setRowCountValidate(true)
                                                                        } else {
                                                                            setErrorMessage('В бета-версии можно импортировать не более 100 строк')
                                                                            setRowCountValidate(false)
                                                                        }
                                                                    }
                                                                },
                                                                valueAsNumber: true,
                                                                validate: {
                                                                    positive: v => (Number.isInteger(v) && v > 0 && v > getValues("startRowNumber") &&
                                                                    (
                                                                        v <= (getValues("startRowNumber") + 99) 
                                                                        || userStore.user.is_staff 
                                                                    )),
                                                                },
                                                            })}
                                                        />
                                                    </dd>
                                                </div>
                                            </div>
                                            {/* <div>
                                                <div className='tw-w-96 tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-2'>
                                                    <dt className="tw-flex tw-flex-row tw-items-center tw-text-sm tw-font-medium tw-text-gray-900">
                                                        <span>Пропуск строк с ошибками</span>
                                                    </dt>
                                                    <dd className="tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                        <Controller
                                                            name={'isSkipError'}
                                                            control={control}
                                                            defaultValue={false}
                                                            render={({field}) =>
                                                                <DataSwitch 
                                                                    isChecked={field.value}
                                                                    onClick={(e) => field.onChange(e)}
                                                                    label={''}
                                                                />}
                                                        />
                                                    </dd>
                                                </div>
                                                <div className='tw-w-96 tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-2'>
                                                    <dt className="tw-flex tw-flex-row tw-items-center tw-text-sm tw-font-medium tw-text-gray-900">
                                                        <span>Обновлять существующие</span>
                                                    </dt>
                                                    <dd className="tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0">
                                                        <Controller
                                                            name={'isUpdateRecord'}
                                                            control={control}
                                                            defaultValue={false}
                                                            render={({field}) =>
                                                                <DataSwitch 
                                                                    isChecked={field.value}
                                                                    onClick={(e) => field.onChange(e)}
                                                                    label={''}
                                                                />}
                                                        />
                                                    </dd>
                                                </div>
                                            </div> */}
                                        </div>
                                        {!userStore.user.is_staff &&
                                            <p className="tw-text-sm tw-font-medium tw-text-red-400 tw-m-2">
                                                В бета-версии можно импортировать не более 100 строк
                                            </p>
                                        }
                                        <div className=" tw-w-full tw-h-12 tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-x-2 tw-mt-2 sm:tw-px-8">
                                            <button
                                                className="tw-inline-flex tw-justify-center tw-border-2 tw-border-gray-700 tw-rounded-md 
                                                        tw-bg-gray-700 tw-mx-2 tw-my-2 tw-px-3 tw-py-1 tw-text-sm tw-font-semibold
                                                        tw-text-white tw-shadow-sm hover:tw-bg-gray-500 disabled:tw-bg-gray-300 disabled:tw-border-gray-300"
                                                disabled={!files.length || watchTemplate.id === '0' || !rowCountValidate}
                                                onClick={handleSubmit(onSubmitClick)}
                                            >
                                                Загрузить
                                            </button>
                                            <button
                                                ref={focusRef}
                                                type="button"
                                                className="tw-inline-flex tw-justify-center tw-border-2 tw-border-gray-700 tw-rounded-md 
                                                        tw-bg-white tw-mx-2 tw-px-3 tw-my-2 tw-py-1 tw-text-sm tw-font-semibold
                                                        tw-text-gray-700 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-200"
                                                onClick={onCloseModal}
                                            >
                                                Отменить
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ImportFromFileModal