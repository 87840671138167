import React, { useContext, useEffect, useState } from 'react'
import SubCompanyItem from './SubCompanyItem'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import { isScrolledIntoView } from '../../../functions/isScrolledIntoView'
import SearchInput from '../../search/SearchInput'
import FilterStore from '../../../store/FilterStore'

/**
 * Визуальный компонент отображает список существующих юр лиц
 *
 * @returns {JSXElement} Html-разметку списка существующих юр лиц с использованием визуальных компонентов 
 * {@link SubCompanyItem}, {@link SearchInput}
 * 
 * @see [Вызов компонента](./components_company_admin_page_CompanyAdminContainer.js.html#line39)
 */
const SubCompanyList = () => {
    const { CompanyAdminStore } = useContext(Context)
    const [filterStore] = useState(() => new FilterStore())
    const [searchResults, setSearchResults] = useState(CompanyAdminStore.subCompanies)

    let className = `tw-flex tw-flex-col tw-h-[calc(100%_-_6rem)] tw-w-full tw-overflow-auto`,
        searchIsDisabled = false

    if (CompanyAdminStore.isSubCompanyCreating || CompanyAdminStore.isSubCompanyEditing || CompanyAdminStore.isUserCreating || CompanyAdminStore.isUserEditing) {
        className += ` tw-pointer-events-none tw-opacity-50`
        searchIsDisabled = true
    }
    
    const handleChange = () => {
        if (filterStore.searchField !== "") {
            let searchArray = filterStore.searchField.split(" ")
            const results = CompanyAdminStore.subCompanies.filter(subCompany => {
                let matches = 0
                searchArray.forEach(item => {
                    if (subCompany.abbreviation?.toLowerCase().includes(item.trim().toLowerCase())
                        || subCompany.name?.toLowerCase().includes(item.trim().toLowerCase())
                        || subCompany.msrn?.toLowerCase().includes(item.trim().toLowerCase())
                        || subCompany.tin?.toLowerCase().includes(item.trim())
                    ) {
                        matches++
                    }
                })
                if (matches === searchArray.length) {
                    return true
                }
            })
            setSearchResults(results)
        } else {
            setSearchResults(CompanyAdminStore.subCompanies)
        }
    }

    useEffect(() => {
        handleChange()
    }, [CompanyAdminStore.subCompanies])

    useEffect(() => {
        if (CompanyAdminStore.selectedSubCompany?.id) {
            const element = document.getElementById(`company-item-${CompanyAdminStore.selectedSubCompany.id}`) 
            if (element && !isScrolledIntoView(element))
                element.scrollIntoView({ behavior: 'smooth' })
        }
    }, [CompanyAdminStore.selectedSubCompany])

    return (
        <>
            <div className='tw-p-2'>
                <SearchInput
                    isDisabled={searchIsDisabled}
                    filterStore={filterStore}
                    selectedFullDataModel={{id: "sub-companies", fields: []}}
                    updateDataObjectList={handleChange}
                />
            </div>
            <div id='sub-company-list-items' className={className}>
                {searchResults.slice().sort((a) => a.is_active ? -1 : 1).map((company) => 
                    <SubCompanyItem
                        key={company.id}
                        item={company}
                    />
                )}
            </div>
        </>
    )
}

export default observer(SubCompanyList)