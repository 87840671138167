import React from 'react'
import { DocumentCheckIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'


/**
 * Визуальный компонент отображает элемент списка
 * 
 * @param {Object} item Текущий элемент списка 
 * @param {Object} selectedItem Выбранный элемент списка
 * @param {Object} linkingItem Элемент, связанный с выбранным элементом списка
 * @param {String} linkField Имя поля, по которому выполняется связывание
 * @param {Function} onClick Обработчик клика мыши на элементе списка
 * [handleEmployeeClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line275),
 * [handlePositionClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line288),
 * [handleSubdivisionClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line298)
 * @param {Function} onLinkClick Обработчик клика мыши на элементе списка, связанном с выбранным элементом списка
 * [handleEmployeeLinkClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line284),
 * [handleEmployeeLinkClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line284),
 * [handleEmployeeLinkClick](./components_main_page_controller_categorizing_cii_form_employees_EmployeesDataForm.js.html#line284)
 * 
 * @returns {HTMLDivElement} Html-разметку с элементом списка
 * 
 * @see [Вызов компонента](./components_main_page_controller_categorizing_cii_form_employees_list_SimpleItemList.js.html#line34)
 */
const SimpleItem = ({item, selectedItem, linkingItem, linkField, onClick, onLinkClick}) => {

    const isItemChecked = linkingItem && linkField && (item[linkField] ? item[linkField] === linkingItem.record_id : linkingItem[linkField] === item.record_id)

    let className = 'tw-relative tw-pl-12 tw-text-sm tw-select-none tw-border-b tw-border-gray-400'

    if (selectedItem && selectedItem.record_id === item.record_id) {
        className += ' tw-text-white tw-bg-gray-500'
    } else {
        className += ` tw-text-gray-900 tw-bg-white hover:tw-bg-gray-200 hover:tw-border-gray-300 hover:tw-shadow-lg
                        hover:tw-border-x hover:tw-border-b-0`
    }


    return (
        <div
            id={item.record_id}
            className={className}
        >
            { linkingItem &&
                <div
                    className='tw-text-gray-500 tw-absolute tw-left-3 tw-py-1.5'
                    onClick={() => onLinkClick(item.value_record_id)}
                >
                    { isItemChecked
                        ?   <DocumentCheckIcon className='tw-w-5 tw-h-5' aria-hidden='true'/>
                        :   null
                    }
                </div>
            }
            { !linkingItem && item.errors && item[linkField] && !item[linkField].length &&
                <div className='tw-absolute tw-left-3 tw-py-1.5'>
                    <ExclamationCircleIcon className='tw-w-5 tw-h-5 tw-text-red-500' aria-hidden='true'/>
                </div>
            }
            <div
                className='tw-w-full tw-py-1.5 hover:tw-cursor-pointer'
                onClick={() => onClick(item)}
            >
                { item.data['name'].value } 
            </div>
        </div>
    )
}

export default SimpleItem